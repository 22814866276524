// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-no-padding {
  padding-bottom: 0px;
}

.lang-container {
  padding: 8px 25px 23px 25px;
}

.lang-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-header-logo {
  display: flex;
}

.lang-title {
  font-size: 16px;
}

.lang-submitBtn {
  background-color: #367419 !important;
  border: 1px solid #367419;
  border-radius: 4px;
  width: 100% !important;
  box-shadow: unset !important;
  height: 41px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  margin-top: 20px !important;
}

.lang-divider {
  margin: 12px 0px !important;
}

.css-1k0kydq-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #0383A4 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageDrawer/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,4BAA4B;EAC5B,uBAAuB;EACvB,0BAA0B;EAC1B,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".menu-no-padding {\n  padding-bottom: 0px;\n}\n\n.lang-container {\n  padding: 8px 25px 23px 25px;\n}\n\n.lang-header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.menu-header-logo {\n  display: flex;\n}\n\n.lang-title {\n  font-size: 16px;\n}\n\n.lang-submitBtn {\n  background-color: #367419 !important;\n  border: 1px solid #367419;\n  border-radius: 4px;\n  width: 100% !important;\n  box-shadow: unset !important;\n  height: 41px !important;\n  font-size: 14px !important;\n  font-weight: 700 !important;\n  margin-top: 20px !important;\n}\n\n.lang-divider {\n  margin: 12px 0px !important;\n}\n\n.css-1k0kydq-MuiButtonBase-root-MuiRadio-root.Mui-checked {\n  color: #0383A4 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
