import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";

const FormInput = ({
  name,
  inputLabel,
  inputClassName,
  labelClassName,
  inputType,
  children,
  list,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, ...field } }) => (
        <>
          {inputLabel && (
            <InputLabel className={labelClassName}>{inputLabel}</InputLabel>
          )}
          {inputType === "mobile" ? (
            <>
              <PhoneInput
                {...field}
                {...otherProps}
                inputExtraProps={{
                  ref,
                }}
                className={`${inputClassName} ${
                  !!errors[name] && "mobileBorderError"
                } `}
              />
              {!!errors[name] && (
                <p
                  className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root"
                  id=":r1:-helper-text"
                >
                  {errors[name] ? errors[name].message : ""}
                </p>
              )}
            </>
          ) : inputType === "select" ? (
            <TextField
              {...otherProps}
              {...field}
              select
              className={inputClassName}
              error={!!errors[name]}
              helperText={errors[name] ? errors[name].message : ""}
            >
              {children}
            </TextField>
          ) : inputType === "autocomplete" ? (
            <Autocomplete
              className={`${inputClassName} ${
                !!errors[name] && "extraPadding"
              } `}
              options={list}
              renderInput={(params) => (
                <TextField
                  {...otherProps}
                  {...field}
                  {...params}
                  className=""
                  error={!!errors[name]}
                  helperText={errors[name] ? errors[name].message : ""}
                />
              )}
            />
          ) : (
            <TextField
              {...otherProps}
              {...field}
              className={inputClassName}
              error={!!errors[name]}
              helperText={errors[name] ? errors[name].message : ""}
            />
          )}
        </>
      )}
    />
  );
};

export default FormInput;
