import { useContext, useEffect, useState } from "react";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  Toolbar,
  Button,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import Cross from "assets/icons/cross-blue.svg";

import I18nContext from "context/I18nContext";

import "./style.css";
import { Box } from "@mui/system";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiDialogContent-root": {
    border: "unset",
    padding: 0,
  },
  "& .MuiToolbar-root": {
    padding: 0,
    minHeight: "unset",
    marginBottom: "24px",
  },
  "& .MuiFormControlLabel-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
    marginTop: "30px",
  },
  "& .MuiPaper-root": {
    padding: "20px",
    width: "100%",
    margin: "25px",
  },
  "& .MuiTypography-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiButtonBase-root": {
    padding: 0,
    paddingInlineEnd: "13px",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            ...(document.body.dir === "rtl" ? { left: 8 } : { right: 8 }),
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function DialogComponent({ open, setOpen }) {
  const { setLanguage, language } = useContext(I18nContext);
  const [value, setValue] = useState(language.lang);
  const { t } = useTranslation(["common"]);

  const handleChangeLang = () => {
    setLanguage(value);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue(language.lang);
  }, [language.lang]);

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {t("Select your language")}
            </Typography>
            <Box onClick={handleClose} className="popupIcon">
              <img src={Cross} alt={t("header logo")} />
            </Box>
          </Toolbar>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label="English"
              />
              <Divider className="divider" />
              <FormControlLabel
                value="ar"
                control={<Radio />}
                label="العربية"
              />
              <Divider className="divider" />
              <FormControlLabel
                value="fr"
                control={<Radio />}
                label="Français"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            className="submitBtn"
            variant="contained"
            onClick={handleChangeLang}
          >
            {t("Save Changes")}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
