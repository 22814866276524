
import UseWidth from "hooks/useUpdateWindowDimensions";

const Search = () => {
    const width = UseWidth()
    if (width < 700)
    return (
        <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0.5H36C37.933 0.5 39.5 2.067 39.5 4V32C39.5 33.933 37.933 35.5 36 35.5H0.5V0.5Z" fill="#F3F3F3" stroke="#DFDFDF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.85 20.0166C13.9024 19.0682 13.3702 17.7823 13.3702 16.4416C13.3702 15.101 13.9024 13.8151 14.85 12.8666V12.8666C15.5615 12.1552 16.468 11.6705 17.4548 11.4739C18.4416 11.2772 19.4646 11.3773 20.3945 11.7616C21.3245 12.1459 22.1197 12.7971 22.6798 13.633C23.24 14.4689 23.5398 15.4521 23.5416 16.4583C23.5444 17.1249 23.4139 17.7852 23.1577 18.4006C22.9015 19.016 22.5249 19.5739 22.05 20.0416C21.5761 20.5137 21.0137 20.8876 20.395 21.1419C19.7764 21.3962 19.1136 21.5259 18.4448 21.5236C17.7759 21.5213 17.1141 21.3869 16.4972 21.1284C15.8803 20.8698 15.3205 20.492 14.85 20.0166ZM28.3333 25.1416L23.7833 20.6C24.7255 19.4159 25.2368 17.9465 25.2333 16.4333C25.2274 14.9114 24.7068 13.4363 23.7562 12.2479C22.8055 11.0594 21.4807 10.2277 19.9972 9.88779C18.5137 9.54793 16.9589 9.71999 15.5857 10.376C14.2124 11.032 13.1016 12.1334 12.4337 13.5009C11.7659 14.8684 11.5805 16.4217 11.9075 17.908C12.2346 19.3944 13.0549 20.7263 14.2351 21.6872C15.4153 22.6481 16.8859 23.1814 18.4077 23.2003C19.9294 23.2193 21.4129 22.7228 22.6166 21.7916L27.1333 26.3333L28.3333 25.1416Z" fill="black" />
        </svg>

    )

    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 0.5H40C41.933 0.5 43.5 2.067 43.5 4V40C43.5 41.933 41.933 43.5 40 43.5H0.5V0.5Z" fill="#F3F3F3" stroke="#DFDFDF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.3648 24.1571C15.3363 23.1296 14.7586 21.7366 14.7586 20.2843C14.7586 18.8319 15.3363 17.4389 16.3648 16.4115C17.1372 15.6407 18.121 15.1157 19.1921 14.9027C20.2633 14.6896 21.3736 14.7981 22.383 15.2144C23.3924 15.6307 24.2556 16.3361 24.8636 17.2417C25.4715 18.1472 25.797 19.2123 25.799 20.3023C25.802 21.0244 25.6603 21.7398 25.3822 22.4065C25.1042 23.0731 24.6954 23.6775 24.1799 24.1842C23.6655 24.6956 23.0551 25.1006 22.3836 25.3761C21.7121 25.6516 20.9927 25.7921 20.2667 25.7896C19.5407 25.7871 18.8223 25.6416 18.1527 25.3614C17.4832 25.0813 16.8756 24.672 16.3648 24.1571ZM31 29.7091L26.0613 24.789C27.084 23.5063 27.639 21.9145 27.6352 20.2753C27.6288 18.6266 27.0637 17.0286 26.0318 15.7411C24.9999 14.4537 23.5619 13.5526 21.9518 13.1844C20.3416 12.8163 18.6539 13.0027 17.1634 13.7133C15.6728 14.424 14.467 15.6171 13.7422 17.0986C13.0173 18.58 12.816 20.2627 13.171 21.8728C13.526 23.483 14.4164 24.9259 15.6974 25.9668C16.9784 27.0078 18.5747 27.5855 20.2264 27.606C21.8782 27.6266 23.4884 27.0887 24.795 26.08L29.6975 31L31 29.7091Z" fill="black" />
        </svg>

    )
}

export default Search;