// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottom-chevron {
    clip-path: polygon(50% 6px, 100% 0, 100% calc(100% - 6px), 50% 100%, 0 calc(100% - 6px), 0 0);
    min-height: 40px;
    width: 12px;
    height: 100%;    
}

.divider-wrapper {
    height: 16px;
    width: 100%;
    display: flex;
}

.divider-wrapper .divider-wrapper--line {
    flex: 1 1;
    height: 2px;
    align-self: center;
}
.divider-wrapper .divider-wrapper--circle {
    width: 16px;
    border-radius: 50%;
    border: 3px solid;
}`, "",{"version":3,"sources":["webpack://./src/pages/FamilyMemories/components/style.css"],"names":[],"mappings":"AAAA;IACI,6FAA6F;IAC7F,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".bottom-chevron {\n    clip-path: polygon(50% 6px, 100% 0, 100% calc(100% - 6px), 50% 100%, 0 calc(100% - 6px), 0 0);\n    min-height: 40px;\n    width: 12px;\n    height: 100%;    \n}\n\n.divider-wrapper {\n    height: 16px;\n    width: 100%;\n    display: flex;\n}\n\n.divider-wrapper .divider-wrapper--line {\n    flex: 1;\n    height: 2px;\n    align-self: center;\n}\n.divider-wrapper .divider-wrapper--circle {\n    width: 16px;\n    border-radius: 50%;\n    border: 3px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
