// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.category-list .inner-div {
    height: 244px;
    width: 341px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    margin: auto;
    padding: 0!important;
}

.category-list-headline {
    margin-bottom: 0!important;
}
.category-list-list {
    margin: 0!important;
    row-gap: 24px;
}
.category-list-list > div {
    padding: 0!important;
    /* flex: 1 1 auto; */
}

.category-list-list > div > div {
    gap: 8px;
}

.category-list-list > div > div > div {
    line-height: 1;
}

.category-list-list > div > div > div svg {
    width: unset;
    height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/SelectCategory/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,SAAS;IACT,sBAAsB;IACtB,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,0BAA0B;AAC9B;AACA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,oBAAoB;IACpB,oBAAoB;AACxB;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".category-list {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    gap: 8px;\n}\n\n.category-list .inner-div {\n    height: 244px;\n    width: 341px;\n    display: flex;\n    gap: 16px;\n    flex-direction: column;\n    margin: auto;\n    padding: 0!important;\n}\n\n.category-list-headline {\n    margin-bottom: 0!important;\n}\n.category-list-list {\n    margin: 0!important;\n    row-gap: 24px;\n}\n.category-list-list > div {\n    padding: 0!important;\n    /* flex: 1 1 auto; */\n}\n\n.category-list-list > div > div {\n    gap: 8px;\n}\n\n.category-list-list > div > div > div {\n    line-height: 1;\n}\n\n.category-list-list > div > div > div svg {\n    width: unset;\n    height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
