import React from "react";
import CATEGORIES from "../../../../data/categories";
import Plus from "./PlusIcon";
import "./style.css"
import UseWidth from "hooks/useUpdateWindowDimensions";
import { useTranslation } from "react-i18next";


const Cover = ({ category, uploadMedia, showActions }) => {
    const { t } = useTranslation(["add-event"]);

    const width = UseWidth();
console.log(CATEGORIES[category])
    return (
        
        <div className="cover-wrapper">
            {width < 900 && CATEGORIES[category]?.Cover}
            {width >= 900 && CATEGORIES[category]?.desktopCover}
            {showActions &&<button className="upload-media-btn" onClick={uploadMedia}>
                <Plus /> {t("PHOTOS/AUDIO")}
            </button>}
        </div>
    )
}

export default Cover;