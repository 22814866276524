/* eslint-disable react-hooks/exhaustive-deps */
import React ,{ useCallback, useEffect } from "react";
import FamilySearchRouter from "router";
import NotAuthRouter from "router/NotAuthRouter";
import { useState } from "react";
import UserContext from "context/UserContext";
import customtheme from "./theme/customTheme";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import I18Provider from "components/I18nProvider/I18nProviderComponent";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Storage from "../src/helpers/Storage";
import { useDispatch, useSelector } from 'react-redux';
import { websiteIstance, websiteIstanceFormData } from "./api/axios";
import { TIMELINES, AUTHENTICATE_BY_FS } from "./api/endpoints";
import { removeTimeLine } from "redux/slices/myTimeLinesSlice";
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";
import { clearMemories, setTemorarilyEventFormData } from "redux/slices/familyMemoriesSlice";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import LoginRegisterModal from "components/LoginRegisterModal/LoginRegisterModal";
import 'moment/min/locales';
import FullScreenLoader from "./components/Loader/FullScreenLoader";
import { useNavigate, useLocation } from "react-router-dom";
import Analytics from "../src/helpers/analytics";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary', error, errorInfo);
  }

  render() {

    return this.props.children;
  }
}


function App() {
  const [user, setUser] = useState();
  const [isFullLoading, setLoadingFull] = useState(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const myTimeLinesInfo = useSelector((state) => state.myTimeLines.data);
  const appReducer = useSelector((state) => state.app);
  const myFamilyMemoriesInfo = useSelector((state) => state.familyMemories.familyMemories);
  const myFamilyMemoriesInfoList = Object.values(myFamilyMemoriesInfo)
  const localFamilyMemories = myFamilyMemoriesInfoList.filter(timeline => (timeline.isOffline || !timeline.size))
  const timelinesList = myTimeLinesInfo.timelines || [];
  const localTimeLines = timelinesList.filter(timeline => !timeline.creator_id)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.dir = i18n.dir();
    if (i18n.dir() === 'rtl') {
      moment.locale("ar")
    } else {
      moment.locale("en")

    }
  }, [i18n]);

  const authByFamilySearch = async() => {
    try {
      var code = new URLSearchParams(window.location.search).get("code");
      if (!code) {
        setup()
        return
      }
      const {
        data
      } = await websiteIstance.post(AUTHENTICATE_BY_FS, {
        authorizationCode: code
      });
      const userData = data.user;
      // const userData = {
      //   userId: 2,
      //   accessToken: "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJjaXMudXNlci5NTVMyLVRRTTgiLCJjb3VudHJ5IjoiUFMiLCJnZW5kZXIiOiJNIiwiaXNzIjoiaHR0cHM6Ly9pZGVudC5mYW1pbHlzZWFyY2gub3JnL3NlcnZpY2UvaWRlbnQvY2lzL2Npcy13ZWIvb2F1dGgyL3YzIiwibGFuZ3VhZ2UiOiJlbiIsInNlc3Npb25JZCI6InAwLVVOaTFjTzBoOEpILkZVVGlRbk5HZXBiIiwiZ2l2ZW5fbmFtZSI6IkFobWVkIiwibG9jYWxlIjoiZW4iLCJhdWQiOiI5UlFXLTQ3NlEtN0c4Qy0xSkI5LVpWOVctTExOVi1WMlgzLUQxVloiLCJxdWFsaWZpZXNfZm9yX2FmZmlsaWF0ZV9hY2NvdW50IjoiZmFsc2UiLCJleHAiOjE2OTMyNDAzODMsImlhdCI6MTY5MzIzMzE4MywiZmFtaWx5X25hbWUiOiJTaGF0YXQiLCJlbWFpbCI6ImFobWVkLm1kLnNoYXRhdEBnbWFpbC5jb20ifQ.ebr1WqEpGOW_-M8VVmTdz6U-uyg8T9mlc8PBDhAqUxOfA3E9lRQY0OPYYcwxHlvtCaCoYXucAzQVaM2nEXxRsSdv-RC-Q-c42wodUmdWh3EI6fPDoTjXCB4HRrfvMI8HqPrWupePcxehb-hIZ08dMI4XZIgxskoTWr-7GDVyRgru2oWWZenK0sNFSm3pfUNKTIKkuGl2GEmCrvJYSlx72-oaKLllN9dhliIP1fsH_QaW_CkdwjSoRYCzElGYgKqW2VqlSNFeoMccklt12b3nDslKPzRq6OWeWbxRamVvskThLEJ8B6aiUwiSCw21-ys2Qz5xNRhWj1GQrcSmWjTZtA"
      // };
     
      const accessToken = data["id_token"]
      // const accessToken = userData["accessToken"]
      setUser(userData);
      localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("userId", userData.id);

      // localStorage.setItem("userId", 2);
      window.isAuth = true;
      Analytics.sendSessionIdToServer()
      if (accessToken){
        websiteIstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        websiteIstanceFormData.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
      }
    setup()

      if (
        location?.state?.from &&
        location?.state?.from.includes("event-added-successfully")
      ) {
        setTimeout(() => {
          navigate("/my-timelines", {
            state: {
              from: null,
            },
            replace: true
          });
        }, 3000);


      } else if (location?.state?.from &&
        location?.state?.action === "create_new_modal") {
        navigate("/my-timelines", {
          state: {
            from: null,
            action: location?.state?.action
          },
          replace: true
        });

      } else if (location?.state && location?.state?.from) {
        navigate(location.state.from)
      } else {
        setTimeout(() => {
          navigate(location?.state?.from ?? "/", {
            state: {
              ...location?.state,
              from: null,
            },
            replace: true
          });
        }, 3000);

      }
    } catch (error) {
      
    }
  }
  useEffect(() => {
    Analytics.storeSessionId()
    Analytics.sendLangAnalytics()
    authByFamilySearch()
  }, [])
  const userId = +localStorage.getItem("userId")
  useEffect(() => {

    window.isAuth = !!userId
  })
  // useEffect(() => {
  //   setup()
  // }, [])

  // const callSetup = useCallback(async ()=>{
  //   await setup()
  // }, [])
  async function setup() {
    // debugger
    const currentSessionId = window.sessionStorage.getItem("currentSession")
    if (!currentSessionId) {
      dispatch(setTemorarilyEventFormData(null))

      window.sessionStorage.setItem("currentSession", new Date().getTime());
    }
    const accessToken = Storage.getAccessToken();
    if (window.isAuth || accessToken){
      websiteIstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      websiteIstanceFormData.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      // window.userId = 
      await setupMigrationFromLocalToServer()
    }
  }
  async function setupMigrationFromLocalToServer() {
   try {
    const response = await migrateLocalTimelines()
    const timelineId = response?.[0]?.data?.data?.id
    localStorage.setItem("timelineId", timelineId)
    if (timelineId){
      await migrateLocalFamilyMemories(timelineId)
    }
   } catch (error) {
    
   }
  }

  async function migrateLocalTimelines() {
    try {
      
      if (localTimeLines.length) {
        setLoadingFull(true)
        const requests = localTimeLines.map(timelineData => {

          const formData = new FormData();
          formData.append("timeline", JSON.stringify({title: timelineData.title, status: timelineData.status}));
          if (timelineData.thumbnail) {
            const thumbnailFile = dataURLtoFile(timelineData.thumbnail.path, timelineData.thumbnail.name)
            formData.append("thumbnail", thumbnailFile);
          }
          return websiteIstanceFormData.request({
            method: 'post',
            data: formData,
            url: TIMELINES
        })})
        const response = await Promise.all(requests)
        localTimeLines.forEach(timeline => {
          dispatch(removeTimeLine(timeline.id))
        });
        return response
      } else {
        setLoadingFull(false)
      }
    } catch (error) {
    setLoadingFull(false)

      console.error("error", error)
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  async function migrateLocalFamilyMemories(timelineId) {
    setLoadingFull(true)
  try {
    const Images = {
      0: [],
      1: [],
    };
    const Voices = {
      0: [],
      1: [],
    };

    if (!localFamilyMemories.length) {
      setLoadingFull(false)
      return;
    }


    const requests = [];
  const updateOperations = [];

    localFamilyMemories.forEach((localFamily) => {
      localFamily.events.forEach(async (event, i) => {
        try {
          const formData = new FormData();

          if (event.files?.images?.length) {
            Images[i].push(...event.files.images);
          }

          if (event?.cropImage) {
            const file = dataURLtoFile(
              event.cropImage,
              `${event.currentCropImageName.split(".").slice(0, -1).join(".")}-thumbnail.jpg`
            );
            formData.append("files", file);
          }

          if (event.files?.voices?.length) {
            Voices[i].push(...event.files.voices);
          }

          const data = {
            category: event.category,
            title: event.title,
            day: event.day,
            month: event.month,
            year: event.year,
            place: event.place,
            details: event.details,
          };

          formData.append("event", JSON.stringify(data));
          requests.push(
            websiteIstanceFormData.post(`api/v1/timelines/${timelineId}/events`, formData)
          );
        } catch (error) {
          console.error("error", error);
        }
      });
    });

    const responses = await Promise.all(requests);

    responses.forEach(async (response, index) => {
      const eventData = response.data.data;
      const eventId = eventData.id;
      const images = Images[index];
      const voices = Voices[index];

      if (eventId && images.length) {
        for (const image of images) {
          const formData = createFormData(eventData, image.path, image.name);
          // await updateEventFormData(timelineId, eventId, formData);
          updateOperations.push(updateEventFormData(timelineId, eventId, formData))
        }
      }

      if (eventId && voices.length) {
        for (const voice of voices) {
          const formData = createFormData(eventData, voice.path, voice.name);
          // await updateEventFormData(timelineId, eventId, formData);
          updateOperations.push(updateEventFormData(timelineId, eventId, formData))

        }
      }
    });
    await Promise.all(updateOperations);

    dispatch(clearMemories());
    setLoadingFull(false)

  } catch (error) {
    setLoadingFull(false)

    console.error("error", error);
  }
}

function createFormData(eventData, filePath, fileName) {
  const formData = new FormData();
  const event = {
    category: eventData.category,
    title: eventData.title,
    place: eventData.place,
    day: eventData.date.day,
    month: eventData.date.month,
    year: eventData.date.year,
    details: eventData.details,
    deletedFiles: [],
  };

  formData.append("event", JSON.stringify(event));
  const file = dataURLtoFile(filePath, fileName);
  formData.append("files", file);

  return formData;
}

async function updateEventFormData(timelineId, eventId, formData) {
  await websiteIstanceFormData.put(`api/v1/timelines/${timelineId}/events/${eventId}`, formData);
}


  const closeLoginRegisterModal = () => {
    dispatch(setLoginRegisterModalOpen(false))
  }
  return (
    <I18Provider>
      <ErrorBoundary>
        <UserContext.Provider value={{ user, setUser }}>
          <ThemeProvider theme={customtheme}>
            <SnackbarProvider autoHideDuration={1500} maxSnack={3}>
              <BottomSheet handleClose={closeLoginRegisterModal} open={appReducer.isLoginRegisterModalOpen}>
                <LoginRegisterModal handleClose={closeLoginRegisterModal} />
              </BottomSheet>
              {isFullLoading && <FullScreenLoader />}
              {localStorage.getItem("userId") ? <FamilySearchRouter /> : <NotAuthRouter /> }
            </SnackbarProvider>
          </ThemeProvider>
        </UserContext.Provider>
      </ErrorBoundary>
    </I18Provider>
  );
}

export default App;
