import { createSlice, current } from '@reduxjs/toolkit'
import mapKeys from "lodash/mapKeys";

const initialState = {
    data: {
        timelines: []
    },
}

const myTimeLinesSlice = createSlice({
    name: 'myTimelines',
    initialState,
    reducers: {
        setMyTimelines(state, { payload }) {
          try {
              const currentState = current(state);
              const timeLines = payload?.timelines ? [...currentState.data.timelines, ...payload?.timelines] : currentState.data.timelines;
              const timeLinesDictionary = mapKeys(timeLines, 'id')          
              state.data = {
                  ...payload,
                  timelines: Object.values(timeLinesDictionary)
              };
          } catch (error) {
            console.error("error", error)
          }
        },
        clearMyTimelines(state) {
          try {
            
              state.data = initialState.data;
          } catch (error) {
            console.error("error", error)
          }
        },
        setTimeLine(state, { payload }) {
            try {
                const currentState = current(state);
                const timeLines = currentState.data.timelines.length ? [payload, ...currentState.data.timelines] : [payload];
                state.data.timelines = timeLines;

            } catch (error) {
                console.error("error", error)
            }
        },
        removeTimeLine(state, { payload }) {
            try {
                const currentState = current(state);
                const timeLines = currentState.data.timelines.filter(timeline => timeline.id !== payload);
                state.data.timelines = timeLines;

            } catch (error) {
                console.error("error", error)
            }
        }
    },
})

export const { setMyTimelines, setTimeLine, removeTimeLine, clearMyTimelines } = myTimeLinesSlice.actions
export default myTimeLinesSlice.reducer