

const GDrive = () => {
    return (
        <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0156 0H26.9219L40 22.5469H26.0625L13.0156 0Z" fill="url(#paint0_linear_1_18867)" />
            <path d="M40 22.5469L33.0312 34.5937H6.9375L13.9219 22.5469H40Z" fill="url(#paint1_linear_1_18867)" />
            <path d="M6.9375 34.5937L0 22.5469L13.0156 0L20 12.0781L6.9375 34.5937Z" fill="url(#paint2_linear_1_18867)" />
            <path opacity="0.1" d="M6.9375 34.5937L19.9219 22.5469H13.9219L6.9375 34.5937V34.5937Z" fill="black" />
            <path opacity="0.1" d="M40 22.5469H26.0781L23.0156 17.2656L40 22.5469Z" fill="black" />
            <path opacity="0.1" d="M13.0156 0L17.0312 17.1875L20 12.0781L13.0156 0Z" fill="black" />
            <defs>
                <linearGradient id="paint0_linear_1_18867" x1="13.0156" y1="0" x2="35.2034" y2="26.5546" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F6C338" />
                    <stop offset="0.52278" stopColor="#FFD351" />
                    <stop offset="1" stopColor="#F6C338" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_18867" x1="40" y1="22.5469" x2="32.25" y2="43.8168" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#286EE6" />
                    <stop offset="0.52105" stopColor="#4286FB" />
                    <stop offset="1" stopColor="#286EE6" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_18867" x1="13.0578" y1="3.56049e-07" x2="-2.08667" y2="29.8888" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#069B5A" />
                    <stop offset="0.53103" stopColor="#11AA62" />
                    <stop offset="1" stopColor="#069B5A" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GDrive;