import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import CrossMenu from "assets/icons/cross-blue.svg";
import "./style.css";
import I18nContext from "context/I18nContext";
import {
  FormControlLabel,
  FormControl,
  RadioGroup,
  Divider,
  Button,
  Radio,
} from "@mui/material";
export default function LanguageDrawer({
  languageDrawerOpen,
  setLanguageDrawerOpen,
}) {
  const { t } = useTranslation(["common"]);
  const { setLanguage, language } = useContext(I18nContext);
  const [value, setValue] = useState(language.lang);

  const handleChangeLang = () => {
    setLanguage(value);
    setLanguageDrawerOpen(false);
  };

  return (
    <div>
      <BottomSheet
        handleClose={() => setLanguageDrawerOpen(false)}
        open={languageDrawerOpen}
        className="menu-no-padding lang-container"
      >
        <div className="lang-header">
          <h1 className="lang-title">{t("Select your Language")}</h1>
          <img
            src={CrossMenu}
            alt={t("close image")}
            onClick={() => setLanguageDrawerOpen(false)}
          />
        </div>

        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setValue(e.target.value)}
              value={value}
            >
              <FormControlLabel
                value="en"
                control={<Radio />}
                label="English"
              />
              <Divider className="lang-divider" />
              <FormControlLabel
                value="ar"
                control={<Radio />}
                label="العربية"
              />
              <Divider className="divider" />
              <FormControlLabel
                value="fr"
                control={<Radio />}
                label="Français"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div>
          <Button
            className="lang-submitBtn"
            variant="contained"
            onClick={handleChangeLang}
          >
            {t("Save Changes")}
          </Button>
        </div>
      </BottomSheet>
    </div>
  );
}
