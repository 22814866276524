

const RightSquareArrow = ({ fill, color, stroke }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill={color || "#FBFBFB"} stroke={stroke || "#ECECEC"} />
            <path d="M16 27.21L17.82 29L27 20L17.82 11L16 12.79L23.36 20L16 27.21Z" fill={fill || "#066F90"} />
        </svg>
    )
}

export default RightSquareArrow;