
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import cls from "classnames";
import "./style.css";

const MemoryStoryModal = ({ open, handleClose, className, children }) => {
    const modalBodyClassName = cls("memorydetails-root", {
        [className]: !!className
    })
    const modalWrapperClassName = cls("memory-story-root", {
        hide: !open
    })
    return (
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            closeAfterTransition
            className={modalWrapperClassName}
        >
            <Box className={modalBodyClassName}>
                {children}
            </Box>
        </Modal>
    )
}

export default MemoryStoryModal;