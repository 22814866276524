import { useState, useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Drawer from "components/Drawer/DrawerComponent";
import LanguageDrawer from "components/LanguageDrawer/LanguageDrawer";
import Dialog from "components/Dialog/DialogComponent";
import TimelinesMenuItems from "./TimelinesMenuItems";
import { HideOnScroll } from "components";
import UseWidth from "hooks/useUpdateWindowDimensions";
import Logo from "assets/icons/logo.svg";
import Menu from "assets/icons/menu.svg";
import MenuClose from "assets/icons/menuClose.svg";
import UserContext from "context/UserContext";
import { clearMyTimelines } from "redux/slices/myTimeLinesSlice";
import { clearMemories } from "redux/slices/familyMemoriesSlice";
import { Signup, Login } from "../../helpers/auth";
import "./style.css";
import Language from "components/Icons/Language";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { persistor } from 'redux/store';

export default function Navbar() {
  const { user } = useContext(UserContext);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation(["common"]);
  const width = UseWidth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const list = [
    {
      text: t("Instant Family Tree"),
      link: "https://www.familysearch.org/mena/",
    },
    {
      text: t("Timelines"),
      link: "#",
      childrens: [
        {
          text: t("My Timelines"),
          link: "/my-timelines",
        },
        {
          text: t("Public Timelines"),
          link: "/timelines",
        },
        {
          text: t("Shared Timelines"),
          link: "/shared-timelines",
        },
      ]
    },
    {
      text: t("About"),
      link: "https://www.familysearch.org/en/about/",
    }
  ];


const removePersist = () => {
const dbName = "mydb";
const objectStoreName = "myObjectStore";
const keyToDelete = "persist:root";

const request = indexedDB.open(dbName, 1);

request.onupgradeneeded = function(event) {
  const db = event.target.result;
  
  // Create an object store if it doesn't exist
  if (!db.objectStoreNames.contains(objectStoreName)) {
    const objectStore = db.createObjectStore(objectStoreName, { keyPath: "key" });
  }
};

request.onsuccess = function(event) {
  const db = event.target.result;

  // Start a transaction with readwrite access
  const transaction = db.transaction([objectStoreName], "readwrite");

  // Get the object store
  const objectStore = transaction.objectStore(objectStoreName);

  // Delete the key from the object store
  const deleteRequest = objectStore.delete(keyToDelete);

  deleteRequest.onsuccess = function(event) {
    console.log(`Key '${keyToDelete}' deleted successfully.`);
  };

  deleteRequest.onerror = function(event) {
    console.error(`Error deleting key '${keyToDelete}':`, event.target.error);
  };
};

request.onerror = function(event) {
  console.error("Error opening database:", event.target.error);
};

  
  
};


  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem('persist:root')
    removePersist()
    localStorage.removeItem("isRedirected")
    localStorage.removeItem("timelineId")
    localStorage.removeItem("SessionId")
    localStorage.removeItem('localEventsCounter')
    
    dispatch(clearMyTimelines())
    dispatch(clearMemories())
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    setDrawerOpen(false);
    window.isAuth = false;
    navigate('/')
  };

  return (
    <>
      <CssBaseline />
      <HideOnScroll>
        <AppBar className="navbar" component="nav">
          <Toolbar className="navbarToolbar">
            <Typography
              role="button"
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <div className="navbar-toolbar-start-side">
                <img
                  onClick={() => navigate("/")}
                  src={Logo}
                  alt={t("header logo")}
                />
                <div className="navbar-toolbar-start-side--items-list">
                  {list.map((item, i) => {
                    if (item.link.includes("http")) {
                      return (
                        <a key={item.link + "-" + i} href={item.link} target='_blank' className="navbar-toolbar-start-side--items-list---item">
                          {item.text}
                        </a>
                      )
                    }
                    if (item.link === "#") {
                      return (
                        <TimelinesMenuItems key={i+30} title={item.text} childrens={item.childrens} />
                      )
                    }
                    return (<Link key={item.link + "-" + i} to={item.link} className="navbar-toolbar-start-side--items-list---item">
                      {item.text}
                    </Link>)
                  })}
                </div>
              </div>
            </Typography>
            <Box
              onClick={handleClickOpen}
              className="navbarIcon navbarIconMargin"
            >
              <Language />
            </Box>
            {/* <Box
              onClick={handleClickOpen}
              className="navbarIcon help-menu-button"
            >
              <Help />
            </Box> */}
            <div className="action-buttons-menu">
              {!window.isAuth ? <>
                <Link onClick={Login} className="signin-button">
                  {t("Sign in")}
                </Link>
                <Link onClick={Signup}  state={{
                  from: location.pathname
                }
                } className="signup-button">
                  {t("create account")}
                </Link>
              </> :
                <div className="signin-button" onClick={() => handleLogout()}>
                  {t("Logout")}
                </div>}
            </div>
            <Box
              onClick={() => setDrawerOpen(!drawerOpen)}
              className="navbarIcon hamurger-menu"
            >
              {drawerOpen ? (
                <img src={MenuClose} alt={t("Menu close icon")} />
              ) : (
                <img src={Menu} alt={t("Menu icon")} />
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar style={{ height: width < 700 ? "85px" : "62px" }} />

      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      {width > 1024 ? (
        <Dialog open={open} setOpen={setOpen} />
      ) : (
        <LanguageDrawer
          languageDrawerOpen={open}
          setLanguageDrawerOpen={setOpen}
        />
      )}
    </>
  );
}
