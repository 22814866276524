import * as React from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import StyledButton from "components/StyledButton/StyledButton";
import  {DeleteInvitation} from "components/Icons/Delete";
import  ArrowDown from "components/Icons/ArrowDown";
import { useTranslation } from "react-i18next";
import "./invitations.css"

export default function PopoverPopupState({label, value, onChange, row, onDeleteInvitedUser}) {
     const { t } = useTranslation(["memories"]); 
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div className='role-select-btn-wrapper'>
          <Button className='role-select-btn' variant="contained" {...bindTrigger(popupState)}>
            {label}
            <ArrowDown />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
                <FormControl className='popover-invitations'>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={value}
                        name="radio-buttons-group"
                        onChange={onChange}
                    >
                        <FormControlLabel 
                        style={value === "VIEWER" ? {
                            background: "#F6F6F6"
                        } : {}} 
                        className='popover-invitations-row' value="VIEWER" control={<Radio />} label="Viewer" />
                        <FormControlLabel
                          style={value === "EDITOR" ? {
                            background: "#F6F6F6"
                        } : {}} 
                        className='popover-invitations-row' value="EDITOR" control={<Radio />} label="Editor" />                        
                    </RadioGroup>
                </FormControl>
                     <hr style={{margin: 0}} />
                    <StyledButton className="invite-friends--invited-users-item--delete-btn"  id={row.id} onClick={onDeleteInvitedUser}>
                        <DeleteInvitation />
                            {t("Remove")}
                    </StyledButton>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
