import "./style.css";

const HorizontalDivider = ({color}) => {
    return (
        <div className="divider-wrapper">
            <div className="divider-wrapper--circle" style={{
                borderColor: color
            }} />
            <div className="divider-wrapper--line" style={{
                backgroundColor: color
            }} />
        </div>
    )
}

export default HorizontalDivider;