import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import CustomTypography from "components/CustomTypography/CustomTypography";
import InputLabel from "@mui/material/InputLabel";
import { useTranslation } from "react-i18next";
import CloseIcon from "components/Icons/Close";
import StyledButton from "components/StyledButton/StyledButton";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import useDialog from "hooks/useDialog";
import { FileUploader } from "react-drag-drop-files";
import SvgIcon from "@mui/material/SvgIcon";
import "./style.css";
import { useEffect, useState } from "react";
import { DeleteIcon } from "pages/AddEvent/components/SVGIcons/SVGIcons";
import { bytesToSize, truncate } from "pages/AddEvent/utils";
import Modal from "@mui/material/Modal";
import Cropper from "pages/AddEvent/components/Cropper"
import heicToAny from "heic2any"
import TimeLinePlaceholder, { TimeLineUploadMediaPlaceholder } from "components/Icons/TimeLinePlaceholder";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    height: "36px",
    width: "100%",
    "& .MuiInputBase-input": {
        width: "100%",
        borderRadius: 4,
        position: "relative",
        border: "1px solid #DFDFDF",
        fontSize: 12,
        padding: "9px 0px 10px 0px",
        height: "15px",
        paddingInlineStart: "15px",
        [theme.breakpoints.up('sm')]: {
            height: "23px",

        },
    },
    [theme.breakpoints.up('sm')]: {
        height: "44px",

    },
}));

const fileTypes = [
    "JPG",
    "JPEG",
    "HEIF",
    "HEIC",
    "TIF",
    "PNG",
];

const TimeLineForm = ({ handleClose, title, id, loading, isUpdate, onSubmit, status, onDelete, thumbnail, onDeleteFile, isFileDeleted }) => {
    const [statusValue, setStatusValue] = useState(status)
    const [fileList, setFileList] = useState([]);
    const [currentCover, setCurrentCover] = useState(null);
    const [currentCropImage, setCurrentCropImage] = useState(null);
    const [cropImage, setCropImage] = useState(null);
    const [open, setOpen] = useState(false);

    const INITIAL_VALUES = {
        title: title || "",
        status: status || ""
    }
    const { t } = useTranslation(["timeline"]);

    const validationSchema = Yup.object({
        title: Yup.string().required(t("Title required")),
        status: Yup.string().required(t("Status required")),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: INITIAL_VALUES
    };

    const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleStatusChange = (value) => {
        setValue("status", value)
        setStatusValue(value)
    };

    const onFormSubmit = async (values) => {
        try {
            const formData = new FormData();
            let data = {};
            let file = {};
            if (cropImage) {
                const response = await fetch(cropImage);
                const blob = await response.blob();
                file = new File(
                  [blob],
                  `${currentCropImage.name
                    .split(".")
                    .slice(0, -1)
                    .join(".")}-thumbnail.jpg`,
                  { type: blob.type }
                );
            }

            if (window.isAuth) {
                if (cropImage) {
                    formData.append("thumbnail", file);
                }
                formData.append("timeline", JSON.stringify({title: values.title, status: values.status, delThumb: isFileDeleted}) );
                data = formData
            } else {
                data = {
                    title: values.title, 
                    status: values.status,
                    ...(cropImage && {thumbnail: file})
                }
            }

            await onSubmit(data)
            // handleClose()
            reset();
        } catch (error) {
        }
    }
    const onDeleteTimeline = async () => {
        onDelete(id)
    }

    useEffect(() => {
        if (window.isAuth && thumbnail) {
            setFileList([{...thumbnail, size: thumbnail["size"] * 1000}])
        } else if (!window.isAuth && thumbnail) {
            setFileList([thumbnail])
        } else {
            setFileList([])
        }
    }, [thumbnail])

    const handleChangeFile = async (file) => {
        if (file?.length !== 0) {
            if(onDeleteFile) onDeleteFile(false)
            if (file[0].type === "image/heif" || file[0].type === "image/heic") {
                const conversionResult = await heicToAny({ blob: file[0], toType: "image/jpeg", quality: 0.5 })
                const newImage = new File([conversionResult], `${file[0].name.split(".")[0]}.jpg`, {
                    type: "image/jpg",
                    lastModified: Date.now(),
                  });
                setFileList([newImage]);
                setCurrentCropImage(newImage);
                setOpen(true)
            } else {
                setFileList([file[0]]);
                setCurrentCropImage(file[0]);
                setOpen(true)
            }
        }
      };

      const handleChangeRadio = (file) => {
        setCurrentCropImage(file);
      };

      const handleDeleteFile = () => {
        setFileList([]);
        setCropImage(null);
        setCurrentCover(null);
        setCurrentCropImage(null);
        if(onDeleteFile) onDeleteFile(true)
      };

    return (
        <div className="create-timeline-wrapper">

            <div className="create-timeline-header">
                <div>
                    <CustomTypography className="create-timeline-header--create-update" type="headline">
                        {isUpdate ? t("Update timeline") : t("create new timeline")}
                    </CustomTypography>
                </div>
                <button onClick={handleClose} className="text-button">
                    <CloseIcon />
                </button>
            </div>
            <div className="create-timeline-form">
                <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="create-timeline-form-input-wrapper">
                        <div className="create-timeline-form-input--row" style={{alignItems: 'flex-end'}}>
                            <div className="create-timeline-form-input--block">
                                <InputLabel
                                    // style={{fontWeight: '700'}}
                                    className="create-timeline-form-input--label"
                                    htmlFor="title-input"
                                >
                                    {t("Timeline Name")}
                                </InputLabel>
                                <BootstrapInput
                                    placeholder={t("Enter Timeline Title")}
                                    id="title-input"
                                    label="Filled"
                                    className="create-timeline-form-input"
                                    {...register("title")}
                                    style={{marginBottom: '5px'}}
                                />
                                {errors.title && <CustomTypography type="error">
                                    {t(errors.title.message)}
                                </CustomTypography>}
                            </div>
                            <div>
                                <TimeLinePlaceholder />
                            </div>
                        </div>
                        {/* <InputLabel
                            className="create-timeline-form-input--label create-timeline-form-input--title-label"
                            htmlFor="title-input"
                            >
                            {t("Event Privacy")}
                        </InputLabel> */}
                        <InputLabel
                            style={{ paddingTop: "15px"}}
                            className="create-timeline-form-input--label"
                            htmlFor="title-input"
                        >
                            {t("Cover Image")}
                        </InputLabel>
                        <div className="cover-image-description">{t("This image will be the cover for your timeline")}</div>
                        <div className="uploadMedia">
                            <div className="create-timeline-form-input--row">
                                <div className="create-timeline-form-input--block">
                            <FileUploader
                                multiple={true}
                                handleChange={handleChangeFile}
                                name="file"
                                types={fileTypes}
                                maxSize={15}
                                children={
                                <div
                                    style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    border: "1px dashed #DFDFDF",
                                    // padding: "16px 38px 16px",
                                    maxHeight: "96px",
                                    }}
                                >
                                    <SvgIcon
                                    width="18"
                                    height="22"
                                    viewBox="0 0 18 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="datePickerIcon"
                                    >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9 15L7 13L4 17H14L11 11L9 15ZM7 12C7.39556 12 7.78224 11.8827 8.11114 11.6629C8.44004 11.4432 8.69638 11.1308 8.84776 10.7654C8.99913 10.3999 9.03874 9.99778 8.96157 9.60982C8.8844 9.22186 8.69392 8.86549 8.41421 8.58579C8.13451 8.30608 7.77814 8.1156 7.39018 8.03843C7.00222 7.96126 6.60009 8.00087 6.23463 8.15224C5.86918 8.30362 5.55682 8.55996 5.33706 8.88886C5.1173 9.21776 5 9.60444 5 10C5 10.5304 5.21071 11.0391 5.58579 11.4142C5.96086 11.7893 6.46957 12 7 12ZM2 20V2H12V6H16V20H2ZM13.41 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H16C16.5304 22 17.0391 21.7893 17.4142 21.4142C17.7893 21.0391 18 20.5304 18 20V4.59L13.41 0Z"
                                        fill="#066F90"
                                    />
                                    </SvgIcon>
                                    <p
                                    style={{
                                        fontSize: "12px",
                                        margin: "8px 0px 7px",
                                        color: "#878A8C",
                                        fontWeight: "700",
                                    }}
                                    className="uploader-title"
                                    >
                                    {t("Click to Add Cover Image")}
                                    </p>
                                    <p
                                    style={{
                                        fontSize: "8px",
                                        margin: "0",
                                        color: "#878A8C",
                                        fontStyle: "italic",
                                        textAlign: "center",
                                    }}
                                    className="uploader-subtitle"
                                    >
                                            {t("The image you will see when viewing timelines")}
                                    </p>
                                </div>
                                }
                            />
                            </div>
                                <div>
                                    <TimeLineUploadMediaPlaceholder />
                                </div>
                            </div>
                            <p
                                style={{
                                margin: "10px 0px",
                                }}
                            >
                                {fileList.length ? (
                                <div>
                                    {fileList.map((e) => {
                                    return (
                                        <div
                                        key={e.name}
                                        style={{
                                            display: "flex",
                                            alignContent: "center",
                                            marginBottom: "8px",
                                        }}
                                        >
                                        <div
                                            style={{
                                            width: "37px",
                                            height: "40px",
                                            marginInlineEnd: "4px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                width: "37px",
                                                height: "40px",
                                                borderRadius: "4px",
                                                border: "1px solid #DFDFDF",
                                                }}
                                                src={e.path ? e.path : URL.createObjectURL(e)}
                                                alt={e.name}
                                                onClick={() => {
                                                    handleChangeRadio(e);
                                                    setOpen(true);
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                            fontSize: "12px",
                                            color: "#202121",
                                            display: "flex",
                                            flexDirection: "column",
                                            flexGrow: 1,
                                            justifyContent: "center"
                                            }}
                                        >
                                            <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center"
                                            }}
                                            >
                                            {truncate(e.name)}{" "}
                                            <span
                                                style={{
                                                fontSize: "10px",
                                                color: "#878A8C",
                                                marginInlineStart: "5px",
                                                }}
                                            >
                                                “{bytesToSize(e.size)}”
                                            </span>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            }}
                                        >
                                            <DeleteIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() => handleDeleteFile(e)}
                                            />
                                        </div>
                                        </div>
                                    );
                                    })}
                                </div>
                                ) : (
                                ""
                                )}
                            </p>
                        </div>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            onChange={(e) => { handleStatusChange(e.target.value) }}
                            value={statusValue}
                        // {...register("status")}
                        >
                               <InputLabel
                                    // style={{fontWeight: '700'}}
                                    className="create-timeline-form-input--label"
                                    
                                    style={{
                                        marginTop: 10
                                    }}
                                >
                                    {t("Timeline Privacy")}
                                </InputLabel>
                            <div className="timeline-privacy-option">
                                <FormControlLabel
                                    value="PRIVATE"
                                    control={<Radio />}
                                    label={t("Private")}
                                />
                                <FormControlLabel
                                    value="PUBLIC"
                                    control={<Radio style={{ padding: 8 }} />}
                                    label={t("Public")}
                                    className="create-timeline-form-input--first-radio"
                                />

                            </div>
                            {statusValue === 'PRIVATE' ? <CustomTypography className="help-text-status" style={{ fontSize: 12 }} type="normal">
                                {t("This is just for yourself!")}
                            </CustomTypography> : <CustomTypography className="help-text-status" style={{ fontSize: 12 }} type="normal">
                                {t("Anyone can view this timeline")}
                            </CustomTypography>}
                            <div style={{ fontSize: 12, marginBottom: 10 }} />
                            {errors.status && <CustomTypography type="error">
                                {t(errors.status.message)}
                            </CustomTypography>}
                        </RadioGroup>
                        {isUpdate && <div>
                            <StyledButton
                                color="warning"
                                variant="outlined"
                                fullWidth
                                style={{
                                    background: "#C32B261A",

                                }}
                                className="create-timeline-delete-button"
                                onClick={onDeleteTimeline}
                            >
                                <CustomTypography className="create-timeline-delete-button--text" type="subHeadLine">
                                    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.556 4.36353C11.3644 4.36353 11.1807 4.44014 11.0453 4.57653C10.9099 4.71291 10.8338 4.89789 10.8338 5.09076V13.2293C10.813 13.597 10.6489 13.9416 10.377 14.188C10.1051 14.4344 9.74747 14.5627 9.3821 14.5448H3.61877C3.25339 14.5627 2.89581 14.4344 2.62391 14.188C2.35201 13.9416 2.18782 13.597 2.1671 13.2293V5.09076C2.1671 4.89789 2.09101 4.71291 1.95557 4.57653C1.82012 4.44014 1.63642 4.36353 1.44488 4.36353C1.25333 4.36353 1.06963 4.44014 0.93419 4.57653C0.798747 4.71291 0.722656 4.89789 0.722656 5.09076V13.2293C0.743271 13.9828 1.05963 14.6975 1.60245 15.2167C2.14527 15.7359 2.87031 16.0173 3.61877 15.9993H9.3821C10.1306 16.0173 10.8556 15.7359 11.3984 15.2167C11.9412 14.6975 12.2576 13.9828 12.2782 13.2293V5.09076C12.2782 4.89789 12.2021 4.71291 12.0667 4.57653C11.9312 4.44014 11.7475 4.36353 11.556 4.36353Z" fill="#C32B26" />
                                        <path d="M12.2778 2.18171H9.38889V0.727235C9.38889 0.534361 9.3128 0.349385 9.17735 0.213002C9.04191 0.0766192 8.85821 0 8.66667 0H4.33333C4.14179 0 3.95809 0.0766192 3.82265 0.213002C3.6872 0.349385 3.61111 0.534361 3.61111 0.727235V2.18171H0.722222C0.530677 2.18171 0.346977 2.25833 0.211534 2.39471C0.076091 2.53109 0 2.71607 0 2.90894C0 3.10182 0.076091 3.28679 0.211534 3.42317C0.346977 3.55956 0.530677 3.63618 0.722222 3.63618H12.2778C12.4693 3.63618 12.653 3.55956 12.7885 3.42317C12.9239 3.28679 13 3.10182 13 2.90894C13 2.71607 12.9239 2.53109 12.7885 2.39471C12.653 2.25833 12.4693 2.18171 12.2778 2.18171ZM5.05556 2.18171V1.45447H7.94444V2.18171H5.05556Z" fill="#C32B26" />
                                        <path d="M5.77843 11.6358V6.54511C5.77843 6.35223 5.70234 6.16726 5.5669 6.03087C5.43146 5.89449 5.24776 5.81787 5.05621 5.81787C4.86466 5.81787 4.68096 5.89449 4.54552 6.03087C4.41008 6.16726 4.33398 6.35223 4.33398 6.54511V11.6358C4.33398 11.8286 4.41008 12.0136 4.54552 12.15C4.68096 12.2864 4.86466 12.363 5.05621 12.363C5.24776 12.363 5.43146 12.2864 5.5669 12.15C5.70234 12.0136 5.77843 11.8286 5.77843 11.6358Z" fill="#C32B26" />
                                        <path d="M8.6671 11.6358V6.54511C8.6671 6.35223 8.59101 6.16726 8.45557 6.03087C8.32013 5.89449 8.13643 5.81787 7.94488 5.81787C7.75333 5.81787 7.56963 5.89449 7.43419 6.03087C7.29875 6.16726 7.22266 6.35223 7.22266 6.54511V11.6358C7.22266 11.8286 7.29875 12.0136 7.43419 12.15C7.56963 12.2864 7.75333 12.363 7.94488 12.363C8.13643 12.363 8.32013 12.2864 8.45557 12.15C8.59101 12.0136 8.6671 11.8286 8.6671 11.6358Z" fill="#C32B26" />
                                    </svg>

                                    {t("Delete This TimeLine")}
                                </CustomTypography>
                            </StyledButton>
                        </div>}
                    </div>
                    <StyledButton
                        color="primary"
                        loading={loading}
                        variant="contained"
                        fullWidth
                        type="submit"
                    >
                        <CustomTypography type="subHeadLine">
                            {isUpdate ? t("Update") : t("Create")}
                        </CustomTypography>
                    </StyledButton>
                </form>
            </div>
            <Modal
          slotProps={{ backdrop: { style: { backgroundColor: "unset" } } }}
          open={open}
        >
          <>
            <Cropper
              currentCropImage={currentCropImage}
              setCurrentCover={setCurrentCover}
              setCropImage={setCropImage}
              setOpen={setOpen}
              open={open}
            />
          </>
        </Modal>
        </div>
    )
}

export default TimeLineForm;