import Home from "./Home/Home";
import Login from "./Login/Login";
import FamilyMemories from "./FamilyMemories/FamilyMemories";
import AddEvent from "./AddEvent/AddEvent";
import Signup from "./Signup/Signup";
import MyTimeLines from "./MyTimeLines/MyTimeLines"; 
import PublicTimeLines from "./PublicTimeLines/PublicTimeLines"; 
import SharedTimeLines from "./SharedTimeLines/SharedTimeLines"; 
import EventDetails from "./EventDetails/EventDetails";
import EventAddedSuccessfully from "./EventAddedSuccessfully/EventAddedSuccessfully";
import EditEvent from "./EditEvent/EditEvent";
import AcceptInvite from "./AcceptInvite";
import Notfound404 from "./404/404";

export {
  Login,
  Signup,
  Home,
  FamilyMemories,
  MyTimeLines,
  AddEvent,
  EventAddedSuccessfully,
  EventDetails,
  EditEvent,
  PublicTimeLines,
  SharedTimeLines,
  Notfound404,
  AcceptInvite
};
