
const Language = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0ZM2 10C1.99955 9.40046 2.07004 8.80297 2.21 8.22L7 13V14C7 14.5304 7.21072 15.0391 7.58579 15.4142C7.96086 15.7893 8.46957 16 9 16V17.93C7.06798 17.6866 5.29108 16.7469 4.0024 15.287C2.71373 13.8271 2.00177 11.9473 2 10V10ZM14 14C14.4243 14.0017 14.837 14.1382 15.1785 14.3899C15.5201 14.6415 15.7727 14.9953 15.9 15.4C16.7703 14.4475 17.3973 13.2987 17.7275 12.0515C18.0577 10.8043 18.0814 9.49573 17.7966 8.23739C17.5117 6.97905 16.9267 5.80827 16.0915 4.82491C15.2563 3.84155 14.1956 3.07478 13 2.59V3C13 3.53043 12.7893 4.03914 12.4142 4.41421C12.0391 4.78929 11.5304 5 11 5H9V7C9 7.26522 8.89465 7.51957 8.70711 7.70711C8.51957 7.89464 8.26522 8 8 8H6V10H12C12.2652 10 12.5196 10.1054 12.7071 10.2929C12.8946 10.4804 13 10.7348 13 11V14H14Z" fill="#202121" />
        </svg>
    )
}

export default Language;

