import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";
import Cone from "../../assets/icons/cone.svg";
import "./style.css";

const EventAddedSuccessfully = () => {
  const { t } = useTranslation(["addEventSuccess"]);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { id, timelineId } = params;
  const data = useSelector((state) => state.familyMemories.familyMemories);
  const familyMemoriesList = data[timelineId]?.events ?? [];
  const onAddNewEvent = () => {
    if (Array.isArray(familyMemoriesList) && familyMemoriesList.length < 2 || window.isAuth) {
      navigate(`/add-event/${timelineId}`)
    } else {
      dispatch(setLoginRegisterModalOpen(true))
    }
  }
  return (
    <div className="successMainContainer">
      <div className="successContainer">
        <img src={Cone} alt={t("cone icon alt")} />

        <h1 className="successTitle">{t("success title")}</h1>

        <button
          className="successViewEvent"
          onClick={() => navigate(`/event-details/${timelineId}/${id}`)}
        >
          {t("View This Event")}
        </button>
        <button
          className="successAddEvent"
          onClick={onAddNewEvent}
        >
          {t("Add New Event")}
        </button>
      </div>
    </div>
  );
};

export default EventAddedSuccessfully;
