import React from "react";
import Notfound from "components/Notfound404/Notfound404";
import  "./style.css";

const Notfound404 = () => {
    return (
        <main className="not-found-404">
            <Notfound />
        </main>
    )
}

export default Notfound404;