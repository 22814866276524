import "./style.css"

const BottomSheveron = ({color}) => {
    return (
       <div className="bottom-chevron" style={{
            backgroundColor: color
       }} />
    )
}


export default BottomSheveron;