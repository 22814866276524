

const Filter = ({ width, height }) => {
    return (
        <svg width={width || "40"} height={height || "40"} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#F3F3F3" stroke="#ECECEC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11 14V16H29V14H11ZM18 26H22V24H18V26ZM26 21H14V19H26V21Z" fill="black" />
        </svg>



    )
}

export default Filter;