import * as React from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Loader from "./Loader";
import "./style.css"


export default function FullScreenLoader() {


  return (
    <div>
      <Button>Open modal</Button>
      <Modal
        open={true}       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='full-screen-loader'>
            <Loader style={{
                color: '#ffff'
            }} />
        </div>
      </Modal>
    </div>
  );
}