
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import cls from "classnames";
import "./style.css";

const ReusableModal = ({ open, handleClose, className, children }) => {
    const ReusableModalClassName = cls("reusable-modal-root", {
        [className]: !!className
    })
    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Box className={ReusableModalClassName}>               
                {children}
            </Box>
        </Modal>
    )
}

export default ReusableModal;