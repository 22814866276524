import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from "react-router-dom";
import { websiteIstanceFormData } from "../../../../api/axios";
import { TIMELINES } from "../../../../api/endpoints";
import { useDispatch } from 'react-redux';
import { setTimeLine } from "redux/slices/myTimeLinesSlice";
import SelectCategory from "components/SelectCategory";

import TimeLineForm from "../TimeLineForm/TimeLineForm";

import Enum from "./Enum";

async function dataToBase64(audioFile) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsDataURL(audioFile);
    });
  }


const CreateNewTimeLine = ({ handleClose, disabled }) => {
    const { t } = useTranslation(["timeline"]);
    const [isSelectCategoryOpen, SetIsSelectCategoryOpen] = useState(false);
    const location = useLocation();

    const [loading, setLoading] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();



    const navigate = useNavigate();


    const unRegisteredCreateTimeline = async (values) => {
        let imageData
        if (values.thumbnail) {
            imageData = {
                path:  await dataToBase64(values.thumbnail),
                name: values.thumbnail.name,
                size: values.thumbnail.size,
                type: values.thumbnail.type,
            }
        }
        const id = new Date().getTime();
        const TimeLine = {
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            creator_id: null,
            events: [],
            id,
            thumbnail: imageData,
            title: values.title,
            status: values.status

        }
        dispatch(setTimeLine(TimeLine))
        enqueueSnackbar(t("Timeline created successfuly"), { variant: Enum.success })
        // handleClose()
        // navigate(`/add-event/${id}`, {
        //     state: {
        //         timelineTitle: values.title
        //     }
        // })
        handleOpenSelectCategory({ id, title: values.title })


    }

    const onSubmit = async (values) => {
        try {
            // for (const value of values.values()) {
            //     console.log(value);
            // }
            if (!disabled && !window.isAuth) { 
                unRegisteredCreateTimeline(values)
                return;
            }
            setLoading(Enum.CREATE)
            const response = await websiteIstanceFormData.request({
                method: 'post',
                data: values,
                url: TIMELINES
            })
            const data = response.data;
            enqueueSnackbar(t("Timeline created successfuly"), { variant: Enum.success })
            // handleClose()
            setLoading("")
            handleOpenSelectCategory({ id: data.data.id, title: data.data.title})
            // navigate(`/add-event/${data.data.id}`, {
            //     state: {
            //         timelineTitle: values.title
            //     }
            // })
        } catch (error) {
            enqueueSnackbar(t(error.message || "TimelineCreateError"), { variant: Enum.error })
            setLoading("")
        }
    }
    const handleCloseSelectCategory = () => {
        SetIsSelectCategoryOpen(false)
    }
    const handleOpenSelectCategory = (id) => {
        SetIsSelectCategoryOpen(id)
    }
    // console.log("isSelectCategoryOpen", isSelectCategoryOpen);
    const onSelectCategory = (cat) => {
        handleClose()
        navigate(`/add-event/${isSelectCategoryOpen.id}`, {
            state: {
                ...location.state,
                timelineTitle: isSelectCategoryOpen.title,
                category: cat.name
            }
        })
    }

    return (
        <>
        <SelectCategory onSelectCategory={onSelectCategory} open={isSelectCategoryOpen} handleClose={handleCloseSelectCategory} />
        <TimeLineForm handleClose={handleClose} loading={loading === Enum.CREATE} onSubmit={onSubmit} status='PRIVATE'/>
        </>
    )
}

export default CreateNewTimeLine;