import { useEffect } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import TimeLineHeader from "components/TimeLineHeader/TimeLineHeader";
import TimeLinesEmptyState from "components/TimeLinesEmptyState/TimeLinesEmptyState"
import Loader from "components/Loader/Loader"
import InfiniteScroll from 'react-infinite-scroller';
import useNetwork from "hooks/useNetwork";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import "./style.css";


export default function MyTimeLine({ data, onSearch, SearchValue, isMyTimeLines, fetch, renderItem, isGrid, totalPages, onCreate, currentPage, isLoading, onLayoutChange, headline, onFilter, isPublic = false, isShared = false }) {
    const location = useLocation();

    const { isOnline } = useNetwork();

    useEffect(() => {
        if (isOnline)
            fetch()
    }, [isOnline, location.search])



    if (isLoading) {
        return (
            <div className="loader-page">
                <Loader />
            </div>
        )
    }
    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={() => {
                if ((currentPage + 1) < totalPages && !isLoading) {
                    fetch(currentPage + 1, false)
                }
            }}
            hasMore={currentPage < totalPages}
            loader={isLoading  && <Loader />}
            useWindow={true}
        >
            <main className="timeline-page"> 
                <TimeLineHeader SearchValue={SearchValue} onSearch={onSearch} isShared={isShared} isMyTimeLines={isMyTimeLines} onFilter={onFilter} onCreate={onCreate} headline={headline}  onLayoutChange={onLayoutChange} isGrid={isGrid} />
                {!data.length && !isLoading && <div className="timeline-empty-state">
                    <TimeLinesEmptyState isPublic={isPublic} onCreate={onCreate} isShared={isShared} />
                </div>}
                {!!data.length && <>
                    <Container sx={{ mt: 2 }} className="timeline-wrapper container">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container className="timelines-grid" spacing={{
                                xs: 2,
                                sm: 2,
                                md: 4
                            }}>
                                {[...data].map((item, idx) => renderItem(item, idx))}
                            </Grid>
                        </Box>
                    </Container>
                </>}
            </main>
        </InfiniteScroll>

    );
}