import {
    Birthday,
    Memory,
    Graduation,
    MarriageEngagement,
    NewBaby,
    Achievements,
    Travel,
    Other,
} from "./SVGIcons/SVGIcons";
import {
    MarriageCover,
    AchievementCover,
    GraduationCover as GraduationCoverDesk,
    FamilyMemoryCover,
    MemorialCover as MemorialCoverDesk,
    NewbabyCover,
    BirthdayCover as BirthdayCoverDesk,
    TravelCover as TravelCoverDesk,
    OtherCover as OtherCoverDesk
} from "./SVGIcons/DesctopCover";

import {
    Birthday as BirthdayCover,
    Memory as MemoryCover,
    Graduation as GraduationCover,
    MarriageEngagement as MarriageEngagementCover,
    NewBaby as NewBabyCover,
    Achievements as AchievementsCover,
    Travel as TravelCover,
    Other as OtherCover,
    Memorial as MemorialCover,
    Memorial
} from "./SVGIcons/CoverCategory";

const CATEGORIES = {
    Birthday: {
        name: "Birthday",
        label: "Birthday",
        placeHolder: "BirthdayPlaceHolder",
        color: "#790E15",
        Icon: <Birthday color={"#790E15"} />,
        Cover: <BirthdayCover color={"#000000"} />,
        desktopCover: <BirthdayCoverDesk color={"#000000"} />,
    },
    Graduation: {
        name: "Graduation",
        label: "Graduation",
        placeHolder: "GraduationPlaceHolder",
        color: "#0084A6",
        Icon: <Graduation color={"#0084A6"} />,
        Cover: <GraduationCover color={"#000000"} />,
        desktopCover: <GraduationCoverDesk color={"#000000"} />,

    },

    MarriageEngagement: {
        name: "MarriageEngagement",
        label: "MarriageEngagement",
        placeHolder: "MarriageEngagementPlaceHolder",
        color: "#A157DE",
        Icon: <MarriageEngagement color={"#A157DE"} />,
        Cover: <MarriageEngagementCover color={"#000000"} />,
        desktopCover: <MarriageCover color={"#000000"} />,


    },


    NewBaby: {
        name: "NewBaby",
        label: "NewBaby",
        placeHolder: "NewBabyPlaceHolder",
        color: "#D6406E",
        Icon: <NewBaby color={"#D6406E"} />,
        Cover: <NewBabyCover color={"#000000"} />,
        desktopCover: <NewbabyCover color={"#000000"} />,


    },


    Achievements: {
        name: "Achievements",
        label: "Achievements",
        placeHolder: "AchievementsPlaceHolder",
        color: "#DB3D33",
        Icon: <Achievements color={"#DB3D33"} />,
        Cover: <AchievementsCover color={"#000000"} />,
        desktopCover: <AchievementCover color={"#000000"} />,


    },

    Travel: {
        name: "Travel",
        label: "Travel",
        placeHolder: "TravelPlaceHolder",
        color: "#D14A12",
        Icon: <Travel color={"#D14A12"} />,
        Cover: <TravelCover color={"#000000"} />,
        desktopCover: <TravelCoverDesk color={"#000000"} />,


    },

    FamilyMemory: {
        name: "FamilyMemory",
        label: "FamilyMemory",
        placeholder: "FamilyMemoryPlaceHolder",
        color: "#4A8721",
        Icon: <Memory color={"#4A8721"} />,
        Cover: <MemoryCover />,
        desktopCover: <FamilyMemoryCover color={"#000000"} />,


    },
    Memorial: {
        name: "Memorial",
        label: "Memorial",
        placeHolder: "MemorialPlaceHolder",
        color: "#4A8721",
        Icon: <Memory color={"#4A8721"} />,
        Cover: <Memorial />,
        desktopCover: <Memorial color={"#000000"} />,


    },

    Other: {
        name: "Other",
        label: "Other",
        placeHolder: "OtherPlaceHolder",
        color: "#76797A",
        Icon: <Other color={"#76797A"} />,
        Cover: <OtherCover color={"#000000"} />,
        desktopCover: <OtherCoverDesk color={"#000000"} />,


    },
}

export default CATEGORIES;
