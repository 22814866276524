import React from 'react'
import './style.css';
import CakeIcon from '../../../../assets/icons/cake_24px.svg';
import WeddingIcon from '../../../../assets/icons/wedding.svg';
import AchiveIcon from '../../../../assets/icons/achive.svg';

import Img1 from '../../../../assets/images/png/landing-01.png';
import Img2 from '../../../../assets/images/png/landing-02.png';
import Img3 from '../../../../assets/images/png/mic.svg';

function FamilyMemoryVector({ translate }) {
  return (
    <div className="family-tree-vector-container">
      <div>
        <div className="chevron"></div>
        <div className="content">
          <h3>
            <div className="icon">
              <img src={CakeIcon} alt="" />
            </div>
            {translate('birthday')}</h3>
          <div className="devider"></div>
          <p>{translate('birthday date')}</p>
        </div>
        <div className="image">
          <img src={Img1} alt="" />
        </div>
      </div>

      <div>
        <div className="chevron"></div>
        <div className="content">
          <h3>
            <div className="icon">
              <img src={WeddingIcon} alt="" />
            </div>
            {translate('married')}</h3>
          <div className="devider"></div>
          <p>{translate('married date')}</p>
        </div>
        <div className="image">
          <img src={Img2} alt="" />
        </div>
      </div>

      <div>
        <div className="chevron"></div>
        <div className="content">
          <h3>
            <div className="icon">
              <img src={AchiveIcon} alt="" />
            </div>
            {translate('new job')}</h3>
          <div className="devider"></div>
          <p>{translate('new job date')}</p>
        </div>
        <div className="image">
          <img src={Img3} alt="" />
        </div>
      </div>
    </div>
  )
}

export default FamilyMemoryVector
