
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import cls from "classnames";
import "./style.css";

const BottomSheet = ({ open, handleClose, className, children }) => {
    const bottomSheetClassName = cls("bottomsheet-root", {
        [className]: !!className
    })

    return (
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
        >
            <Box className={bottomSheetClassName}>
                <div className='bottomsheet-root--notch'>
                    <div />
                </div>
                {children}
            </Box>
        </Modal>
    )
}

export default BottomSheet;