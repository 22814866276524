

const SquareClose = ({ fill, color, stroke }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill={color || "#FBFBFB"} stroke={stroke || "#ECECEC"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.8639 28.9707L12.0147 14.1214L14.136 12.0001L28.9852 26.8494L26.8639 28.9707Z" fill={fill || "#202121"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.9706 14.1213L14.1213 28.9706L12 26.8492L26.8492 12L28.9706 14.1213Z" fill={fill || "#202121"} />
        </svg>
    )
}

export default SquareClose;