
const Gmail = () => {
    return (
        <svg width="46" height="35" viewBox="0 0 46 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3027 34.0079V16.4931L4.87088 11.5238L0 8.76617V30.917C0 32.6272 1.38563 34.0079 3.09089 34.0079H10.3027Z" fill="#4285F4" />
            <path d="M35.0293 34.0079H42.2411C43.9515 34.0079 45.332 32.622 45.332 30.917V8.7663L39.815 11.9248L35.0293 16.4931V34.0079Z" fill="#34A853" />
            <path d="M10.3026 16.4931L9.56348 9.6496L10.3026 3.09967L22.6658 12.3722L35.029 3.09967L35.856 9.29598L35.029 16.4931L22.6658 25.7656L10.3026 16.4931Z" fill="#EA4335" />
            <path d="M35.0293 3.09964V16.4931L45.332 8.76613V4.645C45.332 0.822771 40.9688 -1.35617 37.9143 0.936101L35.0293 3.09964Z" fill="#FBBC04" />
            <path d="M0 8.76609L4.73843 12.32L10.3027 16.4931V3.0996L7.41779 0.93606C4.35789 -1.35639 0 0.82273 0 4.64478V8.76591V8.76609Z" fill="#C5221F" />
        </svg>
    )
}

export default Gmail;