// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-timeline-wrapper {
    padding: 10px 25px 5px;
}

.create-timeline-wrapper .create-timeline-form-input-wrapper {
    margin-bottom: 25px;
}

.create-timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.create-timeline-form-input-wrapper .create-timeline-form-input--label {
    color: #202121;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyTimeLines/components/TimeLineDeleteForm/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".create-timeline-wrapper {\n    padding: 10px 25px 5px;\n}\n\n.create-timeline-wrapper .create-timeline-form-input-wrapper {\n    margin-bottom: 25px;\n}\n\n.create-timeline-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.create-timeline-form-input-wrapper .create-timeline-form-input--label {\n    color: #202121;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
