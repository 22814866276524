// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-cell-invitation .invitation-checkbox {
    display: none !important;
}

.role-select-btn, .role-select-btn:hover {
    background: #EFEFEF !important;
    border: none !important;
    box-shadow: none !important;
    color: #333333 !important;
    display: flex !important;
    gap: 12px !important;
    margin: 0 !important;
    justify-content: flex-end !important;
}

.popover-invitations {
    min-width: 313px !important;

}
.popover-invitations-row {
    padding: 10px 20px;
    margin: 0 !important;
    display: flex;
    gap: 25px;
}

.role-select-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.invite-friends--invited-users-item--delete-btn {
    display: flex !important; 
    gap: 33px !important;
    color: #C32B26 !important;
    font-weight: 100 !important; 
    align-items: center !important;  
    padding: 10px 30px !important;  
    width: 100%;

}
@media (max-width: 700px) {
    
.popover-invitations {
    min-width: 131px !important;
    }
.popover-invitations-row {

    gap: 10.5px;
}
.invite-friends--invited-users-item--delete-btn {
    gap: 10.5px !important;
}
}

`, "",{"version":3,"sources":["webpack://./src/pages/FamilyMemories/components/InviteFriends/invitations.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,8BAA8B;IAC9B,uBAAuB;IACvB,2BAA2B;IAC3B,yBAAyB;IACzB,wBAAwB;IACxB,oBAAoB;IACpB,oBAAoB;IACpB,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;;AAE/B;AACA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,wBAAwB;IACxB,oBAAoB;IACpB,yBAAyB;IACzB,2BAA2B;IAC3B,8BAA8B;IAC9B,6BAA6B;IAC7B,WAAW;;AAEf;AACA;;AAEA;IACI,2BAA2B;IAC3B;AACJ;;IAEI,WAAW;AACf;AACA;IACI,sBAAsB;AAC1B;AACA","sourcesContent":[".table-cell-invitation .invitation-checkbox {\n    display: none !important;\n}\n\n.role-select-btn, .role-select-btn:hover {\n    background: #EFEFEF !important;\n    border: none !important;\n    box-shadow: none !important;\n    color: #333333 !important;\n    display: flex !important;\n    gap: 12px !important;\n    margin: 0 !important;\n    justify-content: flex-end !important;\n}\n\n.popover-invitations {\n    min-width: 313px !important;\n\n}\n.popover-invitations-row {\n    padding: 10px 20px;\n    margin: 0 !important;\n    display: flex;\n    gap: 25px;\n}\n\n.role-select-btn-wrapper {\n    display: flex;\n    justify-content: flex-end;\n}\n.invite-friends--invited-users-item--delete-btn {\n    display: flex !important; \n    gap: 33px !important;\n    color: #C32B26 !important;\n    font-weight: 100 !important; \n    align-items: center !important;  \n    padding: 10px 30px !important;  \n    width: 100%;\n\n}\n@media (max-width: 700px) {\n    \n.popover-invitations {\n    min-width: 131px !important;\n    }\n.popover-invitations-row {\n\n    gap: 10.5px;\n}\n.invite-friends--invited-users-item--delete-btn {\n    gap: 10.5px !important;\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
