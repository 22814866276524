// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memory-story .memory-story-header{
    color: #fff; 
    height: 96px; 
    display: flex; 
    justify-content: space-between; 
    align-items: center;
    padding: 0 27px;
}

.memory-story-header .memory-story-header--title {
    color: #FBFBFB;
}

.memory-story-header .memory-story-header--title span {
    color: #FBFBFB;
    display: inline-block; 
    font-weight: 400;
    font-size: 12px;
}

.memory-story .memory-story-footer {
    height: var(--sticky-bottom-actions-height);
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(225, 223, 215, 0.2);
}

.memory-story .memory-story-footer div{
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/FamilyMemories/components/MemoryStory/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,2CAA2C;IAC3C,aAAa;IACb,mBAAmB;IACnB,8CAA8C;AAClD;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,WAAW;AACf","sourcesContent":[".memory-story .memory-story-header{\n    color: #fff; \n    height: 96px; \n    display: flex; \n    justify-content: space-between; \n    align-items: center;\n    padding: 0 27px;\n}\n\n.memory-story-header .memory-story-header--title {\n    color: #FBFBFB;\n}\n\n.memory-story-header .memory-story-header--title span {\n    color: #FBFBFB;\n    display: inline-block; \n    font-weight: 400;\n    font-size: 12px;\n}\n\n.memory-story .memory-story-footer {\n    height: var(--sticky-bottom-actions-height);\n    display: flex;\n    align-items: center;\n    border-top: 1px solid rgba(225, 223, 215, 0.2);\n}\n\n.memory-story .memory-story-footer div{\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
