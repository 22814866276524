
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useTranslation } from "react-i18next";
import CloseIcon from "components/Icons/Close";
import WarningIcon from "components/Icons/Warning";
import StyledButton from "components/StyledButton/StyledButton";


import "./style.css";



const TimeLineForm = ({ handleClose, loading, deleteConfirm }) => {

    const { t } = useTranslation(["timeline"]);



    return (
        <div className="create-timeline-wrapper">

            <div className="create-timeline-header">
                <div>
                    <CustomTypography type="headline">
                        {t("Are you sure you want to delete this event?")}
                    </CustomTypography>
                </div>
                <button onClick={handleClose} className="text-button">
                    <CloseIcon />
                </button>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: 19}}>
                <WarningIcon />
            </div>
            <div style={{marginBottom: 19}}>
                <CustomTypography type="normal">
                    {t("Deleting this event cannot be undone.")}
                      <span style={{
                        fontWeight: 700
                    }}>
                        {t("Is that what you want to do?")}                        
                    </span>
                </CustomTypography>
            </div>
            <div style={{
                marginBottom: 10
            }}>
                <StyledButton
                    color="warning"
                    variant="contained"
                    fullWidth                   
                    loading={loading}
                    onClick={deleteConfirm}
                    type="loading"

                >
                    <CustomTypography style={{color: '#fff'}} type="subHeadLine">
                        {t("Yes, delete it.")}
                    </CustomTypography>
                </StyledButton>
            </div>
            <div className="create-timeline-form">
               
                    <StyledButton
                        color="warning"
                        variant="outlined"
                        fullWidth
                        style={{
                            background: "#C32B261A"
                        }}
                        loading={loading}
                        onClick={handleClose}
                    >
                        <CustomTypography type="subHeadLine">
                        {t("No, I changed my mind.")}
                        </CustomTypography>
                    </StyledButton>
                    
            </div>
        </div>
    )
}

export default TimeLineForm;