

const LeftSquareArrow = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="#FBFBFB" stroke="#ECECEC" />
            <path d="M24 12.79L22.18 11L13 20L22.18 29L24 27.21L16.64 20L24 12.79Z" fill="#066F90" />
        </svg>


    )
}

export default LeftSquareArrow;