import EditEvent from "../../../EditEvent/EditEvent";
import ReusableModal from "components//Modals/ReusableModal/ReusableModal";
import  "./style.css";

const UpdateEventModal = ({ isOpen, onClose, refetch }) => {
    return (
        <ReusableModal        
            open={isOpen}
            className="update-event-desktop-modal"
        >
            <EditEvent onClose={onClose} refetch={refetch} />
        </ReusableModal>
    )
}

export default UpdateEventModal;