import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import StyledButton from "components/StyledButton/StyledButton";

import EmptyTimeLinePlaceholder from "components/Icons/EmptyTimeLine";
import CustomTypography from "components/CustomTypography/CustomTypography";

import "./style.css";

const EmptyState = ({onCreate, isPublic, isShared}) => {
    const { t } = useTranslation("timeline");
    const navigate = useNavigate();
    const location = useLocation();


    const onBack = () => {
        if (location.pathname === "/my-timelines") {
            isPublic ?     navigate(-1, {
          replace: true
        }) : navigate("/timelines")
        } else {
            isPublic ?     navigate(-1, {
          replace: true
        }) : navigate("/")
        }
    }

    const onAddNewTimeline = () => {
        if (location.pathname === "/my-timelines") {
            isPublic ? navigate("/my-timelines") : onCreate()
        } else {
            isPublic ? navigate("/my-timelines") : navigate("/my-timelines")
        }
    }

    return (
        <Container className="container">
            <div className="empty-timeline-wrapper">
                <EmptyTimeLinePlaceholder isPublic={isPublic} isShared={isShared} />
                <CustomTypography className="empty-timeline-wrapper--text" type="subHeadLine">
                    {!isShared ? (isPublic ? t("No public timelines have been shared yet.") : t("NoTimelinesTitle")) : t("No timelines have been shared with you")}
                    
                </CustomTypography>
                <CustomTypography className="empty-timeline-wrapper--text-description" type="normal">                    
                    {t("NoTimelinesDescription")}
                </CustomTypography>       
                <div className="empty-timeline-actions-wrapper">
                    <StyledButton className="empty-timeline-create-btn" onClick={onAddNewTimeline} color="primary" fullWidth variant="contained">
                        {isPublic || isShared ? t("go to my timelines") : t("create new timeline")}                
                    </StyledButton>
                    <StyledButton onClick={onBack} className="empty-timeline-wrapper---back-button" color="primary" fullWidth variant="contained">
                        <span>
                            {isPublic || isShared ? t("Back to home") : t("discover public timelines")}                
                        </span>
                    </StyledButton>
                </div>         
            </div>
        </Container>
    )
}

export default EmptyState;
