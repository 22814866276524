import Button from "@mui/material/Button";
import CustomTypography from "components/CustomTypography/CustomTypography";
import ReusableModal from "components/Modals/ReusableModal/ReusableModal";
import CloseIcon from "components/Icons/Close";
import StyledButton from "components/StyledButton/StyledButton";
import { useTranslation } from "react-i18next";

import "./style.css";

const PrivacyPolicy = ({ onSubmit, onClose, open}) => {
    const { t } = useTranslation(["common"]);

    return (
        <ReusableModal open={open}>
            <div className="privacy-policy-wrapper">
                <div className="privacy-policy-header">
                    <div>
                        <CustomTypography type="headline">
                            {t("privacyTitle")}
                        </CustomTypography>
                    </div>
                    <div>
                        <button onClick={onClose} className="text-button">
                            <CloseIcon />                        
                        </button>
                    </div>
                </div>
                <div className="privacy-policy-content">
                    <CustomTypography className="privacy-policy-content--p" type="normal">
                        {t("privacyDescription")}
                    </CustomTypography>
                </div>
                <div className="privacy-policy-actions">
                    <StyledButton
                        className="submitBtn"
                        variant="contained"
                        onClick={onSubmit}
                    >
                        {t("gotit")}
                    </StyledButton>
                </div>
            </div>
        </ReusableModal>
    )
}

export default PrivacyPolicy;