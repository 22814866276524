import { useTranslation } from "react-i18next";
import StickyBottomActions from "components/StickyBottomActions/StickyBottomActions";
import StyledButton from "components/StyledButton/StyledButton";
import  "./style.css";

const MyTimelineHotActions = ({ onCreate, disabled }) => {
    const { t } = useTranslation("timeline");

    return (
        <StickyBottomActions className="my-timeline-hot-actions">         
            {/* <button className="text-button" disabled={disabled} role="button" onClick={onCreate}>
                <Plus disabled={disabled} />
            </button>            */}
            <StyledButton 
                disabled={disabled}  onClick={onCreate}
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                >
                {/* <Plus disabled={disabled} /> */}
                {t("create new timeline")}
            </StyledButton>
        </StickyBottomActions>
    )
}

export default MyTimelineHotActions;