/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState, useRef } from "react";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import MemoryCard from "./components/MemoryCard/MemoryCard";
import CustomTypography from "../../components/CustomTypography/CustomTypography";
import Filter from "../../components/Icons/Filter";
import InviteIcon from "../../components/Icons/Invite";
import FamilyMemoriesSkeleton from "./components/FamilyMemoriesSkeleton/FamilyMemoriesSkeleton";
import InviteFriends from "./components/InviteFriends/InviteFriends";
import ShareMemories from "./components/ShareMemories/ShareMemories";
import FilterMemories from "./components/FilterMemories/FilterMemories";
import MemoryStory from "./components/MemoryStory/MemoryStory";
import { setFamilyMemories } from "redux/slices/familyMemoriesSlice";
import { EVENTS, EVENTS_BY_TIMELINE_ID } from "../../api/endpoints";
import BottomSheet from "../../components/Modals/BottomSheet/BottomSheet";
import MemoryStoryModal from "../../components/Modals/MemoryStoryModal/MemoryStoryModal";
import EmptyEvents from "./components/EmptyEvents/EmptyEvents";
import BreadcrumbsCustom from "../../components/Breadcrumbs/Breadcrumbs";
import { websiteIstance } from "../../api/axios";
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "components/Loader/Loader"
import UseWidth from "hooks/useUpdateWindowDimensions";
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";
import Back from "assets/images/svg/back.svg";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import GreenCorrect from "assets/icons/green-correct.svg";
import mapKeys from "lodash/mapKeys";
import SelectCategory from "components/SelectCategory";
// import {
//     AddEvent,
// } from "pages";
import "./style.css";
import moment from "moment";
import cls from "classnames";
import "./style.css";

import Plus from "components/Icons/Plus";


class Enum {
    static FETCH_TIMELINE_EVENTS = "FETCH_TIMELINE_EVENTS";
    static FETCH_MORE_TIMELINE_EVENTS = "FETCH_MORE_TIMELINE_EVENTS";
}


const FamilyMemories = () => {
    const { t } = useTranslation(["memories"]);
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState("")
    const [currentPage, setCurrentPage] = useState(0)
    const [pageTitle, setPageTitle] = useState("")
    const [eventsCount, setEventsCount] = useState("")
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const searchParams = Object.fromEntries(new URLSearchParams(location.search));

    const { id } = params;
    const [isInviteFriendsModalOpen, SetIsInviteFriendsModalOpen] = useState(false);
    const [isSharedWithMe, SetIsSharedWithMe] = useState(false);
    const [isMemoryStoryModalOpen, SetIsMemoryStoryModalOpen] = useState(false);
    const [isShareMemoriesModalOpen, SetIsShareMemoriesModalOpen] = useState(false);
    const [isFilterMemoriesModalOpen, SetIsFilterMemoriesModalOpen] = useState(false);
    const [isSelectCategoryOpen, SetIsSelectCategoryOpen] = useState(false);
    const queryEndpoint = EVENTS.replace(":id", id).concat(location.search);
    const [creatorId, setCreatorId] = useState(null)
    const dispatch = useDispatch();
    const data = useSelector((state) => state.familyMemories.familyMemories);
    const pageData = data[id] || {};
    const familyMemoriesList = data[id]?.events ?? [];
    const timelines = useSelector((state) => state.myTimeLines.data.timelines);
    const userId = +localStorage.getItem("userId")
    const wrapperRef = useRef(null)
    const width = UseWidth()
    const isPublic = location?.state?.isPublic ?? false
    const isOwner = location?.state?.isOwner ?? false
    const isShared = location?.state?.isShared ?? false
    const [showAction, setShowAction] = useState(false)
    const [showAddEvent, setShowAddEvent] = useState(false)
    const filters = { ...searchParams };
    delete filters["sortType"];
    delete filters["sortBy"];
    const hasFilters = !!Object.keys(filters)?.length

    const [contributer, setContributer] = useState(false)

    // console.log(userId)

    async function fetchData(page = 0, clearState = true) {
        try {
            // if (!userId) return;
            if (clearState) {
                setCurrentPage(0)
            }
            setLoading(page ? Enum.FETCH_MORE_TIMELINE_EVENTS : Enum.FETCH_TIMELINE_EVENTS)
            const response = await websiteIstance.request({
                method: "get",
                url: queryEndpoint.includes("?") ? queryEndpoint.concat(`&page=${page}&size=10`) : queryEndpoint.concat(`?page=${page}&size=10`)
            });
            const data = response?.data?.data;
            // if ((!page && !location.search)) {
            //     setCreatorId(data.creator_id)
            //     setPageTitle(data.title)
            // }
            setEventsCount(data.totalElements)
            dispatch(setFamilyMemories({ data, id, clearState }))
            setLoading("")

        } catch (error) {
            setLoading("");
            console.log(error)
        }
    }
    async function fetchTitle() {
        try {
            if (userId || isPublic) {

                const response = await websiteIstance.request({
                    method: "get",
                    url: EVENTS_BY_TIMELINE_ID.replace(":id", id)
                });
                const data = response?.data?.data;
                // console.log(data)
                setCreatorId(data.creator_id)
                setPageTitle(data.title)
                const contributors = data?.contributors ?? [];
                const contributersDictionary = mapKeys(contributors, "id")
                const isImContributer = !!contributersDictionary[userId];
                const cont = contributors.filter(user => user.id.userId === userId)
                if(cont) setContributer(cont[0].role === 'EDITOR' ? true : false)
                // if (cont) setShowAction(cont[0].role === 'EDITOR')
                // if(data.creator_id) setShowAction(true)

                SetIsSharedWithMe(isImContributer);
            } else {
                const title = timelines && timelines[0].title;
                setPageTitle(title)

            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (location?.state && location?.state?.from === "addEvent") {
            location.state.from = ""
            if (localStorage.getItem("toast"))
                setSnackbarOpen(true)
        }
    }, [])

    useEffect(() => {
        fetchData()
    }, [queryEndpoint, snackbarOpen])
    useEffect(() => {
        fetchTitle()
    }, [])


    useEffect(() => {
        setShowAction(userId === creatorId || !creatorId || contributer)
    }, [creatorId])

    const renderFamilyMemoriesList = useMemo(() => {
        if (familyMemoriesList && Array.isArray(familyMemoriesList) && familyMemoriesList.length) {
            const FamilyMemoriesDictionary = {

            }
            familyMemoriesList.forEach((item) => {
                const year = item?.date ? `y_${item?.date?.year}` : item?.year;
                if (FamilyMemoriesDictionary[year]) {
                    FamilyMemoriesDictionary[year] = [...FamilyMemoriesDictionary[year], { ...item }]
                } else {
                    FamilyMemoriesDictionary[year] = [{ ...item }]
                }
            })
            return Object.entries(FamilyMemoriesDictionary).map(([year, data]) => {
                return <>
                    <CustomTypography style={width >= 700 ? {
                        paddingInlineStart: 168,
                        marginBottom: 20
                    } : {}} type="headline" className="family-memories-page-sub-headline">
                        {year.replace("y_", "")}
                    </CustomTypography>
                    {data.map((memory, i) => (
                        <MemoryCard
                            key={memory.id + " " + i}
                            {...memory}
                            width={width}
                            onClick={() => memoryStoryModalToggle(memory.id)}
                        />
                    ))}
                </>
            })

        }
        if (loading === Enum.FETCH_TIMELINE_EVENTS) {
            return (
                <FamilyMemoriesSkeleton />
            )
        }


        if (familyMemoriesList && Array.isArray(familyMemoriesList) && !familyMemoriesList.length) {
            return (
                <EmptyEvents hasFilters={hasFilters} isPublic={isPublic} onAddNewEvent={onAddNewEvent} isOwner={isOwner} isSharedWithMe={isSharedWithMe} showActions={showAction} timelineTitle={pageTitle} timelineId={id} />
            )
        }
    }, [loading, familyMemoriesList, showAction])

    const inviteFriendsModalToggle = () => {
        SetIsInviteFriendsModalOpen((prevValue) => !prevValue)
    }
    function memoryStoryModalToggle(eventId) {
        navigate(`/event-details/${id}/${eventId}`, {
            state: location.state
        })
        // SetIsMemoryStoryModalOpen((prevValue) => !prevValue)
    }
    function shareMemoriesModalToggle() {
        SetIsShareMemoriesModalOpen((prevValue) => !prevValue)
    }
    function filterMemoriesToggle() {
        SetIsFilterMemoriesModalOpen((prevValue) => !prevValue)
    }

    function sortToggle(isFirst) {
        if (!searchParams["sortBy"]) {
            searchParams["sortBy"] = "date"
        }

        searchParams["sortType"] = (searchParams["sortType"] === "DESC" && !isFirst) ? "ASC" : "DESC"

        const result = '?' + new URLSearchParams(searchParams).toString();
        navigate(result, {
            state: location.state,
            replace: true
        })

    }

    const sortButtonClassname = cls("text-button")
    var loadmore = (e) => {
        if (loading === Enum.FETCH_MORE_TIMELINE_EVENTS || loading === Enum.FETCH_TIMELINE_EVENTS || currentPage === pageData.page + 1)
            return
        if (pageData.page < pageData.totalPages) {
            setCurrentPage(pageData.page + 1)
            fetchData(pageData.page + 1, false)
        }
    }
    const infinitscrollOptions = width >= 899 ? {
        useWindow: false,
        getScrollParent: () => wrapperRef.current
    } : {
        useWindow: true,
    }

    function onAddNewEvent() {
        localStorage.setItem("isTooltipDismiss", "true")
        if (Array.isArray(familyMemoriesList) && familyMemoriesList.length < 2 || userId) {
            handleOpenSelectCategory()
            // navigate(`/add-event/${id}`, {
            //     state: {
            //         ...location.state,
            //         timelineTitle: pageTitle
            //     }
            // })
        } else {
            dispatch(setLoginRegisterModalOpen(true))
        }
    }

    // useEffect(() => {
    //     if (location?.state?.action === "create_new_modal" && timelines?.[0]?.id) {
    //         // onAddNewEvent()
    //         // navigate(`/timelines/${timelines?.[0]?.id}`)
    //     }
    // }, [])
    const onBack = () => {
        if (hasFilters && familyMemoriesList && Array.isArray(familyMemoriesList) && !familyMemoriesList.length) {
            return navigate(window.location.pathname, {
                replace: true

            })
        }
        navigate(-1, {
            replace: true
        })
    }

    const handleCloseSelectCategory = () => {
        SetIsSelectCategoryOpen(false)
    }
    const handleOpenSelectCategory = () => {
        SetIsSelectCategoryOpen(true)
    }
    const onSelectCategory = (cat) => {
        navigate(`/add-event/${id}`, {
            state: {
                ...location.state,
                timelineTitle: pageTitle,
                category: cat.name
            }
        })
    }


    const renderList = () => {
        // console.log(pageTitle)
        return (

            <InfiniteScroll
                pageStart={0}
                loadMore={loadmore}
                hasMore={pageData.page < pageData.totalPages - 1}
                loader={loading && <Loader />}
                {...infinitscrollOptions}
            >
                <div className="family-memories-page--header">
                    <SelectCategory onSelectCategory={onSelectCategory} open={isSelectCategoryOpen} handleClose={handleCloseSelectCategory} className='category-list' />
                    {(!isPublic && !isShared) && <div className="family-memories-page-breadcrumbs">
                        <BreadcrumbsCustom count={eventsCount} list={[
                            {
                                path: "/my-timelines",
                                name: t("My Timelines")
                            },
                            {
                                path: "",
                                name: pageTitle
                            }
                        ]} />
                    </div>}
                    {isPublic && <div className="family-memories-page-breadcrumbs">
                        <BreadcrumbsCustom count={eventsCount} list={[
                            {
                                path: "/timelines",
                                name: t("Public Timelines")
                            },
                            {
                                path: "",
                                name: pageTitle
                            }
                        ]} />
                    </div>}

                    {
                        isShared && <div className="family-memories-page-breadcrumbs">
                            <BreadcrumbsCustom count={eventsCount} list={[
                                {
                                    path: "/shared-timelines",
                                    name: t("Shared Timelines")
                                },
                                {
                                    path: "",
                                    name: pageTitle
                                }
                            ]} />
                        </div>
                    }

                    <CustomTypography type="headline" className="family-memories-page-headline">
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                backgroundColor: "#F3F3F3",
                                marginInlineEnd: "10px",
                            }}
                            onClick={onBack}
                        >
                            <img src={Back} alt={t("Back")} className="backIconRotate" />
                        </div>
                        {!isFilterMemoriesModalOpen && hasFilters && familyMemoriesList && Array.isArray(familyMemoriesList) && !familyMemoriesList.length && <div>
                            {t("No results found")}
                        </div>}
                        {((hasFilters && familyMemoriesList && Array.isArray(familyMemoriesList) && familyMemoriesList.length) || !hasFilters) && pageTitle}
                    </CustomTypography>
                    <div className="family-memories-page--header---actions">
                        {/* Changed By Waleed */}

                        {
                            (!isPublic && showAction) &&
                            <button onClick={onAddNewEvent} className="addEventButton">
                                +{t("ADD YOUR EVENT")}
                            </button>
                        }
                        {!!userId && <>
                            {/* <button onClick={()=>sortToggle()} className={sortButtonClassname}>
                                {searchParams.sortType === "ASC" ? <AscSort /> : <Sort />}
                            </button> */}
                            {familyMemoriesList && Array.isArray(familyMemoriesList) && !!familyMemoriesList.length && <button onClick={filterMemoriesToggle} className="text-button">
                                <Filter />
                            </button>}
                            {console.log(userId === creatorId)}
                            {(!isPublic && !isShared && (userId === creatorId)) && <>

                                <button onClick={inviteFriendsModalToggle} className="text-button">
                                    <InviteIcon isOutline />
                                </button>
                            </>}
                        </>}
                    </div>
                </div>
                <div className="family-memories-page-wrapper--content-public-list" ref={wrapperRef} >

                    {!isPublic && renderFamilyMemoriesList}
                </div>
                {isPublic && <div className="family-memories-page-wrapper--content-public-list" ref={wrapperRef} >
                    {renderFamilyMemoriesList}
                </div>}
            </InfiniteScroll>
        )
    }
    return (
        <Container className="family-memories-page--container container">
            <BottomSheet handleClose={inviteFriendsModalToggle} open={isInviteFriendsModalOpen}>
                <InviteFriends timelineId={id} />
            </BottomSheet>
            <BottomSheet handleClose={shareMemoriesModalToggle} open={isShareMemoriesModalOpen}>
                <ShareMemories handleClose={shareMemoriesModalToggle} url={window.location.href} />
            </BottomSheet>
            <BottomSheet handleClose={filterMemoriesToggle} open={isFilterMemoriesModalOpen}>
                <FilterMemories onClose={filterMemoriesToggle} url={window.location.href} />
            </BottomSheet>

            <MemoryStoryModal handleClose={memoryStoryModalToggle} open={isMemoryStoryModalOpen}>
                <MemoryStory open={isMemoryStoryModalOpen} handleClose={memoryStoryModalToggle} />
            </MemoryStoryModal>
            <div className="family-memories-page-wrapper">
                {(!isPublic || isSharedWithMe) && <div className="family-memories-page-wrapper--content" ref={wrapperRef} >
                    {renderList()}
                </div>}
                {(isPublic && !isSharedWithMe) && <div className="family-memories-page-wrapper--content-public" ref={wrapperRef} >
                    {renderList()}
                </div>}


                {/* {(!isPublic || isSharedWithMe) && showAddEvent && <div className="family-memories-page-wrapper--form">
                    <AddEvent setSnackbarOpen={setSnackbarOpen} resetAddEvent={resetAddEvent} />
                </div>} */}
            </div>
            {/* {((showAction && !isPublic) || isSharedWithMe) && <FamilyMemoriesHotActions
                shareMemoriesModalToggle={shareMemoriesModalToggle}
                inviteFriendsModalToggle={inviteFriendsModalToggle}
                timelineId={id}
                onAddNewTimeline={onAddNewEvent}
                isSharedWithMe={isSharedWithMe}
            />} */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    localStorage.removeItem("toast");
                    setSnackbarOpen(false);
                }}
                className="alert-message-container"
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    icon={false}
                    style={{
                        background: "#252525",
                        minHeight: "66px",
                        padding: "8px 15px",
                        color: "#C3E070",
                        fontSize: "14px",
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                    className="alert-message"
                >
                    <img className="alert-message-icon" src={GreenCorrect} />
                    <div>
                        <span style={{ fontWeight: "700" }}>{t("Great job, ")}</span>
                        {t("you’ve added an event to your timeline.")}
                    </div>
                </Alert>
            </Snackbar>
            {/* {console.log(showAddEvent)} */}
            {(!isPublic && showAction) &&
                <div className="floated-add-event" role="button" onClick={onAddNewEvent}>
                    <Plus />
                </div>
            }
        </Container>
    )
}

export default FamilyMemories;