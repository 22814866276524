import Plus from "../PlusIcon";
import { useTranslation } from "react-i18next";
import noMediaImage from '../../../../assets/images/png/no-media.png';

export default function EventDetailsImageGallery({ images, handleClickOnImage, uploadMedia, width, showActions, audio }) {
  const { t } = useTranslation(["add-event"]);
  const remainingImages = width > 1024 ? images.length - 4 : images.length - 3;
  // console.log(images)

  return (
    <div className="event-images" style={{ display: "flex", flexDirection: "column", position: 'relative', gap: '8px', width: '100%' }}>
      {
        images.length > 0 ?
          <>
            <img
              src={images[0]}
              alt={images[0]?.alt}
              style={{
                width: "100%",
                height: "53.9vh",
                objectFit: "cover",
                // padding: "5px",
                // borderRadius: "2px",
              }}
              onClick={() => handleClickOnImage(0)}
            />
            <div style={{ display: "flex", gap: '8px', height: '26.24%', width: '100%'}}>
              {images.slice(1, 4).map((image, index) => {
                return (
                  <div key={index} style={{ width: "calc(100%/3)", position: "relative" }}>
                    <img
                      src={image}
                      alt={image.alt}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        // padding: "5px",
                        // borderRadius: "10px",
                        height: '16.4vh'
                      }}
                      onClick={() => handleClickOnImage(index + 1)}
                    />
                    {index === 2 && images.length > 4 && (
                      <div
                        style={{
                          position: "absolute",
                          color: "#FBFBFB",
                          top: "0px",
                          left: "0px",
                          fontSize: "26px",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // borderRadius: "6px",
                          width: "100%",
                          height: "calc(100% - 6px)",
                          // padding: "5px",
                        }}
                        onClick={() => handleClickOnImage(index + 1)}
                      >
                        {`+ ${remainingImages}`}

                      </div>

                    )}
                  </div>
                );
              })}
            </div>
          </>
          :
          <div className={`${audio ? '' : 'no-audio'} no-media-image`}>
            <img src={noMediaImage} />
          </div>
      }
    </div>
  )
}