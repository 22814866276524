// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-wrapper .breadcrumbs-active-item{
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-decoration: none;
        color: var(--secondary-text-color);
        display: flex;
        gap: 5px;
}
.breadcrumbs-wrapper .breadcrumbs-active-item span{
        font-size: 18px;
        font-weight: 400;
}
.breadcrumbs-wrapper a{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.6px;
        text-decoration: none;
        color: var(--secondary-text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/style.css"],"names":[],"mappings":"AAAA;QACQ,eAAe;QACf,gBAAgB;QAChB,iBAAiB;QACjB,mBAAmB;QACnB,qBAAqB;QACrB,kCAAkC;QAClC,aAAa;QACb,QAAQ;AAChB;AACA;QACQ,eAAe;QACf,gBAAgB;AACxB;AACA;QACQ,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;QACf,mBAAmB;QACnB,qBAAqB;QACrB,kCAAkC;AAC1C","sourcesContent":[".breadcrumbs-wrapper .breadcrumbs-active-item{\n        font-size: 18px;\n        font-weight: 700;\n        line-height: 25px;\n        letter-spacing: 0em;\n        text-decoration: none;\n        color: var(--secondary-text-color);\n        display: flex;\n        gap: 5px;\n}\n.breadcrumbs-wrapper .breadcrumbs-active-item span{\n        font-size: 18px;\n        font-weight: 400;\n}\n.breadcrumbs-wrapper a{\n        font-style: normal;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 19.6px;\n        text-decoration: none;\n        color: var(--secondary-text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
