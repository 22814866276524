import React from "react";

const Plus = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.5625 9.5625H14.4375C14.5867 9.5625 14.7298 9.50324 14.8352 9.39775C14.9407 9.29226 15 9.14918 15 9C15 8.85082 14.9407 8.70774 14.8352 8.60225C14.7298 8.49676 14.5867 8.4375 14.4375 8.4375H9.5625V3.5625C9.5625 3.41332 9.50324 3.27024 9.39775 3.16475C9.29226 3.05926 9.14918 3 9 3C8.85082 3 8.70774 3.05926 8.60225 3.16475C8.49676 3.27024 8.4375 3.41332 8.4375 3.5625V8.4375H3.5625C3.41332 8.4375 3.27024 8.49676 3.16475 8.60225C3.05926 8.70774 3 8.85082 3 9C3 9.14918 3.05926 9.29226 3.16475 9.39775C3.27024 9.50324 3.41332 9.5625 3.5625 9.5625H8.4375V14.4375C8.4375 14.5867 8.49676 14.7298 8.60225 14.8352C8.70774 14.9407 8.85082 15 9 15C9.14918 15 9.29226 14.9407 9.39775 14.8352C9.50324 14.7298 9.5625 14.5867 9.5625 14.4375V9.5625Z" fill="#0084A6" />
        </svg>

    )
}

export default Plus;