// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.styled-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    height: 35px;
    box-shadow: none;
    border-radius: 4px;
    font-family: 'verdana';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}

  button.styled-button-wrapper.outlined {
      font-weight: 400;
  }


@media (min-width: 700px) {
    button.styled-button-wrapper {
        padding: 9px 20px !important;
        height: 44px !important;        
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 16px !important;
        line-height: 20px !important;
    }   

    [lang='fr'] button.styled-button-wrapper {
        font-size: 0.9375rem!important;
    }

}`, "",{"version":3,"sources":["webpack://./src/components/StyledButton/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;EAEE;MACI,gBAAgB;EACpB;;;AAGF;IACI;QACI,4BAA4B;QAC5B,uBAAuB;QACvB,6BAA6B;QAC7B,2BAA2B;QAC3B,0BAA0B;QAC1B,4BAA4B;IAChC;;IAEA;QACI,8BAA8B;IAClC;;AAEJ","sourcesContent":["button.styled-button-wrapper {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding: 12px 20px;\n    height: 35px;\n    box-shadow: none;\n    border-radius: 4px;\n    font-family: 'verdana';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 17px;\n}\n\n  button.styled-button-wrapper.outlined {\n      font-weight: 400;\n  }\n\n\n@media (min-width: 700px) {\n    button.styled-button-wrapper {\n        padding: 9px 20px !important;\n        height: 44px !important;        \n        font-style: normal !important;\n        font-weight: 700 !important;\n        font-size: 16px !important;\n        line-height: 20px !important;\n    }   \n\n    [lang='fr'] button.styled-button-wrapper {\n        font-size: 0.9375rem!important;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
