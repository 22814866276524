import * as React from 'react';
import BreadcrumbsMui from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import "./style.css";

export default function BreadcrumbsCustom({list=[], count}) {
    const { t } = useTranslation(["memories"]);

    const lastBreadCrumb = list.pop();
    const renderLastBreadCrumb = () =>{

// console.log(lastBreadCrumb)
        if(list.length){
            return (
                <div                   
                    color="text.primary"
                    to={"/"}
                    aria-current="page"
                    className='breadcrumbs-active-item'
                >
                    {lastBreadCrumb.name}
                    {!!count && <span>“{count} {t("Events")}”</span>}
                </div>
            )
        }
        return null;
    }
    return (
        <div className='breadcrumbs-wrapper' role="presentation" >
            <BreadcrumbsMui aria-label="breadcrumb">
                {list.map((item, i) => (              
                    <Link                       
                        color="inherit"
                        to={item.path}
                        key={item.path + "_" + i}
                    >
                        {item.name}
                    </Link>                
                ))}
                {renderLastBreadCrumb()}
            </BreadcrumbsMui>
        </div>
    );
}