
const EmptyEvents = () => {
    return (
        <svg width="184" height="178" viewBox="0 0 184 178" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3947 50.1587H10.8066V140.867H17.3947V50.1587Z" fill="#FE5E5A" />
            <path d="M12.8327 43.855L10.8066 50.158H17.3947L15.3686 43.855H12.8327Z" fill="white" />
            <path d="M14.1019 39.9121L12.834 43.8553H15.3699L14.1019 39.9121Z" fill="#FF635C" />
            <path d="M44.9652 46.0532H38.377V136.761H44.9652V46.0532Z" fill="#6D7087" />
            <path d="M40.4032 39.7495L38.377 46.0525H44.9652L42.9389 39.7495H40.4032Z" fill="white" />
            <path d="M41.6722 35.8066L40.4043 39.7498H42.94L41.6722 35.8066Z" fill="#3B3E54" />
            <path d="M27.264 56.3555H20.6758V147.064H27.264V56.3555Z" fill="#3B3E54" />
            <path d="M22.702 50.0518L20.6758 56.3548H27.264L25.2377 50.0518H22.702Z" fill="white" />
            <path d="M23.9709 46.1089L22.7031 50.0521H25.2388L23.9709 46.1089Z" fill="#FF9F9C" />
            <path d="M163.445 108.221C163.445 146.655 132.288 177.809 93.8576 177.809C55.9971 177.809 25.1887 147.568 24.2849 109.923C24.2697 109.359 24.2637 108.792 24.2637 108.221C24.2637 93.0614 29.1137 79.0298 37.3459 67.5976C49.9792 50.0535 70.5838 38.6274 93.8576 38.6274C113.871 38.6274 131.915 47.0749 144.606 60.6061C154.871 71.5438 161.641 85.7969 163.133 101.588C163.339 103.772 163.445 105.983 163.445 108.221Z" fill="#458FB0" />
            <path d="M100.73 92.4488L98.1575 96.2191L93.1861 100.505C93.1861 100.505 92.4703 107.703 91.7999 108.218C91.1296 108.734 94.3842 112.504 94.3842 112.504L96.2163 117.133L101.755 117.819L108.267 116.105L115.465 116.62L118.38 117.822L115.638 122.963L120.267 129.991L117.694 137.704L119.581 144.902L121.98 151.245C121.98 151.245 120.952 154.329 121.98 154.329C123.009 154.329 127.122 154.329 127.122 154.329L137.234 146.789V138.22C137.234 138.22 143.234 131.022 143.577 130.506C143.92 129.991 145.976 122.28 145.976 122.28L151.46 115.938L148.888 111.825L144.602 110.624L137.574 100.511L135.175 94.5114L128.662 92.4549L123.691 95.1969L120.949 92.4549L118.207 89.8827L116.836 87.6533L109.123 90.0526L103.981 89.7098L100.73 92.4488Z" fill="#63ADCE" />
            <path d="M49.822 114.734L45.1934 120.991L47.5077 132.39L52.0514 135.991L58.2209 140.962L60.6202 149.877L67.9909 160.505C67.9909 160.505 71.0756 167.876 71.934 168.219C72.7924 168.561 76.7327 171.989 76.7327 171.989L83.5876 171.303V169.074C83.5876 169.074 78.2735 163.59 77.9307 162.904C77.5879 162.219 77.7608 155.191 77.9307 154.335C78.1006 153.477 82.3865 146.109 82.3865 146.109L81.1853 140.795C81.1853 140.795 84.9557 135.654 84.9557 134.796C84.9557 133.937 84.0972 130.167 84.0972 130.167L83.0689 123.7L74.3273 120.994L67.8149 121.765L65.0729 117.652L57.8751 116.793L49.822 114.734Z" fill="#63ADCE" />
            <path d="M154.375 98.106L151.463 99.4801L156.432 104.279L151.463 108.907H147.004L143.404 101.54L138.775 97.4266L135.175 94.5117L141.002 89.2005L135.175 87.6566L132.26 85.4242C132.26 85.4242 127.807 85.4242 125.921 86.6284C124.034 87.8265 121.58 84.7417 121.58 84.7417L115.805 82.1695L112.72 82.852L108.78 86.7952H103.808L101.752 89.1975L97.293 85.9368L101.066 82.1665H107.236L108.434 80.2798L106.208 79.5974L106.032 77.7107L108.947 76.6825L105.862 73.9404L103.46 74.6229H100.205V72.9092L105.346 69.8244L109.975 74.6229H111.689L114.091 72.3965L116.493 73.079L120.776 74.6229L124.889 73.7706C124.889 73.7706 129.861 69.1419 129.861 68.6293C129.861 68.1167 127.458 66.9125 127.458 66.9125C127.458 66.9125 125.056 66.9125 124.543 66.9125C124.031 66.9125 125.917 62.6296 125.917 62.6296L121.577 64.856C121.577 64.856 121.974 67.9407 121.577 68.6293C121.18 69.3117 119.232 70.5099 119.232 70.5099C119.232 70.5099 115.289 67.598 114.773 68.1136C114.258 68.6293 112.377 68.1136 112.377 68.1136L110.833 65.1987L114.946 64.1705L117.003 61.0857L124.37 56.457C124.37 56.457 127.801 56.9727 128.999 57.6551C130.197 58.3437 132.6 58.6834 132.6 58.6834L130.027 61.4284L132.43 64.1705H136.722L143.577 61.4284L144.605 60.6064C154.87 71.5442 161.64 85.7972 163.132 101.588L161.06 100.848L154.375 98.106Z" fill="#63ADCE" />
            <path d="M61.4791 84.7354C61.4791 85.7636 58.7339 89.1942 58.7339 89.1942L54.451 91.2507L49.4797 96.0492L48.6273 104.445L47.4231 108.904H43.6529L41.7661 105.473L38.1657 104.445L34.7412 107.706L35.2569 111.13C35.2569 111.13 39.5398 114.045 39.7097 114.731C39.8856 115.419 40.7378 117.476 40.7378 117.476L45.1967 119.702V122.105L36.1092 117.303L26.5152 114.731L24.2888 109.923C24.2736 109.359 24.2676 108.792 24.2676 108.222C24.2676 93.0615 29.1176 79.0299 37.3498 67.5977L37.829 67.252L41.8541 70.5066V73.7673L39.5398 75.8238L35.4268 77.1918V78.7357L39.8856 78.2201L43.31 76.5063L46.9105 73.7642H49.8253L47.4231 75.9906L43.31 80.4495L40.5681 84.2197L41.8541 86.9617L44.3383 85.7636L44.5143 87.6503L46.228 89.8767L48.6303 85.248L49.6586 80.6193L53.4289 78.3929L55.6552 79.4212H59.9442C59.9351 79.4242 61.4791 83.7071 61.4791 84.7354Z" fill="#63ADCE" />
            <path d="M57.8751 62.6266H61.4755V65.8812L63.3623 67.2522L64.0477 75.3084H67.3023L69.7046 70.5099L74.8459 64.3403L78.6163 61.7681L84.9586 57.1395L85.817 51.1398L89.9301 46.3412L86.1598 44.9702L82.9022 46.1714L73.9027 50.4543L68.6764 51.9982L63.0194 54.9131L53.7622 57.4822L49.1334 60.1393V64.1674L45.1934 67.2522L49.822 67.9377L52.9067 65.3686L55.6488 62.2838L57.8751 62.6266Z" fill="#63ADCE" />
            <path d="M27.4668 24.7752L93.427 49.5475V0L27.4668 24.7752Z" fill="#6D7087" />
            <path d="M93.4277 0V49.5475L159.385 24.7752L93.4277 0Z" fill="#5E6175" />
            <path d="M131.711 25.8188H55.1465V50.3332H131.711V25.8188Z" fill="#3B3E54" />
            <path d="M93.4286 29.0313C114.571 29.0313 131.711 27.5932 131.711 25.8191C131.711 24.0451 114.571 22.6069 93.4286 22.6069C72.286 22.6069 55.1465 24.0451 55.1465 25.8191C55.1465 27.5932 72.286 29.0313 93.4286 29.0313Z" fill="#3B3E54" />
            <path d="M106.006 4.81689V51.0127" stroke="#44B4B8" strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M110.241 51.9665C110.797 49.595 109.325 47.2219 106.954 46.6659C104.583 46.1098 102.209 47.5813 101.653 49.9527C101.097 52.3241 102.569 54.6974 104.94 55.2535C107.312 55.8095 109.685 54.3379 110.241 51.9665Z" fill="#44B4B8" />
            <path d="M106.006 47.9585L98.4961 72.5912H113.517L106.006 47.9585Z" fill="#44B4B8" />
            <path d="M50.4984 139.359C50.6561 141.685 49.7704 143.802 48.2689 145.307C46.8827 146.693 44.9687 147.555 42.8515 147.555H1.96995C1.36634 147.555 0.875 147.063 0.875 146.46C0.875 145.856 1.36634 145.365 1.96995 145.365H37.5555C40.34 145.365 42.8212 143.366 43.1701 140.602C43.5947 137.263 40.9983 134.412 37.7407 134.412H1.96995C1.36634 134.412 0.875 133.92 0.875 133.317C0.875 132.713 1.36634 132.222 1.96995 132.222H42.612C46.6795 132.225 50.2223 135.297 50.4984 139.359Z" fill="#3B3E54" />
            <path d="M48.3271 139.89C48.3271 140.023 48.324 140.157 48.3118 140.287C48.3027 140.433 48.2846 140.575 48.2634 140.718C48.2209 140.994 48.1572 141.264 48.0753 141.528C48.0298 141.674 47.9782 141.819 47.9176 141.959C47.8023 142.241 47.6659 142.511 47.5051 142.769C47.4141 142.917 47.314 143.06 47.2079 143.199C46.9834 143.493 46.7316 143.766 46.4525 144.009C46.2766 144.164 46.0885 144.306 45.8913 144.44C45.0208 145.025 43.9744 145.365 42.8491 145.365H3.0625V134.415H42.8491C43.9653 134.415 45.0058 134.752 45.8733 135.328C46.0704 135.461 46.2584 135.604 46.4374 135.759C46.7165 136.001 46.9712 136.274 47.1957 136.569C47.3019 136.705 47.402 136.851 47.493 136.999C47.6537 137.257 47.7933 137.527 47.9085 137.809C47.9662 137.949 48.0208 138.094 48.0663 138.24C48.1482 138.501 48.2118 138.771 48.2573 139.05C48.2786 139.192 48.2967 139.335 48.3089 139.481C48.321 139.614 48.3271 139.754 48.3271 139.89Z" fill="white" />
            <path d="M46.4413 135.759H3.06641V135.328H45.8803C46.0744 135.461 46.2623 135.604 46.4413 135.759Z" fill="#90D4D6" />
            <path d="M47.5 136.999H3.06641V136.568H47.2027C47.3059 136.705 47.4059 136.85 47.5 136.999Z" fill="#90D4D6" />
            <path d="M48.0733 138.24H3.06641V137.809H47.9156C47.9732 137.949 48.0248 138.094 48.0733 138.24Z" fill="#90D4D6" />
            <path d="M48.3128 139.48H3.06641V139.05H48.2643C48.2856 139.192 48.3006 139.335 48.3128 139.48Z" fill="#90D4D6" />
            <path d="M48.3128 140.29C48.3037 140.436 48.2856 140.578 48.2643 140.721H3.06641V140.29H48.3128Z" fill="#90D4D6" />
            <path d="M48.0773 141.531C48.0318 141.676 47.9802 141.822 47.9195 141.961H3.06445V141.531H48.0773Z" fill="#90D4D6" />
            <path d="M47.5071 142.768C47.4161 142.917 47.316 143.06 47.2098 143.199H3.06445V142.768H47.5071Z" fill="#90D4D6" />
            <path d="M46.4545 144.009C46.2785 144.164 46.0904 144.306 45.8933 144.44H3.06445V144.009H46.4545Z" fill="#90D4D6" />
            <path d="M101.92 139.359C102.078 141.685 101.192 143.802 99.6908 145.307C98.3046 146.693 96.3907 147.555 94.2735 147.555H53.3918C52.7882 147.555 52.2969 147.063 52.2969 146.46C52.2969 145.856 52.7882 145.365 53.3918 145.365H88.9775C91.762 145.365 94.2431 143.366 94.5919 140.602C95.0166 137.263 92.4202 134.412 89.1625 134.412H53.3918C52.7882 134.412 52.2969 133.92 52.2969 133.317C52.2969 132.713 52.7882 132.222 53.3918 132.222H94.0339C98.1044 132.225 101.647 135.297 101.92 139.359Z" fill="#FE5E5A" />
            <path d="M99.749 139.89C99.749 140.023 99.746 140.157 99.7339 140.287C99.7248 140.433 99.7065 140.575 99.6853 140.718C99.6428 140.994 99.5791 141.264 99.4972 141.528C99.4517 141.674 99.4003 141.819 99.3396 141.959C99.2243 142.241 99.0877 142.511 98.927 142.769C98.836 142.917 98.7359 143.06 98.6297 143.199C98.4053 143.493 98.1536 143.766 97.8745 144.009C97.6986 144.164 97.5105 144.306 97.3134 144.44C96.4428 145.025 95.3963 145.365 94.271 145.365H54.4844V134.415H94.271C95.3872 134.415 96.4276 134.752 97.2951 135.328C97.4923 135.461 97.6803 135.604 97.8593 135.759C98.1383 136.001 98.3931 136.274 98.6176 136.569C98.7237 136.705 98.8238 136.851 98.9148 136.999C99.0756 137.257 99.2152 137.527 99.3304 137.809C99.388 137.949 99.4426 138.094 99.4881 138.24C99.57 138.501 99.6337 138.771 99.6792 139.05C99.7004 139.192 99.7187 139.335 99.7278 139.481C99.7429 139.614 99.749 139.754 99.749 139.89Z" fill="white" />
            <path d="M97.8612 135.759H54.4863V135.328H97.3001C97.4972 135.461 97.6853 135.604 97.8612 135.759Z" fill="#90D4D6" />
            <path d="M98.9217 136.999H54.4883V136.568H98.6244C98.7306 136.705 98.8307 136.85 98.9217 136.999Z" fill="#90D4D6" />
            <path d="M99.495 138.24H54.4883V137.809H99.3373C99.3979 137.948 99.4495 138.094 99.495 138.24Z" fill="#90D4D6" />
            <path d="M99.7347 139.48H54.4883V139.05H99.6861C99.7073 139.192 99.7256 139.335 99.7347 139.48Z" fill="#90D4D6" />
            <path d="M99.7347 140.29C99.7256 140.436 99.7073 140.578 99.6861 140.721H54.4883V140.29H99.7347Z" fill="#90D4D6" />
            <path d="M99.4972 141.531C99.4517 141.676 99.4003 141.822 99.3396 141.961H54.4844V141.531H99.4972Z" fill="#90D4D6" />
            <path d="M98.9309 142.768C98.8399 142.917 98.7398 143.06 98.6336 143.199H54.4883V142.768H98.9309Z" fill="#90D4D6" />
            <path d="M97.8783 144.009C97.7024 144.163 97.5143 144.306 97.3171 144.44H54.4883V144.009H97.8783Z" fill="#90D4D6" />
            <path d="M151.524 139.359C151.681 141.685 150.796 143.802 149.294 145.307C147.908 146.693 145.994 147.555 143.877 147.555H102.995C102.392 147.555 101.9 147.063 101.9 146.46C101.9 145.856 102.392 145.365 102.995 145.365H138.581C141.366 145.365 143.847 143.366 144.195 140.602C144.62 137.263 142.024 134.412 138.766 134.412H102.995C102.392 134.412 101.9 133.92 101.9 133.317C101.9 132.713 102.392 132.222 102.995 132.222H143.637C147.705 132.225 151.248 135.297 151.524 139.359Z" fill="#FE5E5A" />
            <path d="M149.352 139.89C149.352 140.023 149.35 140.157 149.337 140.287C149.328 140.433 149.31 140.575 149.289 140.718C149.246 140.994 149.183 141.264 149.101 141.528C149.055 141.674 149.004 141.819 148.943 141.959C148.828 142.241 148.691 142.511 148.53 142.769C148.439 142.917 148.339 143.06 148.233 143.199C148.009 143.493 147.757 143.766 147.478 144.009C147.302 144.164 147.114 144.306 146.917 144.44C146.046 145.025 145 145.365 143.874 145.365H104.088V134.415H143.874C144.991 134.415 146.031 134.752 146.899 135.328C147.096 135.461 147.284 135.604 147.463 135.759C147.742 136.001 147.997 136.274 148.221 136.569C148.327 136.705 148.427 136.851 148.518 136.999C148.679 137.257 148.819 137.527 148.934 137.809C148.992 137.949 149.046 138.094 149.092 138.24C149.174 138.501 149.237 138.771 149.283 139.05C149.304 139.192 149.322 139.335 149.334 139.481C149.346 139.614 149.352 139.754 149.352 139.89Z" fill="white" />
            <path d="M147.463 135.759H104.088V135.328H146.902C147.096 135.461 147.284 135.604 147.463 135.759Z" fill="#90D4D6" />
            <path d="M148.521 136.999H104.088V136.568H148.224C148.33 136.705 148.43 136.85 148.521 136.999Z" fill="#90D4D6" />
            <path d="M149.095 138.24H104.088V137.809H148.937C148.995 137.949 149.049 138.094 149.095 138.24Z" fill="#90D4D6" />
            <path d="M149.334 139.48H104.088V139.05H149.286C149.307 139.192 149.322 139.335 149.334 139.48Z" fill="#90D4D6" />
            <path d="M149.334 140.29C149.325 140.436 149.307 140.578 149.286 140.721H104.088V140.29H149.334Z" fill="#90D4D6" />
            <path d="M149.099 141.531C149.053 141.676 149.002 141.822 148.941 141.961H104.086V141.531H149.099Z" fill="#90D4D6" />
            <path d="M148.529 142.768C148.438 142.917 148.337 143.06 148.231 143.199H104.086V142.768H148.529Z" fill="#90D4D6" />
            <path d="M147.476 144.009C147.3 144.164 147.112 144.306 146.915 144.44H104.086V144.009H147.476Z" fill="#90D4D6" />
            <path d="M45.1392 125.071C44.9814 122.745 45.867 120.628 47.3685 119.123C48.7546 117.737 50.6687 116.876 52.7859 116.876H93.6674C94.271 116.876 94.7624 117.367 94.7624 117.971C94.7624 118.574 94.271 119.066 93.6674 119.066H58.0818C55.2974 119.066 52.8161 121.065 52.4673 123.828C52.0427 127.167 54.6392 130.019 57.8969 130.019H93.6674C94.271 130.019 94.7624 130.51 94.7624 131.114C94.7624 131.717 94.271 132.209 93.6674 132.209H53.0255C48.9549 132.206 45.4122 129.133 45.1392 125.071Z" fill="#3B3E54" />
            <path d="M47.3125 124.54C47.3125 124.407 47.3156 124.273 47.3278 124.143C47.3369 123.997 47.355 123.855 47.3762 123.712C47.4187 123.436 47.4824 123.163 47.5643 122.902C47.6098 122.757 47.6613 122.611 47.722 122.472C47.8373 122.19 47.9737 121.92 48.1345 121.662C48.2255 121.513 48.3256 121.371 48.4317 121.231C48.6562 120.937 48.908 120.664 49.1871 120.421C49.363 120.267 49.5511 120.124 49.7483 119.991C50.6188 119.405 51.6652 119.065 52.7905 119.065H92.5771V130.015H52.7905C51.6743 130.015 50.634 129.679 49.7665 129.102C49.5693 128.969 49.3811 128.826 49.2022 128.672C48.9231 128.429 48.6683 128.156 48.4439 127.862C48.3377 127.725 48.2376 127.58 48.1466 127.431C47.9859 127.173 47.8465 126.903 47.7312 126.621C47.6736 126.482 47.619 126.336 47.5735 126.19C47.4916 125.93 47.4278 125.66 47.3823 125.381C47.361 125.238 47.3428 125.095 47.3307 124.95C47.3186 124.813 47.3125 124.677 47.3125 124.54Z" fill="white" />
            <path d="M49.1973 128.672H92.5722V129.103H49.7584C49.5643 128.969 49.3732 128.827 49.1973 128.672Z" fill="#90D4D6" />
            <path d="M48.1367 127.431H92.5703V127.862H48.434C48.3278 127.722 48.2277 127.58 48.1367 127.431Z" fill="#90D4D6" />
            <path d="M47.5625 126.191H92.5692V126.621H47.7202C47.6596 126.482 47.608 126.336 47.5625 126.191Z" fill="#90D4D6" />
            <path d="M47.3242 124.95H92.5706V125.381H47.3726C47.3514 125.238 47.3333 125.096 47.3242 124.95Z" fill="#90D4D6" />
            <path d="M47.3242 124.14C47.3333 123.995 47.3514 123.852 47.3726 123.709H92.5706V124.14H47.3242Z" fill="#90D4D6" />
            <path d="M47.5605 122.9C47.606 122.754 47.6576 122.609 47.7183 122.469H92.5734V122.9H47.5605Z" fill="#90D4D6" />
            <path d="M48.1309 121.662C48.2219 121.513 48.3219 121.371 48.4281 121.231H92.5735V121.662H48.1309Z" fill="#90D4D6" />
            <path d="M49.1836 120.421C49.3595 120.267 49.5476 120.124 49.7448 119.991H92.5736V120.421H49.1836Z" fill="#90D4D6" />
            <path d="M156.985 124.028C157.142 126.355 156.257 128.472 154.755 129.976C153.369 131.363 151.455 132.224 149.338 132.224H108.456C107.853 132.224 107.361 131.733 107.361 131.129C107.361 130.525 107.853 130.034 108.456 130.034H144.042C146.826 130.034 149.308 128.035 149.656 125.272C150.081 121.932 147.485 119.081 144.227 119.081H108.456C107.853 119.081 107.361 118.59 107.361 117.986C107.361 117.382 107.853 116.891 108.456 116.891H149.098C153.166 116.894 156.709 119.967 156.985 124.028Z" fill="#6D7087" />
            <path d="M154.813 124.559C154.813 124.692 154.81 124.826 154.798 124.956C154.789 125.102 154.771 125.244 154.75 125.387C154.707 125.663 154.644 125.936 154.562 126.197C154.516 126.342 154.465 126.488 154.404 126.627C154.289 126.909 154.152 127.179 153.991 127.437C153.9 127.586 153.8 127.728 153.694 127.868C153.47 128.162 153.218 128.435 152.939 128.678C152.763 128.832 152.575 128.975 152.378 129.108C151.507 129.694 150.461 130.034 149.335 130.034H109.549V119.084H149.335C150.452 119.084 151.492 119.42 152.36 119.997C152.557 120.13 152.745 120.273 152.924 120.427C153.203 120.67 153.458 120.943 153.682 121.237C153.788 121.374 153.888 121.519 153.979 121.668C154.14 121.926 154.28 122.196 154.395 122.478C154.452 122.617 154.507 122.763 154.553 122.909C154.634 123.169 154.698 123.439 154.744 123.718C154.765 123.861 154.783 124.004 154.795 124.149C154.807 124.283 154.813 124.419 154.813 124.559Z" fill="white" />
            <path d="M152.926 120.428H109.551V119.997H152.365C152.559 120.127 152.747 120.273 152.926 120.428Z" fill="#90D4D6" />
            <path d="M153.984 121.668H109.551V121.237H153.687C153.793 121.374 153.893 121.519 153.984 121.668Z" fill="#90D4D6" />
            <path d="M154.558 122.909H109.551V122.478H154.4C154.457 122.618 154.512 122.76 154.558 122.909Z" fill="#90D4D6" />
            <path d="M154.797 124.146H109.551V123.716H154.749C154.77 123.858 154.785 124.001 154.797 124.146Z" fill="#90D4D6" />
            <path d="M154.797 124.956C154.788 125.102 154.77 125.244 154.749 125.387H109.551V124.956H154.797Z" fill="#90D4D6" />
            <path d="M154.562 126.197C154.516 126.342 154.465 126.488 154.404 126.628H109.549V126.197H154.562Z" fill="#90D4D6" />
            <path d="M153.991 127.437C153.9 127.586 153.8 127.728 153.694 127.868H109.549V127.437H153.991Z" fill="#90D4D6" />
            <path d="M152.939 128.678C152.763 128.833 152.575 128.975 152.378 129.109H109.549V128.678H152.939Z" fill="#90D4D6" />
            <path d="M97.9751 109.758C97.8174 107.431 98.703 105.314 100.204 103.809C101.591 102.423 103.505 101.562 105.622 101.562H146.503C147.107 101.562 147.598 102.053 147.598 102.657C147.598 103.26 147.107 103.752 146.503 103.752H110.918C108.133 103.752 105.652 105.751 105.303 108.514C104.879 111.853 107.475 114.705 110.733 114.705H146.503C147.107 114.705 147.598 115.196 147.598 115.8C147.598 116.403 147.107 116.895 146.503 116.895H105.861C101.794 116.895 98.2511 113.819 97.9751 109.758Z" fill="#FE5E5A" />
            <path d="M100.15 109.226C100.15 109.093 100.153 108.959 100.165 108.829C100.175 108.683 100.193 108.541 100.214 108.398C100.257 108.122 100.32 107.849 100.402 107.588C100.448 107.443 100.499 107.297 100.56 107.158C100.675 106.876 100.812 106.606 100.972 106.348C101.063 106.199 101.163 106.057 101.27 105.917C101.494 105.623 101.746 105.35 102.025 105.107C102.201 104.953 102.389 104.81 102.586 104.677C103.457 104.091 104.503 103.751 105.628 103.751H145.415V114.701H105.628C104.512 114.701 103.472 114.365 102.604 113.788C102.407 113.655 102.219 113.512 102.04 113.358C101.761 113.115 101.506 112.842 101.282 112.548C101.176 112.411 101.076 112.266 100.985 112.117C100.824 111.859 100.684 111.589 100.569 111.307C100.511 111.168 100.457 111.022 100.411 110.876C100.329 110.616 100.266 110.346 100.22 110.067C100.199 109.924 100.181 109.781 100.172 109.636C100.153 109.502 100.15 109.366 100.15 109.226Z" fill="white" />
            <path d="M102.035 113.358H145.41V113.789H102.596C102.402 113.658 102.214 113.516 102.035 113.358Z" fill="#90D4D6" />
            <path d="M100.975 112.117H145.408V112.548H101.272C101.169 112.411 101.069 112.266 100.975 112.117Z" fill="#90D4D6" />
            <path d="M100.4 110.877H145.407V111.307H100.558C100.5 111.168 100.449 111.025 100.4 110.877Z" fill="#90D4D6" />
            <path d="M100.162 109.639H145.408V110.07H100.211C100.189 109.927 100.174 109.785 100.162 109.639Z" fill="#90D4D6" />
            <path d="M100.162 108.829C100.171 108.684 100.189 108.541 100.211 108.398H145.408V108.829H100.162Z" fill="#90D4D6" />
            <path d="M100.398 107.589C100.444 107.443 100.496 107.297 100.556 107.158H145.411V107.589H100.398Z" fill="#90D4D6" />
            <path d="M100.969 106.348C101.06 106.199 101.16 106.057 101.266 105.917H145.411V106.348H100.969Z" fill="#90D4D6" />
            <path d="M102.021 105.107C102.197 104.953 102.385 104.81 102.583 104.677H145.411V105.107H102.021Z" fill="#90D4D6" />
            <path d="M157.588 93.3657C157.746 95.6921 156.86 97.8093 155.359 99.3138C153.973 100.7 152.059 101.561 149.941 101.561H109.06C108.456 101.561 107.965 101.07 107.965 100.466C107.965 99.8628 108.456 99.3714 109.06 99.3714H144.646C147.43 99.3714 149.911 97.3725 150.26 94.6093C150.685 91.2697 148.088 88.4185 144.83 88.4185H109.06C108.456 88.4185 107.965 87.9271 107.965 87.3235C107.965 86.7199 108.456 86.2285 109.06 86.2285H149.702C153.769 86.2315 157.312 89.3042 157.588 93.3657Z" fill="#6D7087" />
            <path d="M155.417 93.8963C155.417 94.0298 155.414 94.1633 155.402 94.2937C155.393 94.4393 155.374 94.5818 155.353 94.7244C155.311 95.0004 155.247 95.2734 155.165 95.5343C155.12 95.6799 155.068 95.8255 155.007 95.965C154.892 96.2471 154.756 96.517 154.595 96.7749C154.504 96.9235 154.404 97.0661 154.298 97.2056C154.073 97.4998 153.822 97.7728 153.543 98.0154C153.367 98.1701 153.178 98.3127 152.981 98.4462C152.111 99.0316 151.064 99.3713 149.939 99.3713H110.152V88.4214H149.939C151.055 88.4214 152.096 88.7581 152.963 89.3344C153.16 89.4678 153.348 89.6104 153.527 89.7651C153.806 90.0078 154.061 90.2808 154.286 90.575C154.392 90.7115 154.492 90.8571 154.583 91.0057C154.744 91.2635 154.883 91.5335 154.998 91.8156C155.056 91.9551 155.111 92.1007 155.156 92.2463C155.238 92.5072 155.302 92.7771 155.347 93.0561C155.368 93.1987 155.387 93.3413 155.399 93.4869C155.411 93.6234 155.417 93.7599 155.417 93.8963Z" fill="white" />
            <path d="M153.529 89.7652H110.154V89.3345H152.968C153.162 89.4679 153.35 89.6105 153.529 89.7652Z" fill="#90D4D6" />
            <path d="M154.588 91.0059H110.154V90.5752H154.29C154.397 90.7147 154.497 90.8573 154.588 91.0059Z" fill="#90D4D6" />
            <path d="M155.161 92.2462H110.154V91.8154H155.003C155.061 91.955 155.116 92.1006 155.161 92.2462Z" fill="#90D4D6" />
            <path d="M155.401 93.4869H110.154V93.0562H155.352C155.373 93.1987 155.389 93.3413 155.401 93.4869Z" fill="#90D4D6" />
            <path d="M155.401 94.2969C155.392 94.4425 155.373 94.585 155.352 94.7276H110.154V94.2969H155.401Z" fill="#90D4D6" />
            <path d="M155.165 95.5371C155.12 95.6827 155.068 95.8283 155.007 95.9678H110.152V95.5371H155.165Z" fill="#90D4D6" />
            <path d="M154.595 96.7749C154.504 96.9235 154.404 97.0661 154.298 97.2056H110.152V96.7749H154.595Z" fill="#90D4D6" />
            <path d="M153.542 98.0156C153.367 98.1703 153.178 98.3129 152.981 98.4464H110.152V98.0156H153.542Z" fill="#90D4D6" />
            <path d="M14.55 127.532C14.5257 127.508 14.5075 127.477 14.4893 127.447C14.3437 127.201 14.4287 126.886 14.6744 126.74L17.8744 124.863C18.1201 124.717 18.4356 124.802 18.5812 125.048C18.7267 125.293 18.6419 125.609 18.3962 125.754L15.196 127.632C14.9807 127.759 14.7108 127.711 14.55 127.532Z" fill="#E1B58F" />
            <path d="M15.4285 126.234C15.4285 126.234 14.194 125.651 14.0939 125.954C13.9938 126.258 12.92 130.732 13.1232 130.805C13.8178 131.044 15.2404 127.498 15.2435 127.125C15.2465 126.749 15.6104 126.315 15.4285 126.234Z" fill="#292B3B" />
            <path d="M54.9815 90.2689C54.9815 90.2689 57.3081 88.9767 57.9269 89.2315C58.5456 89.4863 56.4709 90.9665 54.8633 90.915L54.9815 90.2689Z" fill="#E1B58F" />
            <path d="M54.9666 90.0562C55.2608 90.3716 55.558 90.6658 55.8462 90.9661C51.1841 95.7161 47.2713 96.5776 41.3748 93.784C40.4739 93.3563 40.5133 93.0075 40.941 92.2947C40.8651 92.1612 40.856 92.1703 40.941 92.2947C41.3686 91.5819 41.6295 91.5394 42.5455 91.9701C48.5392 94.7971 49.6312 93.9205 54.9666 90.0562Z" fill="#434659" />
            <path d="M53.5492 113.199C53.5158 113.205 53.4794 113.205 53.4461 113.205C53.161 113.19 52.9426 112.948 52.9578 112.662L53.161 108.956C53.1761 108.671 53.4188 108.452 53.7039 108.468C53.9891 108.483 54.2074 108.725 54.1922 109.01L53.989 112.717C53.9769 112.966 53.7858 113.166 53.5492 113.199Z" fill="#E1B58F" />
            <path d="M36.8574 106.478C37.8068 107.64 38.7533 108.708 39.824 109.763C48.1289 102.499 53.1186 98.9167 52.8031 111.298L54.5229 111.222C55.4511 97.0634 47.228 98.5496 36.8574 106.478Z" fill="#292B3B" />
            <path d="M17.1083 126.987C16.7565 126.538 16.4107 126.083 16.1074 125.601C24.0696 121.445 33.791 112.91 38.4713 105.072C39.7301 105.87 40.9737 106.692 42.2112 107.517C36.4845 114.623 24.7429 122.334 17.1083 126.987Z" fill="#292B3B" />
            <path d="M52.7999 112.35C52.7999 112.35 52.1872 113.57 52.4906 113.679C52.7939 113.788 57.2407 114.971 57.3135 114.768C57.5683 114.079 54.0619 112.569 53.6858 112.557C53.3097 112.544 52.8849 112.171 52.7999 112.35Z" fill="#292B3B" />
            <path d="M36.7483 106.569C37.5066 106.457 38.2437 107.124 40.009 107.458C41.2071 107.685 42.2202 106.084 42.927 106.351C41.4043 99.0772 41.8744 96.8508 42.5812 95.0733C42.933 94.1876 42.7905 93.1806 42.1869 92.4435L41.9502 92.1554C40.8552 90.8177 38.729 91.0937 38.0223 92.6741C37.7857 93.2018 37.5552 93.8146 37.352 94.5183C36.3844 97.8639 35.9748 101.655 36.7483 106.569Z" fill="#6D7087" />
            <path d="M39.763 89.1676C40.5334 90.5264 42.1017 91.0906 42.9328 90.6204C43.7639 90.1503 43.5394 88.8218 42.769 87.4659C41.9985 86.1071 40.9339 85.7976 40.1059 86.2678C39.2717 86.7379 38.9926 87.8117 39.763 89.1676Z" fill="#E1B58F" />
            <path d="M39.4152 89.9833C39.5942 90.2987 40.1372 90.5262 40.3677 90.3958C40.5952 90.2653 40.5073 89.9772 40.3283 89.6618C40.1493 89.3463 39.7944 89.1552 39.5669 89.2856C39.3394 89.413 39.2363 89.6648 39.4152 89.9833Z" fill="#E1B58F" />
            <path d="M39.075 88.4155C39.075 88.4155 38.8688 87.3842 39.3723 86.6835C39.8758 85.9828 41.2165 85.2943 42.1841 86.3377C42.1841 86.3377 42.803 87.1415 41.7686 87.8058C40.7373 88.4701 40.6553 87.7967 40.3763 88.5944C40.0972 89.3922 39.4209 89.2193 39.3815 89.3922L39.075 88.4155Z" fill="#1A1C2E" />
            <path d="M40.5439 88.8975L42.1696 89.1128C42.1393 89.3342 41.8238 91.4454 41.5599 92.2067C41.4446 92.4342 41.0231 92.4524 40.6166 92.2461C40.2526 92.0611 40.0251 91.7608 40.0676 91.5363H40.0646C40.2223 91.0814 40.4408 89.677 40.5439 88.8975Z" fill="#E1B58F" />
            <path d="M33.395 107.328C33.395 107.328 32.0999 109.803 32.382 110.446C32.6641 111.089 34.1655 108.875 34.0685 107.188L33.395 107.328Z" fill="#E1B58F" />
            <path d="M34.3496 107.586C33.9249 107.683 33.5093 107.771 33.0999 107.859C31.9078 99.2745 31.1615 98.6315 37.6981 92.6864C38.6991 91.7764 38.7476 92.1374 39.2905 92.8532C39.4847 92.8411 39.4665 92.8168 39.2905 92.8532C39.8304 93.5691 39.8638 93.7268 38.8416 94.5185C32.1534 99.7113 34.0766 99.6749 34.3496 107.586Z" fill="#434659" />
            <path d="M63.0462 72.4551L60.959 71.5737L50.5769 96.1598L52.6641 97.0412L63.0462 72.4551Z" fill="#292B3B" />
            <path d="M60.5596 80.0945L57.2148 78.6821L49.6799 96.5258L53.0246 97.9382L60.5596 80.0945Z" fill="#44B4B8" />
            <path d="M72.9057 46.402C65.6321 43.3293 57.2452 46.7387 54.1756 54.0123C51.106 61.2859 54.5123 69.6728 61.7859 72.7424C69.0595 75.815 77.4464 72.4057 80.516 65.1321C83.5856 57.8615 80.1793 49.4746 72.9057 46.402ZM62.2834 71.5564C55.6649 68.7628 52.565 61.1313 55.3585 54.5128C58.1521 47.8943 65.7837 44.7944 72.4021 47.5879C79.0206 50.3815 82.1206 58.0131 79.327 64.6316C76.5334 71.25 68.9018 74.35 62.2834 71.5564Z" fill="#292B3B" />
            <path opacity="0.61" d="M67.3482 72.5787C74.5314 72.5787 80.3545 66.7556 80.3545 59.5723C80.3545 52.3891 74.5314 46.5659 67.3482 46.5659C60.1649 46.5659 54.3418 52.3891 54.3418 59.5723C54.3418 66.7556 60.1649 72.5787 67.3482 72.5787Z" fill="white" />
            <path d="M170.643 77.083C170.276 77.083 169.976 77.3802 169.976 77.7503V84.2808H160.409V77.7503C160.409 77.3833 160.111 77.083 159.741 77.083C159.371 77.083 159.074 77.3802 159.074 77.7503V147.071C159.074 147.438 159.371 147.739 159.741 147.739C160.111 147.739 160.409 147.441 160.409 147.071V141.314H169.976V147.071C169.976 147.438 170.273 147.739 170.643 147.739C171.013 147.739 171.31 147.441 171.31 147.071V77.7503C171.307 77.3802 171.01 77.083 170.643 77.083ZM160.409 112.071V106.578H169.976V112.071H160.409ZM169.976 113.524V119.017H160.409V113.524H169.976ZM160.409 105.122V99.6288H169.976V105.122H160.409ZM160.409 120.47H169.976V125.963H160.409V120.47ZM169.976 98.1759H160.409V92.6828H169.976V98.1759ZM160.409 127.419H169.976V132.912H160.409V127.419ZM169.976 85.7337V91.2268H160.409V85.7337H169.976ZM160.409 139.858V134.365H169.976V139.858H160.409Z" fill="#3B3E54" />
            <path d="M182.772 135.124C182.802 135.115 182.835 135.1 182.863 135.081C183.102 134.945 183.184 134.639 183.045 134.399L181.249 131.287C181.112 131.047 180.806 130.965 180.566 131.105C180.327 131.241 180.245 131.548 180.385 131.787L182.18 134.899C182.301 135.109 182.55 135.197 182.772 135.124Z" fill="#EBBF99" />
            <path d="M182.924 133.974C182.924 133.974 184.034 134.693 183.834 134.933C183.634 135.172 180.495 138.333 180.334 138.202C179.785 137.754 181.978 134.775 182.284 134.581C182.594 134.387 182.767 133.868 182.924 133.974Z" fill="#FE5E5A" />
            <path d="M161.07 83.2104C161.07 83.2104 160.827 80.6443 160.284 80.2894C159.741 79.9346 159.696 82.4006 160.618 83.6563L161.07 83.2104Z" fill="#EBBF99" />
            <path d="M161.339 83.0674C160.921 83.0977 160.505 83.125 160.102 83.1826C160.951 89.7495 161.973 92.5189 168.422 94.7088C169.408 95.0425 169.587 94.6694 169.678 93.8747C169.859 93.8686 169.859 93.8717 169.678 93.8747C169.768 93.08 169.483 92.9253 168.619 92.6402C162.959 90.7838 162.643 88.5969 161.339 83.0674Z" fill="#D68963" />
            <path d="M166.717 137.62C166.747 137.635 166.777 137.647 166.811 137.659C167.075 137.738 167.354 137.587 167.433 137.323L168.452 133.877C168.531 133.613 168.379 133.334 168.115 133.255C167.851 133.176 167.572 133.328 167.493 133.592L166.474 137.038C166.404 137.268 166.514 137.511 166.717 137.62Z" fill="#EBBF99" />
            <path d="M166.656 135.273C167.145 135.525 167.645 135.752 168.161 135.943C170.245 126.783 170.381 115.138 172.987 106.897C171.616 106.521 170.239 106.16 168.865 105.799C166.662 115.457 170.014 124.029 166.656 135.273Z" fill="#FE5E5A" />
            <path d="M181.262 133.768C181.75 133.528 182.229 133.277 182.7 133.001C177.128 123.501 173.63 116.979 174.261 105.602C172.839 105.411 171.41 105.25 169.984 105.089C170.024 115.987 175.538 124.944 181.262 133.768Z" fill="#FE5E5A" />
            <path d="M169.823 88.7036C170.475 88.1667 171.613 88.0242 172.295 88.5216C172.978 89.0191 172.926 90.0807 172.429 90.7662C171.931 91.4487 170.172 91.7217 169.49 91.2242C168.807 90.7268 168.998 89.3831 169.823 88.7036Z" fill="#1A1C2E" />
            <path d="M170.87 89.5959C170.23 90.9669 168.756 91.6281 167.919 91.2368C167.082 90.8456 167.197 89.5474 167.84 88.1763C168.48 86.8053 169.484 86.4262 170.321 86.8175C171.158 87.2118 171.51 88.2279 170.87 89.5959Z" fill="#EBBF99" />
            <path d="M171.27 90.3563C171.121 90.6748 170.615 90.9357 170.384 90.8265C170.154 90.7173 170.217 90.4322 170.366 90.1137C170.515 89.7952 170.842 89.5829 171.073 89.6921C171.303 89.8013 171.419 90.0378 171.27 90.3563Z" fill="#EBBF99" />
            <path d="M171.477 88.8153C171.477 88.8153 171.598 87.8022 171.058 87.1653C170.518 86.5283 169.171 85.9641 168.319 87.0439C168.319 87.0439 167.782 87.8659 168.832 88.4301C169.878 88.9912 169.905 88.3361 170.236 89.0853C170.567 89.8345 171.204 89.6161 171.258 89.7799L171.477 88.8153Z" fill="#1A1C2E" />
            <path d="M168.575 108.48C169.773 108.517 170.701 108.095 171.717 107.482C172.497 107.012 173.501 106.809 174.323 106.478C174.923 102.772 174.089 96.7479 172.642 94.3729C171.371 92.2861 171.368 92.6319 170.907 92.4377C170.264 92.1678 169.524 92.2557 169.015 92.732C169.006 92.7411 168.996 92.7501 168.984 92.7592C168.396 93.3265 168.378 94.5549 168.287 95.8319C168.196 97.1089 167.707 96.8753 168.836 99.0987C169.324 100.06 168.535 106.576 168.575 108.48Z" fill="#E89F7B" />
            <path d="M170.395 89.7954L168.814 89.935C168.833 90.1503 169.048 92.2068 169.269 92.956C169.37 93.1805 169.779 93.2169 170.179 93.0349C170.54 92.8711 170.771 92.592 170.741 92.3736H170.744C170.61 91.9247 170.461 90.5537 170.395 89.7954Z" fill="#EBBF99" />
            <path d="M167.491 136.762C167.491 136.762 168.24 137.85 167.964 137.996C167.688 138.139 163.578 139.858 163.478 139.673C163.141 139.046 166.311 137.138 166.669 137.077C167.027 137.016 167.388 136.601 167.491 136.762Z" fill="#FE5E5A" />
            <path d="M161.095 100.776C161.095 100.776 159.351 98.7075 158.668 98.7257C157.986 98.7439 159.424 100.9 160.974 101.431L161.095 100.776Z" fill="#EBBF99" />
            <path d="M161.034 100.461C160.776 100.849 160.506 101.167 160.264 101.531C168.174 105.687 171.162 103.815 172.624 95.1463C172.848 93.8208 170.798 93.7025 170.625 95.7408C170.019 102.902 167.289 103.372 161.034 100.461Z" fill="#D68963" />
            <path d="M39.1816 83.4497L40.683 87.2351L42.2936 86.5951L43.0368 85.9187L45.8971 83.3223L39.1816 83.4497Z" fill="#4FD2D6" />
            <path d="M34.2109 87.9626L38.0692 87.8928L39.0792 87.8716L40.6838 87.2347L39.1824 83.4492L34.2109 87.9626Z" fill="#4FD2D6" />
            <path d="M42.6371 84.6537L37.6699 86.627L38.3015 88.2169L43.2688 86.2437L42.6371 84.6537Z" fill="#44B4B8" />
            <path d="M42.5363 84.6573C42.4908 84.542 41.3411 84.8908 39.9701 85.4368C38.5991 85.9828 37.5223 86.5166 37.5678 86.6319C37.6133 86.7472 38.7629 86.3983 40.1339 85.8523C41.5079 85.3064 42.5818 84.7725 42.5363 84.6573Z" fill="#44B4B8" />
            <path d="M37.0352 85.4639L37.1474 88.6852" stroke="#1A1C2E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M37.4518 88.6759C37.4457 88.506 37.3032 88.3726 37.1334 88.3786C36.9635 88.3847 36.8301 88.5272 36.8361 88.6971C36.8422 88.8669 36.9847 89.0004 37.1546 88.9943C37.3244 88.9883 37.4579 88.8457 37.4518 88.6759Z" fill="#1A1C2E" />
            <path d="M37.1378 88.4727L37.7233 90.1743L36.6738 90.2107L37.1378 88.4727Z" fill="#1A1C2E" />
            <path d="M150.105 85.6832C150.142 85.6832 150.175 85.6802 150.211 85.6711C150.5 85.6134 150.688 85.3314 150.627 85.0402L149.863 81.279C149.805 80.9908 149.523 80.8028 149.232 80.8634C148.943 80.9211 148.755 81.2031 148.816 81.4943L149.58 85.2555C149.635 85.5073 149.856 85.6832 150.105 85.6832Z" fill="#CDA17B" />
            <path d="M150.774 84.9723C150.774 84.9723 151.104 86.3433 150.774 86.3827C150.443 86.4222 145.687 86.5647 145.66 86.3433C145.566 85.5911 149.451 84.8934 149.834 84.9723C150.216 85.0511 150.731 84.7721 150.774 84.9723Z" fill="#292B3B" />
            <path d="M128.109 40.1693C128.109 40.1693 126.598 37.8701 125.907 37.8064C125.215 37.7427 126.407 40.0935 127.905 40.8154L128.109 40.1693Z" fill="#CDA17B" />
            <path d="M128.2 39.7749C127.933 40.1328 127.663 40.4695 127.402 40.8122C132.75 45.1467 136.872 45.5714 142.605 42.0104C143.481 41.4644 143.4 41.1125 142.875 40.427C142.939 40.2814 142.948 40.2905 142.875 40.427C142.35 39.7445 142.077 39.7324 141.185 40.2814C135.362 43.8909 134.136 43.1205 128.2 39.7749Z" fill="#325481" />
            <path d="M140.804 85.6832C140.841 85.6832 140.874 85.6802 140.911 85.6711C141.199 85.6134 141.387 85.3314 141.326 85.0402L140.562 81.279C140.504 80.9908 140.222 80.8028 139.931 80.8634C139.643 80.9211 139.455 81.2031 139.515 81.4943L140.28 85.2555C140.334 85.5073 140.556 85.6832 140.804 85.6832Z" fill="#CDA17B" />
            <path d="M139.626 83.4919C140.196 83.4767 140.763 83.4191 141.324 83.3342C139.632 74.2922 138.352 62.1594 142.877 53.7088C141.503 53.0355 140.123 52.3772 138.74 51.7251C135.434 59.0897 138.249 75.7148 139.626 83.4919Z" fill="#292B3B" />
            <path d="M148.99 83.2377C149.558 83.0709 150.119 82.892 150.665 82.6675C146.433 74.399 143.337 61.3804 144.077 51.9684C142.539 51.8895 140.998 51.838 139.457 51.7925C140.036 61.2136 145.329 74.7448 148.99 83.2377Z" fill="#3B3E54" />
            <path d="M141.469 84.9728C141.469 84.9728 141.8 86.3438 141.469 86.3832C141.139 86.4227 136.382 86.5652 136.355 86.3438C136.261 85.5916 140.15 84.8939 140.529 84.9728C140.911 85.0486 141.427 84.7726 141.469 84.9728Z" fill="#292B3B" />
            <path d="M144.286 54.6006C143.549 54.3094 142.484 54.0273 140.628 53.9485C139.366 53.8939 138.377 53.9394 137.604 54.0425C140.849 47.0752 141.413 43.6022 141.119 41.6458C140.974 40.6691 141.353 39.6924 142.135 39.0918L142.442 38.8583C143.861 37.7693 145.936 38.5458 146.276 40.3051C146.391 40.8936 146.476 41.5639 146.515 42.3222C146.7 45.9165 146.221 49.8324 144.286 54.6006Z" fill="#052653" />
            <path d="M144.886 35.8558C144.276 37.3512 142.741 38.1398 141.828 37.7667C140.915 37.3936 140.967 36.0044 141.577 34.5091C142.186 33.0137 143.236 32.5527 144.149 32.9257C145.062 33.2988 145.492 34.3605 144.886 35.8558Z" fill="#CDA17B" />
            <path d="M145.351 36.6444C145.208 36.9933 144.683 37.2966 144.429 37.1935C144.177 37.0903 144.228 36.784 144.371 36.4352C144.514 36.0863 144.85 35.8437 145.102 35.9468C145.354 36.0529 145.49 36.2986 145.351 36.6444Z" fill="#CDA17B" />
            <path d="M145.489 34.9916C145.489 34.9916 145.562 33.9057 144.955 33.2536C144.346 32.6014 142.878 32.0736 142.025 33.2718C142.025 33.2718 141.497 34.1787 142.647 34.7217C143.794 35.2646 143.787 34.5639 144.179 35.3465C144.57 36.129 145.24 35.8591 145.307 36.032L145.489 34.9916Z" fill="#1A1C2E" />
            <path d="M144.625 35.9108L142.939 35.7471C142.915 35.9776 142.739 38.1797 142.824 39.0078C142.884 39.2626 143.306 39.3809 143.764 39.2686C144.174 39.1685 144.474 38.9198 144.483 38.6832H144.486C144.434 38.1858 144.547 36.7177 144.625 35.9108Z" fill="#CDA17B" />
            <path d="M144.162 36.4688C143.868 36.2504 143.683 36.5324 143.158 36.9783C142.636 37.4212 141.565 37.5395 141.229 36.1775C141.013 35.31 140.795 36.8388 140.795 36.8388C140.834 37.5759 141.113 37.9732 141.653 38.1946C142.403 38.498 143.452 38.2341 144.283 37.4909C144.365 37.1088 144.362 36.6174 144.162 36.4688Z" fill="#1A1C2E" />
            <path d="M141.172 36.1318C141.172 36.1318 141.193 36.1379 141.232 36.15C141.551 36.2501 143.007 36.7354 143.244 37.2541C143.51 37.8365 142.491 37.154 142.158 36.8113C141.824 36.4685 141.521 36.5838 141.223 36.5534C140.929 36.5261 140.735 36.2016 141.172 36.1318Z" fill="#1A1C2E" />
            <path d="M157.146 37.7183C157.146 37.7183 157.483 34.8489 158.102 34.4636C158.721 34.0784 158.709 36.8477 157.641 38.2309L157.146 37.7183Z" fill="white" />
            <path d="M157.057 37.0381C157.475 37.211 157.891 37.3839 158.279 37.5204C155.461 44.4179 151.09 48.2276 145.166 41.8912C144.26 40.9206 144.639 40.869 145.188 40.3109C145.215 40.1016 145.182 40.1683 145.188 40.3109C145.74 39.7528 146.125 39.495 146.883 40.4474C151.84 46.6685 153.957 41.9398 157.057 37.0381Z" fill="#325481" />
            <path d="M145.129 29.6714L143.624 33.4568L142.017 32.8198L141.271 32.1434L138.41 29.5439L145.129 29.6714Z" fill="#FFA19E" />
            <path d="M150.099 34.1874L146.238 34.1146L145.228 34.0963L143.623 33.4563L145.128 29.6709L150.099 34.1874Z" fill="#FFA19E" />
            <path d="M141.823 30.7905L141.191 32.3804L146.159 34.3536L146.79 32.7637L141.823 30.7905Z" fill="#FF7975" />
            <path d="M141.769 30.8785C141.814 30.7632 142.964 31.112 144.335 31.658C145.706 32.204 146.783 32.7378 146.737 32.8531C146.692 32.9683 145.542 32.6195 144.171 32.0736C142.8 31.5306 141.723 30.9937 141.769 30.8785Z" fill="#FF7975" />
            <path d="M147.274 31.6855L147.162 34.9099" stroke="#1A1C2E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M146.852 34.898C146.858 34.7282 147 34.5947 147.17 34.6008C147.34 34.6069 147.474 34.7494 147.468 34.9193C147.462 35.0891 147.319 35.2226 147.149 35.2165C146.979 35.2135 146.846 35.0709 146.852 34.898Z" fill="#1A1C2E" />
            <path d="M147.169 34.6978L146.584 36.3964L147.633 36.4328L147.169 34.6978Z" fill="#1A1C2E" />
            <path d="M172.079 83.978L170.577 87.7634L168.967 87.1265L168.224 86.45L165.363 83.8506L172.079 83.978Z" fill="#FF6561" />
            <path d="M177.049 88.494L173.191 88.4212L172.181 88.403L170.576 87.763L172.078 83.9775L177.049 88.494Z" fill="#FF6561" />
            <path d="M168.772 85.0907L168.141 86.6807L173.108 88.6539L173.739 87.0639L168.772 85.0907Z" fill="#FF7975" />
            <path d="M168.724 85.1851C168.77 85.0698 169.919 85.4186 171.29 85.9646C172.661 86.5106 173.738 87.0445 173.692 87.1597C173.647 87.275 172.497 86.9261 171.126 86.3802C169.752 85.8372 168.679 85.3004 168.724 85.1851Z" fill="#FF7975" />
            <path d="M174.226 85.9922L174.113 89.2165" stroke="#1A1C2E" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M173.807 89.2042C173.813 89.0343 173.955 88.9009 174.125 88.9069C174.295 88.913 174.429 89.0555 174.423 89.2254C174.416 89.3953 174.274 89.5287 174.104 89.5227C173.934 89.5166 173.801 89.374 173.807 89.2042Z" fill="#1A1C2E" />
            <path d="M174.122 89.0034L173.537 90.702L174.587 90.7384L174.122 89.0034Z" fill="#1A1C2E" />
            <path d="M126.297 25.1548H114.652V40.9275H126.297V25.1548Z" fill="white" />
            <path d="M124.954 28.4302H116V29.0277H124.954V28.4302Z" fill="#44B4B8" />
            <path d="M122.698 26.353H118.258V26.9506H122.698V26.353Z" fill="#44B4B8" />
            <path d="M124.954 29.6709H116V30.2684H124.954V29.6709Z" fill="#44B4B8" />
            <path d="M124.954 30.9121H116V31.5097H124.954V30.9121Z" fill="#44B4B8" />
            <path d="M124.954 32.1528H116V32.7504H124.954V32.1528Z" fill="#44B4B8" />
            <path d="M124.954 33.3931H116V33.9906H124.954V33.3931Z" fill="#44B4B8" />
            <path d="M124.954 34.6338H116V35.2313H124.954V34.6338Z" fill="#44B4B8" />
            <path d="M124.954 35.8745H116V36.4721H124.954V35.8745Z" fill="#44B4B8" />
            <path d="M131.47 126.197L128.082 123.531L124.697 126.197V106.348H131.47V126.197Z" fill="#2F7D80" />
            <path d="M75.0211 129.158L71.6361 126.492L68.248 129.158V119.958H75.0211V129.158Z" fill="#458FB0" />
            <path d="M167.195 44.0604C164.729 44.0604 162.658 42.2162 162.369 39.7684L161.129 29.228H173.262L172.021 39.7684C171.736 42.2162 169.661 44.0604 167.195 44.0604Z" fill="#FF6561" />
            <path d="M167.919 41.0269H166.479V50.354H167.919V41.0269Z" fill="#FF6561" />
            <path d="M170.342 51.1881H164.051C164.051 50.1507 164.891 49.3105 165.928 49.3105H168.461C169.501 49.3105 170.342 50.1507 170.342 51.1881Z" fill="#FF6561" />
            <path d="M161.964 30.9839H158.151C157.496 30.9839 157.017 31.6057 157.187 32.2396L158.47 37.0503C158.837 38.4304 160.087 39.3889 161.512 39.3889H163.557" stroke="#FF6561" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M172.427 30.9839H176.24C176.895 30.9839 177.375 31.6057 177.205 32.2396L175.925 37.0503C175.558 38.4304 174.308 39.3889 172.882 39.3889H170.838" stroke="#FF6561" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M169.828 38.6121C171.294 37.1468 171.294 34.7712 169.828 33.3059C168.363 31.8407 165.988 31.8406 164.522 33.3059C163.057 34.7711 163.057 37.1468 164.522 38.6121C165.988 40.0773 168.363 40.0773 169.828 38.6121Z" fill="#FF8682" />
            <path d="M169.134 37.915C170.214 36.8347 170.214 35.0833 169.134 34.003C168.053 32.9227 166.302 32.9226 165.222 34.0029C164.141 35.0832 164.141 36.8347 165.222 37.915C166.302 38.9953 168.054 38.9953 169.134 37.915Z" fill="#458FB0" />
            <path d="M81.6443 76.8652C83.4099 76.8652 84.8413 75.4339 84.8413 73.6682C84.8413 71.9026 83.4099 70.4712 81.6443 70.4712C79.8786 70.4712 78.4473 71.9026 78.4473 73.6682C78.4473 75.4339 79.8786 76.8652 81.6443 76.8652Z" fill="white" />
            <path d="M92.863 40.2089C94.6287 40.2089 96.06 38.7776 96.06 37.012C96.06 35.2463 94.6287 33.8149 92.863 33.8149C91.0974 33.8149 89.666 35.2463 89.666 37.012C89.666 38.7776 91.0974 40.2089 92.863 40.2089Z" fill="white" />
            <path d="M83.8492 31.8912C85.0905 31.8912 86.0967 30.8849 86.0967 29.6436C86.0967 28.4023 85.0905 27.396 83.8492 27.396C82.6079 27.396 81.6016 28.4023 81.6016 29.6436C81.6016 30.8849 82.6079 31.8912 83.8492 31.8912Z" fill="white" />
            <path d="M52.2983 74.8053C53.5396 74.8053 54.5459 73.799 54.5459 72.5577C54.5459 71.3164 53.5396 70.3101 52.2983 70.3101C51.057 70.3101 50.0508 71.3164 50.0508 72.5577C50.0508 73.799 51.057 74.8053 52.2983 74.8053Z" fill="white" />
            <path d="M88.4078 67.4376C89.6491 67.4376 90.6555 66.4313 90.6555 65.19C90.6555 63.9486 89.6491 62.9424 88.4078 62.9424C87.1665 62.9424 86.1602 63.9486 86.1602 65.19C86.1602 66.4313 87.1665 67.4376 88.4078 67.4376Z" fill="white" />
        </svg>

    )
}

export default EmptyEvents;