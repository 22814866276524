import React from "react";
import BottomSheet from "../Modals/BottomSheet/BottomSheet";
import CATEGORIES from "../../data/categories";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import CrossMenu from "../../assets/icons/cross-menu.svg";
import './style.css';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const SelectCategory = ({ open, handleClose, className, onSelectCategory }) => {
    const { t } = useTranslation(["add-event"]);

   return (
       <BottomSheet open={open} handleClose={handleClose} className={className}>
            <div className="inner-div" style={{padding: "10px 24px"}}>
                <div className={className ? `${className}-headline` : ''} style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20
                }}>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 'bold'
                    }}>
                       Create New Event
                    </div>
                   <img
                       src={CrossMenu}
                       alt={t("close image")}
                       onClick={handleClose}
                   />
                </div>
                <Grid className={className ? `${className}-list` : ''} container spacing={3}>
                    {Object.values(CATEGORIES).map(cat => (
                        <Grid item xs={4} onClick={() => onSelectCategory(cat)}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: "center"
                            }}>
                                <div>
                                    {cat.Icon}
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: 14
                                }}>
                                {t(cat.label)}
                                </div>
                            </div>
                        </Grid>
                    ))}                           
                </Grid>           
            </div>
       </BottomSheet>
   )
}

export default SelectCategory;