import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import {
    FacebookShareButton,
    WhatsappShareButton,
} from "react-share";
import CopyIcon from "components/Icons/Copy";
import FacebookIcon from "components/Icons/Facebook";
import GmailIcon from "components/Icons/Gmail";
import WhatsappIcon from "components/Icons/Whatsapp";
import GDriveIcon from "components/Icons/GDrive";
import DownloadIcon from "components/Icons/Download";
import Close from "components/Icons/Close";
import CustomTypography from "components/CustomTypography/CustomTypography";
import useGoogle from "../../../../hooks/useGoogle";
import "./style.css";
import { useCallback, useState } from "react";
import UseWidth from "hooks/useUpdateWindowDimensions";
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';


const ShareMemories = ({ url, handleClose, isApp }) => {
    const { t } = useTranslation(["memories"]);
    const { token, authorize, load } = useGoogle();
    const { enqueueSnackbar } = useSnackbar();
    const [isDriveLoading, setIsdriveLoading] = useState(false)

    const width = UseWidth()
    const upload = useCallback(() => {
        let url = "http://localhost:3000/TestPDFfile.pdf"
        fetch(url).then(res => res.blob()).then(blob => {
            const form = new FormData();
            form.append('metadata', new Blob([JSON.stringify({ name: `${new Date().getFullYear()}/${new Date().getMonth()}/${new Date().getDate()}_${new Date().getDay()}_${new Date().getHours()}_${new Date().getSeconds()}.pdf`, mimeType: "application/pdf" })], { type: 'application/json' }));
            form.append('file', blob);
            load((accessToken) => {
                setTimeout(() => {
                    fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
                        method: 'POST',
                        headers: { Authorization: "Bearer " + accessToken },
                        body: form
                    }).then(res => res.json()).then(val => {
                        enqueueSnackbar(t("File shared into google drive successfuly"), { variant: "success" })
                        setIsdriveLoading(false)
                        if (val.id) {
                            window.open(`https://drive.google.com/file/d/${val.id}/view`, "_blank", "toolbar=no,scrollbars=yes,resizable=yes,top=500,left=500,height=700");
                        }
                    }).catch((err) => {
                        setIsdriveLoading(false)
                    })

                }, 3000);
            })
        });
    }, [token])


    const shareToGoogleDrive = async () => {
        if (isDriveLoading) return
        setIsdriveLoading(true)
        if (!token)
            await authorize()
        upload()
    }
    //TODO: Waiting the body of email instead of empty string
    const emailBody = ""
    return (
        <Container className="container share-memories-container">
            <div className="share-memories-title-wrapper">
                <CustomTypography className="share-memories-title" align="center" type="headline">
                    {isApp ? t("Share the Application") : t("Share your timeline")}
                </CustomTypography>
                <button className="text-button" onClick={handleClose}>
                    <Close />
                </button>
            </div>
            <div className="share-memories-wrapper">
                <div className="text-button share-btn">
                    <FacebookShareButton style={{ height: 34 }} url={url}>
                        <FacebookIcon />
                    </FacebookShareButton>
                    {t("Facebook")}
                </div>
                <WhatsappShareButton className="text-button share-btn" url={url} title="title">
                    <WhatsappIcon />
                    {t("WhatsApp")}
                </WhatsappShareButton>
                <button className="text-button share-btn" onClick={() => {
                    let url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body=${emailBody}&ui=2&tf=1&pli=1`;
                    window.open(url, 'sharer', 'toolbar=0,status=0,width=648,height=395');
                }}>
                    <GmailIcon />
                    {t("Gmail")}
                </button>
                <button className="text-button share-btn" onClick={() => {
                    navigator.clipboard.writeText(url);
                    enqueueSnackbar(t("Url Copied successfuly"), { variant: "success" })
                }}>
                    <CopyIcon />
                    {t("Copy Link")}
                </button>
                {!isApp && <button className="text-button share-btn" onClick={shareToGoogleDrive}>
                    <GDriveIcon />
                    {isDriveLoading && <CircularProgress size={12} />}
                    {!isDriveLoading && t("Drive")}
                </button>}
                {width < 700 && !isApp && <button className="text-button share-btn" onClick={() => { }}>
                    <DownloadIcon />
                    {t("Download")}
                </button>}
            </div>
            <div className="share-memories-wrapper">
                {width >= 700 && !isApp && <button className="text-button share-btn" onClick={() => { }}>
                    <DownloadIcon  />
                    {t("Download")}
                </button>}


            </div>
        </Container>
    )
}

export default ShareMemories;