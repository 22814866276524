// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memorydetails-root {
  background: #000;
  min-height: 100%;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.memorydetails-root--notch div {
  width: 65px;
  height: 4px;
  background: #dfdfdf;
  border-radius: 100px;
}

.memory-story-root {
  opacity: 1;
  transition: all 1s ease;
}

.hide {
  opacity: 0;
  transition: all 500ms ease;
}

@media (min-width: 700px) {
  .memorydetails-root {
    margin: auto;
    max-width: 450px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Modals/MemoryStoryModal/style.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,SAAS;EACT,SAAS;EACT,OAAO;EACP,QAAQ;EACR,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,0BAA0B;AAC5B;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".memorydetails-root {\n  background: #000;\n  min-height: 100%;\n  top: auto;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  position: absolute;\n}\n\n.memorydetails-root--notch div {\n  width: 65px;\n  height: 4px;\n  background: #dfdfdf;\n  border-radius: 100px;\n}\n\n.memory-story-root {\n  opacity: 1;\n  transition: all 1s ease;\n}\n\n.hide {\n  opacity: 0;\n  transition: all 500ms ease;\n}\n\n@media (min-width: 700px) {\n  .memorydetails-root {\n    margin: auto;\n    max-width: 450px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
