import "./variables.css";
import { createTheme } from '@mui/material/styles';

let theme = createTheme({});

const customtheme = createTheme(theme, {
    typography: {
        fontFamily: [
            'verdana',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                maxWidthLg: {
                    [theme.breakpoints.up('xl')]: {
                        maxWidth: 1350
                    }
                }
            }
        }
    },
    palette: {
        primary: {
            light: '#85B807',
            main: "#367419",
            dark: 'rgb(110, 149, 11)',
            contrastText: '#fff',
        },      
        secondary: {
            light: '#e6c578',
            main: '#F1AB0D',
            dark: '#F2940A',
            contrastText: '#000',
        },
        danger: {
            light: '#C32B26	',
            main: '#C32B26',
            dark: '#C32B26',
            contrastText: '#000',
        },
        tertiary: {
            light: '#055C7F	',
            main: '#0383A4',
            dark: '#00496F',
            contrastText: '#fff',
        },
        grey: {
            light: '#EEEEEE	',
            main: '#EFEFEF',
            dark: '#878A8C',
            contrastText: '#000000',
        },
        warning: {
            light: '#C32B26	',
            main: '#C32B26',
            dark: '#C32B26',
            contrastText: '#000000',
        },
    },
});

export default customtheme;