import { useState } from "react";
import Container from '@mui/material/Container';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTypography from 'components/CustomTypography/CustomTypography';
import StyledButton from 'components/StyledButton/StyledButton';
import BackIcon from 'components/Icons/Back';
import Filter from 'components/Icons/Filter';
import Grid from "components/Icons/Grid";
import List from "components/Icons/List";
import Search from "components/Icons/Search";

import "./style.css";

const MyTimeLineHeader = ({ isGrid, SearchValue = "", onSearch, isMyTimeLines, isShared, onLayoutChange, onCreate, headline, onFilter }) => {
    const [searchValue, setSearchValue] = useState(SearchValue)
    const { t } = useTranslation(["timeline"]);
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1, {
            replace: true
        }) || navigate("/")
    }
    return (
        <Container sx={{ mt: 2 }} className="timeline-header container">
            <div className="timeline-header-wrapper" >
                <div className='timeline-header-breadcrumb'>
                    <div className='timeline-header-breadcrumb--back'>
                        <StyledButton onClick={onBack} className='text-button'>
                            <BackIcon />
                        </StyledButton>
                    </div>
                    <CustomTypography type="headline">
                        {headline}
                    </CustomTypography>
                </div>
                <div className='timeline-header-wrapper--actions'>
                    <StyledButton onClick={() => onLayoutChange(!isGrid)} className='text-button timeline-header-wrapper--actions-layout'>
                        {isGrid ? <Grid isActive={true} /> : <List isActive={true} />}
                    </StyledButton>                 
                </div>
            </div>
            <div className='timeline-search-wrapper'>
                {!isMyTimeLines && !isShared &&<>
                <div className="search-timeline-input-wrapper">
                        <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="search" placeholder={t("Search by timeline title")} id="search-timeline-input" className="search-timeline-input" />
                        <button onClick={() => onSearch(searchValue)} className='text-button search-button-submit'>
                        <Search />
                    </button>
                </div>
                <div style={{display: 'flex'}}>
                    <StyledButton onClick={onFilter} className='text-button filter-btn'>
                        <Filter width={"40"} height="36" />
                    </StyledButton>
                </div>
                </>}
                {onCreate && <div className='timeline-header-wrapper--actions-create-timeline'>
                    <StyledButton onClick={onCreate} variant="contained">{t("create new timeline")}</StyledButton>
                </div>}              
            </div>
        </Container>
    )
}

export default MyTimeLineHeader;