import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {  FormControl, Grid, Typography, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";

const SelectForm = ({ submitClicked, setSubmitClicked, handleError, handleDayChange: changeDay,
handleMonthChange: changeMonth,
handleYearChange: changeYear, day: DAY,month: MONTH,year: YEAR }) => {
    const { t } = useTranslation(["add-event"]);

    const [day, setDay] = useState(DAY);
    const [month, setMonth] = useState(MONTH);
    const [year, setYear] = useState(YEAR);
    const [errors, setErrors] = useState({});
    const [days, setDays] = useState([]);

    const generateOptions = (start, end) => {
        const options = [];
        for (let i = start; i <= end; i++) {
            options.push(i);
        }
        return options;
    };


    // const days = generateOptions(1, moment().daysInMonth());
    const months = generateOptions(1, 12);
    const currentYear = moment().year();
    const years = generateOptions(currentYear - 100, currentYear).reverse();

    const updateDays = () => {
        const m = month || moment().month()
        const y = year || moment().year()
        if (m && y) {
            const totalDays = moment(`${y}-${m}`, 'YYYY-MM').daysInMonth();
            const Days = generateOptions(1, totalDays)
            setDays(Days);
            // console.log({ month: m, isValid: Days.includes(day), Days, day });
            if (!Days.includes(day)) {
                setDay('')
            }
        } else {
            setDays([]);
        }
    };

    useEffect(() => {
        updateDays();
    }, [month, year]);
    

    
    const validateForm = () => {
        const errors = {};
        if (!year) {
            errors.year = 'Year is required';
        }
        
        if (day && !month) {
            errors.month = 'Month is required';
        }
        handleError(!!Object.keys(errors)?.length)
        return errors;
    };


    const handleDayChange = (value) => {
        changeDay(value)
        setDay(value);
        setErrors((prevErrors) => ({ ...prevErrors, day: '' }));
        setSubmitClicked(false)
    };

    const handleMonthChange = (value) => {
        changeMonth(value)

        setMonth(value);
        
        setErrors((prevErrors) => ({ ...prevErrors, month: '' }));
        // validate()
        setSubmitClicked(false)

    };

    const handleYearChange = (value) => {
        changeYear(value)

        setYear(value);
        setErrors((prevErrors) => ({ ...prevErrors, year: '' }));
        setSubmitClicked(false)

    };

    const validate = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            const data = {
                day,
                month,
                year,
            };
            // Handle form submission
            // console.log(data);
        } else {
            setErrors(validationErrors);
        }
    };
    useEffect(() => {
        // handleError(false)
        if (submitClicked) {
            validate();
        }
    }, [submitClicked]);

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4}>
                        <InputLabel
                            shrink
                            className="inputPaddingTop inputLableFontSize"
                            htmlFor="password-input"
                        >
                            {t("Day")}
                        </InputLabel>
                        <FormControl fullWidth error={!!errors.day} >
                            <Autocomplete
                                options={days}
                                className="addEventTagsInput"
                                value={day}
                                renderInput={(params) => <TextField  {...params} placeholder={t('DD')}  />}
                                onChange={(e, value) => handleDayChange(value)}
                            />
                            {errors.day && (
                                <Typography variant="caption" color="error">
                                    {errors.day}
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <InputLabel
                            shrink
                            className="inputPaddingTop inputLableFontSize"
                            htmlFor="password-input"
                        >
                            {t("Month")}
                        </InputLabel>
                        <FormControl fullWidth error={!!errors.month}>
                            <Autocomplete
                                options={months}
                                value={month}

                                className="addEventTagsInput"
                                renderInput={(params) => <TextField {...params} placeholder={t('MM')} />}
                                onChange={(e, value) => handleMonthChange(value)}

                            />
                            {day && (
                                <Typography variant="caption" color={errors.month ? "error" : ""} style={{
                                    fontSize: '0.6rem'
                                }}>
                                    *{t("required")}
                                </Typography>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <InputLabel
                            shrink
                            className="inputPaddingTop inputLableFontSize"
                            htmlFor="password-input"
                        >
                            {t("Year")}
                        </InputLabel>
                        <FormControl fullWidth error={!!errors.year}>
                            <Autocomplete
                                options={years}
                                value={year}

                                className="addEventTagsInput"
                                renderInput={(params) => <TextField {...params} placeholder={t('YYYY')}/>}
                                onChange={(e, value) => handleYearChange(value)}
                                onBlur={validate}
                            />
                       
                                
                            <Typography variant="caption" color={errors.year ? "error" : ""} style={{
                                    fontSize: '0.6rem'
                                }}>
                                        *{t("required")}
                                </Typography>
            

                        
                        </FormControl>
                
                    </Grid>
                </Grid>               
            </Grid>
        </Grid>
    );
};

export default SelectForm;

