// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /** Primary **/
    --main-color: #367419;
    --main-color-light: #85B807;
    --main-color-dark: rgb(110, 149, 11);

    /** Secondary **/
    --secondary-color: #F1AB0D;
    --secondary-color-light: #e6c578;
    --secondary-color-dark: #F2940A;

    /** Errors **/
    --error-color: #C32B26;

    --sticky-bottom-actions-height: 98px;

    --main-text-color: #000000;
    --secondary-text-color: #202121;
    --light-text-color: #878A8C;

};`, "",{"version":3,"sources":["webpack://./src/theme/variables.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;IACrB,2BAA2B;IAC3B,oCAAoC;;IAEpC,gBAAgB;IAChB,0BAA0B;IAC1B,gCAAgC;IAChC,+BAA+B;;IAE/B,aAAa;IACb,sBAAsB;;IAEtB,oCAAoC;;IAEpC,0BAA0B;IAC1B,+BAA+B;IAC/B,2BAA2B;;AAE/B,CAAA","sourcesContent":[":root {\n    /** Primary **/\n    --main-color: #367419;\n    --main-color-light: #85B807;\n    --main-color-dark: rgb(110, 149, 11);\n\n    /** Secondary **/\n    --secondary-color: #F1AB0D;\n    --secondary-color-light: #e6c578;\n    --secondary-color-dark: #F2940A;\n\n    /** Errors **/\n    --error-color: #C32B26;\n\n    --sticky-bottom-actions-height: 98px;\n\n    --main-text-color: #000000;\n    --secondary-text-color: #202121;\n    --light-text-color: #878A8C;\n\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
