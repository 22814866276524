import Typography from '@mui/material/Typography';
import cls from "classnames";
import "./style.css";

/**
* @Author Ahmed Shatat
* @param {node} children - The employee who is responsible for the project.
* @param {string} align - The align of the children it's default inherit it take: ['center' | 'inherit' | 'justify' | 'left' | 'right'] 
* @param {string} className 
* @param {bool} marginBottom - If true, the text will have a bottom margin.
* @param {bool} noWrap - If true, the text will not wrap, but instead will truncate with a text overflow ellipsis. 
        Note that text overflow can only happen with block or inline-block level elements (the element needs to have 
        a width in order to overflow).
* @param {bool} paragraph - If true, the element will be a paragraph element.
* @param {string} tag - object	{ h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6', subtitle1: 'h6', subtitle2: 'h6', body1: 'p', body2: 'p', inherit: 'p', }
* @param {enum} type - Accept [headline | subHeadLine | normal | error]
*/

const CustomTypography = ({ children, align, marginBottom, noWrap, paragraph, tag, className, type, style }) => {
    const CustomTypographyClass = cls("typography", {
        [type]: !!type,
        [className]: !!className
    })
    return (
        <Typography 
            align={align}
            gutterBottom={!!marginBottom}
            noWrap={!!noWrap}
            paragraph={!!paragraph}
            variantMapping={tag}
            className={CustomTypographyClass}
            style={style}
        >
            {children}
        </Typography>
    )
}

export default CustomTypography;