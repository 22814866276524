import { useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CustomTypography from "components/CustomTypography/CustomTypography";
import SquareClose from "components/Icons/SquareClose";
import Gallery from "components/StorySlider/StorySlider";

import "./style.css";

const MemoryStory = ({ handleClose, data, open, images }) => {
   
    return (
        <div id="memory-story" className="memory-story">
            <Container className="memory-story-header container">
                <CustomTypography className="memory-story-header--title" type="headline">
                    {data.title}
                    <span>
                        {data.date?.day && <span>
                            {data.date?.day}/
                        </span>}
                        {data.date?.month && <span>
                            {data.date?.month}/
                        </span>}
                        {data.date?.year && <span>
                            {data.date?.year}
                        </span>}    
                    </span>
                </CustomTypography>
                <Button className="text-button" onClick={handleClose}>
                    <SquareClose />
                </Button>
            </Container>
            <div className="memory-story--gallery">
                <Gallery images={images} />
            </div>        
        </div>              
    )
}

export default MemoryStory;