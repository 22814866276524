import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import { CssVarsProvider } from "@mui/joy/styles";
import { useNavigate, useLocation } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Textarea from "@mui/joy/Textarea";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cover from "./components/Cover/index";
// import { DateIcon } from "./components/SVGIcons/SVGIcons";
import DrawerComponentCategory from "./DrawerComponentCategory";
import RecorderModal from "./components/RecorderModal/RecorderModal";
import Cropper from "./components/Cropper";
import { websiteIstanceFormData } from "api/axios";
import { setOfflineFamilyMemories, setTemorarilyEventFormData } from "redux/slices/familyMemoriesSlice";
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";
import Media from "./Media";
import Back from "assets/images/svg/back.svg";
import SelectArrow from "assets/icons/select-arrow.svg";
import UseWidth from "hooks/useUpdateWindowDimensions";
import dataToBase64 from "helpers/dataToBase64";
import getBase64FromUrl from "helpers/getBase64FromUrl";
import "./style.css";
import StyledButton from "components/StyledButton/StyledButton";
import heicToAny from "heic2any"
import CATEGORIES from "../../data/categories";
import ImageGallery from "pages/EventDetails/components/ImageGallery";
import DateForm from "./components/Date";

import { Editor } from '@tinymce/tinymce-react';

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
const BootstrapInput = styled(InputBase)(() => ({
  height: "36px",
  width: "100%",
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    border: "1px solid #DFDFDF",
    fontSize: 12,
    padding: "9px 0px 10px 0px",
    height: "15px",
    paddingInlineStart: "15px",
  },
}));

const fileTypes = [
  "JPG",
  "JPEG",
  "HEIF",
  "HEIC",
  "TIF",
  "DMP",
  "PNG",
  "MP3",
  "M4A",
  "WAV",
];

const getMinutes = (recordedTime, text) => {
  const minutes = Math.floor(recordedTime / 60);
  const seconds = recordedTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return `${formattedTime} ${text}`;
};
var hasError = false;
export default function AddEvent({ setSnackbarOpen, resetAddEvent }) {
  const location = useLocation();

  const category = location.state?.category ?? "";

  const { t } = useTranslation(["add-event"]);
  // const [tags, setTags] = useState([]);
  const tempEventFormData = useSelector((state) => state.familyMemories.tempEventFormData) ?? {};
  const [value, setValue] = useState(getDatafromSession("value") || "PRIVATE");
  const [date, setDate] = useState(getDatafromSession("date") || null);
  const [title, setTitle] = useState(getDatafromSession("title") || null);
  const [isMediaOpen, setIsmediaOpen] = useState(false);
  const [place, setPlace] = useState(getDatafromSession("place") || null);
  const [details, setDetails] = useState(getDatafromSession("details") || null);
  const [currentCropImage, setCurrentCropImage] = useState(tempEventFormData.currentCropImage || null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [categoryValue, setCategoryValue] = useState(category || getDatafromSession("categoryValue") || null);
  const [currentCover, setCurrentCover] = useState(tempEventFormData.currentCover || null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [recorderDrawerOpen, setRecorderDrawerOpen] = useState(false);
  const [otherValue, setOtherValue] = useState(getDatafromSession("otherValue") || "");
  const navigate = useNavigate();
  const [cropImage, setCropImage] = useState(tempEventFormData.cropImage || null);
  const [fileList, setFileList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [numberOfAudio, setNumberOfAudio] = useState(getDatafromSession("numberOfAudio") || 1);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [day, setDay] = useState(getDatafromSession("day") || '');
  const [month, setMonth] = useState(getDatafromSession("month") || '');
  const [year, setYear] = useState(getDatafromSession("year") || '');
  const [errors, setErrors] = useState({});
  // const [hasError, setHasError] = useState(false);

  const editorRef = useRef();


  const params = useParams();
  let { timelineId } = params;
  const dispatch = useDispatch();
  const width = UseWidth();
  const timelineTitle = location.state?.timelineTitle ?? "";
  const newTimeLineId =
    location?.pathname.includes("timelines") &&
    location?.pathname?.split("/")[2];
  timelineId = timelineId ? timelineId : newTimeLineId;

  const fileInputRef = useRef(null);

  const handleDayChange = (v) => {
    setDay(v)
    setDataToSession("day", v)
  }
  const handleMonthChange = (v) => {
    setMonth(v)
    setDataToSession("month", v)
  }
  const handleYearChange = (v) => {
    setYear(v)
    setDataToSession("year", v)
  }
  const data = useSelector((state) => state.familyMemories.familyMemories);

  const familyMemoriesList = data[timelineId]?.events ?? [];

  const resetState = () => {
    resetAddEvent?.()

  }
  async function createFile(dataURL, name, type) {
    const blob = await (await fetch(dataURL)).blob();
    const file = new File([blob], name, { type, lastModified: new Date() });
    return file

  }
  const storeFileListInStore = async (files, cb, isAudio) => {
    const list = [];
    for (let index = 0; index < files.length; index++) {
      const item = files[index];
      const FILE = await dataToBase64(isAudio ? item.file : item);
      list.push({
        ...item,
        path: FILE,
        name: item.name,
        size: item.size,
        type: item.type,
      });
    }
    cb(list)

  }
  const setDataToSession = (key, value) => {
    if (value === null) {
      window.sessionStorage.removeItem(key)
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }
  function getDatafromSession(key) {
    return window.sessionStorage.getItem(key);
  }

  const handleChangeFile = async (file) => {
    if (file?.length !== 0) {
      // file[0].name = `${new Date().getTime()}${file[0].name.replaceAll(" ", "_")}`
      let fileListNew = [...fileList];
      for (const property in file) {
        if (!isNaN(property)) {
          // console.flog(file[property].type)
          if (file[property].type === "image/heif" || file[property].type === "image/heic") {
            const conversionResult = await heicToAny({ blob: file[property], toType: "image/jpeg", quality: 1 })
            const newImage = new File([conversionResult], `${new Date().getTime()}_${file[property].name.split(".")[0]}.jpeg`, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            fileListNew = [...fileListNew, newImage];
          } else {
            const newBlob = new Blob([file[property]], { type: file[property].type });

            const newImage = new File([newBlob], `${new Date().getTime()}_${file[property].name}`, { type: file[property].type, lastModified: 444444 });

            fileListNew = [...fileListNew, newImage];
          }
        }
      }
      setFileList(fileListNew);
      await storeFileListInStore(fileListNew, (images) => {
        dispatch(setTemorarilyEventFormData({ fileList: images }))
      })
    }
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
    setDataToSession("date", newValue)
  };
  const handleChangeRadio = async (file) => {
    // console.log(file)
    try {
      setCurrentCropImage(file);
      dispatch(setTemorarilyEventFormData({ currentCropImage: file }))
    } catch (err) {
      console.log(err)
    }
  };
  const handleDeleteFile = async (file) => {
    console.log("file", file);
    const index = fileList.findIndex((e) => e.name === file.name);
    if (index !== -1) {
      const cloneFileList = [...fileList];
      cloneFileList.splice(index, 1);
      setFileList(cloneFileList);
      await storeFileListInStore(cloneFileList, (images) => {
        dispatch(setTemorarilyEventFormData({ fileList: images }))
      })
      if (currentCropImage && currentCropImage.name === file.name) {
        setCropImage(null);
        setCurrentCover(null);

        setDataToSession("categoryValue", null)
        setCurrentCropImage(null);
      }
    }
  };
  const setFileList_ = async () => {
    if (tempEventFormData.fileList) {
      const imagesList = []
      for (let index = 0; index < tempEventFormData.fileList.length; index++) {
        const file_ = await createFile(tempEventFormData.fileList[index].path, tempEventFormData.fileList[index].name, tempEventFormData.fileList[index].type);
        imagesList.push(file_)

      }
      setFileList(imagesList)
    }
  }
  const setAudioList_ = async () => {
    if (tempEventFormData.audioList) {
      const audioList = []
      for (let index = 0; index < tempEventFormData.audioList.length; index++) {
        const file_ = await createFile(tempEventFormData.audioList[index].path, tempEventFormData.audioList[index].name, tempEventFormData.audioList[index].type);
        audioList.push({ ...tempEventFormData.audioList[index], file: file_ })

      }
      setAudioList(audioList)
    }
  }
  useEffect(() => {
    setFileList_()
    setAudioList_()

  }, [tempEventFormData.fileList, tempEventFormData.audioList]);


  const unRegisteredCreateEvent = async () => {
    try {
      let delay = 3000;
      let totalFilesSize = 0;
      if (
        (Array.isArray(familyMemoriesList) && familyMemoriesList.length < 2) ||
        window.isAuth
      ) {
        const images = [];
        for (let index = 0; index < fileList.length; index++) {
          const img = fileList[index];
          const image = await dataToBase64(img);
          images.push({
            path: image,
            name: img.name,
            isThumbnailOrigin: false,
            size: img.size,
            type: img.type,
          });
          totalFilesSize = totalFilesSize + img.size;
        }

        const voices = [];
        console.log(audioList)
        for (let index = 0; index < audioList.length; index++) {
          try {
            const audio = audioList[index];
            const voice = audio.file ? await dataToBase64(audio.file) : audio;
            // console.log(voice)
            if (audio.file) {
              voices.push({
                path: voice,
                name: audio.file.name,
                isThumbnailOrigin: false,
                size: audio.file.size,
                type: audio.file.type,
                duration: audio.file.duration
              });
              totalFilesSize = totalFilesSize + audio.file.size;
            } else {
              voices.push(voice)
            }
          } catch (error) {
            console.log("errrrrrrrrrr", error);
          }
        }

        const eventId = new Date().getTime();

        const event = {
          category: otherValue || categoryValue,
          createdAt: moment().format("YYYY-MM-DD"),
          creator_id: null,
          date: {
            day,
            month,
            year
          },
          day,
          month,
          year,
          details: editorRef.current.getContent(),
          files: { images, voices },
          id: eventId,
          status: value,
          // tags: [],
          thumbnail: cropImage ? await getBase64FromUrl(cropImage) : images[0]?.path,
          timelineId,
          title,
          updatedAt: moment().format("YYYY-MM-DD"),
          cropImage: cropImage ? await getBase64FromUrl(cropImage) : "",
          currentCropImageName: currentCropImage?.name ?? "",
          place
        };

        const payload = {
          event,
          timelineId,
        };
        // console.log("payload", payload);
        dispatch(setOfflineFamilyMemories(payload));
        //   if(totalFilesSize > 1000000){
        //   delay = 5000
        // }
        // if(totalFilesSize > 3000000){
        //   delay = 8000
        // }
        // if(totalFilesSize > 10000000){
        //   delay = 10000
        // }
        // if(totalFilesSize > 20000000){
        //   delay = 20000
        // }

        let n = localStorage.getItem('localEventsCounter') ?? 0;
        if (n === null) {
          n = 0;
        } else {
          n++;
        }

        localStorage.setItem("localEventsCounter", n);


        await new Promise((resolve) => setTimeout(() => {
          window.sessionStorage.clear()
          dispatch(setTemorarilyEventFormData(null))
          resetState()
          if (newTimeLineId) {
            setSnackbarOpen?.(true);
          } else {
            navigate(`/timelines/${timelineId}`, {
              state: {
                from: "addEvent",
              },
            });
          }
          resolve("")
        }, delay))

      } else {
        dispatch(setLoginRegisterModalOpen(true));
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setSubmitClicked(true);
    localStorage.setItem("toast", "true")
    setErrors({})
    // await new Promise((resolve) => setTimeout(() => {
    //   resolve("")
    // }, 500));
    if (hasError) return
    if (!title) {
      setErrors({
        title: true
      })
      return;
    }

    if (!window.isAuth) {
      unRegisteredCreateEvent();
      return;
    }
    try {
      let Images = []
      let Voices = []
      const formData = new FormData();
      if (fileList.length) {
        Images = [...fileList]

        if (cropImage) {
          const response = await fetch(cropImage);
          const blob = await response.blob();
          const file = new File(
            [blob],
            `${currentCropImage.name
              .split(".")
              .slice(0, -1)
              .join(".")}-thumbnail.jpeg`,
            { type: blob.type }
          );
          formData.append("files", file);
        }
      }

      if (audioList.length) {
        Voices = [...audioList]
      }

      const data = {
        category: otherValue || categoryValue,
        title,
        place,
        day,
        month,
        year,
        details: editorRef.current.getContent(),
      };

      formData.append("event", JSON.stringify(data));

      const res = await websiteIstanceFormData.post(
        `api/v1/timelines/${timelineId}/events`,
        formData
      );

      const id = res.data.data.id;
      Images = [...Images.slice(0, 10)]
      if (id && Images.length) {
        for (const image of Images) {
          const formData = new FormData();
          formData.append("event", JSON.stringify(data));
          formData.append("files", image);
          await updateEventFormData(timelineId, id, formData)

        }
      }

      if (id && Voices.length) {
        for (const voice of Voices) {
          const formData = new FormData();
          // console.log('Voices before add:',voice.file)
          formData.append("event", JSON.stringify(data));
          formData.append("files", voice.file);
          await updateEventFormData(timelineId, id, formData)

        }
      }

      // console.log("id", id);
      window.sessionStorage.clear()
      dispatch(setTemorarilyEventFormData(null))
      resetState()

      if (newTimeLineId) {
        setSnackbarOpen(true);
      } else {
        navigate(`/timelines/${timelineId}`, {
          state: {
            from: "addEvent",
          },
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };


  async function updateEventFormData(timelineId, eventId, formData) {
    await websiteIstanceFormData.put(`api/v1/timelines/${timelineId}/events/${eventId}`, formData);
  }
  const handleUpdateAudioList = (file, audiolength) => {
    setAudioList([...audioList, { file, name: file.name, time: audiolength }]);
    // await storeFileListInStore([...audioList, { file, name: file.name, time: audiolength }], (data) => {
    //   dispatch(setTemorarilyEventFormData({ audioList: data }))
    // }, true)
  };

  const handleDeleteAudio = async (id) => {
    const cloneAudioList = [...audioList];
    cloneAudioList.splice(id, 1);
    setAudioList(cloneAudioList);
    await storeFileListInStore(cloneAudioList, (data) => {
      dispatch(setTemorarilyEventFormData({ audioList: data }))
    }, true)

    if (!cloneAudioList.length) {
      setNumberOfAudio(1)
      setDataToSession("numberOfAudio", 1)

    };
  };

  const handleCloseMedia = () => {
    setIsmediaOpen(false)
  }
  const handleOpenMedia = () => {
    setIsmediaOpen(true)
  }
  return (
    <>
      <div style={{
        maxWidth: 666,
        margin: "auto"
      }}>

        <Media
          fileTypes={fileTypes}
          handleChangeFile={handleChangeFile}
          fileList={fileList}
          handleChangeRadio={handleChangeRadio}
          setOpen={setOpen}
          currentCover={currentCover}
          handleDeleteFile={handleDeleteFile}
          setRecorderDrawerOpen={setRecorderDrawerOpen}
          audioList={audioList}
          getMinutes={getMinutes}
          handleDeleteAudio={handleDeleteAudio}
          open={isMediaOpen} handleClose={handleCloseMedia}

        />
        <Container className="addEventContainerBreadCrumb" >
          <Typography
            component="div"
            style={{
              fontSize: "16px",
              fontWeight: "700",
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
            className="add-event-title"
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                backgroundColor: "#F3F3F3",
                marginInlineEnd: "10px",
              }}
              onClick={() => navigate(-1, {
                replace: true
              })}
            >
              <img src={Back} alt={t("Back")} className="backIconRotate" />
            </div>
            {timelineTitle ? `${t("Add")} ${t(CATEGORIES[category]?.label)} ${t("Event")} ${"To"}` : `${t("Add")} ${t(CATEGORIES[category]?.label)} ${t("Event")}`}{" "}
            {timelineTitle}
          </Typography>
        </Container>
        {fileList?.length ? (
          <ImageGallery
            images={fileList.map(file => {

              // Create a temporary URL for the file
              const imageUrl = URL.createObjectURL(file);

              return imageUrl


            })}
            handleClickOnImage={handleOpenMedia}
            uploadMedia={handleOpenMedia}
            width={width}
            showActions={true}
          />
        ) : <Cover showActions={true} category={category} uploadMedia={handleOpenMedia} />
        }

        <Container className="addEventContainer" >
          <Box component="form" noValidate >


            <InputLabel
              className="inputLableFontSize inputPaddingTop"
              shrink
              htmlFor="title-input"
            >
              {t("Event Title")}
            </InputLabel>
            <BootstrapInput
              placeholder={t(CATEGORIES[category]?.placeHolder)}
              id="title-input"
              label="Filled"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value)
                setDataToSession("title", e.target.value)
              }}
            />
            {errors && errors.title && <div style={{
              color: '#d32f2f',
              fontSize: '0.6rem'
            }}>
              {t("required")}
            </div>}

            <DateForm handleError={(v) => {
              hasError = v;
            }}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
              setSubmitClicked={setSubmitClicked} submitClicked={submitClicked} />


            <InputLabel
              className="inputLableFontSize inputPaddingTop"
              shrink
              htmlFor="place-input"
            >
              {t("Event Place")}
            </InputLabel>
            <BootstrapInput
              id="place-input"
              label="Filled"
              value={place}
              onChange={(e) => {
                setPlace(e.target.value)
                setDataToSession("place", e.target.value)
              }}
            />
            <InputLabel
              className="inputPaddingTop inputLableFontSize"
              shrink
              htmlFor="details-input"
            >
              {t("Event Details")}
            </InputLabel>
            <CssVarsProvider>
              <Editor
                apiKey='zgmuezjaq8ok4h11mu1q00a1uq6s74u7timoaqndexhuyzfa'
                onInit={(evt, editor) => editorRef.current = editor}
                // initialValue={details}
                init={{
                  height: 255,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: false,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
              {/* <Textarea
                className="addEventTextarea"
                minRows={5}
                id="details-input"
                value={details}
                onChange={(e) => {
                  setDetails(e.target.value)
                  setDataToSession("details", e.target.value)
                }}
              /> */}
            </CssVarsProvider>




            <InputLabel
              className="inputPaddingTop inputLableFontSize"
              shrink
              style={{
                visibility: 'hidden',
                height: 0, position: 'absolute'
              }}
            // htmlFor="category-input"
            >
              {t("Event Privacy")}
            </InputLabel>
            {/* <FormControl style={{
            visibility: 'hidden',
              height: 0, position: 'absolute'

          }}>
            <RadioGroup
              name="event-privacy-controlled-radio-buttons-group"
              onChange={(e) => {
                setValue(e.target.value)
                setDataToSession('value', e.target.value)
              }
              }
              value={value}
              row
            >
              <FormControlLabel
                value="PRIVATE"
                control={<Radio />}
                label={t("Private")}
                className="addEventPrivacy"
              />
              <FormControlLabel
                value="PUBLIC"
                control={<Radio />}
                label={t("Public")}
                className="addEventPrivacy"
              />
            </RadioGroup>
          </FormControl> */}
            <InputLabel
              className="inputPaddingTop inputLableFontSize"
              shrink
              htmlFor="category-input"
              style={{ visibility: 'hidden', height: 0, position: 'absolute' }}
            >
              {t("Event Category")}
            </InputLabel>
            <div style={{ position: "relative", visibility: 'hidden', height: 0, position: 'absolute' }}>
              <BootstrapInput
                placeholder={t("Select Option")}
                id="category-input"
                className="addEventCategoryInput"
                value={otherValue || t(categoryValue)}
                readOnly
                onClick={() => setDrawerOpen(true)}
              />
              <div className="arrow-icon-div" onClick={() => setDrawerOpen(true)}>
                <span>
                  <img src={SelectArrow} alt="arrow icon" />
                </span>
              </div>
            </div>
            <div style={{
              visibility: 'hidden'
            }}>
              {value === "PRIVATE" ? (
                <div className="privacy-statement">
                  {t("You will be the only person who can view this timeline.")}
                </div>
              ) : (
                <div className="privacy-statement">
                  {t("Anyone can view and share.")}
                </div>
              )}
            </div>
            {/* media */}
          </Box>
        </Container>
        <DrawerComponentCategory
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          categoryValue={categoryValue}
          setCategoryValue={setCategoryValue}
          setDataToSession={setDataToSession}
          showOtherInput={showOtherInput}
          setShowOtherInput={setShowOtherInput}
          BootstrapInput={BootstrapInput}
          otherValue={otherValue}
          setOtherValue={setOtherValue}

        />
        <Modal
          slotProps={{ backdrop: { style: { backgroundColor: "unset" } } }}
          open={open}
        >
          <>
            <Cropper
              currentCropImage={currentCropImage}
              setCurrentCover={setCurrentCover}
              setDataToSession={setDataToSession}
              setCropImage={setCropImage}
              setOpen={setOpen}
              open={open}
            />
          </>
        </Modal>
        {recorderDrawerOpen && (
          <RecorderModal
            recorderDrawerOpen={recorderDrawerOpen}
            setRecorderDrawerOpen={setRecorderDrawerOpen}
            numberOfAudio={numberOfAudio}
            setNumberOfAudio={setNumberOfAudio}
            handleUpdateAudioList={handleUpdateAudioList}
            categoryValue={categoryValue}
          />
        )}

        {width < 700 && (
          <AppBar className="navbar-login-button add-button" component="nav">
            {loading ? (
              <StyledButton
                color="primary"
                loading={true}
                variant="contained"
                fullWidth
                type="submit"
              ></StyledButton>
            ) : (
              <button onClick={() => handleSubmit()} disabled={submitClicked} className="addEventBtn">
                {t("ADD YOUR EVENT")}
              </button>
            )}
          </AppBar>
        )}
      </div>
      {width >= 700 && (
        <AppBar
          className="navbar-login-button navbar-login-button-desktop"
          component="nav"
        >
          {loading ? (
            <StyledButton
              color="primary"
              loading={true}
              variant="contained"
              fullWidth
              type="submit"
            >
            </StyledButton>
          ) : (
            <button onClick={() => handleSubmit()} disabled={submitClicked} className="addEventBtn">
              {t("ADD YOUR EVENT")}
            </button>
          )}
        </AppBar>
      )}
    </>
  );
}
