// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memory-story .memory-story-header {
  color: #fff;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 27px;
}

.memory-story-header .memory-story-header--title {
  color: #fbfbfb;
}

.memory-story-header .memory-story-header--title > span {
  color: #fbfbfb;
  display: block;
  font-weight: 400;
  font-size: 12px;
}

.memory-story .memory-story-footer {
  height: var(--sticky-bottom-actions-height);
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(225, 223, 215, 0.2);
}

.memory-story .memory-story-footer div {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}


@media only screen and (max-width: 375px) and (max-height: 667px) {
  .memory-story {
    height: 100vh;
    padding-top: 55px;
    /* overflow: auto; */
  }

  .memory-story--gallery {
    height: 100%;
  }

  .memory-story--gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/EventDetails/components/MemoryStory/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;EACnB,8CAA8C;AAChD;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,WAAW;AACb;;;AAGA;EACE;IACE,aAAa;IACb,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;AACF","sourcesContent":[".memory-story .memory-story-header {\n  color: #fff;\n  height: 96px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 27px;\n}\n\n.memory-story-header .memory-story-header--title {\n  color: #fbfbfb;\n}\n\n.memory-story-header .memory-story-header--title > span {\n  color: #fbfbfb;\n  display: block;\n  font-weight: 400;\n  font-size: 12px;\n}\n\n.memory-story .memory-story-footer {\n  height: var(--sticky-bottom-actions-height);\n  display: flex;\n  align-items: center;\n  border-top: 1px solid rgba(225, 223, 215, 0.2);\n}\n\n.memory-story .memory-story-footer div {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n  width: 100%;\n}\n\n\n@media only screen and (max-width: 375px) and (max-height: 667px) {\n  .memory-story {\n    height: 100vh;\n    padding-top: 55px;\n    /* overflow: auto; */\n  }\n\n  .memory-story--gallery {\n    height: 100%;\n  }\n\n  .memory-story--gallery img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
