import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
// import { useNavigate } from "react-router-dom";
import { websiteIstanceFormData } from "../../../../api/axios";
import { QUERY_TIMELINES } from "../../../../api/endpoints";
import TimeLineForm from "../TimeLineForm/TimeLineForm";
import Enum from "./Enum";
import { useDispatch, useSelector } from 'react-redux';
import { setTimeLine, removeTimeLine } from "redux/slices/myTimeLinesSlice";


const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

async function dataToBase64(audioFile) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onerror = reject;
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(audioFile);
  });
}


const UpdateTimeLine = ({ handleClose, onDelete, item, refetch }) => {
  const { t } = useTranslation(["timeline"]);
  const [loading, setLoading] = useState("");
  const [isFileDeleted, setDeletedFile] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const myTimeLinesInfo = useSelector((state) => state.myTimeLines.data);

  const oldThumbnail = myTimeLinesInfo.timelines.length && myTimeLinesInfo.timelines[0].thumbnail;

  const onDeleteFile = (isDeleted) => {
    setDeletedFile(isDeleted);

  }
  const unRegisteredUpdateTimeline = async (values) => {
    try {

      setLoading(Enum.UPDATE)

      const id = values.id;
      const events = [];
      let imageData = oldThumbnail;
      console.log(imageData)
      if (values.thumbnail) {
        imageData = {
          path: await dataToBase64(values.thumbnail),
          name: values.thumbnail.name,
          size: values.thumbnail.size,
          type: values.thumbnail.type,
        }
      }
      const TimeLine = {
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        creator_id: null,
        events,
        id,
        thumbnail: !isFileDeleted ? imageData : null,
        title: values.title,
        status: values.status

      }
      dispatch(removeTimeLine(TimeLine.id))
      dispatch(setTimeLine(TimeLine))
      enqueueSnackbar(t("Timeline updated successfuly"), { variant: Enum.success })
      handleClose()
      setLoading("")
      refetch?.()
    } catch (err) {
      console.log(err)
    }

  }
  const onSubmit = async (values) => {
    try {
      if (window.isAuth) {

        setLoading(Enum.UPDATE)
        await websiteIstanceFormData.request({
          method: 'put',
          data: values,
          url: QUERY_TIMELINES.replace("?", `/${item.id}`)
        })
        enqueueSnackbar(t("Timeline updated successfuly"), { variant: Enum.success })
        handleClose()
        setLoading("")
        refetch?.()
      } else {
        unRegisteredUpdateTimeline({ ...values, id: item.id })
      }
      // navigate(`/timelines/${item.id}`)
    } catch (error) {
      enqueueSnackbar(t(error.message || "TimelineUpdateError"), { variant: Enum.error })
      setLoading("")
    }
  }
  // console.log("onDeleteFile", onDeleteFile);

  return (
    <TimeLineForm
      {...item} onDelete={onDelete}
      setDeletedFile={setDeletedFile} handleClose={handleClose} isUpdate loading={loading === Enum.UPDATE} onSubmit={onSubmit}
      onDeleteFile={onDeleteFile}
      isFileDeleted={isFileDeleted}
    />
  )
}

export default UpdateTimeLine;