import ControlList from "assets/icons/control-list.svg";
import PlayerPause from "assets/icons/player-pause.svg";
import PlayerPlay from "assets/icons/player-play.svg";

import PlayerControlPlay from "assets/icons/audio-player-play-small.svg";
import PlayerControlPause from "assets/icons/player-control-pause.svg";
import PlayerBack from "assets/icons/player-back.svg";
import PlayerNext from "assets/icons/player-next.svg";
import MoveForword from "assets/icons/move-forword.svg";
import MoveBackword from "assets/icons/move-backword.svg";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import useGetWidth from "hooks/useUpdateWindowDimensions";

const AudioPlayerComponent = ({
  showList,
  setShowList,
  audioList,
  currentTrack,
  handleShowList,
  audioPlayerRef,
  handleClickNext,
  handleClickBack,
  setIsPlay,
  isPlay,
  handlePauseAudio,
  handlePlayAudio,
}) => {

  const width = useGetWidth();
  
  return (
    <div className="player-container">
      <div className="player-show">
        <div className="player-header">
          <h5 className="player-header-title">
            {audioList[currentTrack].name}
          </h5>
          <img
            className="player-header-icon"
            src={ControlList}
            alt="control-list"
            onClick={handleShowList}
          />
        </div>
        <div className="player-control">
          <AudioPlayer
            volume="1"
            progressJumpSteps={{ backward: 15000, forward: 15000 }}
            src={audioList[currentTrack].path}
            ref={audioPlayerRef}
            customProgressBarSection={[
              RHAP_UI.PROGRESS_BAR,
              RHAP_UI.CURRENT_TIME,
              RHAP_UI.DURATION,
            ]}
            preload="auto"
            autoPlay={false}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            customIcons={{
              play: <img src={PlayerControlPlay} alt="PlayerPlay" />,
              pause: <img src={PlayerControlPause} alt="PlayerControlPause" />,
              rewind: <img src={MoveBackword} alt="MoveBackword" />,
              forward: <img src={MoveForword} alt="MoveForword" />,
              previous: <img src={PlayerBack} alt="PlayerBack" />,
              next: <img src={PlayerNext} alt="PlayerNext" />,
            }}
            showSkipControls
            onClickNext={handleClickNext}
            onClickPrevious={handleClickBack}
            onPlay={() => setIsPlay(true)}
            onPause={() => setIsPlay(false)}
            onEnded={handleClickNext}
          />
        </div>
      </div>
      {showList && (
        <div className="player-audio-list">
          {audioList.map((item, index) => {
            return (
              <div className="player-audio-list-item" key={index}>
                {index === currentTrack ? (
                  <>
                    <h5 className="player-header-title-selected">
                      {item.name}
                    </h5>
                    {isPlay ? (
                      <img
                        src={PlayerPause}
                        alt="pause-icon"
                        onClick={handlePauseAudio}
                      />
                    ) : (
                      <img
                        src={PlayerPlay}
                        alt="play-icon"
                        onClick={() => handlePlayAudio(index)}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <h5 className="player-header-title">{item.name}</h5>
                    <img
                      src={PlayerPlay}
                      alt="play-icon"
                      onClick={() => handlePlayAudio(index)}
                    />
                  </>
                )}
              </div>
            );
          })}
        </div>
      )}
      {showList && width <= 1024 && (
        <div
          className="player-backdrop"
          onClick={() => setShowList(false)}
        ></div>
      )}
    </div>
  );
};

export default AudioPlayerComponent;
