import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StyledButton from "components/StyledButton/StyledButton";

import EmptyEventsPlaceholder from "components/Icons/EmptyEvents";
import NoResultsEvents from "components/Icons/NoResultsEvents";
import CustomTypography from "components/CustomTypography/CustomTypography";
import { useDispatch, useSelector } from 'react-redux';
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";

import "./style.css";

const EmptyEvents = ({ timelineId, timelineTitle, showActions, isSharedWithMe, isPublic, onAddNewEvent, hasFilters }) => {
    const { t } = useTranslation(["memories"]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.familyMemories.familyMemories);
    const familyMemoriesList = data[timelineId]?.events ?? [];
    const userId = +localStorage.getItem("userId")

    const onAddNewEvents = () => {
        if (Array.isArray(familyMemoriesList) && familyMemoriesList.length < 2 || window.isAuth || userId) {
            navigate(`/add-event/${timelineId}`, {
                state: {timelineTitle}
            })
        } else {
            dispatch(setLoginRegisterModalOpen(true))
        }
    }

    const onBack = () => navigate("/my-timelines")
    const onBackToHome = () => navigate("/")
    return (
        <Container className="container">
            <div className="empty-events-wrapper">
                {!hasFilters&&<EmptyEventsPlaceholder />}
                {hasFilters && <NoResultsEvents />}
                {!hasFilters ? <CustomTypography className="empty-events-wrapper--text" type="subHeadLine">                    
                    {!isPublic ? t("noEventsTitle") : t("There are no Events Under this Timeline")}
                </CustomTypography> : 
                    <CustomTypography className="empty-events-wrapper--text" type="subHeadLine">
                        {t("We're sorry, but no results found that match your search criteria.")}
                    </CustomTypography>
                }
                {/* <CustomTypography className="empty-events-wrapper--text-description" type="normal">                    
                    {t("NoEventsDescription")}
                </CustomTypography> */}
                {(showActions || isSharedWithMe) && !hasFilters && <StyledButton className={"empty-events-wrapper-action"} onClick={onAddNewEvent} color="primary" fullWidth variant="contained">
                    {t("Add new event")}
                </StyledButton>}
                {hasFilters && <StyledButton className={"empty-events-wrapper-action"} onClick={onBack} color="primary" fullWidth variant="contained">
                    {t("Back To My timelines")}
                </StyledButton>}
                {hasFilters && <StyledButton onClick={onBackToHome} className="empty-events-wrapper---back-button empty-events-wrapper-action" color="primary" fullWidth variant="outlined">
                    <span>
                        {t("Back To Home")}
                    </span>
                </StyledButton>}
                {!hasFilters &&<StyledButton onClick={onBack} className="empty-events-wrapper---back-button empty-events-wrapper-action" color="primary" fullWidth variant="outlined">
                    <span>
                        {t("Back To My timelines")}
                    </span>
                </StyledButton>}
            </div>
        </Container>
    )
}

export default EmptyEvents;
