import { useState } from "react";
import TimeLinesList from "components/TimeLinesList/TimeLinesList";
import { TIMELINES } from "../../api/endpoints";
import { useTranslation } from "react-i18next";
import { websiteIstance } from "../../api/axios";
import TimeLineCard from "components/TimeLineCard/TimeLineCard";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import Filters from "components/Filters/Filters";
import { useParams, useLocation, useNavigate } from "react-router-dom";


export default function MyTimeLine() {
  const userId = +localStorage.getItem("userId")

  const [isGrid, setIsGrid] = useState(true);
  const [isFiltersOpen, setIsFiltersOpenOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timelines, setTimelines] = useState([]);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const location = useLocation();
  const navigate = useNavigate();
  const queryEndpoint = TIMELINES.concat(location.search);

  const { t } = useTranslation(["timeline"]);

  const headline = t("Public Timelines");
  const data = timelines || [1]

  const filtersToggle = () => {
    setIsFiltersOpenOpen(prevValue => !prevValue)
  }
  const Params = Object.fromEntries(new URLSearchParams(location.search));
  const title = Params["search"]
  const onSearch = (value) => {
    const params = Object.fromEntries(new URLSearchParams(location.search));
    if (value) {
      params["search"] = value;
    } else {
      delete params["search"];
    }
    const result = '?' + new URLSearchParams(params).toString();
    navigate(result, {
      state: location.state
    })

  }
  async function fetchData(page = 0, clearState = true) {
    try {
      // if(!window.isAuth) return;
      setIsLoading(true)
      if (clearState) {
        setCurrentPage(0)
      }
      const response = await websiteIstance.request({
        method: "get",
        url: queryEndpoint.includes("?") ? queryEndpoint.concat(`&page=${page}`) : queryEndpoint.concat(`?page=${page}`)
      });
      const data = response?.data?.data;
      const totalPages = data?.totalPages;
      const currentPage = data?.page;
      setCurrentPage(currentPage)
      setTotalPages(totalPages)
      if (clearState) {
        setTimelines(data?.timelines)

      } else {

        setTimelines(prevTimelines => [...prevTimelines, ...data?.timelines])
      }
      setIsLoading(false)

    } catch (error) {
      console.log(error)
      setIsLoading(false)

    }
  }


  const onLayoutChange = (isTypeGrid) => {
    setIsGrid(isTypeGrid)
  }


  const renderItem = (item, idx) => {
    return (
      <TimeLineCard
        thumbnail={item.thumbnail}
        countOfEvents={item.events?.length}
        isGrid={isGrid}
        key={item.id + "_" + idx}
        path={`/timelines/${item.id}`}
        title={item.title}
        createdAt={item.createdAt}
        isPublic={true}
        isOwner={item.creator_id === userId}
      />
    )
  }


  return (
    <>
      <BottomSheet handleClose={filtersToggle} open={isFiltersOpen}>
        <Filters onClose={filtersToggle} url={window.location.href} />
      </BottomSheet>
      <TimeLinesList
        headline={headline}
        isGrid={isGrid}
        SearchValue={title}
        isLoading={isLoading}
        onLayoutChange={onLayoutChange}
        totalPages={totalPages}
        currentPage={currentPage}
        fetch={fetchData}
        data={data}
        renderItem={renderItem}
        onFilter={filtersToggle}
        onSearch={onSearch}
        isPublic={true}
      />
    </>
  )

}