import UseWidth from "hooks/useUpdateWindowDimensions";


const Filter = ({color}) => {
    const width = UseWidth()
    if (width < 700) {
        return (
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 18H15V14H18V18ZM5 14V18H2V14H5ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H5C5.53043 20 6.03914 19.7893 6.41421 19.4142C6.78929 19.0391 7 18.5304 7 18V14C7 13.4696 6.78929 12.9609 6.41421 12.5858C6.03914 12.2107 5.53043 12 5 12H2V10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10V12H15C14.4696 12 13.9609 12.2107 13.5858 12.5858C13.2107 12.9609 13 13.4696 13 14V18C13 18.5304 13.2107 19.0391 13.5858 19.4142C13.9609 19.7893 14.4696 20 15 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19V10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0V0Z" fill={color} />
            </svg>

        )
    }
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.5 31.5H26.25V24.5H31.5V31.5ZM8.75 24.5V31.5H3.5V24.5H8.75ZM17.5 0C15.2019 0 12.9262 0.452651 10.803 1.33211C8.67984 2.21157 6.75066 3.50061 5.12563 5.12563C1.84374 8.40752 0 12.8587 0 17.5V33.25C0 33.7141 0.184374 34.1592 0.512563 34.4874C0.840752 34.8156 1.28587 35 1.75 35H8.75C9.67826 35 10.5685 34.6313 11.2249 33.9749C11.8813 33.3185 12.25 32.4283 12.25 31.5V24.5C12.25 23.5717 11.8813 22.6815 11.2249 22.0251C10.5685 21.3687 9.67826 21 8.75 21H3.5V17.5C3.5 13.787 4.975 10.226 7.60051 7.60051C10.226 4.975 13.787 3.5 17.5 3.5C21.213 3.5 24.774 4.975 27.3995 7.60051C30.025 10.226 31.5 13.787 31.5 17.5V21H26.25C25.3217 21 24.4315 21.3687 23.7751 22.0251C23.1187 22.6815 22.75 23.5717 22.75 24.5V31.5C22.75 32.4283 23.1187 33.3185 23.7751 33.9749C24.4315 34.6313 25.3217 35 26.25 35H33.25C33.7141 35 34.1592 34.8156 34.4874 34.4874C34.8156 34.1592 35 33.7141 35 33.25V17.5C35 15.2019 34.5474 12.9262 33.6679 10.803C32.7884 8.67984 31.4994 6.75066 29.8744 5.12563C28.2493 3.50061 26.3202 2.21157 24.197 1.33211C22.0738 0.452651 19.7981 0 17.5 0V0Z" fill={color} />
        </svg>


    )
}

export default Filter;