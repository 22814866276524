import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from "react-i18next";
import { websiteIstance } from "../../../../api/axios";
import { INVITATION_UPDATE } from "../../../../api/endpoints";

import Popover from "./Popover";

// import "./invitations.css"
const Row = ({ row, onDeleteInvitedUser, timelineId }) => {
    const { t } = useTranslation(["memories"]);
    const { i18n } = useTranslation();
    const [role, setRole] = React.useState(row.role || "")

    const handleInvitationUpdate = async (e) => {
        setRole(e.target.value);
        await websiteIstance.patch(
            INVITATION_UPDATE.replace(":timelineId", timelineId).replace(":id", row.id), {
            role: e.target.value
        }
        );
    }

    return (
        <TableRow
            key={row.recipientEmail}
            className='invitations-table-body-row'
            sx={{
                'td, th': { border: 0, padding: 0, width: 'auto' }, 'tr': {
                    minHeight: 80
                }
            }}
        >
            <TableCell align={i18n.dir() === 'rtl' ? "right" : "left"} style={{ width: 225 }}>
                {row.recipientEmail}
            </TableCell>
{/* {console.log(role)} */}
            <TableCell className='table-cell-invitation' style={{ width: 100, background: '#fff' }} align={i18n.dir() === 'rtl' ? "left" : "right"}>
                <Popover onDeleteInvitedUser={onDeleteInvitedUser} row={row} onChange={handleInvitationUpdate} value={role} label={role === "VIEWER" ? t("Viewer") : t("Editor")} />
                {/* <Select style={{ width: 100, background: '#fff' }} className="contributer-role invitations" value={role} onChange={handleInvitationUpdate}>
                                        <MenuItem className="contributer-role-item" value="VIEWER">
                                            {t("Viewer")}
                                        </MenuItem>
                                        <MenuItem className="contributer-role-item" value="EDITOR">{t("Editor")}</MenuItem>
                                        <hr />
                                        <StyledButton className="invite-friends--invited-users-item--delete-btn" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: "5px 10px", width: 100}} id={row.id} onClick={onDeleteInvitedUser}>
                                            <DeleteIcon />
                                                {t("Remove")}
                                        </StyledButton>
                                </Select> */}
            </TableCell>
        </TableRow>
    )
}

export default function Invitations({ invitations = null, onDeleteInvitedUser, timelineId }) {
    const { t } = useTranslation(["memories"]);
    const { i18n } = useTranslation();


    return (
        <TableContainer >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow
                        className='invitations-table-head'
                        sx={{ 'td, th': { border: 0, padding: 0, paddingBottom: "16px", width: 'auto' } }}
                    >
                        <TableCell align={i18n.dir() === 'rtl' ? "right" : "left"}>{t("Email")}</TableCell>
                        {/* <TableCell align={i18n.dir() === 'rtl' ? "right" : "left"}>{t("Status")}</TableCell> */}
                        <TableCell align={i18n.dir() === 'rtl' ? "right" : "left"}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(invitations) ? invitations.map((row) => (
                        <Row timelineId={timelineId} role={row.role} key={row.recipientEmail} row={row} onDeleteInvitedUser={onDeleteInvitedUser} />
                    )) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

