
const List = ({isActive}) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill={isActive ? "#066F90" : "#F3F3F3"} stroke="#ECECEC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17 25.5H28V23.5H17V25.5ZM13.5 26C13.2033 26 12.9133 25.912 12.6666 25.7472C12.42 25.5824 12.2277 25.3481 12.1142 25.074C12.0006 24.7999 11.9709 24.4983 12.0288 24.2074C12.0867 23.9164 12.2296 23.6491 12.4393 23.4393C12.6491 23.2296 12.9164 23.0867 13.2074 23.0288C13.4983 22.9709 13.7999 23.0007 14.074 23.1142C14.3481 23.2277 14.5824 23.42 14.7472 23.6666C14.912 23.9133 15 24.2033 15 24.5C15 24.8978 14.842 25.2794 14.5607 25.5607C14.2794 25.842 13.8978 26 13.5 26ZM17 20.5H28V18.5H17V20.5ZM13.5 21C13.2033 21 12.9133 20.912 12.6666 20.7472C12.42 20.5824 12.2277 20.3481 12.1142 20.074C12.0006 19.7999 11.9709 19.4983 12.0288 19.2074C12.0867 18.9164 12.2296 18.6491 12.4393 18.4393C12.6491 18.2296 12.9164 18.0867 13.2074 18.0288C13.4983 17.9709 13.7999 18.0006 14.074 18.1142C14.3481 18.2277 14.5824 18.42 14.7472 18.6666C14.912 18.9133 15 19.2033 15 19.5C15 19.8978 14.842 20.2794 14.5607 20.5607C14.2794 20.842 13.8978 21 13.5 21ZM17 15.5H28V13.5H17V15.5ZM13.5 16C13.2033 16 12.9133 15.912 12.6666 15.7472C12.42 15.5824 12.2277 15.3481 12.1142 15.074C12.0006 14.7999 11.9709 14.4983 12.0288 14.2074C12.0867 13.9164 12.2296 13.6491 12.4393 13.4393C12.6491 13.2296 12.9164 13.0867 13.2074 13.0288C13.4983 12.9709 13.7999 13.0006 14.074 13.1142C14.3481 13.2277 14.5824 13.42 14.7472 13.6666C14.912 13.9133 15 14.2033 15 14.5C15 14.8978 14.842 15.2794 14.5607 15.5607C14.2794 15.842 13.8978 16 13.5 16Z" fill={isActive ? "white" : "black"} />
        </svg>
    )
}

export default List;

