
const Plus = ({ color, outlineColor, disabled }) => {
    return(
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="27.5" cy="27.5" r="27.5" fill={disabled ? "#eee" : "#367419"} fillOpacity="0.1" />
            <circle cx="27.4999" cy="27.5001" r="24.0079" fill={disabled ? "#eee" : "#367419"} />
            <path d="M27.9366 37.5398C27.5656 37.5398 27.2544 37.4141 27.0029 37.1626C26.7524 36.9121 26.6271 36.6013 26.6271 36.2303V28.3731H18.77C18.3989 28.3731 18.0881 28.2474 17.8376 27.996C17.5862 27.7454 17.4604 27.4346 17.4604 27.0636C17.4604 26.6926 17.5862 26.3813 17.8376 26.1299C18.0881 25.8793 18.3989 25.7541 18.77 25.7541H26.6271V17.8969C26.6271 17.5259 26.7524 17.2147 27.0029 16.9632C27.2544 16.7127 27.5656 16.5874 27.9366 16.5874C28.3077 16.5874 28.6189 16.7127 28.8703 16.9632C29.1209 17.2147 29.2462 17.5259 29.2462 17.8969V25.7541H37.1033C37.4743 25.7541 37.7856 25.8793 38.037 26.1299C38.2876 26.3813 38.4128 26.6926 38.4128 27.0636C38.4128 27.4346 38.2876 27.7454 38.037 27.996C37.7856 28.2474 37.4743 28.3731 37.1033 28.3731H29.2462V36.2303C29.2462 36.6013 29.1209 36.9121 28.8703 37.1626C28.6189 37.4141 28.3077 37.5398 27.9366 37.5398Z" fill="white" />
        </svg>

    )
}

export default Plus;