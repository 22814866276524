  export const Login = () => {
    var appKey = "9RQW-476Q-7G8C-1JB9-ZV9W-LLNV-V2X3-D1VZ";
    var redirect = "https://testggateway.tech/";
    var authUrl =
      "https://ident.familysearch.org/cis-web/oauth2/v3/authorization?response_type=code&scope=openid%20profile%20email%20qualifies_for_affiliate_account%20country&client_id=" +
      appKey +
      "&redirect_uri=" +
      redirect;
    window.location.href = authUrl;
    // window.location.href = "http://localhost:3000?code=444";
  }

export const Signup = () => {
    var appKey = "9RQW-476Q-7G8C-1JB9-ZV9W-LLNV-V2X3-D1VZ";
    var redirect = process.env.REACT_APP_DOMAIN;
    var authUrl =
      "https://www.familysearch.org/identity/signup/" +
      appKey +
      "&redirect_uri=" +
      redirect;
    window.location.href = authUrl;
  }
