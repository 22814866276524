import { createSlice, current } from '@reduxjs/toolkit'
import mapKeys from "lodash/mapKeys";

const initialState = {
    familyMemories: {
        
    },
    invitedUsersList: null,
    tempEventFormData: null
}

const familyMemoriesSlice = createSlice({
    name: 'familyMemories',
    initialState,
    reducers: {
        setFamilyMemories(state, { payload }) {
            const { data, id, clearState } = payload
            const currentState = current(state);
            const currentEvents =  currentState.familyMemories[id] ? currentState.familyMemories[id].events : []
            const eventsList = clearState ? data.events : [...currentEvents, ...data.events]
            // const events = { ...mapKeys(eventsList, 'id')}
            // const currentEvents = currentState.familyMemories[id] ? currentState.familyMemories[id].events : []
            // const events = mapKeys(data.events, 'id')
            state.familyMemories[id] = {
                ...data,
                events: eventsList
            };
        },
        setOfflineFamilyMemories(state, { payload }) {
            const page = 0;
            const size = 10;
            const totalElements = 10;
            const totalPages = 1;
            const timelineId = payload.timelineId;
            const timelineData = {
                page,
                size,
                totalElements,
                totalPages,
                isOffline: true
            };
            const event = payload.event;
            const currentEvents = state.familyMemories[timelineId]?.events ?? []
            state.familyMemories[timelineId] = {
                ...timelineData,
                events: [...currentEvents, event]
            };
        },
        setTemorarilyEventFormData(state, { payload }) {
      
            const event = payload;
           
            state.tempEventFormData = event ? (state.tempEventFormData ? { ...state.tempEventFormData, ...event} : event) : null;
        },
        clearMemories(state, { payload }) {           
            state.familyMemories = initialState.familyMemories
        },

        setInvitations(state, { payload }) {
            const currentState = current(state);
            if (Array.isArray(payload)){
                state.invitedUsersList = payload;
            } else if (Array.isArray(currentState.invitedUsersList)) {
                state.invitedUsersList.push(payload);
            } else {
                state.invitedUsersList = [payload];
            }            
        },
        removeEvent(state, { payload }) {
            try {
                const currentState = current(state);  
                const events = currentState.familyMemories[payload.timelineId].events.filter(event => event.id != payload.eventId);
                state.familyMemories[payload.timelineId].events = events;

            } catch (error) {
                console.error("error", error)
            }
        }
    },
})

export const { setFamilyMemories, setInvitations, setOfflineFamilyMemories, setTemorarilyEventFormData, clearMemories ,removeEvent } = familyMemoriesSlice.actions
export default familyMemoriesSlice.reducer