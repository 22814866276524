// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submitBtn {
  background-color: #367419 !important;
  border: 1px solid #367419;
  border-radius: 4px;
  width: 100% !important;
  box-shadow: unset !important;
  height: 41px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}

.MuiDialogContent-root, .MuiFormControl-root {
  width: 100% !important;
}

.divider {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.popupIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dialog/style.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,4BAA4B;EAC5B,uBAAuB;EACvB,0BAA0B;EAC1B,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".submitBtn {\r\n  background-color: #367419 !important;\r\n  border: 1px solid #367419;\r\n  border-radius: 4px;\r\n  width: 100% !important;\r\n  box-shadow: unset !important;\r\n  height: 41px !important;\r\n  font-size: 14px !important;\r\n  font-weight: 700 !important;\r\n}\r\n\r\n.MuiDialogContent-root, .MuiFormControl-root {\r\n  width: 100% !important;\r\n}\r\n\r\n.divider {\r\n  margin-top: 16px !important;\r\n  margin-bottom: 16px !important;\r\n}\r\n\r\n.popupIcon {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 30px;\r\n  height: 30px;\r\n  cursor: pointer;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
