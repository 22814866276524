import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { websiteIstance } from "../../../../api/axios";
import { DELETE_EVENT } from "../../../../api/endpoints";
import EventDeleteForm from "../EventDeleteForm/EventDeleteForm";
import Enum from "./Enum";
import { clearMemories } from "redux/slices/familyMemoriesSlice";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { removeEvent } from "redux/slices/familyMemoriesSlice";

const DeleteTimeLine = ({ handleClose, refetch, timelineId, eventId, cb }) => {
    const { t } = useTranslation(["timeline"]);
    const [loading, setLoading] = useState("");
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const unregisterDelete = () => {
        setLoading(Enum.DELETE)

        dispatch(removeEvent({ eventId, timelineId }));
        enqueueSnackbar(t("Event deleted successfuly"), { variant: Enum.success })
        handleClose()
        setLoading("")


        let n = localStorage.getItem('localEventsCounter');
        if (n === null) {
            n = 0;
        } else {
            n--;
        }

        localStorage.setItem("localEventsCounter", n);

        navigate(-1, { replace: true })
        navigate(-1, { replace: true })
        cb()

    }
    const onSubmit = async (values) => {
        try {
            if (!window.isAuth) {
                unregisterDelete()
                return
            }
            setLoading(Enum.DELETE)
            await websiteIstance.request({
                method: 'delete',
                url: DELETE_EVENT.replace(":timelineId", timelineId).replace(":eventId", eventId)
            })
            dispatch(clearMemories())

            enqueueSnackbar(t("Event deleted successfuly"), { variant: Enum.success })
            handleClose()
            setLoading("")
            navigate(-1, { replace: true })
            navigate(-1, { replace: true })
            cb()
        } catch (error) {
            enqueueSnackbar(t(error.response?.data?.message ?? error?.message ?? "EventDeleteError"), { variant: Enum.error })
            setLoading("")
        }
    }

    return (
        <EventDeleteForm deleteConfirm={onSubmit} handleClose={handleClose} loading={loading === Enum.DELETE} />
    )
}

export default DeleteTimeLine;