// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page-wrapper {
    /* background: url('../../assets/images/svg/homebg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    /* min-height: calc(100vh - 50px); */
}
.home-page-wrapper .home-page--container {
    padding-top: 24px;
    padding-bottom: 24px;
}

.home-page-wrapper .home-page--container .home-page--container---cards {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.home-page-wrapper .home-page--container .home-page--container---footer {
  display: flex;
  margin-top: 24px;
  padding-bottom: 8px;
}
.home-page-wrapper .home-page--container .home-page--container---footer div{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.home-page-wrapper .home-page--container .home-page--container---footer img {
  width: 125px;
}
.home-page-wrapper .home-page--container .home-page--container---footer .home-page--container---footer----title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-align: start;
  width: 100%;
}

.home-page--container---footer----description {
  font-size: 14px!important;
  line-height: 17px;
}

.home-page--container---footer----description span{
  color: #859407;
  font-weight: 700;
  /* font-size: 14px; */

}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/style.css"],"names":[],"mappings":"AAAA;IACI;;;6BAGyB;IACzB,oCAAoC;AACxC;AACA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;AACA;EACE,SAAS;EACT,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,qBAAqB;;AAEvB","sourcesContent":[".home-page-wrapper {\n    /* background: url('../../assets/images/svg/homebg.svg');\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover; */\n    /* min-height: calc(100vh - 50px); */\n}\n.home-page-wrapper .home-page--container {\n    padding-top: 24px;\n    padding-bottom: 24px;\n}\n\n.home-page-wrapper .home-page--container .home-page--container---cards {\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 18px;\n}\n\n.home-page-wrapper .home-page--container .home-page--container---footer {\n  display: flex;\n  margin-top: 24px;\n  padding-bottom: 8px;\n}\n.home-page-wrapper .home-page--container .home-page--container---footer div{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n}\n\n.home-page-wrapper .home-page--container .home-page--container---footer img {\n  width: 125px;\n}\n.home-page-wrapper .home-page--container .home-page--container---footer .home-page--container---footer----title {\n  margin: 0;\n  font-size: 16px;\n  font-weight: 700;\n  text-align: start;\n  width: 100%;\n}\n\n.home-page--container---footer----description {\n  font-size: 14px!important;\n  line-height: 17px;\n}\n\n.home-page--container---footer----description span{\n  color: #859407;\n  font-weight: 700;\n  /* font-size: 14px; */\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
