
const NoResultsEvents = () => {
    return (
        <svg width="175" height="154" viewBox="0 0 175 154" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M99.631 130.38H22.9135C17.5137 130.38 13.0938 125.903 13.0938 120.431V9.94618C13.0938 4.47466 17.5137 0 22.9135 0H99.631C105.033 0 109.453 4.47466 109.453 9.94618V120.431C109.453 125.903 105.033 130.38 99.631 130.38Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M93.0958 135.104H16.3783C10.9786 135.104 6.55859 130.627 6.55859 125.156V14.6703C6.55859 9.19878 10.9786 4.72412 16.3783 4.72412H93.0958C98.4978 4.72412 102.918 9.19878 102.918 14.6703V125.156C102.918 130.627 98.4978 135.104 93.0958 135.104Z" fill="#999999" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.7161 138.833H9.84003C4.42912 138.833 0 134.375 0 128.926V18.8982C0 13.4493 4.42912 8.99316 9.84003 8.99316H86.7161C92.1292 8.99316 96.5583 13.4493 96.5583 18.8982V128.926C96.5583 134.375 92.1292 138.833 86.7161 138.833Z" fill="#D8DBEA" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.7161 138.833H9.84003C4.42912 138.833 0 134.375 0 128.926V18.8982C0 13.4493 4.42912 8.99316 9.84003 8.99316H86.7161C92.1292 8.99316 96.5583 13.4493 96.5583 18.8982V128.926C96.5583 134.375 92.1292 138.833 86.7161 138.833Z" fill="#DDDDDD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6297 42.5151H18.8474C17.2258 42.5151 15.8984 41.2398 15.8984 39.6818C15.8984 38.1217 17.2258 36.8464 18.8474 36.8464H75.6297C77.2513 36.8464 78.5787 38.1217 78.5787 39.6818C78.5787 41.2398 77.2513 42.5151 75.6297 42.5151Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6297 59.5215H18.8474C17.2258 59.5215 15.8984 58.2462 15.8984 56.6882C15.8984 55.1281 17.2258 53.8528 18.8474 53.8528H75.6297C77.2513 53.8528 78.5787 55.1281 78.5787 56.6882C78.5787 58.2462 77.2513 59.5215 75.6297 59.5215Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6297 76.5271H18.8474C17.2258 76.5271 15.8984 75.2518 15.8984 73.6938C15.8984 72.1358 17.2258 70.8584 18.8474 70.8584H75.6297C77.2513 70.8584 78.5787 72.1358 78.5787 73.6938C78.5787 75.2518 77.2513 76.5271 75.6297 76.5271Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M75.6297 93.5329H18.8474C17.2258 93.5329 15.8984 92.2576 15.8984 90.6997C15.8984 89.1396 17.2258 87.8643 18.8474 87.8643H75.6297C77.2513 87.8643 78.5787 89.1396 78.5787 90.6997C78.5787 92.2576 77.2513 93.5329 75.6297 93.5329Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.4869 110.539H18.86C17.2315 110.539 15.8984 109.264 15.8984 107.706C15.8984 106.146 17.2315 104.871 18.86 104.871H48.4869C50.1154 104.871 51.4484 106.146 51.4484 107.706C51.4484 109.264 50.1154 110.539 48.4869 110.539Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.293 88.561C96.9387 100.026 79.361 101.319 66.5998 92.4675C64.9689 91.316 63.4166 90.0226 61.969 88.561C61.2238 87.811 60.5215 87.0369 59.8596 86.2219C58.5359 84.5944 57.3764 82.8588 56.4265 81.0654C54.7527 78.0364 53.6147 74.8223 52.9933 71.5C50.9886 61.0811 53.9861 49.8666 61.969 41.8084C69.971 33.7262 81.0776 30.7189 91.3962 32.7238C94.6841 33.3704 97.8696 34.5196 100.869 36.1903C102.648 37.1711 104.343 38.3419 105.957 39.6785C106.762 40.3444 107.529 41.056 108.272 41.8084C109.721 43.27 111.021 44.835 112.14 46.4842C120.909 59.3671 119.626 77.1181 108.293 88.561Z" fill="white" fill-opacity="0.1" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M105.116 86.3027C93.3031 98.2343 74.16 98.2321 62.3453 86.3027C50.546 74.3867 50.546 55.0519 62.3607 43.1225C74.16 31.2065 93.3031 31.2065 105.102 43.1225C116.915 55.0519 116.915 74.3867 105.116 86.3027ZM111.178 36.9936C96.0155 21.6812 71.4454 21.6812 56.283 36.9936C41.1228 52.3038 41.1096 77.1326 56.2698 92.445C70.0694 106.377 91.6985 107.633 106.911 96.1943C108.397 95.0755 109.828 93.8213 111.193 92.445C112.556 91.0665 113.798 89.6214 114.904 88.1208C126.23 72.7551 124.975 50.9275 111.178 36.9936Z" fill="#999999" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M170.842 150.166L170.206 150.808C165.995 155.064 159.098 155.064 154.885 150.808L116 111.539L131.958 95.4233L170.842 134.693C175.056 138.948 175.056 145.91 170.842 150.166Z" fill="#7D7D7D" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M115.745 86.9202L127.236 98.5245L119.078 106.761L107.59 95.1562C109.106 94.0146 110.564 92.7348 111.959 91.3304C113.35 89.926 114.617 88.4514 115.745 86.9202Z" fill="#999999" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M170.265 134.21L154.409 150.221L151.555 147.336L167.409 131.325L170.265 134.21Z" fill="#DDDDDD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M132.839 95.9772L116.549 112.429L114.129 109.985L130.419 93.5332L132.839 95.9772Z" fill="#DDDDDD" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M97.2998 39.0186L57.3496 79.3621C55.8451 76.6389 54.8221 73.7494 54.2656 70.7626L88.7841 35.9021C91.7397 36.4856 94.6032 37.5165 97.2998 39.0186Z" fill="white" fill-opacity="0.5" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M110.388 48.6082L67.197 89.755C65.6503 88.7267 64.1781 87.5693 62.8053 86.2613C62.0985 85.588 61.4301 84.8975 60.8047 84.1682L104.521 42.5159C105.287 43.1139 106.014 43.7507 106.718 44.424C108.091 45.732 109.326 47.1324 110.388 48.6082Z" fill="white" fill-opacity="0.5" />
        </svg>


    )
}

export default NoResultsEvents;