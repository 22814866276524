// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomsheet-root {
    background: rgb(255, 255, 255);
    min-height: 20%;
    top: auto;
    bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-top: 10px;
    padding-bottom: 20px;
    left: 0;
    right: 0;
    position: absolute;
}

.bottomsheet-root--notch {
    display: flex;
    justify-content: center;
}
.bottomsheet-root--notch div {
    width: 65px;
    height: 4px;
    background: #DFDFDF;
    border-radius: 100px;
}

@media (min-width: 700px) {
    .bottomsheet-root {           
        width: 500px;
        top: 50%;
        transform: translate(0, -50%);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: auto;
        margin: auto;
        min-height: 10%;
    }
    .bottomsheet-root--notch {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/BottomSheet/style.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,eAAe;IACf,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,4BAA4B;IAC5B,iBAAiB;IACjB,oBAAoB;IACpB,OAAO;IACP,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,WAAW;IACX,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI;QACI,YAAY;QACZ,QAAQ;QACR,6BAA6B;QAC7B,8BAA8B;QAC9B,+BAA+B;QAC/B,YAAY;QACZ,YAAY;QACZ,eAAe;IACnB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".bottomsheet-root {\n    background: rgb(255, 255, 255);\n    min-height: 20%;\n    top: auto;\n    bottom: 0;\n    border-top-left-radius: 4px;\n    border-top-right-radius: 4px;\n    padding-top: 10px;\n    padding-bottom: 20px;\n    left: 0;\n    right: 0;\n    position: absolute;\n}\n\n.bottomsheet-root--notch {\n    display: flex;\n    justify-content: center;\n}\n.bottomsheet-root--notch div {\n    width: 65px;\n    height: 4px;\n    background: #DFDFDF;\n    border-radius: 100px;\n}\n\n@media (min-width: 700px) {\n    .bottomsheet-root {           \n        width: 500px;\n        top: 50%;\n        transform: translate(0, -50%);\n        border-bottom-left-radius: 4px;\n        border-bottom-right-radius: 4px;\n        bottom: auto;\n        margin: auto;\n        min-height: 10%;\n    }\n    .bottomsheet-root--notch {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
