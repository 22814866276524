// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-page {
    padding-bottom: var(--sticky-bottom-actions-height);
    height: 100%!important;
}
.timeline-page .timeline-wrapper {
    margin-top: 16px;
    margin-bottom: 20px;
}


.timeline-empty-state {
    margin-top: 180px;
}


.timeline-list-template {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}
.timeline-grid-template {
    opacity: 1;
    animation-name: fadeInOpacity2;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInOpacity2 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (min-width: 700px) {
    .timeline-wrapper .timeline-wrapper--date {
        font-size: 14px;
    }

    .timeline-wrapper .timeline-wrapper--count {
        font-size: 12px;
    }
    .timeline-page .timeline-wrapper {
        margin-top: 21px;
    }
    .timeline-empty-state {
        margin-top: calc((100vh/2) - 225px);
    }
}

@media (min-width: 700px) and (max-width: 900px) {
    .timeline-page {
        padding-top: 30px;
    }
} `, "",{"version":3,"sources":["webpack://./src/components/TimeLinesList/style.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;IACnD,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;;AAGA;IACI,iBAAiB;AACrB;;;AAGA;IACI,UAAU;IACV,6BAA6B;IAC7B,4BAA4B;IAC5B,kCAAkC;IAClC,sBAAsB;AAC1B;AACA;IACI,UAAU;IACV,8BAA8B;IAC9B,4BAA4B;IAC5B,kCAAkC;IAClC,sBAAsB;AAC1B;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;IACA;QACI,gBAAgB;IACpB;IACA;QACI,mCAAmC;IACvC;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".timeline-page {\n    padding-bottom: var(--sticky-bottom-actions-height);\n    height: 100%!important;\n}\n.timeline-page .timeline-wrapper {\n    margin-top: 16px;\n    margin-bottom: 20px;\n}\n\n\n.timeline-empty-state {\n    margin-top: 180px;\n}\n\n\n.timeline-list-template {\n    opacity: 1;\n    animation-name: fadeInOpacity;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-in;\n    animation-duration: 1s;\n}\n.timeline-grid-template {\n    opacity: 1;\n    animation-name: fadeInOpacity2;\n    animation-iteration-count: 1;\n    animation-timing-function: ease-in;\n    animation-duration: 1s;\n}\n\n@keyframes fadeInOpacity {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n@keyframes fadeInOpacity2 {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}\n\n@media (min-width: 700px) {\n    .timeline-wrapper .timeline-wrapper--date {\n        font-size: 14px;\n    }\n\n    .timeline-wrapper .timeline-wrapper--count {\n        font-size: 12px;\n    }\n    .timeline-page .timeline-wrapper {\n        margin-top: 21px;\n    }\n    .timeline-empty-state {\n        margin-top: calc((100vh/2) - 225px);\n    }\n}\n\n@media (min-width: 700px) and (max-width: 900px) {\n    .timeline-page {\n        padding-top: 30px;\n    }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
