import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
// import storage from 'redux-persist-indexeddb-storage';
import thunk from 'redux-thunk';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'

import familyMemoriesReducer from "./slices/familyMemoriesSlice";
import myTimeLinesReducer from "./slices/myTimeLinesSlice";
import appReducer from "./slices/appSlice";
import { insertOrUpdate, GetData } from "./IndexDb";
import mapKeys from "lodash/mapKeys";


const initRecord = {
  myTimeLines: {
    data: {
      timelines: []
    }
  },
  familyMemories: {
    familyMemories: {

    }
  }

}
const rootPersistConfig = {
key: 'root',
  storage: createIdbStorage({name: 'FamilySearch', storeName: 'keyval'}),
  serialize: false, // Data serialization is not required and disabling it allows you to inspect storage value in DevTools; Available since redux-persist@5.4.0
  deserialize: false, // Required to bear same value as `serialize` since redux-persist@6.0
  writeFailHandler: (err) => console.log("error of persist ============", err),
  // blacklist: ['myTimeLines', 'familyMemories']
};

const rootReducer = combineReducers({
    familyMemories: familyMemoriesReducer,
    myTimeLines: myTimeLinesReducer,
    app: appReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const customMiddleware = store => next => action => {

  GetData('keyval', (records) => {
    if(!records.length) {
      records = [initRecord]
    }
    if(!records[0].myTimeLines) {
      records[0].myTimeLines = initRecord.myTimeLines;
    }
    if(!records[0].familyMemories) {
      records[0].familyMemories = initRecord.familyMemories;
    }
    const timeLines = records[0]?.myTimeLines?.data?.timelines ?? [];
    if(action.type === 'familyMemories/setOfflineFamilyMemories'){
      // debugger
      const timelineId = +action.payload.timelineId;
      const event = {...action.payload.event};
      const events = records?.[0]?.familyMemories?.familyMemories?.[timelineId]?.events ?? [];
      const ObjectOfEvents = mapKeys(events, 'id')
      ObjectOfEvents[event.id] = event;
      const payload = Object.values(ObjectOfEvents)
      records[0].familyMemories.familyMemories[timelineId] = {
        isOffline: !localStorage.getItem("userId")
      }
      records[0].familyMemories.familyMemories[timelineId].events = payload;
      insertOrUpdate({
        tableName: 'keyval',
        payload: records[0]
      })
    }
    if(action.type === 'familyMemories/removeEvent') {
      const timelineId = +action.payload.timelineId;
      const eventID = +action.payload.eventId;

      const events = records[0]?.familyMemories?.familyMemories?.[timelineId]?.events ?? [];

      const payload = events.filter((event) => event.id !== eventID);
      records[0].familyMemories.familyMemories[timelineId] = {
        isOffline: !localStorage.getItem("userId")
      }
      records[0].familyMemories.familyMemories[timelineId].events = payload;
  
      insertOrUpdate({
        tableName: 'keyval',
        payload: records[0]
      })
    }

    if(action.type === 'myTimelines/setTimeLine'){
      const ObjectOfTimelines = mapKeys(timeLines, 'id')
      ObjectOfTimelines[action.payload.id] = action.payload;
      const payload = Object.values(ObjectOfTimelines)
      records[0].myTimeLines.data.timelines = payload;
      insertOrUpdate({
        tableName: 'keyval',
        payload: records[0]
      })
    }

    if(action.type === 'myTimelines/removeTimeLine') {
      const payload = timeLines.filter((timeline) => timeline.id !== action.payload);
      records[0].myTimeLines.data.timelines = payload;

      insertOrUpdate({
        tableName: 'keyval',
        payload: records[0]
      })
  }

  })

  return next(action);
};


export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [customMiddleware, thunk],
});


export const persistor = persistStore(store);

