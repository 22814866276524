
const Facebook = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.1235 34.0002C33.1597 34.0002 34 33.16 34 32.1236V1.87651C34 0.84004 33.1597 0 32.1235 0H1.87651C0.839907 0 0 0.84004 0 1.87651V32.1236C0 33.16 0.839907 34.0002 1.87651 34.0002H32.1235" fill="#395185" />
            <path d="M23.4592 34.0001V20.8334H27.8788L28.5405 15.7022H23.4592V12.426C23.4592 10.9404 23.8719 9.92794 26.0023 9.92794L28.7195 9.92674V5.33741C28.2494 5.27485 26.6365 5.13513 24.7601 5.13513C20.8424 5.13513 18.1604 7.52642 18.1604 11.918V15.7022H13.7295V20.8334H18.1604V34.0001H23.4592" fill="white" />
        </svg>
    )
}

export default Facebook;