import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";

export default function TimelinesMenuItems({ title, childrens=[], key }) {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link) => {
        try {
            setAnchorEl(null);
            if (link) {
                navigate(link)
            }
        } catch(err) {
            // console.log(err)
        }
    };

    return (
        <div key={key}>
            <div
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="navbar-toolbar-start-side--items-list---item"
            >
                {title}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {childrens.map(item => <MenuItem onClick={()=>handleClose(item.link)}>{item.text}</MenuItem>)}              
            </Menu>
        </div>
    );
}