import axios from "axios";
import Storage from "../helpers/Storage";

const websiteIstance = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});
if (Storage.getAccessToken())
websiteIstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${Storage.getAccessToken()}`;

const websiteIstanceFormData = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  withCredentials: false,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

if (Storage.getAccessToken())
websiteIstanceFormData.defaults.headers.common[
  "Authorization"
] = `Bearer ${Storage.getAccessToken()}`;

export { websiteIstance, websiteIstanceFormData };
