/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { websiteIstance } from "api/axios";
import { ACCEPT_INVITE } from "api/endpoints";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import { Login } from "../../helpers/auth";

import "./style.css"
const AcceptInvite = () => {
    // console.log("444444444444");
    const { t } = useTranslation(["common"]);
    const navigate = useNavigate();
    const url = new URL(window.location.href);


    useEffect(() => {
        // Redirect to login if not authenticated
        if (!localStorage.getItem("userId")) {
            Login()
        }
    }, [localStorage.getItem("userId")]);


    const invitationToken = url
        .searchParams.get("invitationToken");
    const accept = async () => {
        try {
            const res = await websiteIstance.put(
                ACCEPT_INVITE, {
                invitationToken
            }
            );
            const data = res.data?.data ?? {};
            const timelineId = data.timelineId;
            navigate(`/timelines/${timelineId}`)
        } catch (e) {
            console.log(e);
            navigate('/shared-timelines')
        }
    }
    useEffect(() => {
        accept()
    }, [invitationToken])
    return (
        <div className="accept-invitation">
            <div className="spinner3">
                <Loader style={{
                    width: 87,
                    height: 87
                }} />
            </div>
            {t("accept-invitation")}
        </div>
    )
}

export default AcceptInvite;