// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[lang='en'] .update-event-desktop-modal,
[lang='fr'] .update-event-desktop-modal {
    top: 0;
    bottom: 0;
    transform: none;
    right: 0;
    left: unset;
    inset-inline-end: 0;
    width: 567px;
}

[lang='ar'] .update-event-desktop-modal {
    top: 0;
    bottom: 0;
    transform: none;
    right: unset;
    left: 0;
    inset-inline-end: 0;
    width: 567px;
}`, "",{"version":3,"sources":["webpack://./src/pages/EventDetails/components/UpdateEventModal/style.css"],"names":[],"mappings":"AAAA;;IAEI,MAAM;IACN,SAAS;IACT,eAAe;IACf,QAAQ;IACR,WAAW;IACX,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,MAAM;IACN,SAAS;IACT,eAAe;IACf,YAAY;IACZ,OAAO;IACP,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["[lang='en'] .update-event-desktop-modal,\n[lang='fr'] .update-event-desktop-modal {\n    top: 0;\n    bottom: 0;\n    transform: none;\n    right: 0;\n    left: unset;\n    inset-inline-end: 0;\n    width: 567px;\n}\n\n[lang='ar'] .update-event-desktop-modal {\n    top: 0;\n    bottom: 0;\n    transform: none;\n    right: unset;\n    left: 0;\n    inset-inline-end: 0;\n    width: 567px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
