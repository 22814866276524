import { useState } from "react";

const useDialog = () => {
    const [open, setOpen] = useState(false);
    const openDialog = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const props = {
        open,
        handleClose
    };
    return [openDialog, props];
};

export default useDialog;