import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import Cross from "assets/icons/cross.svg";
import { generateDownload, calculateZoom } from "../utils";

export default function AddEvent({
  currentCropImage,
  setCurrentCover,
  open,
  setOpen,
  setCropImage,
}) {

  console.log(currentCropImage)
  const { t } = useTranslation(["add-event"]);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = async (croppedArea, { x, y, width, height }) => {
    if (
      croppedAreaPixels?.x !== x ||
      croppedAreaPixels?.y !== y ||
      croppedAreaPixels?.width !== width ||
      croppedAreaPixels?.height !== height
    ) {
      setCroppedAreaPixels({ x, y, width, height });
    }
    if (!croppedAreaPixels) {
      setCroppedAreaPixels({ x, y, width, height });
    }
  };
  const onCropChange = async (croppedArea) => {
    if (croppedArea?.x !== crop?.x || croppedArea?.y !== crop?.y) {
      setCrop(croppedArea);
    }
    if (!crop) {
      setCrop(croppedArea);
    }
  };
  const handleSaveCropImage = async () => {
    setCurrentCover(currentCropImage);

    setOpen(false);
    const cropImageUrl = await generateDownload(
      currentCropImage.path
        ? currentCropImage.path
        : URL.createObjectURL(currentCropImage),
      croppedAreaPixels
    );
    setCropImage(cropImageUrl);
  };

  return (
    <Modal
      slotProps={{
        backdrop: {
          style: { backgroundColor: "unset" },
          className: "backDropCropperModal",
        },
      }}
      open={open}
    >
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "37px 25px 0",
            zIndex: "1",
            position: "fixed",
            width: "calc(100% - 50px)",
          }}
          className="cropperModalHeader"
        >
          <div
          className="cropTitle"
            style={{
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            {t("Adjust Cover Image")}
          </div>
          <div>
            <Box onClick={() => setOpen(false)} className="navbarIcon">
              <img src={Cross} alt={t("Cross icon")} />
            </Box>
          </div>
        </div>
        <Cropper
          image={
            currentCropImage && currentCropImage.path
              ? currentCropImage.path
              : URL.createObjectURL(currentCropImage)
          }
          crop={crop}
          zoom={zoom}
          minZoom={minZoom}
          onCropChange={onCropChange}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          cropShape="round"
          showGrid={false}
          cropSize={{ width: 337, height: 337 }}
          onMediaLoaded={(mediaSize) => {
            const initalZoom = calculateZoom(
              337,
              337,
              mediaSize.width,
              mediaSize.height
            );
            if (minZoom !== initalZoom) {
              setZoom(initalZoom);
              setMinZoom(initalZoom);
            }
          }}
        />
        <AppBar className="navbar-cropper-modal" component="nav">
          {/* <Button
            onClick={handleSaveCropImage}
            className="saveCropperBtn"
            variant="contained"
          >
            {t("Save changes")}
          </Button> */}

          <button onClick={handleSaveCropImage} className="saveCropperBtn">
            {t("Save changes")}
          </button>
        </AppBar>
      </div>
    </Modal>
  );
}
