import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from "react-router-dom";
import PLACEHOLDER from '../../assets/images/svg/placeholder.svg'
import CustomTypography from 'components/CustomTypography/CustomTypography';
import moment from "moment";
import Avatar from '@mui/material/Avatar';
import SquareEditIcon from "components/Icons/SquareEdit";
import StyledButton from "components/StyledButton/StyledButton";
import { useTranslation } from "react-i18next";
import useGetWidth from "hooks/useUpdateWindowDimensions";

import "./style.css";



const Image = styled("div")(({ theme, image, isGrid }) => {
    const width = useGetWidth();

    const Style = {
        backgroundColor: theme.palettemode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: 220,
        borderRadius: 4,
    }
    if (isGrid) {
        return {
            ...Style,
            [theme.breakpoints.up('xs')]: width < 390 ? {
                height: 210,
            } : {height: 115,},
            [theme.breakpoints.up('sm')]: {
                height: 236,
            },
            [theme.breakpoints.up('md')]: {
                height: 236,
            },
            [theme.breakpoints.up('xl')]: {
                height: 236,
            }
        }


    }
    return ({
        [theme.breakpoints.up('sm')]: Style,
        [theme.breakpoints.down('sm')]: {
            ...Style,
            width: 95,
            height: 84,
            marginBottom: 0,
        },

    })
});
const Item = styled(Paper)(({ theme, isGrid, isMyTimeLines }) => {
    const Style = {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: "20px",
        background: "#FFFFFF",
        borderBottom: '1px solid rgba(156, 149, 122, 0.3)',
        borderRadius: 4,
        boxShadow: 'none',
        color: '#00000',

    };
    if (isGrid) {
        return {
            ...Style,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 10,
            "& .timeline-wrapper--content": {
                flex: 1,
                "& .timeline-wrapper--headline": {
                    marginBottom: isMyTimeLines ? 27 : 41
                }
            },
            [theme.breakpoints.down('sm')]: {
                padding: "8px",
            },
            [theme.breakpoints.up('sm')]: {
                padding: "20px",
            },
        }
    }

    return (
        {
            [theme.breakpoints.up('sm')]: Style,
            [theme.breakpoints.down('sm')]: {
                ...Style,
                display: 'flex',
                padding: "8px",
                gap: 10,
            }
        }
    )
})


const AuthorItem = styled("div")(({ theme, isGrid }) => {
    const Style = {
        display: 'flex',
        gap: 6
    };

    if (isGrid) {
        return {
            ...Style,

            '& .timeline-wrapper--content---date': {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12,
                lineHeight: "19px",
                [theme.breakpoints.up('sm')]: {
                    fontSize: 14
                },
            },
            '& .timeline-wrapper--content---count': {
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 10,
                lineHeight: "19px",
                [theme.breakpoints.up('sm')]: {
                    fontSize: 14
                }
            }
        }
    }
    return Style

})




const TimeLineCard = ({ thumbnail, countOfEvents, isGrid, key, path, title, createdAt, authorImg, isMyTimeLines, isUpdateble, onUpdate, isPublic, isOwner, isShared }) => {
    const { t } = useTranslation(["timeline"]);
    const navigate = useNavigate()
    const location = useLocation()
    const width = useGetWidth();
    let Grids = {};
    if(width < 390) {
        Grids = isGrid ? {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 3
    
        } : {
            xs: 12,
            sm: 4,
            md: 4,
            lg: 3
        }
    } else {
         Grids = isGrid ? {
            xs: 6,
            sm: 6,
            md: 4,
            lg: 3
    
        } : {
            xs: 12,
            sm: 6,
            md: 4,
            lg: 3
        }
    }

    const onNavigate = () => {
        navigate(path, {
            state: {
                isPublic,
                isOwner,
                from: "/timelines",
                title: title,
                isShared
            }
        })
    }

    // console.log(title)

    return (
        <Grid className={isGrid ? "timeline-grid-template" : 'timeline-list-template'} {...Grids} key={key}>
            <a className="reset-a-tag" onClick={onNavigate}>
                <Item isGrid={isGrid} isMyTimeLines={isMyTimeLines}>
                    <Image isGrid={isGrid} image={  thumbnail?.path ? thumbnail.path : PLACEHOLDER  }>
                    </Image>
                    <div className="timeline-wrapper--content">
                        <CustomTypography className="timeline-wrapper--headline" type="subHeadLine">
                            {title}
                        </CustomTypography>
                        {isMyTimeLines && <div className='timeline-wrapper--footer'>
                            <div>
                                <CustomTypography className="timeline-wrapper--date" type="normal">
                                    {moment(createdAt).format('DD MMM YYYY')}
                                </CustomTypography>
                                <CustomTypography className="timeline-wrapper--count" type="normal">
                                    {countOfEvents} {countOfEvents > 1 ? t("events") : t("event")}
                                </CustomTypography>
                            </div>
                        </div>}
                        {!isMyTimeLines && <div>
                            <AuthorItem isGrid>
                                <div>
                                    <Avatar sx={{ width: 38, height: 38 }} alt="Remy Sharp" src={authorImg} />
                                </div>
                                <div>
                                    <CustomTypography className="timeline-wrapper--content---date" type="normal">
                                        {moment(createdAt).format('DD MMM YYYY')}
                                    </CustomTypography>
                                    <CustomTypography className="timeline-wrapper--count" type="normal">
                                        {countOfEvents} {countOfEvents > 1 ? t("events") : t("event")}
                                    </CustomTypography>
                                </div>
                            </AuthorItem>
                        </div>}
                    </div>
                </Item>
            </a>
            {isUpdateble && <div className='edit-button'>
                <StyledButton onClick={onUpdate} className="text-button">
                    <SquareEditIcon />
                </StyledButton>
            </div>}
        </Grid>
    )
}

export default TimeLineCard;