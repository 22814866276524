import { useForm, FormProvider } from "react-hook-form";
import { literal, object, string, number } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useContext } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormInput from "components/FormInput/FormInputComponent";
import Facebook from "../../assets/icons/facebook.svg";
import { websiteIstance, websiteIstanceFormData } from "../../api/axios";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "context/UserContext";
import countryList from "data/countries.json"
import months from "data/months.json"
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import WhiteCross from "assets/icons/white-cross.svg";
import StyledButton from "components/StyledButton/StyledButton";
import I18nContext from "context/I18nContext";
import "./style.css";


const days = Array.from(Array(31).keys()).map((day) => day + 1);
const years = Array.from(Array(142).keys())
  .map((year) => year + 1873)
  .reverse();


const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { language } = useContext(I18nContext);
  const location = useLocation();

  const INITIAL_VALUES = {
    month: "none",
    day: 0,
    year: 0,
  };

  const { t } = useTranslation(["signup"]);

  const registerSchema = object({
    firstName: string().optional(),
    lastName: string().optional(),
    username: string({
      required_error: t("Username is required"),
    }).min(4, t("username should be at least 4 char.")).refine(
      (val) => !val.includes(" "),
        () => ({ message: t("username without spaces.") })
    ),
    month: string().optional(),
    day: number().optional(),
    year: number().optional(),
    country: string()
      .optional()
      .refine(
        (val) => !countryList.includes(val),
        () => ({ message: t("Country not valid") })
      ),
    mobile: string().optional(),
    email: string({
      required_error: t("Email is required"),
    }).email(t("Email is invalid")),
    password: string({
      required_error: t("Password is required"),
    })
      .min(8, t("Password must be more than 8 characters"))
      .max(32, t("Password must be less than 32 characters")),
    terms: literal(true, {
      invalid_type_error: t("Accept Terms is required"),
    }),
  });

  const methods = useForm({
    resolver: zodResolver(registerSchema),
    defaultValues: INITIAL_VALUES,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const onSubmitHandler = async (values) => {
    try {
      setLoading(true);
      if (values.month === "none" || values.year === 0 || values.day === 0) {
        delete values.birthDate;
      } else {
        values.birthDate = `${values.year}-${moment()
          .month(values.month)
          .format("MM")}-${moment(values.day).format("DD")}`;
      }

      delete values.month;
      delete values.day;
      delete values.year;
      delete values.terms;

      const {
        data: { data: userData },
      } = await websiteIstance.post("/api/v1/auth/signup", values);
      setUser(userData);
      localStorage.setItem("accessToken", userData.accessToken);
      localStorage.setItem("userId", userData.id);
      window.isAuth = true;
      if (userData.accessToken){
        websiteIstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.accessToken}`;
        websiteIstanceFormData.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.accessToken}`;
      }
      if (
        location?.state?.from &&
        location?.state?.from.includes("event-added-successfully")
      ) {
        setTimeout(() => {
          navigate("/my-timelines", {
            state: {
              from: null,
            },
            replace: true
          });
        }, 3000);
      } else if (location?.state?.from &&
        location?.state?.action === "create_new_modal") {
        navigate("/my-timelines", {
          state: {
            from: null,
            action: location?.state?.action
          },
          replace: true
        });
      } else {
        setTimeout(() => {
          navigate(location?.state?.from ?? "/", {
            state: {
              ...location?.state,
              from: null,
            },
            replace: true
          });
        }, 3000);

      }
    } catch (error) {
      if(language.lang === "ar" && error?.response?.data?.message.includes("Username")) {
        setServerMessage("اسم المستخدم غير صالح!")
      } else {
        setServerMessage(error?.response?.data?.message);
      }
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="signupContainer">
      <Typography className="signupWith" component="div">
        {t("Sign up with")}
      </Typography>
      <img src={Facebook} alt={t("facebook icon")} />

      <Divider className="signupDivider">{t("Or")}</Divider>

      <Typography className="signInWith" component="div">
        {t("Create an Account")}
      </Typography>

      <Typography className="helpText-Instructions-title" component="h5">
        {t("Instructions")}:
      </Typography>
      <Typography className="helpText-Instructions" component="div">
        <span style={{fontWeight: "bold"}}>- {t("Username")}</span> {t("should be one word with no spaces and at least 4 characters.")} 
      </Typography>
      <Typography className="helpText-Instructions" component="div">
      <span style={{fontWeight: "bold"}}>- {t("Password")}</span> {t("should be at least 8 characters")}
      </Typography>
      <Typography className="helpText-Instructions" component="div">
      <span style={{fontWeight: "bold"}}>- {t("Email")}</span> {t("should be valid (example: john.marvel@company.com)")}
      </Typography>

      <Divider className="divider" />

      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FormInput
            name="firstName"
            fullWidth
            inputClassName="signupInput"
            labelClassName="label"
            placeholder={t("Enter first name")}
            inputLabel={t("First Name")}
          />
          <FormInput
            name="lastName"
            fullWidth
            inputClassName="signupInput"
            labelClassName="label"
            placeholder={t("Enter last name")}
            inputLabel={t("Last Name")}
          />

          <InputLabel className="label">{t("Birthdate")}</InputLabel>
          <div className="birthdate">
            <FormInput
              name="month"
              inputClassName="signupInput inputMargin"
              inputType="select"
            >
              {[
                <MenuItem disabled key={"asd"} value={"none"}>
                  {t("Month")}
                </MenuItem>,
                ...months.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )),
              ]}
            </FormInput>

            <FormInput
              name="day"
              inputClassName="signupInput inputMargin"
              inputType="select"
            >
              {[
                <MenuItem disabled key={"asd"} value={0}>
                  {t("Day")}
                </MenuItem>,
                ...days.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )),
              ]}
            </FormInput>
            <FormInput
              name="year"
              inputClassName="signupInput"
              inputType="select"
            >
              {[
                <MenuItem disabled key={"asd"} value={0}>
                  {t("Year")}
                </MenuItem>,
                ...years.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                )),
              ]}
            </FormInput>
          </div>

          <FormInput
            name="country"
            inputClassName="signupInput countryInput"
            fullWidth
            labelClassName="label"
            inputLabel={t("Country/Location")}
            placeholder={t("Enter country")}
            list={countryList}
            inputType="autocomplete"
          />

          <FormInput
            name="username"
            fullWidth
            inputClassName="signupInput"
            labelClassName="label"
            placeholder={t("Enter username")}
            inputLabel={t("Username")}
          />

          <FormInput
            name="password"
            fullWidth
            inputClassName="signupInput"
            labelClassName="label"
            inputLabel={t("Password")}
            placeholder={t("Enter password")}
            type="password"
          />

          <FormInput
            name="email"
            fullWidth
            inputClassName="signupInput"
            labelClassName="label"
            inputLabel={t("Email")}
            placeholder={t("Enter email")}
            type="email"
          />

          <FormInput
            name="mobile"
            inputClassName="mobileInput"
            labelClassName="label"
            inputLabel={t("Mobile number")}
            placeholder={t("Enter Mobile")}
            inputType="mobile"
          />
          <Typography className="helpText" component="div">
            {t("Message and data rates may apply.")}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={<Checkbox required />}
              {...register("terms")}
              label={
                <Typography color={errors["terms"] ? "error" : "inherit"}>
                  {t("I agree to the Terms of Use and Privacy Notice.")}
                </Typography>
              }
              className="agreeBtn"
            />
            <FormHelperText error={!!errors["terms"]}>
              {errors["terms"] ? errors["terms"].message : ""}
            </FormHelperText>
          </FormGroup>

            <StyledButton
              color="primary"
              loading={loading}
              variant="contained"
              fullWidth
              type="submit"
              className="doneBtn"
            >{t("Done")}</StyledButton>
         
        </Box>
      </FormProvider>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbarOpen(false);
          setServerMessage("")
        }}
        className="login-alert-message-container"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          icon={false}
          style={{
            background: "#C32B26",
            minHeight: "66px",
            padding: "8px 15px",
            color: "#FBFBFB",
            fontSize: "14px",
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="login-alert-message"
        >
          <img className="login-alert-message-icon" src={WhiteCross} />
          <div>
            {serverMessage}
          </div>
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SignUp;
