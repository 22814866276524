import React from "react";
import ReactDOM from "react-dom/client";
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./theme/variables.css"
import "./index.css";
import './assets/fonts/verdana.ttf';
import './assets/fonts/museo.otf';
import './assets/fonts/verdanab.ttf';
import './assets/fonts/zarid.ttf';
import './assets/fonts/zaridb.ttf';


import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

console.error = () => ""
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
         </PersistGate>
       </Provider>
   </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
