import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Home from "assets/icons/home.svg";
import FamilySearch from "assets/icons/familySearch.svg";
import AboutUs from "assets/icons/aboutUs.svg";
import StartOver from "assets/icons/startOver.svg";
import PrivacyPolicy from "assets/icons/privacyPolicy.svg";
import TermsofService from "assets/icons/termsofService.svg";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import LogoRed from "../../assets/icons/logo-red.svg";
import CrossMenu from "../../assets/icons/cross-menu.svg";
import MyTimelines from "../../assets/icons/my-timelines.svg";
import Share from "../../assets/icons/share.svg";
import LogoutIcon from "../../assets/icons/logout-icon.svg";
import DownArrow from "../../assets/icons/down-arrow.svg";
import Dot from "../../assets/icons/dot.svg";
import ShareMemories from "../../pages/FamilyMemories/components/ShareMemories/ShareMemories";
import "./style.css";
import { useState } from "react";
import { persistor } from 'redux/store';
import { useDispatch } from 'react-redux';
import { clearMyTimelines } from "redux/slices/myTimeLinesSlice";
import { clearMemories } from "redux/slices/familyMemoriesSlice";
import { Signup, Login } from "../../helpers/auth";


export default function TemporaryDrawer({ drawerOpen, setDrawerOpen }) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const [isShareMemoriesModalOpen, setisShareMemoriesModalOpen] =
    useState(false);
  const [showTimelines, setShowTimelines] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const list = [
    {
      text: t("FamilySearch"),
      icon: FamilySearch,
      link: "https://www.familysearch.org/",
    },
    {
      text: t("About"),
      icon: AboutUs,
      link: "https://www.familysearch.org/en/about/",
    },
    {
      text: t("Share the App"),
      icon: Share,
      isButton: true,
      link: '#'
    },
  ];



const removePersist = () => {
const dbName = "mydb";
const objectStoreName = "myObjectStore";
const keyToDelete = "persist:root";

const request = indexedDB.open(dbName, 1);

request.onupgradeneeded = function(event) {
  const db = event.target.result;
  
  // Create an object store if it doesn't exist
  if (!db.objectStoreNames.contains(objectStoreName)) {
    const objectStore = db.createObjectStore(objectStoreName, { keyPath: "key" });
  }
};

request.onsuccess = function(event) {
  const db = event.target.result;

  // Start a transaction with readwrite access
  const transaction = db.transaction([objectStoreName], "readwrite");

  // Get the object store
  const objectStore = transaction.objectStore(objectStoreName);

  // Delete the key from the object store
  const deleteRequest = objectStore.delete(keyToDelete);

  deleteRequest.onsuccess = function(event) {
    console.log(`Key '${keyToDelete}' deleted successfully.`);
  };

  deleteRequest.onerror = function(event) {
    console.error(`Error deleting key '${keyToDelete}':`, event.target.error);
  };
};

request.onerror = function(event) {
  console.error("Error opening database:", event.target.error);
};

  
  
};


  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userId");
    localStorage.removeItem('persist:root')
removePersist()
    localStorage.removeItem("isRedirected")
    localStorage.removeItem("timelineId")
    localStorage.removeItem('localEventsCounter')

    dispatch(clearMyTimelines())
    dispatch(clearMemories())
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    setDrawerOpen(false);
    window.isAuth = false;
    navigate("/");
  };

  const timelinesList = [
    {
      text: t("My Timelines"),
      link: "/my-timelines",
    },
    {
      text: t("Public Timelines"),
      link: "/timelines",
    },
    {
      text: t("Shared Timelines"),
      link: "/shared-timelines",
    },
  ];

  const shareMemoriesModalToggle = () => {
    setisShareMemoriesModalOpen((prev) => !prev);
  };

  const handleShowTimeLine = () => {
    setShowTimelines(!showTimelines);
  };

  return (
    <div>
      <BottomSheet
        handleClose={shareMemoriesModalToggle}
        open={isShareMemoriesModalOpen}
      >
        <ShareMemories
          isApp={true}
          handleClose={shareMemoriesModalToggle}
          url={window.location.href}
        />
      </BottomSheet>
      <BottomSheet
        handleClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        className="menu-no-padding main-menu"
      >
        <div className="menu-header">
          <div className="menu-header-logo">
            <img src={LogoRed} alt={t("logo image")} />
            <span className="menu-title">FamilySearch {t('MENA')}</span>
          </div>
          <img
            src={CrossMenu}
            alt={t("close image")}
            onClick={() => setDrawerOpen(false)}
          />
        </div>

        <div
          className="menu-item menu-item-border menu-item-padding"
          onClick={() => {
                    navigate("/");
                    setDrawerOpen(false);
                  }}
        >
          <img src={Home} alt={t("Home")} className="menu-icon" />
          <h3 className="menu-icon-title">{t("Home")}</h3>
        </div>

        <div className="menu-item-padding-border">
          <div
            className="menu-item timelines-container menu-item-padding"
            onClick={handleShowTimeLine}
          >
            <div className="timelines-title">
              <img
                src={MyTimelines}
                alt={t("My Timelines")}
                className="menu-icon"
              />
              <h3 className="menu-icon-title menu-icon-title-timelines">
                {t("My Timelines")}
              </h3>
            </div>
            <img src={DownArrow} alt={t("DownArrow")} className="" />
          </div>
          {showTimelines ? (
            <div className="timelines-list">
              {timelinesList.map((ele) => (
                <div
                  className="timelines-item"
                  onClick={() => {
                    navigate(ele["link"]);
                    setDrawerOpen(false);
                  }}
                >
                  <img src={Dot} className="timelines-item-img" />
                  <div className="timelines-item-title">{ele["text"]}</div>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div>
          {list.map((item, index) => {
            return (
              <a
                href={item.link ? item.link : '#'}
                target= {item.link.includes('http') ? '_blank' : '_self'}
                rel='noreferrer'
                key={index}
                className="menu-item menu-item-padding"
                onClick={() => {
                  setDrawerOpen(false);
                  if (item.isButton) {
                    shareMemoriesModalToggle();
                  }
                }}
              >
                <img
                  src={item["icon"]}
                  alt={item["text"]}
                  className="menu-icon"
                />
                <h3 className="menu-icon-title">{item["text"]}</h3>
              </a>
            );
          })}
        </div>

        {window.isAuth ? (
          <div
            className="menu-item menu-item-border-top menu-item-padding"
            onClick={() => handleLogout()}
          >
            <img src={LogoutIcon} alt={t("Logout")} className="menu-icon" />
            <h3 className="menu-icon-title">{t("Logout")}</h3>
          </div>
        ) : null}

        <div
          className="menu-item menu-item-background menu-item-padding"
          onClick={() => setDrawerOpen(false)}
        >
          <img
            src={PrivacyPolicy}
            alt={t("Privacy Policy")}
            className="menu-icon"
          />
          <h3 className="menu-icon-title menu-icon-title-color">
            {t("Privacy Policy")}
          </h3>
        </div>
        <div
          className="menu-item menu-item-background menu-item-padding"
          onClick={() => setDrawerOpen(false)}
        >
          <img
            src={TermsofService}
            alt={t("Terms Of Service")}
            className="menu-icon"
          />
          <h3 className="menu-icon-title menu-icon-title-color">
            {t("Terms Of Service")}
          </h3>
        </div>

        {!window.isAuth ? (
          <div className="buttons-container">
            <button
              type="submit"
              className="loginBtn"
              onClick={Login}
            >
              {t("Login")}
            </button>

            <button
              className="registerBtn"
              onClick={() => {
                Signup()
              }}
            >
              {t("Register")}
            </button>
          </div>
        ) : null}
      </BottomSheet>
    </div>
  );
}
