import { memo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { MainLayout } from "components";
import {
  Home,
  FamilyMemories,
  MyTimeLines,
  AddEvent,
  EventDetails,
  EditEvent,
  PublicTimeLines,
  SharedTimeLines,
  Notfound404,
  AcceptInvite
} from "pages";

const FamilySearchRouter = () => {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Navigate to="/" />} />
        <Route path="/signup" element={<Navigate to="/" />} />
        <Route path="/add-event/:timelineId" element={<AddEvent />} />
        <Route path="/edit-event/:timelineId/:id" element={<EditEvent />} />
        <Route path="/my-timelines" element={<MyTimeLines />} />
        <Route path="/timelines" element={<PublicTimeLines />} />
        <Route path="/shared-timelines" element={<SharedTimeLines />} />
        <Route path="/timelines/:id" element={<FamilyMemories />} />
        <Route path="/event-details/:timelineId/:id" element={<EventDetails />} />
        <Route path="/accept-invitation/" element={<AcceptInvite />} />
        <Route path="*" element={<Notfound404 />} />
      </Routes>
    </MainLayout>
  );
};

export default memo(FamilySearchRouter);
