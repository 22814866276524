import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import MediaCard from "./components/MediaCard/MediaCard";
// import CustomTypography from "../../components/CustomTypography/CustomTypography";
import BeautifulFamTree from "../../assets/images/svg/beautiful-family-tree.svg";
// import FamilyMemories from "../../assets/images/svg/family-memories.svg";
import FamilyMemories from "../../assets/images/svg/newFM.svg";
import FamilyMemoriesAR from "../../assets/images/svg/newFM-ar.svg";
import { websiteIstance } from "api/axios";
import { setMyTimelines } from "redux/slices/myTimeLinesSlice";
import { useDispatch, useSelector } from 'react-redux';
import { MYTIMELINES } from "api/endpoints";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import CreateNewTimeLine from "../MyTimeLines/components/CreateNewTimeLine/CreateNewTimeLine"
import FamilyMemoryVector from "./components/FmailyMemoryVector";

import "./style.css";
import "./homeStyleDesktop.css"

class Enum {
  static FETCH_TIME_LINES = "FETCH_TIME_LINES";
}


export default function Home() {
  const { t } = useTranslation(["home"]);
  const [urlEnque, setUrlEnque] = useState("");
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [loading, setLoading] = useState("")
  const [isCreateNewTimelineOpen, setIsCreateNewTimelineOpen] = useState(false);
  const dispatch = useDispatch();
  const [lang, setLang] = useState('en');

  const myTimeLinesInfo = useSelector((state) => state.myTimeLines.data);
  const { timelines = [] } = myTimeLinesInfo
  const showCreateTimeLineButton = !timelines.length

  const navigate = useNavigate();

  const createNewTimelineModalToggle = () => {   
      setIsCreateNewTimelineOpen(prevValue => !prevValue)
  }
  const createNewTimelineModalClose = () => {
    setIsCreateNewTimelineOpen(false)
  }


  const handleMytimelineSubmit = () => {
    setUrlEnque("/my-timelines");
    setIsPrivacyPolicyOpen(true);
  }
  const handleFamilyTreeNavigate = () => {
    // setUrlEnque("/family-tree");
    // setIsPrivacyPolicyOpen(true);
    window.open('https://www.familysearch.org/mena/', '_blank');
  }
  const onPrivacyPolicySubmit = () => {
    setIsPrivacyPolicyOpen(false);
    if (urlEnque === "/my-timelines") {
      if (showCreateTimeLineButton) {        
        createNewTimelineModalToggle()
        return
      }
    }
    navigate(urlEnque);
  }

  const onPrivacyPolicyClose = () => {
    setIsPrivacyPolicyOpen(false);
    setUrlEnque("");
  }

  async function fetchTimelines() {
    try {
      const userId = localStorage.getItem("userId")
      if (!userId) return;
      setLoading(Enum.FETCH_TIME_LINES);
      const response = await websiteIstance.request({
        method: "get",
        url: MYTIMELINES.replace(":userId", `${userId}`).replace("?", `?page=0`)
      });
      const data = response?.data?.data;
      dispatch(setMyTimelines(data))
      setLoading("")
    } catch (error) {
      setLoading("")
    }
  }

  const img = () => {
    const lang = document.documentElement.getAttribute('lang');
    const image = lang === 'ar' ? FamilyMemoriesAR : FamilyMemories;
    return image;
  }

  useEffect(()=>{
    fetchTimelines();
  },[])

  return <div className="home-page-wrapper">
    <BottomSheet handleClose={createNewTimelineModalClose} open={isCreateNewTimelineOpen}>
      <CreateNewTimeLine handleClose={createNewTimelineModalClose} />
    </BottomSheet>
    <PrivacyPolicy onClose={onPrivacyPolicyClose} open={isPrivacyPolicyOpen} onSubmit={onPrivacyPolicySubmit} />
    <Container className="home-page--container container">
      <div className="home-page--container---cards">
        <MediaCard
          title={t("Family Memories")}
          isCSS={true}
          htmlImage={<FamilyMemoryVector translate={t} />}
          alt={t("Beautiful Family Tree")}
          onClick={handleMytimelineSubmit}
          buttonText={showCreateTimeLineButton ? t("Create your timeline") : t("Go to My Timelines")}
          secondButtonText={t("discover public timelines")}
          onClickSecondButton={() => navigate("/timelines", {
            state: {
              from: "/"
            }
          })}
        />
        <MediaCard 
          title={t("Create a Beautiful family tree")}
          image={BeautifulFamTree} 
          alt={t("Create a Beautiful family tree")}
          onClick={handleFamilyTreeNavigate}
        />
         
      </div>     
    </Container>
  </div>;
}
