const dbName = 'FamilySearch';
const dbVersion = 1;



export const initializeDatabase = (table="general", cb) => {
    // debugger

    const request = indexedDB.open(dbName, dbVersion);

    request.onerror = (event) => {
        console.error('Error opening database:', event.target.error);
    };

    request.onupgradeneeded = (event) => {
        const db = event.target.result;

        if (!db.objectStoreNames.contains(table)) {
           db.createObjectStore(table);
        }
    };

    request.onsuccess = (event) => {
        const db = event.target.result;

        console.log('Database initialized successfully');
        cb(db)
    };
};



export const insertOrUpdate = (data) => {
 initializeDatabase(data.tableName, (db) => {

            const transaction = db.transaction(data.tableName, 'readwrite');
            const objectStore = transaction.objectStore(data.tableName);
            const request = objectStore.get("persist:root");


            request.onsuccess = () => {
                 if(request.result) {
                    Update(data)
                 } else {
                    insert(data)
                 }
                

            };

            request.onerror = (event) => {
                console.error('Error updating data:', event.target.error);
            };         
});
}
export const insert = (data) => {
    initializeDatabase(data.tableName, (db) => {

            const transaction = db.transaction(data.tableName, 'readwrite');
            const objectStore = transaction.objectStore(data.tableName);

            const request = objectStore.add(data.payload, "persist:root");

            request.onsuccess = () => {
                console.log('Data inserted successfully');
            };

            request.onerror = (event) => {
                console.error('Error inserting data:', event.target.error);
            };         
});
}
export const Delete = (data) => {
    initializeDatabase(data.tableName, (db) => {

            const transaction = db.transaction(data.tableName, 'readwrite');
            const objectStore = transaction.objectStore(data.tableName);

            const request = objectStore.delete(data.id, "persist:root");

            request.onsuccess = () => {
                console.log('Data Delete successfully');
            };

            request.onerror = (event) => {
                console.error('Error Deleting data:', event.target.error);
            };         
});
}
export const Update = (data) => {
    initializeDatabase(data.tableName, (db) => {

            const transaction = db.transaction(data.tableName, 'readwrite');
            const objectStore = transaction.objectStore(data.tableName);
            const request = objectStore.get("persist:root");


            request.onsuccess = () => {
                 const data_ = {...request.result, ...data.payload};
                console.log("data_data_data_", data_);
                const updateRequest = objectStore.put(data_, "persist:root");

                updateRequest.onsuccess = () => {

                    console.log(`data updated, successfuly: ${updateRequest.result}`)

                }

            };

            request.onerror = (event) => {
                console.error('Error updating data:', event.target.error);
            };         
});
}
export const GetData = (tableName, cb) => {
    initializeDatabase(tableName, (db) => {
        const transaction = db.transaction(tableName, 'readonly');
        const objectStore = transaction.objectStore(tableName);

        const records = [];

        const cursorRequest = objectStore.openCursor();

        cursorRequest.onsuccess = (event) => {
            const cursor = event.target.result;

            if (cursor) {
                records.push(cursor.value);
                cursor.continue();
            } else {
                // No more records
                cb(records);
            }
        };

        cursorRequest.onerror = (event) => {
            console.error('Error fetching records:', event.target.error);
            cb([]);
        };
    });
};
