

const TimeLinePlaceholder = () => {
    return (
        <svg width="66" height="59" viewBox="0 0 66 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="66" height="59" fill="#EEEEEE" />
            <rect x="8" y="10" width="30.2" height="5" rx="2.5" fill="#C3C3C3" stroke="#FF0000" />
            <rect x="7.5" y="24.5" width="20" height="3" rx="1.5" fill="#C3C3C3" />
            <rect x="7.5" y="28.5" width="15" height="3" rx="1.5" fill="#C3C3C3" />
            <circle cx="53.5" cy="27.5" r="5" fill="#C3C3C3" />
            <rect x="7.5" y="41.5" width="20" height="3" rx="1.5" fill="#C3C3C3" />
            <rect x="7.5" y="45.5" width="15" height="3" rx="1.5" fill="#C3C3C3" />
            <circle cx="53.5" cy="44.5" r="5" fill="#C3C3C3" />
        </svg>
    )
}
const TimeLineUploadMediaPlaceholder = () => {
    return (
        <svg width="66" height="90" viewBox="0 0 66 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="65.9302" height="90" rx="4" fill="#EEEEEE" />
            <rect x="4.6875" y="17.2441" width="25.1628" height="19.9302" rx="1.5" fill="#C3C3C3" stroke="#FF0000" />
            <rect x="4.1875" y="39.7675" width="20.9302" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="34.5352" y="39.7675" width="20.9302" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="4.1875" y="8.37207" width="40.814" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="4.1875" y="43.9535" width="15.6977" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="34.5352" y="43.9535" width="15.6977" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="4.1875" y="74.3024" width="20.9302" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="34.5352" y="74.3024" width="20.9302" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="4.1875" y="78.4884" width="15.6977" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="34.5352" y="78.4884" width="15.6977" height="3.13953" rx="1.56977" fill="#C3C3C3" />
            <rect x="4.1875" y="51.2791" width="26.1628" height="20.9302" rx="2" fill="#C3C3C3" />
            <rect x="34.5352" y="16.7441" width="26.1628" height="20.9302" rx="2" fill="#C3C3C3" />
            <rect x="34.5352" y="51.2791" width="26.1628" height="20.9302" rx="2" fill="#C3C3C3" />
        </svg>

    )
}



export default TimeLinePlaceholder;
export {TimeLineUploadMediaPlaceholder};

