import cls from "classnames";
import "./style.css";

const StickyBottomActions = ({ children, className }) => {
    const wrapperClassName = cls("sticky-bottom-actions", {
        [className]: !!className
    })
    return (
        <div className={wrapperClassName}>
            {children}
        </div>
    )
}

export default StickyBottomActions;