// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-register-wrapper {
    padding: 11px 25px 5px 25px;
}
.login-register-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 56px;
}
.login-register-header-wrapper .login-register-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-register-header-wrapper .login-register-header .login-register-header-title{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
}
.login-register-header-wrapper .login-register-header .login-register-header-close-btn{
    height: 30px;
}

.login-register-header-wrapper .login-register-header-description{
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.login-register-actions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/LoginRegisterModal/style.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".login-register-wrapper {\n    padding: 11px 25px 5px 25px;\n}\n.login-register-header-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    margin-bottom: 56px;\n}\n.login-register-header-wrapper .login-register-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n.login-register-header-wrapper .login-register-header .login-register-header-title{\n    font-style: normal;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 20px;\n}\n.login-register-header-wrapper .login-register-header .login-register-header-close-btn{\n    height: 30px;\n}\n\n.login-register-header-wrapper .login-register-header-description{\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 19px;\n}\n\n.login-register-actions-wrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
