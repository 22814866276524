
const Grid = ({ isActive }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill={isActive ? "#066F90" : "#F3F3F3"} stroke="#ECECEC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M12.8 11H27.2C27.6774 11 28.1352 11.1896 28.4728 11.5272C28.8104 11.8648 29 12.3226 29 12.8V27.2C29 27.6774 28.8104 28.1352 28.4728 28.4728C28.1352 28.8104 27.6774 29 27.2 29H12.8C12.3226 29 11.8648 28.8104 11.5272 28.4728C11.1896 28.1352 11 27.6774 11 27.2V12.8C11 12.3226 11.1896 11.8648 11.5272 11.5272C11.8648 11.1896 12.3226 11 12.8 11V11ZM12.8 27.2H16.4V23.6H12.8V27.2ZM16.4 21.8H12.8V18.2H16.4V21.8ZM12.8 16.4H16.4V12.8H12.8V16.4ZM21.8 27.2H18.2V23.6H21.8V27.2ZM18.2 21.8H21.8V18.2H18.2V21.8ZM21.8 16.4H18.2V12.8H21.8V16.4ZM23.6 27.2H27.2V23.6H23.6V27.2ZM27.2 21.8H23.6V18.2H27.2V21.8ZM23.6 16.4H27.2V12.8H23.6V16.4Z" fill={isActive ? "white" : "black"} />
        </svg>
    )
}

export default Grid;