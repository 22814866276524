import { useEffect, useState, Fragment } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import styled from "@mui/system/styled";
import { useTranslation } from "react-i18next";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import { CssVarsProvider } from "@mui/joy/styles";
import CATEGORIES from "../../data/categories";
import CloseIcon from "components/Icons/Close";
const BootstrapDrawer = styled(Drawer)(() => ({
  "&": {
    zIndex: 1400
  },
  "& .MuiList-root": { padding: 0, marginBottom: "52px", zIndex: 1400 },
  "& .MuiListItem-root": {
    marginBottom: "12px",
  },
  "& .MuiPaper-root": {
    boxShadow: "unset",
    padding: "24px 25px 22px",
    borderRadius: "15px 15px 0px 0px",
  },
  "& .asideIcon": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
  "& .listButton": {
    padding: 0,
    fontSize: "14px !important",
    borderBottom: "1px solid rgba(156, 149, 122, 0.3)",
    "&::before": {
      content: '""',
      width: "40px",
      height: "1px",
      backgroundColor: "#9C957A",
      position: "absolute",
      bottom: "-1px",
    },
  },
  "& .invitePhrase": {
    fontSize: "14px",
    fontWeight: "700",
    textAlign: "center",
    marginBottom: "10px",
  },
  "& .MuiRadio-root": {
    padding: 0,
  },
}));

export default function DrawerComponentCategory({
  drawerOpen,
  setDrawerOpen,
  categoryValue,
  setCategoryValue,
  showOtherInput,
  setShowOtherInput,
  otherValue,
  setOtherValue,
  setDataToSession
}) {
  const [state, setState] = useState({
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptionOtherValue, setSelectedOptionOtherValue] = useState();
  const { t } = useTranslation(["add-event"]);

  useEffect(() => {
    if (otherValue) {
      setSelectedOptionOtherValue(otherValue);
      setSelectedOption(t("Other"));
    }
  }, [otherValue]);

  useEffect(() => {
    if (!drawerOpen) {
      setState({
        left: false,
        bottom: false,
        right: false,
      });
    } else {
      setState({
        left: false,
        bottom: true,
        right: false,
      });
    }
  }, [drawerOpen]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left", "right", "bottom"].map((anchor) => (
        <Fragment key={anchor}>
          <BootstrapDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={() => {
              setDrawerOpen(false);
              toggleDrawer(anchor, false);
            }}
          >
            <div className="select-category-header">
              <p
                className="addEventDrawerCategoryTitle"
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  margin: 0,
                }}
              >
                {t("Select Event Category")}
              </p>
              <div onClick={() => setDrawerOpen(false)}>
                <CloseIcon />
              </div>
            </div>

            <FormControl>
              <CssVarsProvider>
                <RadioGroup
                  aria-label="Your plan"
                  name="people"
                  defaultValue={categoryValue}
                >
                  <List
                    sx={{
                      minWidth: 240,
                      "--List-gap": "0.5rem",
                      "--List-decorator-size": "32px",
                      marginBottom: "28px",
                      padding: 0,
                    }}
                    className="addEventTagsInputList"
                  >
                    {
                      Object.values(CATEGORIES).map(cat => ({
                        name: t(cat.label),
                        value: cat.name,
                        color: cat.color,
                        Icon: cat.Icon
                      })).map((item, index) => (
                      <ListItem
                        className="addEventTagsInputListItem"
                        variant="outlined"
                        key={item.value}
                        sx={{
                          boxShadow: "sm",
                          bgcolor: "background.body",
                          border: 0,
                          borderBottom: "0.5px solid #DFDFDF"               
                        }}
                        onClick={() => {
                          setSelectedOption(item.value);
                          if (item.value === "Other") {
                            setShowOtherInput(true);
                          } else {
                            setShowOtherInput(false);
                          }
                        }}
                      >
                        <Radio
                          overlay
                          value={item.value}
                          label={item.name}
                          sx={{ flexGrow: 1, flexDirection: "row" }}
                          // className={`radio-color-${item.color.replace("#", "")}`}
                          componentsProps={{
                            action: ({ checked }) => ({
                              sx: (theme) => ({
                                ...(checked && {
                                  inset: -1,
                                  border: "2px solid",
                                  
                                }),
                              }),
                            }),
                          }}
                        />
                        <ListItemDecorator>
                          {
                           item.Icon
                          }
                        </ListItemDecorator>
                      </ListItem>
                    ))}
                    {showOtherInput && (
                      <input
                        placeholder={t("WorldCup")}
                        value={selectedOptionOtherValue}
                        className="otherInput"
                        onChange={(e) =>
                          setSelectedOptionOtherValue(e.target.value)
                        }
                      />
                    )}
                  </List>
                </RadioGroup>
              </CssVarsProvider>
            </FormControl>
            <button
              className="selectCategoryBtn"
              onClick={() => {
                if (selectedOption !== "Other" && selectedOption !== "أخرى") {
                  setOtherValue("");
                  setDataToSession("otherValue", null)
                } else {
                  setOtherValue(selectedOptionOtherValue);
                  setDataToSession("otherValue", selectedOptionOtherValue)
                }
                setCategoryValue(selectedOption);
                setDataToSession("categoryValue", selectedOption)
                setDrawerOpen(false);
              }}
              variant="contained"
            >
              {t("Select")}
            </button>
          </BootstrapDrawer>
        </Fragment>
      ))}
    </div>
  );
}
