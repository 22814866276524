import { FileUploader } from "react-drag-drop-files";
import FormControlLabel from "@mui/material/FormControlLabel";
import SvgIcon from "@mui/material/SvgIcon";
import Radio from "@mui/material/Radio";
import { useTranslation } from "react-i18next";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { DeleteIcon } from "./components/SVGIcons/SVGIcons";
import { bytesToSize, truncate } from "./utils";
import AudioIcon from "assets/icons/audio-icon.svg";
import RecorderMic from "assets/icons/recorder-mic.svg";
import "./style.css";
import { useState } from "react";

const Media = ({
    handleChangeFile,
    fileTypes,
    fileList,
    handleChangeRadio,
    setOpen,
    currentCover,
    handleDeleteFile,
    setRecorderDrawerOpen,
    audioList,
    handleDeleteAudio,
    handleClose,
    className,
    open
}) => {
    const { t } = useTranslation(["add-event"]);

    const [isSizeError, setSizeError] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // console.log(isSizeError, snackbarOpen);
    return (
        <>
            <BottomSheet open={open} handleClose={handleClose} className={className}>
                <Container style={{ height: '100%', paddingTop: 18 }} className="media-box">

                    <div className="mediaContainer">
                        <div className="uploadMedia">
                            <FileUploader
                                multiple={true}
                                handleChange={handleChangeFile}
                                name="file"
                                types={fileTypes}
                                maxSize={15}
                                onSizeError={() => setSnackbarOpen(true)}
                                children={
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "101px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            border: "1px dashed #DFDFDF",
                                            padding: "16px 38px 16px",
                                        }}
                                    >
                                        <SvgIcon
                                            width="18"
                                            height="22"
                                            viewBox="0 0 18 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="datePickerIcon"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M9 15L7 13L4 17H14L11 11L9 15ZM7 12C7.39556 12 7.78224 11.8827 8.11114 11.6629C8.44004 11.4432 8.69638 11.1308 8.84776 10.7654C8.99913 10.3999 9.03874 9.99778 8.96157 9.60982C8.8844 9.22186 8.69392 8.86549 8.41421 8.58579C8.13451 8.30608 7.77814 8.1156 7.39018 8.03843C7.00222 7.96126 6.60009 8.00087 6.23463 8.15224C5.86918 8.30362 5.55682 8.55996 5.33706 8.88886C5.1173 9.21776 5 9.60444 5 10C5 10.5304 5.21071 11.0391 5.58579 11.4142C5.96086 11.7893 6.46957 12 7 12ZM2 20V2H12V6H16V20H2ZM13.41 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V20C0 20.5304 0.210714 21.0391 0.585786 21.4142C0.960859 21.7893 1.46957 22 2 22H16C16.5304 22 17.0391 21.7893 17.4142 21.4142C17.7893 21.0391 18 20.5304 18 20V4.59L13.41 0Z"
                                                fill="#066F90"
                                            />
                                        </SvgIcon>
                                        <p
                                            style={{
                                                fontSize: "12px",
                                                margin: "8px 0px 7px",
                                                color: "#878A8C",
                                                fontWeight: "700",
                                            }}
                                            className="uploader-title"
                                        >
                                            {t("Add Media")}
                                        </p>
                                        <p
                                            style={{
                                                fontSize: "8px",
                                                margin: "0",
                                                color: "#878A8C",
                                                fontStyle: "italic",
                                                textAlign: "center",
                                            }}
                                            className="uploader-subtitle"
                                        >
                                            {t("We Support")} .jpg, .tif, .bmp, .png, .mp3, .m4a, and
                                            .wav,
                                            {t("up to 15MB")}
                                        </p>
                                    </div>
                                }
                            />

                            {fileList.length ? (
                                <div>
                                    {fileList.map((e) => {
                                        const isAudio = e.type.includes("audio");
                                        let underscoreIndex = e.name.indexOf('_');
                                         let newFilename = e.name
                                        if (underscoreIndex !== -1 && !isAudio) {
                                             newFilename = e.name.substring(underscoreIndex + 1);
                                        } 
                                        return (
                                            <div
                                                key={e.name}
                                                style={{
                                                    display: "flex",
                                                    alignContent: "center",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "37px",
                                                        height: "40px",
                                                        marginInlineEnd: "4px",
                                                    }}
                                                >
                                                    {isAudio ? (
                                                        <img
                                                            style={{
                                                                width: "37px",
                                                                height: "40px",
                                                                borderRadius: "4px",
                                                                border: "1px solid #DFDFDF",
                                                            }}
                                                            src={AudioIcon}
                                                            alt={e.name}
                                                        />
                                                    ) : (
                                                        <img
                                                            style={{
                                                                width: "37px",
                                                                height: "40px",
                                                                borderRadius: "4px",
                                                                border: "1px solid #DFDFDF",
                                                            }}
                                                            src={e.path ? e.path : URL.createObjectURL(e)}
                                                            alt={e.name}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#202121",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                        }}
                                                    >
                                                        {truncate(newFilename)}{" "}
                                                        <span
                                                            style={{
                                                                fontSize: "10px",
                                                                color: "#878A8C",
                                                                marginInlineStart: "5px",
                                                            }}
                                                        >
                                                            {("e.size", e.size)}
                                                            “{bytesToSize(e.size)}”
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        {isAudio ? (
                                                            <span className="addEventUseAsCoverLabel"></span>
                                                        ) : (
                                                            e instanceof File ?
                                                                <FormControlLabel
                                                                    className="addEventUseAsCoverLabel"
                                                                    value="ar"
                                                                    control={
                                                                        <Radio
                                                                            onClick={() => {
                                                                                handleChangeRadio(e);
                                                                                setOpen(true);
                                                                            }}
                                                                            className="addEventUseAsCover"
                                                                        />
                                                                    }
                                                                    label={t("Use as cover")}
                                                                    checked={e.name === currentCover?.name}
                                                                /> : ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <DeleteIcon
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleDeleteFile(e)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ""
                            )}

                        </div>
                        <div className="voiceUpload">
                            <label>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "101px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        border: "1px dashed #DFDFDF",
                                        padding: "16px 35px 16px",
                                        textAlign: "center",
                                    }}
                                    onClick={() => setRecorderDrawerOpen(true)}
                                >
                                    <SvgIcon
                                        width="15"
                                        height="25"
                                        viewBox="0 0 15 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.24865 17.8879C9.65611 17.8879 11.6008 15.8015 11.6008 13.2421V4.64586C11.6008 2.08646 9.65611 0 7.24865 0C4.84118 0 2.89648 2.08646 2.89648 4.64586V13.2421C2.89648 15.8015 4.84118 17.8879 7.24865 17.8879Z"
                                            fill="#066F90"
                                        />
                                        <path
                                            d="M13.4163 9.05176V13.4652C13.4163 16.7989 10.6492 19.5123 7.30435 19.5123C3.95946 19.5123 1.19242 16.7989 1.19242 13.4652V9.05176H0.136719V13.4652C0.136719 17.1865 3.08156 20.2543 6.80428 20.5257V24.0033H2.74818V25H11.6938V24.0033H7.85998V20.5257C11.5271 20.2543 14.3609 17.1865 14.3609 13.4652V9.05176H13.4163Z"
                                            fill="#066F90"
                                        />
                                    </SvgIcon>
                                    <p
                                        style={{
                                            fontSize: "12px",
                                            margin: "8px 0px 7px",
                                            fontWeight: "700",
                                            color: "#878A8C",
                                        }}
                                        className="uploader-title"
                                    >
                                        {t("Record Audio")}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: "8px",
                                            margin: "0",
                                            color: "#878A8C",
                                            fontStyle: "italic",
                                        }}
                                        className="uploader-subtitle"
                                    >
                                        {t("Keep recordings under 15 minutes.")}
                                    </p>
                                </div>
                            </label>
                            {/* {console.log("audioList", audioList)} */}
                            {audioList.length ? (
                                <div>
                                    {audioList.map((e, i) => {
                                        return (
                                            <div
                                                key={i}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginBottom: "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: "37px",
                                                        height: "40px",
                                                        marginInlineEnd: "4px",
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            width: "37px",
                                                            height: "40px",
                                                            borderRadius: "4px",
                                                            border: "1px solid #DFDFDF",
                                                        }}
                                                        src={RecorderMic}
                                                        alt={e.name}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        minHeight: "40px",
                                                        flexDirection: "column",
                                                        flexGrow: 1,
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: "#000000",
                                                            fontWeight: "400",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {e.name.split(".")[0]}
                                                    </span>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "end",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#878A8C",
                                                                fontWeight: "400",
                                                                fontSize: "10px",
                                                            }}
                                                        >
                                                            {e.duration}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <DeleteIcon
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleDeleteAudio(e)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                <AppBar
                    className="navbar-login-button navbar-login-button-desktop navbar-login-button-desktop-media"
                    component="nav"
                >
                    <button onClick={handleClose} className="addEventBtn">
                        {t("SAVE")}
                    </button>

                </AppBar>
                </Container>
            </BottomSheet>

            {
                
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={3000}
                    onClose={() => {
                        localStorage.removeItem("toast");
                        setSnackbarOpen(false);
                        setSizeError(false)
                    }}
                    className="alert-message-container"
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        icon={false}
                        style={{
                            background: "#252525",
                            minHeight: "66px",
                            padding: "8px 15px",
                            color: "#C63D2F",
                            fontSize: "14px",
                        }}
                        severity="error"
                        sx={{ width: "100%" }}
                        className="alert-message"
                    >
                        {/* <img className="alert-message-icon" src={GreenCorrect} /> */}
                        <div>
                            <span style={{ fontWeight: "700" }}>{t("Error")}</span>
                            {t("ImageSize")}
                        </div>
                    </Alert>
                </Snackbar>
            }
        </>
    )
}

export default Media