
const Invite = ({ fill, isOutline}) => {
    if(isOutline) {
        return (
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="39" height="39" rx="3.5" fill="#367419" fillOpacity="0.1" stroke="#367419" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.159 28H22.8418C22.2438 26.192 20.0079 25 16.9999 25C13.9919 25 11.757 26.192 11.159 28ZM24.9998 30H9V29C9 25.467 12.29 23 16.9999 23C21.7108 23 24.9998 25.467 24.9998 29V30Z" fill="#367419" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.0002 16C15.8972 16 15.0002 16.896 15.0002 18C15.0002 19.103 15.8972 20 17.0002 20C18.1032 20 19.0002 19.103 19.0002 18C19.0002 16.896 18.1032 16 17.0002 16ZM17.0002 22C14.7942 22 13.0002 20.206 13.0002 18C13.0002 15.794 14.7942 14 17.0002 14C19.2062 14 21.0001 15.794 21.0001 18C21.0001 20.206 19.2062 22 17.0002 22Z" fill="#367419" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31 13H28V10H26.0001V13H23.0001V15H26.0001V18H28V15H31V13Z" fill="#367419" />
            </svg>

        )
    }
    return(
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.68534 21.3333H16.3155C15.6178 19.224 13.0092 17.8333 9.49984 17.8333C5.9905 17.8333 3.383 19.224 2.68534 21.3333ZM18.8332 23.6666H0.166504V22.5C0.166504 18.3781 4.00484 15.5 9.49984 15.5C14.996 15.5 18.8332 18.3781 18.8332 22.5V23.6666Z" fill={fill || "#85B807"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.50019 7.33331C8.21335 7.33331 7.16685 8.37865 7.16685 9.66665C7.16685 10.9535 8.21335 12 9.50019 12C10.787 12 11.8335 10.9535 11.8335 9.66665C11.8335 8.37865 10.787 7.33331 9.50019 7.33331ZM9.50019 14.3333C6.92652 14.3333 4.83352 12.2403 4.83352 9.66665C4.83352 7.09298 6.92652 4.99998 9.50019 4.99998C12.0739 4.99998 14.1669 7.09298 14.1669 9.66665C14.1669 12.2403 12.0739 14.3333 9.50019 14.3333Z" fill={fill || "#85B807"} />
            <path fillRule="evenodd" clipRule="evenodd" d="M25.8335 3.83331H22.3335V0.333313H20.0002V3.83331H16.5002V6.16665H20.0002V9.66665H22.3335V6.16665H25.8335V3.83331Z" fill={fill || "#85B807"} />
        </svg>

    )
}

export default Invite;