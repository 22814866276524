export const EVENTS = "/api/v1/timelines/:id/events";
export const EVENTS_BY_TIMELINE_ID = "/api/v1/timelines/:id";
export const DELETE_EVENT = "/api/v1/timelines/:timelineId/events/:eventId";
export const TIMELINES = "/api/v1/timelines";
export const QUERY_TIMELINES = "/api/v1/timelines?";
export const MYTIMELINES = "/api/v1/users/:userId/timelines?";
export const SHARED_TIMELINES = "api/v1/users/:userId/contributed-timelines?";
export const LOGIN = "/api/v1/auth/login";
export const INVITE = "/api/v1/timelines/:timelineId/invite";
export const DELETE_INVITATION = "/api/v1/timelines/:timelineId/invitations/:id";
export const INVITATIONS = "/api/v1/timelines/:timelineId/invitations";
export const AUTHENTICATE_BY_FS = "/api/v1/auth/exchange-code";
export const ACCEPT_INVITE = "/api/v1/timelines/accept-invitation";
export const ANANLYTICS = "/api/v1/analytic";
export const INVITATION_UPDATE =  "/api/v1/timelines/:timelineId/invitations/:id";;
