/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
// import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import { CssVarsProvider } from "@mui/joy/styles";
import { useNavigate, useLocation } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
// import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
// import SvgIcon from "@mui/material/SvgIcon";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Textarea from "@mui/joy/Textarea";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import UseWidth from "hooks/useUpdateWindowDimensions";
import moment from "moment";
// import { DeleteIcon, DateIcon } from "../AddEvent/components/SVGIcons/SVGIcons";
import DrawerComponentCategory from "../AddEvent/DrawerComponentCategory";
import RecorderModal from "../AddEvent/components/RecorderModal/RecorderModal";
// import { bytesToSize, truncate } from "../AddEvent/utils";
import Cropper from "../AddEvent/components/Cropper";
import { useParams } from "react-router-dom";
import Back from "assets/images/svg/back.svg";
import DeleteIconBig from "assets/icons/delete-icon.svg";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import DeleteEvent from "./components/DeleteEvent/DeleteEvent";
import "./style.css";
import { websiteIstance, websiteIstanceFormData } from "../../api/axios";
// import AudioIcon from "assets/icons/audio-icon.svg";
// import RecorderMic from "assets/icons/recorder-mic.svg";
import StyledButton from "components/StyledButton/StyledButton";
import { setOfflineFamilyMemories, removeEvent } from "redux/slices/familyMemoriesSlice";
import { useDispatch, useSelector } from "react-redux";
import mapkeys from "lodash/mapKeys";
import dataToBase64 from "helpers/dataToBase64";
import getBase64FromUrl from "helpers/getBase64FromUrl";
import heicToAny from "heic2any"
import DateForm from "pages/AddEvent/components/Date";
// import CATEGORIES from "../../data/categories";
import ImageGallery from "pages/EventDetails/components/ImageGallery";
import Cover from "pages/AddEvent/components/Cover";
import Media from "pages/AddEvent/Media";
import { useSnackbar } from 'notistack';

import { Editor } from '@tinymce/tinymce-react';

var hasError = false;

const BootstrapInput = styled(InputBase)(() => ({
  height: "36px",
  width: "100%",
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    border: "1px solid #DFDFDF",
    fontSize: 12,
    padding: "9px 0px 10px 0px",
    height: "15px",
    paddingInlineStart: "15px",
  },
}));

const fileTypes = ["JPG", "JPEG", "HEIF", "HEIC", "TIF", "DMP", "PNG", "MP3", "M4A", "WAV"];

const getMinutes = (recordedTime, text) => {
  const minutes = Math.floor(recordedTime / 60);
  const seconds = recordedTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return `${formattedTime} ${text}`;
};

export default function EditEvent({ onClose, refetch }) {
  const { t } = useTranslation(["add-event"]);
  // const [tags, setTags] = useState([]);
  const [value, setValue] = useState("PRIVATE");
  const [date, setDate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const [title, setTitle] = useState(null);
  const [place, setPlace] = useState(null);
  const [details, setDetails] = useState(null);
  const [currentCropImage, setCurrentCropImage] = useState(null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [categoryValue, setCategoryValue] = useState(null);
  const [currentCover, setCurrentCover] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [recorderDrawerOpen, setRecorderDrawerOpen] = useState(false);
  const [otherValue, setOtherValue] = useState("");
  const navigate = useNavigate();
  const [cropImage, setCropImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [audioList, setAudioList] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [numberOfAudio, setNumberOfAudio] = useState(1);
  const [isDeleteEventOpen, setIsDeleteEventOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isMediaOpen, setIsmediaOpen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const editorRef = useRef();


  const width = UseWidth();
  const dispatch = useDispatch()
  const params = useParams();
  const { id, timelineId } = params;
  const familyMemories = useSelector((state) => state.familyMemories.familyMemories);
  const setDataToSession = (key, value) => {
    if (value === null) {
      window.sessionStorage.removeItem(key)
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }

  function getDuration(time) {
    let totalSeconds = time;
    // let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, "0");
    // hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    return minutes + ":" + seconds
}

  const handleChangeFile = async (file) => {
    if (file?.length !== 0) {
      let fileListNew = [...fileList];
      for (const property in file) {
        if (!isNaN(property)) {
          console.log(file[property])
          if (file[property].type === "image/heif" || file[property].type === "image/heic") {
            const conversionResult = await heicToAny({ blob: file[property], toType: "image/jpeg", quality: 1 })
            const newImage = new File([conversionResult], `${file[property].name.split(".")[0]}.jpeg`, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            fileListNew = [...fileListNew, newImage];
          } else {
            fileListNew = [...fileListNew, file[property]];
          }
        }
      }
      setFileList(fileListNew);
    }
  };

  const handleChangeRadio = (file) => {
    setCurrentCropImage(file);
  };

  const handleDeleteFile = (file) => {
    const index = fileList.findIndex((e) => e.name === file.name);
    if (index !== -1) {
      const cloneFileList = [...fileList];
      cloneFileList.splice(index, 1);
      setFileList(cloneFileList);
      if (currentCropImage && currentCropImage.name === file.name) {
        setCropImage(null);
        setCurrentCover(null);
        setCurrentCropImage(null);
      }
    }
    if (file.path) {
      setDeletedFiles([...deletedFiles, file.id]);
    }
  };



  const unRegisteredUpdateEvent = async () => {
    try {
      setLoading(true)
      const images = [];
      let delay = 3000;
      let totalFilesSize = 0;
      for (let index = 0; index < fileList.length; index++) {
        const img = fileList[index];
        const image = img?.path?.includes("base64") ? img.path : await dataToBase64(img);
        totalFilesSize = totalFilesSize + img.size;
        images.push({
          path: image,
          name: img.name,
          isThumbnailOrigin: false,
          size: img.size,
          type: img.type,
        });
      }

      const voices = [];

      for (let index = 0; index < audioList.length; index++) {
        try {
          const audio = audioList[index];
          const voice = audio.file ? await dataToBase64(audio.file) : audio;
          // console.log(voice)
          if (audio.file) {
            voices.push({
              path: voice,
              name: audio.file.name,
              isThumbnailOrigin: false,
              size: audio.file.size,
              type: audio.file.type,
              duration: audio.file.duration
            });
            totalFilesSize = totalFilesSize + audio.file.size;
          } else {
            voices.push(voice)
          }
        } catch (error) {
          console.log("errrrrrrrrrr", error);
        }
      }

      const eventId = id;
      const event = {
        category: otherValue || categoryValue,
        createdAt: moment().format("YYYY-MM-DD"),
        creator_id: null,
        date: {
          month,
          year,
          day
        },
        details: editorRef.current.getContent(),
        files: { images, voices },
        id: eventId,
        status: value,
        place,
        thumbnail: cropImage ? await getBase64FromUrl(cropImage) : images[0]?.path,
        timelineId,
        title,
        updatedAt: moment().format("YYYY-MM-DD"),
        cropImage: cropImage ? await getBase64FromUrl(cropImage) : "",
        currentCropImageName: currentCropImage?.name ?? "",
      };

      const payload = {
        event,
        timelineId,
      };
      dispatch(removeEvent({ eventId: id, timelineId: timelineId }));
      dispatch(setOfflineFamilyMemories(payload));
      // if(totalFilesSize > 1000000){
      //   delay = 5000
      // }
      // if(totalFilesSize > 3000000){
      //   delay = 8000
      // }
      // if(totalFilesSize > 10000000){
      //   delay = 10000
      // }
      // if(totalFilesSize > 20000000){
      //   delay = 20000
      // }

      navigate(-1, {
        replace: true
      })
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  };


  const handleSubmit = async () => {
    try {
      setSubmitClicked(true);
      await new Promise((resolve) => setTimeout(() => {
        resolve("")
      }, 500));

      if (hasError) return
      if (!window.isAuth) {
        unRegisteredUpdateEvent();
        refetch?.()
        return;
      }
      setLoading(true);
      let Images = []
      let Voices = []

      const formData = new FormData();

      if (fileList.length) {
        for (let i = 0; i < fileList.length; i++) {
          if (!fileList[i].path) {
            Images.push(fileList[i])
            // formData.append("files", fileList[i]);
          }
        }
        if (cropImage) {

          const response = await fetch(cropImage);
          const blob = await response.blob();
          const file = new File(
            [blob],
            `${currentCropImage.name
              .split(".")
              .slice(0, -1)
              .join(".")}-thumbnail.jpeg`,
            { type: blob.type }
          );
          formData.append("files", file);
        }
      }
      if (audioList.length) {
        Voices = [...audioList]
        // for (let i = 0; i < audioList.length; i++) {
        //   formData.append("files", audioList[i].file);
        // }
      }

      // console.log(Voices)

      const data = {
        category: otherValue || categoryValue,
        title,
        place,
        // date: typeof date === "string" ? date : date.format("YYYY-MM-DD"),
        day,
        month,
        year,
        // status: value,
        // tags: [],
        details: editorRef.current.getContent(),
        deletedFiles,
      };

      formData.append("event", JSON.stringify(data));

      // console.log(formData)


      await websiteIstanceFormData.put(
        `api/v1/timelines/${timelineId}/events/${id}`,
        formData
      );

      Images = [...Images.slice(0, 10)]
      if (id && Images.length) {
        for (const image of Images) {
          const formData = new FormData();
          formData.append("event", JSON.stringify(data));
          formData.append("files", image);
          await updateEventFormData(timelineId, id, formData)

        }
      }

      if (id && Voices.length) {
        // console.log(Voices)
        for (const voice of Voices) {
          const formData = new FormData();
          formData.append("event", JSON.stringify(data));
          formData.append("files", voice.file);
          // console.log(voice)
          // console.log(voice.file)

          await updateEventFormData(timelineId, id, formData)

        }
      }

      // if(width < 700){
      navigate(-1, {
        replace: true
      })
      // } else {
      //   typeof onClose === 'function' && onClose()
      // }
      setLoading(false);
      refetch?.()
    } catch (error) {
      setLoading(false);
      // console.log(error)
      enqueueSnackbar(error.response?.data?.data[0].message, { variant: "error" })
      // enqueueSnackbar(t(error.response?.data?.data ?? error.essage), { variant: "error" })
    }
  };

  async function updateEventFormData(timelineId, eventId, formData) {
    // console.log('data ->',formData)
    await websiteIstanceFormData.put(`api/v1/timelines/${timelineId}/events/${eventId}`, formData);
  }

  const handleUpdateAudioList = (file, audiolength) => {
    // console.log('duration', getDuration(audiolength))
    setAudioList([...audioList, { file, name: file.name, time: audiolength, duration: getDuration(audiolength) }]);
  };

  const handleDeleteAudio = (file) => {

    const index = file
    // const index = audioList.findIndex((e) => e.name === file.name);
    if (index !== -1) {
      const cloneAudioList = [...audioList];
      cloneAudioList.splice(index, 1);
      setAudioList(cloneAudioList);
      if (!cloneAudioList.length) setNumberOfAudio(1);
    }

    if (file.path) {
      setDeletedFiles([...deletedFiles, file.id]);
    }
  };

  const getOfflineData = () => {
    const timeline = familyMemories?.[timelineId] ?? {}
    const events = timeline?.events ?? [];
    const eventDictionary = mapkeys(events, "id");
    const event = eventDictionary[id] ?? {}
    const {
      title,
      place,
      category,
      date,
      details,
      files: { images, voices },
      // tags,
      status,
    } = event;

    setValue(status);
    setDate(date);
    setDay(date?.day);
    setMonth(date?.month);
    setYear(date?.year);
    setTitle(title);
    setPlace(place);
    setDetails(details);
    // setTags(tags);
    setFileList(
      images.map((ele) => {
        if (ele.isThumbnailOrigin) {
          setCurrentCover({ name: ele.name });
        }
        return {
          ...ele,
          size: ele["size"],
        };
      })
    );

    setAudioList(voices);
    const isCategoryExist = [
      "Add A Family Memory",
      "Birthday",
      "Graduation",
      "Marriage",
      "New Baby",
      "Achievements",
      "Travel",
      "Other",
    ].filter((cat) => cat === category);
    if (isCategoryExist.length) {
      setCategoryValue(category);
    } else {
      setShowOtherInput(true);
      setOtherValue(category);
      setCategoryValue("Other");
    }
  }

  useEffect(() => {
    const userId = +localStorage.getItem("userId")

    if (!userId) {
      getOfflineData()
      return
    }
    const getData = async () => {
      const eventData = await websiteIstance.get(
        `/api/v1/timelines/${timelineId}/events/${id}`
      );

      const {
        title,
        place,
        category,
        date,
        details,
        files: { images, voices },
        // tags,
        status,
      } = eventData.data.data;
      setValue(status);
      setDate(date);
      setDay(date?.day);
      setMonth(date?.month);
      setYear(date?.year);
      setTitle(title);
      setPlace(place);
      setDetails(details);
      // setTags(tags);
      setFileList(
        images.map((ele) => {
          if (ele.isThumbnailOrigin) {
            setCurrentCover({ name: ele.name });
          }
          return {
            ...ele,
            size: ele["size"],
          };
        })
      );

      setAudioList(voices);
      const isCategoryExist = [
        "Add A Family Memory",
        "Birthday",
        "Graduation",
        "Marriage",
        "New Baby",
        "Achievements",
        "Travel",
        "Other",
      ].filter((cat) => cat === category);
      if (isCategoryExist.length) {
        setCategoryValue(category);
      } else {
        setShowOtherInput(true);
        setOtherValue(category);
        setCategoryValue("Other");
      }
    };
    if (!isDeleted)
      getData();
  }, []);

  const onDelete = () => {
    setIsDeleteEventOpen(true);
  };
  const onCloseDeleteEvent = () => {
    setIsDeleteEventOpen(false);
  };

  const handleCloseMedia = () => {
    setIsmediaOpen(false)
  }
  const handleOpenMedia = () => {
    setIsmediaOpen(true)
  }

  const handleDayChange = (v) => {
    setDay(v)
  }
  const handleMonthChange = (v) => {
    setMonth(v)
  }
  const handleYearChange = (v) => {
    setYear(v)
  }
  // console.log("fileList", fileList);
  return (
    <>
      <Media
        fileTypes={fileTypes}
        handleChangeFile={handleChangeFile}
        fileList={fileList}
        handleChangeRadio={handleChangeRadio}
        setOpen={setOpen}
        currentCover={currentCover}
        handleDeleteFile={handleDeleteFile}
        setRecorderDrawerOpen={setRecorderDrawerOpen}
        audioList={audioList}
        getMinutes={getMinutes}
        handleDeleteAudio={handleDeleteAudio}
        open={isMediaOpen} handleClose={handleCloseMedia}
        className='media-container-update'
      />
      <Container className="addEventContainer updateEventContainer">
        <BottomSheet handleClose={onCloseDeleteEvent} open={isDeleteEventOpen}>
          <DeleteEvent
            cb={() => {
              setIsDeleted(true)
              onCloseDeleteEvent();
            }}
            timelineId={timelineId}
            eventId={id}
            handleClose={onCloseDeleteEvent}
          />
        </BottomSheet>
        <Typography
          component="div"
          style={{
            fontSize: "16px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
          className="edit-event-title"
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              backgroundColor: "#F3F3F3",
              marginInlineEnd: "10px",
            }}
            onClick={() => {
              // const queueNavigation = location.state.from || [];
              // queueNavigation.pop()
              navigate(-1, {
                replace: true
              })
            }}
          >
            <img src={Back} alt={t("Back")} className="backIconRotate" />
          </div>
          {t("Edit")} {title}
        </Typography>

        {fileList?.length ? (
          <ImageGallery
            images={fileList.map(file => {

              // Create a temporary URL for the file
              const imageUrl = file.path ? file.path : URL.createObjectURL(file);

              return imageUrl


            })}
            handleClickOnImage={handleOpenMedia}
            width={width}
            uploadMedia={handleOpenMedia}
            showActions={true}
          />
        ) : <Cover showActions={true} category={otherValue || categoryValue} uploadMedia={handleOpenMedia} />}
        {categoryValue && (
          <Box component="form" className="editEventForm" noValidate>


            <InputLabel
              className="inputLableFontSize inputPaddingTop"
              shrink
              htmlFor="title-input"
            >
              {t("Event Title")}
            </InputLabel>
            <BootstrapInput
              placeholder={t("Example: It’s My Birthday!")}
              id="title-input"
              label="Filled"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <DateForm handleError={(v) => {
              hasError = v;
            }}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
              setSubmitClicked={setSubmitClicked} submitClicked={submitClicked}
              day={day}
              month={month}
              year={year}
            />
            {/* <InputLabel
            shrink
            className="inputPaddingTop inputLableFontSize"
            htmlFor="password-input"
          >
            {t("Event Date")}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              components={{
                OpenPickerIcon: DateIcon,
              }}
              disableFuture={true}
              inputFormat="DD/MM/YYYY"
              value={date}
              onChange={handleChangeDate}
              minDate="1500-01-01"
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: t("DD/MM/YYYY"),
                    style: { fontSize: "12px" },
                    readOnly: true,
                    className: "date-picker"
                  }}
                />
              )}
              className="addEventDatePicker"
            />
          </LocalizationProvider> */}

            <InputLabel
              className="inputLableFontSize inputPaddingTop"
              shrink
              htmlFor="place-input"
            >
              {t("Event Place")}
            </InputLabel>
            <BootstrapInput
              id="place-input"
              label="Filled"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            />

            <InputLabel
              className="inputPaddingTop inputLableFontSize"
              shrink
              htmlFor="details-input"
            >
              {t("Event Details")}
            </InputLabel>
            <CssVarsProvider>
              <Editor
                apiKey='zgmuezjaq8ok4h11mu1q00a1uq6s74u7timoaqndexhuyzfa'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={details}
                init={{
                  height: 250,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                  ],
                  toolbar: false,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />

              {/* <Textarea
              className="addEventTextarea"
              minRows={5}
              id="details-input"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            /> */}
            </CssVarsProvider>


            <Button
              onClick={onDelete}
              className="deleteEventBtn"
              variant="contained"
            >
              <img alt="e" src={DeleteIconBig} className="deleteIconBtn" />
              {t("Delete this event")}
            </Button>
            {
              width >= 700 && <div style={{ marginTop: 25 }} >
                {loading ? (
                  <StyledButton
                    color="primary"
                    loading={true}
                    variant="contained"
                    fullWidth
                    type="submit"
                  ></StyledButton>
                ) : (
                  <Button
                    onClick={() => handleSubmit()}
                    className="addEventBtn"
                    variant="contained"
                  >
                    {t("Save Changes")}
                  </Button>
                )}
              </div>
            }
          </Box>
        )}
        <DrawerComponentCategory
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          categoryValue={categoryValue}
          setCategoryValue={setCategoryValue}
          showOtherInput={showOtherInput}
          setShowOtherInput={setShowOtherInput}
          BootstrapInput={BootstrapInput}
          otherValue={otherValue}
          setDataToSession={setDataToSession}
          setOtherValue={setOtherValue}
        />
        <Modal
          slotProps={{ backdrop: { style: { backgroundColor: "unset" } } }}
          open={open}
        >
          <>
            <Cropper
              currentCropImage={currentCropImage}
              setCurrentCover={setCurrentCover}
              setCropImage={setCropImage}
              setOpen={setOpen}
              open={open}
            />
          </>
        </Modal>
        {recorderDrawerOpen && (
          <RecorderModal
            recorderDrawerOpen={recorderDrawerOpen}
            setRecorderDrawerOpen={setRecorderDrawerOpen}
            numberOfAudio={numberOfAudio}
            setNumberOfAudio={setNumberOfAudio}
            handleUpdateAudioList={handleUpdateAudioList}
            categoryValue={categoryValue}
          />
        )}



        {width < 700 && <AppBar className="navbar-login-button add-button" component="nav">
          {loading ? (
            <StyledButton
              color="primary"
              loading={true}
              variant="contained"
              fullWidth
              type="submit"
            ></StyledButton>
          ) : (
            <Button
              onClick={() => handleSubmit()}
              className="addEventBtn"
              variant="contained"
            >
              {t("Save Changes")}
            </Button>
          )}

        </AppBar>}
      </Container>

    </>
  );
}
