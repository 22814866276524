import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from 'notistack';
import { websiteIstance } from "../../../../api/axios";
import { QUERY_TIMELINES } from "../../../../api/endpoints";
import TimeLineForm from "../TimeLineDeleteForm/TimeLineForm";
import Enum from "./Enum";
import { removeTimeLine } from "redux/slices/myTimeLinesSlice";
import { useDispatch, useSelector } from 'react-redux';
import { clearMemories } from "redux/slices/familyMemoriesSlice";

const UpdateTimeLine = ({ handleClose, refetch, item, cb }) => {
    const { t } = useTranslation(["timeline"]);
    const [loading, setLoading] = useState("");
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();


    const unRegisteredDeleteTimeline = (values) => {
        setLoading(Enum.UPDATE)

        dispatch(removeTimeLine(item.id))
        dispatch(clearMemories())
        enqueueSnackbar(t("Timeline deleted successfuly"), { variant: Enum.success })
        handleClose()
        setLoading("")
        cb()
        refetch?.()

    }

    const onSubmit = async (values) => {
        try {
            if(window.isAuth) {
                setLoading(Enum.DELETE)
                await websiteIstance.request({
                    method: 'delete',               
                    url: QUERY_TIMELINES.replace("?", `/${item.id}`)
                })
                dispatch(removeTimeLine(item.id))
    
                enqueueSnackbar(t("Timeline deleted successfuly"), { variant: Enum.success })
                handleClose()
                setLoading("")
                // navigate(`/my-timelines`)
                cb()
                refetch?.()
            } else {
                unRegisteredDeleteTimeline(item)
            }
        } catch (error) {
            enqueueSnackbar(t(error.message || "TimelineUpdateError"), { variant: Enum.error })
            setLoading("")
        }
    }

    return (
        <TimeLineForm {...item} deleteTimeLineConfirm={onSubmit} handleClose={handleClose}  loading={loading === Enum.DELETE} onSubmit={onSubmit} />
    )
}

export default UpdateTimeLine;