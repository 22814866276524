import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
   isLoginRegisterModalOpen: false
}

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoginRegisterModalOpen(state, { payload }) {
            state.isLoginRegisterModalOpen = payload;           
        }       
    },
})

export const { setLoginRegisterModalOpen } = appSlice.actions
export default appSlice.reducer