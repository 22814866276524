import { v4 as uuidv4 } from 'uuid';
import { websiteIstance } from "../api/axios";
import axios from "axios";
import { ANANLYTICS } from "../api/endpoints";

const SESSION_ID = "SessionId";

class Analytics {
    constructor() {
        this.userId = null;
        this.ipAddress = null;
    }
    getUserId = () => {
        this.userId = localStorage.getItem("userId");
    }
    static storeSessionId = () => {
        const NewSessionId = uuidv4();
        const SessionId = this.getSessionId();
        if(!SessionId)
        localStorage.setItem(SESSION_ID, NewSessionId);
    }
    static getIpAddress = async () => {
        const res = await axios.get("https://geolocation-db.com/json/");
        this.ipAddress = res?.data?.IPv4;
    }

    static getSessionId = () => {        
        const SessionId = localStorage.getItem(SESSION_ID);
        return SessionId;
    }

    static removeSessionId = () => {
        localStorage.removeItem(SESSION_ID)
    }

    static sendSessionIdToServer = async() => {
        const sessionId =  this.getSessionId();
        // await this.getIpAddress();
        // await websiteIstance.post(
        //     ANANLYTICS, {
        //         sessionId,
        //         userId: this.userId,
        //         ipAddress: this.ipAddress
        //     }
        // );
    }

    static sendLangAnalytics = async() => {
        const Lang = localStorage.getItem("lang");
        // await this.getIpAddress();
        // await websiteIstance.post(
        //     ANANLYTICS, {
        //         sessionId: this.getSessionId(),
        //         userId: this.userId,
        //         ipAddress: this.ipAddress,
        //         lang: Lang
        //     }
        // );
    }





}

export default Analytics;