import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonTranslationEN from "./locale/en/common.json";
import homeTranslationEN from "./locale/en/home.json";
import familyMemoriesTranslationEN from "./locale/en/familyMemories.json";
import addEventTranslationEN from "./locale/en/add-event.json";
import signupTranslationEN from "./locale/en/signup.json";
import loginTranslationEN from "./locale/en/login.json";
import timelineEN from "./locale/en/timeline.json";
import addEventSuccessEN from "./locale/en/add-event-success.json";

import commonTranslationAR from "./locale/ar/common.json";
import homeTranslationAR from "./locale/ar/home.json";
import familyMemoriesTranslationAR from "./locale/ar/familyMemories.json";
import addEventTranslationAR from "./locale/ar/add-event.json";
import signupTranslationAR from "./locale/ar/signup.json";
import loginTranslationAR from "./locale/ar/login.json";
import timelineAR from "./locale/ar/timeline.json";
import addEventSuccessAR from "./locale/ar/add-event-success.json";

import commonTranslationFR from "./locale/fr/common.json";
import homeTranslationFR from "./locale/fr/home.json";
import familyMemoriesTranslationFR from "./locale/fr/familyMemories.json";
import addEventTranslationFR from "./locale/fr/add-event.json";
import signupTranslationFR from "./locale/fr/signup.json";
import loginTranslationFR from "./locale/fr/login.json";
import timelineFR from "./locale/fr/timeline.json";
import addEventSuccessFR from "./locale/fr/add-event-success.json";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  supportedLngs: ["ar", "en", 'fr'],
  resources: {
    en: {
      common: commonTranslationEN,
      home: homeTranslationEN,
      memories: familyMemoriesTranslationEN,
      "add-event": addEventTranslationEN,
      signup: signupTranslationEN,
      login: loginTranslationEN,
      timeline: timelineEN,
      addEventSuccess: addEventSuccessEN

    },
    ar: {
      common: commonTranslationAR,
      home: homeTranslationAR,
      memories: familyMemoriesTranslationAR,
      "add-event": addEventTranslationAR,
      signup: signupTranslationAR,
      login: loginTranslationAR,
      timeline: timelineAR,
      addEventSuccess: addEventSuccessAR
    },
    fr: {
      common: commonTranslationFR,
      home: homeTranslationFR,
      memories: familyMemoriesTranslationFR,
      "add-event": addEventTranslationFR,
      signup: signupTranslationFR,
      login: loginTranslationFR,
      timeline: timelineFR,
      addEventSuccess: addEventSuccessFR
    },
  },
  defaultNS: "common",
  debug: true,
});

export default i18n;
