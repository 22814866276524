import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import { LOGIN } from "api/endpoints";
import UserContext from "context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import WhiteCross from "assets/icons/white-cross.svg";
import { websiteIstance, websiteIstanceFormData } from "api/axios";
import { Login as LoginByFS } from "../../helpers/auth";
import "./style.css";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [values, setValues] = useState({ username: null, password: null });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { t } = useTranslation(["login"]);
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const {
        data: { data: userData },
      } = await websiteIstance.post(LOGIN, values);

      setUser(userData);
      localStorage.setItem("accessToken", userData.accessToken);
      localStorage.setItem("userId", userData.id);
      window.isAuth = true;
      if (userData.accessToken){
        websiteIstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.accessToken}`;
        websiteIstanceFormData.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${userData.accessToken}`;
      }
        if (
        location?.state?.from &&
        location?.state?.from.includes("event-added-successfully")
      ) {
        setTimeout(() => {
          navigate("/my-timelines", {
            state: {
              from: null,
            },
            replace: true
          });
        }, 3000);

        
      } else if (location?.state?.from &&
        location?.state?.action === "create_new_modal") {
        navigate("/my-timelines", {
          state: {
            from: null,
            action: location?.state?.action
          },
          replace: true
        });

        } else if (location?.state && location?.state?.from) {
          navigate(location.state.from)
        } else {
        setTimeout(() => {
          navigate(location?.state?.from ?? "/", {
            state: {
              ...location?.state,
              from: null,
            },
            replace: true
          });
        }, 3000);
        
      }
    } catch (error) {
      // setErrorMessage(error.response.data.message);
      setSnackbarOpen(true);
      localStorage.removeItem("accessToken");
    } finally {
    }
  };



  return (
    <Container className="loginContainer">
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
      
        <form onSubmit={onSubmit}>
         
          <button type="button" onClick={LoginByFS} className="loginBtn">
            {t("Login By Family Search")}
          </button>
          <div className="registerText">{t("Don’t have an account?")}</div>
          <button
            onClick={LoginByFS}
            className="registerBtn"
          >
            {t("Register")}
          </button>
        </form>
      </div>
      <div className="termText">{t("Privacy Notice")}</div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        className="login-alert-message-container"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          icon={false}
          style={{
            background: "#C32B26",
            minHeight: "66px",
            padding: "8px 15px",
            color: "#FBFBFB",
            fontSize: "14px",
          }}
          severity="error"
          sx={{ width: "100%" }}
          className="login-alert-message"
        >
          <img alt="ee" className="login-alert-message-icon" src={WhiteCross} />
          <div>
            {t("Incorrect Username or password")}
          </div>
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
