import React from "react";
import Slider from "react-slick";
import "./style.css"

const CenterMode = ({ sliderRef, children, numberOfDots }) => {

        const settings = {
            centerMode: true,
            centerPadding: "1px",
            slidesToShow: numberOfDots,
            slideToScroll: numberOfDots,
            Infinity: false,
            speed: 300,
            cssEase: "linear",
            dots: false,
            arrows: false,

            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: numberOfDots,
                        focusOnSelect: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: '0px',
                        slidesToShow: numberOfDots,
                        focusOnSelect: true,
                    }
                }
            ],
        }
        return (
            <div className="dots-wrapper" style={{
                width: 100,
                margin: 'auto'
            }}>
                <Slider ref={sliderRef} {...settings}>
                    {children}                   
                </Slider>
            </div>
        );
}

export default CenterMode;