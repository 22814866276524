import { useState } from "react";
import { useTranslation } from "react-i18next";
import { setInvitations } from "redux/slices/familyMemoriesSlice";
import Divider from '@mui/material/Divider';
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { TextField, Select, MenuItem, FormControl, InputAdornment } from '@mui/material';
// import { TextField, InputAdornment, Select, MenuItem } from '@mui/material';

import CustomTypography from "components/CustomTypography/CustomTypography";
import ReusableDialog from "components/ReusableDialog/ReusableDialog";
import StyledButton from "components/StyledButton/StyledButton";
import useDialog from "hooks/useDialog";
import Enum from "./Enum";
import Invitations from "./Invitations";
import { INVITE, DELETE_INVITATION, INVITATIONS } from "api/endpoints";
import { websiteIstance } from "api/axios";

import "./style.css";
import { useEffect } from "react";


const InviteFriends = ({ timelineId }) => {
    const { t } = useTranslation(["memories"]); 
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const invitedUsersList = useSelector((state) => state.familyMemories.invitedUsersList);
    const [openDialog, dialogProps] = useDialog();
    const [loading, setLoading] = useState("");
    const [selectedId, setSelectedId] = useState(null);
    const [role, setRole] = useState('Viewer');

    const INITIAL_VALUES = {
        email: ""
    }
    const validationSchema = Yup.object({
        email: Yup.string().email("Please enter a valid email").required("Email required")
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        defaultValues: INITIAL_VALUES
    };
    const { register, handleSubmit, formState, reset } = useForm(formOptions);
    const { errors } = formState;

    const fetchInvitations = async () => {
        const res = await websiteIstance.get(
            INVITATIONS.replace(":timelineId", timelineId)
        );
        dispatch(setInvitations(res?.data?.data))
    }

    const onSubmit = async(values) => {
        try {
            // console.log("values", values);
            setLoading(Enum.INVITE_NEW_MEMBER)
            await websiteIstance.post(
                INVITE.replace(":timelineId", timelineId),
                { recipientEmail: values.email, role: role.toUpperCase() }
            );
            await fetchInvitations()
            enqueueSnackbar(t("Invitation sent successfuly"), { variant: Enum.success })
            setLoading("")
            reset(INITIAL_VALUES)      
        } catch (error) {
            setLoading("")
            enqueueSnackbar(t(error.message), { variant: Enum.error })

        }
    }


    useEffect(() => {
        fetchInvitations()
    },[])

    const onDeleteInvitedUserConfirm = async() => {
        setLoading(Enum.DELETE_MEMBER)
        await websiteIstance.delete(
            DELETE_INVITATION.replace(":timelineId", timelineId).replace(":id", selectedId)
        );
        fetchInvitations()
        setLoading("")
        setSelectedId(null)
        dialogProps.handleClose()
        enqueueSnackbar(t("Invitation deleted successfuly"), { variant: Enum.success })
   

    }
    const onDeleteInvitedUser = async(e) => {
        const id = e.currentTarget.id;
        setSelectedId(id);
        openDialog()
    }
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    return (
        <div className="invite-friends-wrapper">
            <ReusableDialog handleAccept={onDeleteInvitedUserConfirm} loading={loading === Enum.DELETE_MEMBER} {...dialogProps}  title={t("Confirm Delete")} />                
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container className="invitations-container">
                    <CustomTypography className="invite-friends-wrapper--headline"  type="headline">
                        {t("Invite Others")}
                    </CustomTypography>
                    <CustomTypography type="normal">
                        {t("IvitationHeadParagraph")}
                    </CustomTypography>
                    <div className="invite-friends--email-wrapper">
                        <label htmlFor="invite-friends--email-input">
                            {t("Email Address")}
                        </label>
                        <FormControl>
                            <TextField
                                {...register("email")}
                                placeholder="example@example.com" id="invite-friends--email-input" className="invite-friends--email-input"
                                type="email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Select  className="contributer-role" value={role} onChange={handleRoleChange}>
                                                <MenuItem className="contributer-role-item" value="Viewer">{t("Viewer")}</MenuItem>
                                                <MenuItem className="contributer-role-item" value="Editor">{t("Editor")}</MenuItem>
                                            </Select>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {errors.email && <CustomTypography type="error">
                                {t(errors.email.message)}
                            </CustomTypography>}
                        </FormControl>
                        {/* <FormControl>
                            <InputLabel>Role</InputLabel>
                            <Select value={role} onChange={handleRoleChange}>
                                <MenuItem value="read">Read</MenuItem>
                                <MenuItem value="write">Write</MenuItem>
                            </Select>
                        </FormControl> */}
                        {/* <input {...register("email")} placeholder="example@example.com" id="invite-friends--email-input" className="invite-friends--email-input" /> */}
                        {/* {errors.email && <CustomTypography type="error">
                                {t(errors.email.message)}
                        </CustomTypography>} */}
                    </div>
                    <div className="invite-friends--submit-btn-wrapper">
                        <StyledButton
                            color="primary"
                            loading={loading === Enum.INVITE_NEW_MEMBER}
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            <CustomTypography type="subHeadLine">
                                {t("Send")}
                            </CustomTypography>
                        </StyledButton>
                    </div>
                </Container>
                <Divider className="invite-friends--divider" />
                <Container className="invitations-container">
                    <CustomTypography className="invite-friends-wrapper--headline"  type="subHeadLine">
                        {t("Invitations Sent")}
                    </CustomTypography>
                    <div className="invite-friends--invited-users-list"> 
                        {Array.isArray(invitedUsersList) && !!invitedUsersList.length || true ? <Invitations onDeleteInvitedUser={onDeleteInvitedUser} timelineId={timelineId} invitations={invitedUsersList} />                                          
                            : <CustomTypography align="center" type="normal">
                                {t("No Members Invited")}
                            </CustomTypography>}                   
                    </div>
                  
                </Container>
            </form>
        </div>
    )
}

export default InviteFriends;