
const Warning = ({color}) => {
    return (
        <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.0992 40.3961L32.2034 2.42516C31.3014 0.929289 29.6559 0 27.9091 0C26.1623 0 24.5168 0.929289 23.6147 2.42527L0.719036 40.3961C-0.213195 41.9422 -0.240778 43.8793 0.646974 45.4513C1.53494 47.0234 3.20797 48 5.01334 48H50.8049C52.6103 48 54.2834 47.0234 55.1714 45.4512C56.0591 43.879 56.0315 41.942 55.0992 40.3961ZM52.0069 43.6643C51.7625 44.097 51.3019 44.3659 50.8049 44.3659H5.01334C4.51632 44.3659 4.05571 44.0971 3.81139 43.6644C3.56697 43.2316 3.5746 42.6982 3.83113 42.2727L26.727 4.30183C26.9754 3.89006 27.4283 3.63419 27.9092 3.63419C28.39 3.63419 28.843 3.89006 29.0913 4.30183L51.987 42.2727C52.2437 42.6983 52.2514 43.2316 52.0069 43.6643Z" fill="#C32B26" />
            <path d="M27.9261 14.9495C26.5437 14.9495 25.4648 15.6912 25.4648 17.0062C25.4648 21.0184 25.9368 26.7838 25.9368 30.7961C25.9369 31.8413 26.8473 32.2795 27.9262 32.2795C28.7353 32.2795 29.8817 31.8413 29.8817 30.7961C29.8817 26.7839 30.3536 21.0185 30.3536 17.0062C30.3536 15.6913 29.2411 14.9495 27.9261 14.9495Z" fill="#C32B26" />
            <path d="M27.9597 34.6116C26.4763 34.6116 25.3635 35.7916 25.3635 37.2078C25.3635 38.5902 26.4762 39.804 27.9597 39.804C29.3421 39.804 30.5223 38.5902 30.5223 37.2078C30.5223 35.7916 29.342 34.6116 27.9597 34.6116Z" fill="#C32B26" />
        </svg>


    )
}

export default Warning;