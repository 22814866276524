import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import StyledButton from "components/StyledButton/StyledButton";
import { useTranslation } from "react-i18next";

import "./style.css";

export default function MediaCard({ image, alt, title, onClick, onClickSecondButton, buttonText, secondButtonText, isCSS, htmlImage }) {
    const { t } = useTranslation(["home"]);
    return (
        <Card className='media-card'>
            <h3>
                {title}
            </h3>
            <div className='media-card-image-wrapper'>
                {isCSS ?
                    htmlImage
                    : <CardMedia
                        className='media-card--image'
                        height="196"
                        component="img"
                        image={image}
                        alt={alt || ""}
                    />
                }
            </div>
            <div className='media-card--content-actions-wrapper'>
                <CardActions className='media-card--actions'>
                    <StyledButton onClick={onClick} className='media-card--actions---btn' variant="contained">{buttonText ?? t("start now")}</StyledButton>
                </CardActions>
                {secondButtonText && <CardActions className='media-card--actions'>
                    <StyledButton onClick={onClickSecondButton} style={{ fontWeight: 400 }} className='media-card--actions---btn media-card--actions---second-btn' variant="contained">{secondButtonText}</StyledButton>
                </CardActions>}
            </div>
        </Card>
    );
}
