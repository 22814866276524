
const Delete = ({color}) => {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.75 2.58333V0.75H6.25V2.58333H0.75V4.41667H17.25V2.58333H11.75ZM4.41667 7.16667H13.5833V16.3333H4.41667V7.16667ZM2.58333 16.3333C2.58333 16.8196 2.77649 17.2859 3.1203 17.6297C3.46412 17.9735 3.93044 18.1667 4.41667 18.1667H13.5833C14.0696 18.1667 14.5359 17.9735 14.8797 17.6297C15.2235 17.2859 15.4167 16.8196 15.4167 16.3333V5.33333H2.58333V16.3333ZM6.25 14.5H8.08333V9H6.25V14.5ZM9.91667 14.5H11.75V9H9.91667V14.5Z" fill={color || "#C32B26"} />
        </svg>

    )
}



export const DeleteInvitation = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#C32B26" fill-opacity="0.1"/>
            <path d="M17.444 8.36328C17.2377 8.36328 17.0399 8.4399 16.894 8.57628C16.7482 8.71267 16.6662 8.89764 16.6662 9.09051V17.229C16.6439 17.5967 16.4671 17.9413 16.1743 18.1878C15.8815 18.4342 15.4964 18.5624 15.1029 18.5445H8.89623C8.50275 18.5624 8.11766 18.4342 7.82485 18.1878C7.53203 17.9413 7.35521 17.5967 7.3329 17.229V9.09051C7.3329 8.89764 7.25095 8.71267 7.10509 8.57628C6.95923 8.4399 6.7614 8.36328 6.55512 8.36328C6.34884 8.36328 6.15101 8.4399 6.00515 8.57628C5.85929 8.71267 5.77734 8.89764 5.77734 9.09051V17.229C5.79954 17.9826 6.14024 18.6972 6.72482 19.2164C7.30939 19.7356 8.0902 20.017 8.89623 19.999H15.1029C15.9089 20.017 16.6897 19.7356 17.2743 19.2164C17.8589 18.6972 18.1996 17.9826 18.2218 17.229V9.09051C18.2218 8.89764 18.1398 8.71267 17.994 8.57628C17.8481 8.4399 17.6503 8.36328 17.444 8.36328Z" fill="#C32B26"/>
            <path d="M18.2222 6.1817H15.1111V4.72723C15.1111 4.53436 15.0292 4.34938 14.8833 4.213C14.7374 4.07662 14.5396 4 14.3333 4H9.66667C9.46039 4 9.26256 4.07662 9.11669 4.213C8.97083 4.34938 8.88889 4.53436 8.88889 4.72723V6.1817H5.77778C5.5715 6.1817 5.37367 6.25832 5.22781 6.3947C5.08194 6.53108 5 6.71606 5 6.90893C5 7.10181 5.08194 7.28678 5.22781 7.42316C5.37367 7.55954 5.5715 7.63616 5.77778 7.63616H18.2222C18.4285 7.63616 18.6263 7.55954 18.7722 7.42316C18.9181 7.28678 19 7.10181 19 6.90893C19 6.71606 18.9181 6.53108 18.7722 6.3947C18.6263 6.25832 18.4285 6.1817 18.2222 6.1817ZM10.4444 6.1817V5.45447H13.5556V6.1817H10.4444Z" fill="#C32B26"/>
            <path d="M11.2235 15.6357V10.5451C11.2235 10.3522 11.1416 10.1673 10.9957 10.0309C10.8499 9.89449 10.652 9.81787 10.4457 9.81787C10.2395 9.81787 10.0416 9.89449 9.89578 10.0309C9.74991 10.1673 9.66797 10.3522 9.66797 10.5451V15.6357C9.66797 15.8286 9.74991 16.0136 9.89578 16.15C10.0416 16.2863 10.2395 16.363 10.4457 16.363C10.652 16.363 10.8499 16.2863 10.9957 16.15C11.1416 16.0136 11.2235 15.8286 11.2235 15.6357Z" fill="#C32B26"/>
            <path d="M14.329 15.6357V10.5451C14.329 10.3522 14.2471 10.1673 14.1012 10.0309C13.9553 9.89449 13.7575 9.81787 13.5512 9.81787C13.3449 9.81787 13.1471 9.89449 13.0012 10.0309C12.8554 10.1673 12.7734 10.3522 12.7734 10.5451V15.6357C12.7734 15.8286 12.8554 16.0136 13.0012 16.15C13.1471 16.2863 13.3449 16.363 13.5512 16.363C13.7575 16.363 13.9553 16.2863 14.1012 16.15C14.2471 16.0136 14.329 15.8286 14.329 15.6357Z" fill="#C32B26"/>
        </svg>

)
}

export default Delete;