import { useState, useEffect } from "react";
import MyTimelineHotActions from "./components/MyTimelineHotActions/MyTimelineHotActions";
import { websiteIstance } from "../../api/axios";
import { useTranslation } from "react-i18next";
import TimeLinesList from "components/TimeLinesList/TimeLinesList";
import TimeLineCard from "components/TimeLineCard/TimeLineCard";
import CreateNewTimeLine from "./components/CreateNewTimeLine/CreateNewTimeLine"
import UpdateTimeline from "./components/UpdateTimeLine/UpdateTimeline"
import DeleteTimeline from "./components/DeleteTimeLine/DeleteTimeline"
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import Filters from "components/Filters/Filters";
import { useDispatch, useSelector } from 'react-redux';
import { setMyTimelines } from "redux/slices/myTimeLinesSlice";
import { MYTIMELINES } from "../../api/endpoints";
import { setLoginRegisterModalOpen } from "redux/slices/appSlice";
import { useParams, useLocation, useNavigate } from "react-router-dom";

class Enum {
    static FETCH_TIME_LINES = "FETCH_TIME_LINES";
    static FETCH_MORE_TIME_LINES = "FETCH_MORE_TIME_LINES";
}

export default function MyTimeLine() {
    const [isGrid, setIsGrid] = useState(true);
    const [isCreateNewTimelineOpen, setIsCreateNewTimelineOpen] = useState(false);
    const [isDeleteTimelineOpen, setIsDeleteTimelineOpen] = useState(false);
    const [isUpdateTimelineOpen, setIsUpdateTimelineOpen] = useState(false);
    const [selectedTimeline, setSelectedTimeline] = useState({})
    const [isFiltersOpen, setIsFiltersOpenOpen] = useState(false);
    const [loading, setLoading] = useState("")
    const { t } = useTranslation(["timeline"]);
    const dispatch = useDispatch();
    const myTimeLinesInfo = useSelector((state) => state.myTimeLines.data);
    const { timelines: data, totalPages, page: currentPage } = myTimeLinesInfo
    const location = useLocation()
    const navigate = useNavigate()
    const headline = t("My Timelines");



    async function fetchData(page) {
       try {
           const userId = localStorage.getItem("userId")
           if (!userId) return;
           setLoading(page ? Enum.FETCH_MORE_TIME_LINES : Enum.FETCH_TIME_LINES);
           const response = await websiteIstance.request({
               method: "get",
               url: page ? MYTIMELINES.replace(":userId", `${userId}`).replace("?", `?page=${page}`) : MYTIMELINES.replace(":userId", `${userId}`).replace("?", `?page=0`)
           });
           const data = response?.data?.data;
           dispatch(setMyTimelines(data))       
           setLoading("")
       } catch (error) {
           setLoading("")
       }
    }

    const onLayoutChange = (isTypeGrid) => {
        setIsGrid(isTypeGrid)
    }

    const createNewTimelineModalToggle = () => {
        if (data.length >= 1 && !window.isAuth) {
            dispatch(setLoginRegisterModalOpen(true))
        } else {
            setIsCreateNewTimelineOpen(prevValue => !prevValue)
        }
    }
    const createNewTimelineModalClose = () => {
        setIsCreateNewTimelineOpen(false)
    }

    const onUpdate = (e, item) => {
        e.preventDefault();
        setSelectedTimeline(item);
        setIsUpdateTimelineOpen(true);
    }
    const onDelete = (id) => {
        setIsDeleteTimelineOpen(true);
    }
    const onCloseUpdateTimeline = () => {
        setSelectedTimeline({});
        setIsUpdateTimelineOpen(false);
    }
    const onCloseDeleteTimeline = () => {
        setIsDeleteTimelineOpen(false);
    }

    useEffect(() => {
        if (location?.state?.action === "create_new_modal" && !localStorage.getItem("isRedirected")) {
            setLoading(Enum.FETCH_TIME_LINES);
            setTimeout(() => {
                    const timeline_id = localStorage.getItem("timelineId")
                    if (timeline_id){
                        localStorage.setItem("isRedirected", "true")
                        localStorage.removeItem("timelineId")
                        navigate(`/timelines/${timeline_id}`)

                    }

                    setLoading("")
            }, 2000);
        }
    }, [])

    const renderItem = (item, idx) => {
        return (
            <TimeLineCard 
                isMyTimeLines={true}
                thumbnail={item.thumbnail} 
                countOfEvents={item.events.length ? item.events.length : localStorage.getItem('localEventsCounter')}
                isGrid={isGrid}        
                key={item.id + "_" + idx} 
                path={`/timelines/${item.id}`} 
                title={item.title} 
                createdAt={item.createdAt}
                isUpdateble={true}
                onUpdate={(e) => onUpdate(e, item)}
            />        
        )
    }

    const filtersToggle = () => {
        setIsFiltersOpenOpen(prevValue => !prevValue)
    }
    return (
        <>
            <BottomSheet handleClose={filtersToggle} open={isFiltersOpen}>
                <Filters onClose={filtersToggle} url={window.location.href} />
            </BottomSheet>
            <BottomSheet handleClose={createNewTimelineModalClose} open={isCreateNewTimelineOpen}>
                <CreateNewTimeLine disabled={data.length >= 2 && !window.isAuth} handleClose={createNewTimelineModalClose} />
            </BottomSheet>
            <BottomSheet handleClose={onCloseUpdateTimeline} open={isUpdateTimelineOpen}>
                <UpdateTimeline refetch={fetchData} onDelete={onDelete} item={selectedTimeline} handleClose={onCloseUpdateTimeline} />
            </BottomSheet>
            <BottomSheet handleClose={onCloseDeleteTimeline} open={isDeleteTimelineOpen}>
                <DeleteTimeline cb={()=> {onCloseUpdateTimeline()}} refetch={fetchData} item={selectedTimeline} handleClose={onCloseDeleteTimeline} />
            </BottomSheet>
            <TimeLinesList 
                onCreate={createNewTimelineModalToggle} 
                headline={headline} 
                isGrid={isGrid} 
                isLoading={loading === Enum.FETCH_TIME_LINES} 
                onLayoutChange={onLayoutChange} 
                totalPages={totalPages} 
                currentPage={currentPage} 
                fetch={fetchData} 
                data={data} 
                renderItem={renderItem}
                onFilter={filtersToggle}
                isMyTimeLines={true}
            />

            {!!data.length && <MyTimelineHotActions onCreate={createNewTimelineModalToggle} />}
        </>
    )
}