

const Back = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="4" fill="#F3F3F3" />
            <path d="M17.4147 21.723L11.2028 15.5171C11.129 15.4433 11.0769 15.3632 11.0465 15.277C11.0155 15.1909 11 15.0985 11 15C11 14.9015 11.0155 14.8091 11.0465 14.723C11.0769 14.6368 11.129 14.5567 11.2028 14.4828L17.4147 8.25858C17.5868 8.08619 17.8018 8 18.0599 8C18.318 8 18.5392 8.09235 18.7235 8.27704C18.9078 8.46174 19 8.67722 19 8.92348C19 9.16974 18.9078 9.38522 18.7235 9.56992L13.3041 15L18.7235 20.4301C18.8955 20.6025 18.9816 20.8147 18.9816 21.0669C18.9816 21.3196 18.8894 21.5383 18.7051 21.723C18.5207 21.9077 18.3057 22 18.0599 22C17.8141 22 17.5991 21.9077 17.4147 21.723Z" fill="black" />
        </svg>
    )
}

export default Back;