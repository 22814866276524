import { useEffect, useState } from "react";
import i18n from "i18n";
import I18nContext from "context/I18nContext";

const defaultLanguageInfo = {
  lang: localStorage.getItem("lang") || window.navigator.language.substring(0,window.navigator.language.indexOf("-"))
 || "en",
  dir:
    localStorage.getItem("lang") === "en" || !localStorage.getItem("lang") || localStorage.getItem("lang") === "fr"
      ? "ltr"
      : "rtl",
};

export default function I18nProvider({ children }) {
  const [languageInfo, setLanguageInfo] = useState(defaultLanguageInfo);

  const setLanguage = (languageCode) => {
    setLanguageInfo({
      lang: languageCode,
      dir: languageCode === "ar" ? "rtl" : "ltr",
    });
  };

  useEffect(() => {
    i18n.changeLanguage(languageInfo.lang);
    document.body.dir = languageInfo.dir;
    document.documentElement.setAttribute("lang", languageInfo.lang);
    localStorage.setItem("lang", languageInfo.lang);
  }, [languageInfo]);

  return (
    <I18nContext.Provider
      value={{
        language: languageInfo,
        setLanguage,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
}
