// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-wrapper {
    padding: 15px;
}

.privacy-policy-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.privacy-policy-actions {
    margin-top: 30px;
}

.privacy-policy-actions button {
    font-size: 16px !important;
}

.privacy-policy-content .privacy-policy-content--p {
    font-size: 14px;
    color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".privacy-policy-wrapper {\n    padding: 15px;\n}\n\n.privacy-policy-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 15px;\n}\n\n.privacy-policy-actions {\n    margin-top: 30px;\n}\n\n.privacy-policy-actions button {\n    font-size: 16px !important;\n}\n\n.privacy-policy-content .privacy-policy-content--p {\n    font-size: 14px;\n    color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
