import { useTranslation } from "react-i18next";

import StyledButton from "components/StyledButton/StyledButton";
import CloseIcon from "components/Icons/Close";
import { useNavigate, useLocation } from "react-router-dom";
import { Signup, Login } from "../../helpers/auth";

import "./style.css";


const LoginRegisterModal = ({ handleClose }) => {
    const { t } = useTranslation(["common"]); 
    const navigate = useNavigate();

    const handleLogin = () => {
        handleClose()
        Login()
    }

    const handleRegister = () => {
        handleClose()
        Signup()
    }

    return (
        <div className="login-register-wrapper">
            <div className="login-register-header-wrapper">
                <div className="login-register-header">
                    <div className="login-register-header-title">
                        {t("Login or Create an Account")}
                    </div>
                    <div role="button" onClick={handleClose} className="login-register-header-close-btn">
                        <CloseIcon />
                    </div>
                </div>
                <div className="login-register-header-description">
                    {t("loginregistersubheading")}
                </div>
            </div>
            <div className="login-register-actions-wrapper">
                <StyledButton onClick={handleLogin} variant="outlined">
                    {t("Login now")}
                </StyledButton>
                <StyledButton onClick={handleRegister} variant={"contained"} color="primary">
                    {t("Create Account")}
                </StyledButton>
            </div>
        </div>
    )
}

export default LoginRegisterModal;