// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .reusable-modal-root {
        background: rgb(255, 255, 255);
        min-height: 20%;
        width: 90%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;     
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 700px) {
        .reusable-modal-root {
                background: rgb(255, 255, 255);
                min-height: 20%;
                width: 500px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
    }`, "",{"version":3,"sources":["webpack://./src/components/Modals/ReusableModal/style.css"],"names":[],"mappings":";IACI;QACI,8BAA8B;QAC9B,eAAe;QACf,UAAU;QACV,2BAA2B;QAC3B,4BAA4B;QAC5B,8BAA8B;QAC9B,+BAA+B;QAC/B,kBAAkB;QAClB,SAAS;QACT,QAAQ;QACR,gCAAgC;IACpC;;IAEA;QACI;gBACQ,8BAA8B;gBAC9B,eAAe;gBACf,YAAY;gBACZ,2BAA2B;gBAC3B,4BAA4B;gBAC5B,8BAA8B;gBAC9B,+BAA+B;gBAC/B,kBAAkB;gBAClB,SAAS;gBACT,QAAQ;gBACR,gCAAgC;YACpC;IACR","sourcesContent":["\n    .reusable-modal-root {\n        background: rgb(255, 255, 255);\n        min-height: 20%;\n        width: 90%;\n        border-top-left-radius: 4px;\n        border-top-right-radius: 4px;\n        border-bottom-left-radius: 4px;\n        border-bottom-right-radius: 4px;     \n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n    }\n\n    @media (min-width: 700px) {\n        .reusable-modal-root {\n                background: rgb(255, 255, 255);\n                min-height: 20%;\n                width: 500px;\n                border-top-left-radius: 4px;\n                border-top-right-radius: 4px;\n                border-bottom-left-radius: 4px;\n                border-bottom-right-radius: 4px;\n                position: absolute;\n                left: 50%;\n                top: 50%;\n                transform: translate(-50%, -50%);\n            }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
