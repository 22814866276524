import { useEffect, useState } from "react";
import GOOGLE from "./google";
import useScript from "./useScript";
import useIsMounted from "./useIsMounted";
import { gapi } from "gapi-script"
function useGoogle() {
    const loaded = useScript("https://apis.google.com/js/api.js");
    const [token, setToken] = useState("");
    const mounted = useIsMounted();

    useEffect(() => {
        load()
                             
    }, [mounted, loaded]);
    async function load(cb) {
        function update(google) {
            setToken(google ? google.getAuthResponse().access_token : "");
            if (cb){
                cb(google ? google.getAuthResponse().access_token : "")
            }
        }
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: 'https://www.googleapis.com/auth/drive.file',
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                discoveryDocs: GOOGLE.DISCOVERY_DOCS
            }).then(() => {
                // Listen for sign-in state changes.
                // gapi.auth2.getAuthInstance().isSignedIn.listen(update);
                const instance = gapi.auth2.getAuthInstance();
                instance.currentUser.listen(update);
                update(instance.currentUser.get());
            })
        }
        gapi.load('client:auth2', start);
    }

    async function authorize() {
        await gapi.auth2.getAuthInstance().signIn();
    }

    async function revoke() {
        await gapi.auth2.getAuthInstance().disconnect();
    }

    return { token, authorize, revoke, load };
}

export default useGoogle;
