import { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import ImageGallery from "./components/ImageGallery";
import useGetWidth from "hooks/useUpdateWindowDimensions";
import Back from "assets/images/svg/back.svg";
import Edit from "assets/images/svg/edit.svg";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MemoryStoryModal from "components/Modals/MemoryStoryModal/MemoryStoryModal";
import MemoryStory from "./components/MemoryStory/MemoryStory";
import AudioPlayerComponent from "./components/AudioPlayerComponent";
import "./style.css";
import "react-h5-audio-player/lib/styles.css";
import { websiteIstance } from "api/axios";
import { useDispatch, useSelector } from 'react-redux';
import mapkeys from "lodash/mapKeys";
import UpdateEventModal from "./components/UpdateEventModal/UpdateEventModal";
import EventDetailsImageGallery from "./components/EventDetailsImageGallery/EventDetailsImageGallery";

const EventDetails = () => {
  const [loading, setLoading] = useState(true);
  const [eventData, setEventData] = useState({});
  const [isMemoryStoryModalOpen, SetIsMemoryStoryModalOpen] = useState(false);
  const [showList, setShowList] = useState(false);
  const [currentTrack, setTrackIndex] = useState(0);
  const [isPlay, setIsPlay] = useState(false);
  const [isOpdateEventOpen, setUpdateEventOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [audioList, setAudioList] = useState([]);
  const audioPlayerRef = useRef();
  const width = useGetWidth();
  const params = useParams();
  const [creatorId, setCreatorId] = useState(null);
  const [isContributor, setIsContributor] = useState(false);

  const familyMemories = useSelector((state) => state.familyMemories.familyMemories);
  const userId = +localStorage.getItem("userId")
console.log(userId)
  const { id, timelineId } = params;
  function memoryStoryModalToggle() {
    SetIsMemoryStoryModalOpen((prevValue) => !prevValue);
  }

  const getOfflineData = () => {
    const timeline = familyMemories?.[timelineId] ?? {}
    const events = timeline?.events ?? [];
    const eventDictionary = mapkeys(events, "id");
    const event = eventDictionary[id]
    if (event?.creator_id) setCreatorId(event.creator_id)
    if (!event) return
    const {
      title,
      date,
      details,
      tags,
      files: { images = [], voices = [] },
    } = event;

    setAudioList(voices);
    setEventData({
      images: images.map((img) => img.path),
      title: title,
      date,
      description: details,
      tags,
    });
  }
  const getData = async () => {
    try {
      const eventData = await websiteIstance.get(
        `/api/v1/timelines/${timelineId}/events/${id}`
      );

      const {
        title,
        date,
        details,
        tags,
        files: { images = [], voices = [] },
        creator_id,
        contributors
      } = eventData.data.data;

      console.log(contributors)

      const isContributors = contributors.filter((ele) => ele.id.userId === userId)
      console.log(isContributors)
      if (isContributors.length) setIsContributor(true)
      setCreatorId(creator_id)
      setAudioList(voices);
      setEventData({
        images: images.map((img) => img.path),
        title: title,
        date,
        description: details,
        tags,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };
  async function loadData(params) {
    if (!userId) {
      getOfflineData()
      setLoading(false);

    } else {
      await getData();
    }
  }
  useEffect(() => {
    loadData()
  }, []);

  const handleClickOnImage = (index) => {
    memoryStoryModalToggle();
  };

  const handleShowList = () => {
    setShowList(!showList);
  };

  const handlePauseAudio = () => {
    audioPlayerRef.current.audio.current.pause();
  };

  const handlePlayAudio = (index) => {
    // console.log(audioPlayerRef.current.audio.current)
    try {
      if (index === currentTrack) {
        audioPlayerRef.current.audio.current.play();
      } else {
        setTrackIndex(index);
      }
    } catch(err) {
      console.log(err)
    }
  };

  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < audioList.length - 1 ? currentTrack + 1 : 0
    );
  };

  const handleClickBack = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? audioList.length - 1 : currentTrack - 1
    );
  };

  return (
    <>
      <UpdateEventModal refetch={loadData} isOpen={isOpdateEventOpen} onClose={() => setUpdateEventOpen(false)} />
      <MemoryStoryModal
        handleClose={memoryStoryModalToggle}
        open={isMemoryStoryModalOpen}
      >
        <MemoryStory
          data={eventData}
          open={isMemoryStoryModalOpen}
          images={eventData.images}
          handleClose={memoryStoryModalToggle}
        />
      </MemoryStoryModal>
      {!loading && (
        <>
          {width <= 1024 ? (
            <>
              <div className="viewEventContainer">
                <div className="viewEventHeader">
                  <Typography component="div" className="viewEventHeader-back">
                    <div className="viewEventHeader-back-icon" onClick={() => {
                      navigate(-1)
                    }}>
                      <img src={Back} alt="back" className="backIconRotate" />
                    </div>
                    <div>
                      <h5 className="viewEventHeader-title">
                        {eventData.title}
                      </h5>
                      <h5 className="viewEventHeader-title-date">
                        {eventData.date?.day && eventData.date?.month && <span>
                          {eventData.date?.day}/
                        </span>}
                        {eventData.date?.month && <span>
                          {eventData.date?.month}/
                        </span>}
                        {eventData.date?.year && <span>
                          {eventData.date?.year}
                        </span>}
                      </h5>
                    </div>
                  </Typography>
                  {((userId && creatorId === userId) || (!userId && !creatorId) || isContributor) && <div
                    className="viewEventHeader-edit-icon"
                    onClick={() => {
                      navigate(`/edit-event/${timelineId}/${id}`, {
                        state: {
                          from: location.pathname
                        }
                      });
                    }
                    }
                  >
                    <img src={Edit} alt="edit" />
                  </div>}
                </div>

                {eventData.images?.length ? (
                  <ImageGallery
                    images={eventData.images}
                    handleClickOnImage={handleClickOnImage}
                    uploadMedia={handleClickOnImage}
                    width={width}
                  />
                ) : null}
                <div dangerouslySetInnerHTML={{ __html: eventData.description }} className="viewEventHeader-content" style={audioList && {paddingBottom: '176px'}}></div>

              </div>
              {audioList?.length ? (
                <AudioPlayerComponent
                  showList={showList}
                  setShowList={setShowList}
                  audioList={audioList}
                  currentTrack={currentTrack}
                  handleShowList={handleShowList}
                  audioPlayerRef={audioPlayerRef}
                  handleClickNext={handleClickNext}
                  handleClickBack={handleClickBack}
                  setIsPlay={setIsPlay}
                  isPlay={isPlay}
                  handlePauseAudio={handlePauseAudio}
                  handlePlayAudio={handlePlayAudio}
                />
              ) : null}

              {/* <div className="event-details-divider"></div> */}

              {/* <div
                className={`tags-container ${audioList?.length && "haveAudioList"
                  }`}
              > */}
                {/* {eventData.tags?.map((ele) => (
                  <div className="tag-container">
                    <span className="tag-element">{ele}</span>
                  </div>
                ))} */}
              {/* </div> */}
            </>
          ) : (
            <>
              <div className="viewEventContainerDesktop">
                <div className="viewEventContainerDesktop-first">
                  <div className="viewEventHeader">
                    <Typography
                      component="div"
                      className="viewEventHeader-back"
                    >
                      <div className="viewEventHeader-back-icon" onClick={() => navigate(-1)}>
                        <img alt="edit" src={Back} className="backIconRotate" />
                      </div>
                      <div>
                        <h5 className="viewEventHeader-title">
                          {eventData.title}
                        </h5>
                        <h5 className="viewEventHeader-title-date">
                          {eventData.date?.day && <span>
                            {eventData.date?.day}/
                          </span>}
                          {eventData.date?.month && <span>
                            {eventData.date?.month}/
                          </span>}
                          {eventData.date?.year && <span>
                            {eventData.date?.year}
                          </span>}
                        </h5>
                      </div>
                    </Typography>
                    {console.log(isContributor)}
                    {((userId && creatorId === userId) || (!userId && !creatorId) || isContributor) && <div onClick={() => {
                      if (width >= 700) {
                        // setUpdateEventOpen(true)
                        navigate(`/edit-event/${timelineId}/${id}`, {
                          state: {
                            from: location.pathname
                          }
                        });
                        return
                      }
                    }} className="viewEventHeader-edit-icon">
                      <img alt="edit" src={Edit} />
                    </div>}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: eventData.description }} className="viewEventHeader-content"></div>
                  {/* <div className="event-details-divider"></div> */}
                  {/* {eventData.description} */}

                  <div
                    className={`tags-container ${audioList.length && "haveAudioList"
                      }`}
                  >
                    {/* {eventData.tags.map((ele) => (
                      <div className="tag-container">
                        <span className="tag-element">{ele}</span>
                      </div>
                    ))} */}
                  </div>
                </div>

                <div className="viewEventContainerDesktop-divider"></div>
                <div className="viewEventContainerDesktop-sec">
                  <div className="viewEventContainerDesktop-image" style={eventData.images && {alignItems: 'center', justifyContent: 'center'}}>
                    <EventDetailsImageGallery
                      images={eventData.images}
                      handleClickOnImage={handleClickOnImage}
                      uploadMedia={handleClickOnImage}
                      width={width}
                      audio={audioList.length}
                    />
                  </div>

                  {audioList.length ? (
                    <AudioPlayerComponent
                      showList={showList}
                      setShowList={setShowList}
                      audioList={audioList}
                      currentTrack={currentTrack}
                      handleShowList={handleShowList}
                      audioPlayerRef={audioPlayerRef}
                      handleClickNext={handleClickNext}
                      handleClickBack={handleClickBack}
                      setIsPlay={setIsPlay}
                      isPlay={isPlay}
                      handlePauseAudio={handlePauseAudio}
                      handlePlayAudio={handlePlayAudio}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default EventDetails;
