
const EmptyTimeLine = ({ isPublic, isShared }) => {
    return (
        <>
            {!isPublic && !isShared ?

                <svg width="300" height="163" viewBox="0 0 300 163" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M88.5962 23.5664H12.0781C10.9736 23.5664 10.0781 24.4618 10.0781 25.5664V161C10.0781 162.105 10.9736 163 12.0781 163H88.5962C89.7008 163 90.5962 162.105 90.5962 161V25.5664C90.5962 24.4618 89.7008 23.5664 88.5962 23.5664Z" fill="#F5FAFA" />
                    <path d="M27.804 146.141C27.3258 146.141 26.905 146.001 26.5415 145.72C26.1781 145.439 25.9964 145 25.9964 144.401L26.0251 144.093C26.2355 142.166 26.4842 140.164 26.7711 138.088C27.0771 135.993 27.3354 134.384 27.5458 133.262L25.3078 133.879L24.9635 133.935C24.6 133.935 24.3131 133.795 24.1027 133.514C23.8923 133.234 23.7871 132.916 23.7871 132.56L23.8158 132.308C23.8541 131.952 23.9688 131.663 24.1601 131.438C24.3514 131.214 24.7148 130.989 25.2504 130.765C25.8051 130.54 26.6276 130.297 27.7179 130.035L28.923 129.755C29.3629 129.661 29.7264 129.614 30.0133 129.614C30.568 129.614 30.9792 129.755 31.247 130.035C31.5339 130.316 31.6774 130.709 31.6774 131.214C31.6774 131.438 31.6487 131.681 31.5913 131.943C31.2853 133.402 30.7019 137.555 29.8411 144.401C29.6881 145.561 29.0091 146.141 27.804 146.141Z" fill="#458FB0" />
                    <path d="M40.9442 129.614C42.9144 129.614 44.416 130.222 45.4489 131.438C46.5009 132.635 47.0269 134.3 47.0269 136.433C47.0269 138.341 46.6922 140.033 46.0227 141.511C45.3724 142.97 44.4351 144.111 43.2109 144.934C42.0059 145.739 40.5904 146.141 38.9645 146.141C37.4916 146.141 36.277 145.814 35.3206 145.159C34.3833 144.485 33.8478 143.606 33.7139 142.521L33.6852 142.213C33.6852 141.726 33.8382 141.352 34.1442 141.09C34.4694 140.81 34.9189 140.669 35.4928 140.669C35.9136 140.669 36.2483 140.782 36.497 141.006C36.7648 141.212 36.9943 141.577 37.1856 142.1C37.3386 142.493 37.5873 142.802 37.9316 143.026C38.295 143.251 38.7445 143.363 39.2801 143.363C40.3895 143.363 41.2886 142.942 41.9772 142.1C42.6849 141.24 43.1248 139.968 43.297 138.284C42.7997 138.846 42.2354 139.248 41.6042 139.491C40.9729 139.715 40.2748 139.828 39.5096 139.828C38.5532 139.828 37.6925 139.631 36.9274 139.238C36.1814 138.846 35.598 138.303 35.1772 137.611C34.7563 136.9 34.5459 136.115 34.5459 135.254C34.5459 134.188 34.7946 133.234 35.2919 132.392C35.8084 131.532 36.5448 130.858 37.5012 130.372C38.4767 129.867 39.6244 129.614 40.9442 129.614ZM40.3704 137.022C41.1929 137.022 41.8337 136.797 42.2928 136.348C42.7518 135.899 42.9814 135.301 42.9814 134.553C42.9814 133.879 42.7901 133.346 42.4075 132.953C42.025 132.56 41.4894 132.364 40.8008 132.364C40.0357 132.364 39.414 132.607 38.9358 133.094C38.4767 133.561 38.2472 134.178 38.2472 134.945C38.2472 135.581 38.4385 136.086 38.821 136.461C39.2036 136.835 39.7201 137.022 40.3704 137.022Z" fill="#458FB0" />
                    <path d="M56.0367 129.614C58.0069 129.614 59.5084 130.222 60.5413 131.438C61.5934 132.635 62.1194 134.3 62.1194 136.433C62.1194 138.341 61.7847 140.033 61.1152 141.511C60.4648 142.97 59.5275 144.111 58.3034 144.934C57.0983 145.739 55.6828 146.141 54.0569 146.141C52.5841 146.141 51.3695 145.814 50.4131 145.159C49.4758 144.485 48.9402 143.606 48.8063 142.521L48.7776 142.213C48.7776 141.726 48.9306 141.352 49.2367 141.09C49.5619 140.81 50.0114 140.669 50.5852 140.669C51.006 140.669 51.3408 140.782 51.5894 141.006C51.8572 141.212 52.0868 141.577 52.278 142.1C52.4311 142.493 52.6797 142.802 53.024 143.026C53.3875 143.251 53.837 143.363 54.3726 143.363C55.482 143.363 56.381 142.942 57.0696 142.1C57.7773 141.24 58.2173 139.968 58.3894 138.284C57.8921 138.846 57.3278 139.248 56.6966 139.491C56.0654 139.715 55.3672 139.828 54.6021 139.828C53.6457 139.828 52.7849 139.631 52.0198 139.238C51.2738 138.846 50.6904 138.303 50.2696 137.611C49.8488 136.9 49.6384 136.115 49.6384 135.254C49.6384 134.188 49.887 133.234 50.3844 132.392C50.9008 131.532 51.6372 130.858 52.5936 130.372C53.5692 129.867 54.7169 129.614 56.0367 129.614ZM55.4628 137.022C56.2853 137.022 56.9261 136.797 57.3852 136.348C57.8443 135.899 58.0738 135.301 58.0738 134.553C58.0738 133.879 57.8825 133.346 57.5 132.953C57.1174 132.56 56.5818 132.364 55.8932 132.364C55.1281 132.364 54.5064 132.607 54.0282 133.094C53.5692 133.561 53.3396 134.178 53.3396 134.945C53.3396 135.581 53.5309 136.086 53.9135 136.461C54.296 136.835 54.8125 137.022 55.4628 137.022Z" fill="#458FB0" />
                    <path d="M71.9038 132.392C70.7944 132.392 69.8858 132.822 69.1781 133.683C68.4895 134.525 68.0591 135.787 67.8869 137.471C68.3843 136.91 68.9485 136.517 69.5798 136.292C70.211 136.049 70.9091 135.927 71.6743 135.927C72.6307 135.927 73.4819 136.124 74.2279 136.517C74.993 136.91 75.5859 137.461 76.0068 138.172C76.4276 138.864 76.638 139.641 76.638 140.501C76.638 141.567 76.3798 142.531 75.8633 143.391C75.366 144.233 74.6295 144.906 73.654 145.411C72.6976 145.898 71.5595 146.141 70.2397 146.141C68.2695 146.141 66.7584 145.542 65.7063 144.345C64.6734 143.129 64.157 141.455 64.157 139.323C64.157 137.415 64.4821 135.731 65.1325 134.272C65.802 132.794 66.7392 131.653 67.9443 130.849C69.1685 130.026 70.5935 129.614 72.2194 129.614C73.6923 129.614 74.8973 129.951 75.8346 130.624C76.791 131.279 77.3362 132.149 77.47 133.234L77.4987 133.542C77.4987 134.029 77.3362 134.412 77.011 134.693C76.7049 134.955 76.265 135.086 75.6911 135.086C75.2703 135.086 74.926 134.983 74.6582 134.777C74.4096 134.553 74.1896 134.178 73.9983 133.655C73.8453 133.262 73.5871 132.953 73.2236 132.729C72.8793 132.504 72.4394 132.392 71.9038 132.392ZM70.8135 138.733C69.991 138.733 69.3502 138.958 68.8911 139.407C68.4321 139.856 68.2025 140.454 68.2025 141.203C68.2025 141.876 68.3938 142.409 68.7764 142.802C69.1589 143.195 69.6945 143.391 70.3831 143.391C71.1482 143.391 71.7603 143.157 72.2194 142.69C72.6976 142.203 72.9367 141.577 72.9367 140.81C72.9367 140.174 72.7454 139.669 72.3629 139.295C71.9803 138.92 71.4639 138.733 70.8135 138.733Z" fill="#458FB0" />
                    <path d="M38.1075 98.0094C38.0727 97.9746 38.0407 97.9344 38.0139 97.8889C37.7917 97.5382 37.8934 97.0696 38.2468 96.8474L42.8279 93.9371C43.1786 93.7149 43.647 93.8193 43.8693 94.17C44.0915 94.5207 43.9899 94.9893 43.6364 95.2115L39.0553 98.1218C38.7474 98.3173 38.3512 98.2637 38.1075 98.0094Z" fill="#CDA17B" />
                    <path d="M38.1762 96.6319C38.1762 96.6319 36.4547 97.6413 36.7411 98.0161C37.0276 98.3909 41.5443 103.374 41.796 103.186C42.6555 102.544 39.5497 97.9037 39.0999 97.5904C38.6475 97.2771 38.4225 96.482 38.1762 96.6319Z" fill="#1A1C2E" />
                    <path d="M76.5843 37.2407C76.5843 37.2407 79.6177 34.8016 80.5736 35.0158C81.5294 35.23 78.9055 37.8752 76.5762 38.1992L76.5843 37.2407Z" fill="#CDA17B" />
                    <path d="M76.0241 36.8438C76.4579 37.3016 76.8969 37.7273 77.3226 38.161C70.5622 45.1651 64.8487 46.4689 56.1927 42.4582C54.8674 41.8451 54.9237 41.3337 55.5422 40.2842C55.427 40.0887 55.419 40.1048 55.5422 40.2842C56.1606 39.2347 56.5408 39.1704 57.8875 39.7916C66.6827 43.8531 68.2678 42.56 76.0241 36.8438Z" fill="#325481" />
                    <path d="M60.4443 105.423C60.3935 105.423 60.3426 105.418 60.2918 105.407C59.8821 105.324 59.6198 104.925 59.7028 104.518L60.7844 99.2009C60.8674 98.794 61.2664 98.5289 61.6733 98.6119C62.083 98.6949 62.3453 99.0938 62.2623 99.5008L61.1806 104.818C61.111 105.174 60.7951 105.423 60.4443 105.423Z" fill="#CDA17B" />
                    <path d="M62.0991 102.327C61.2959 102.306 60.4927 102.225 59.6948 102.105C62.0857 89.3204 63.8955 72.1638 57.502 60.2146C59.4431 59.2614 61.3949 58.3324 63.3521 57.4087C68.0241 67.8237 64.0429 91.3311 62.0991 102.327Z" fill="#000835" />
                    <path d="M41.0431 97.5384C40.4782 96.9199 39.9293 96.2907 39.418 95.6267C53.6483 84.7967 57.8196 76.2532 55.4233 58.146C57.5973 57.8006 59.7768 57.482 61.9588 57.1929C61.7232 77.1849 55.8249 85.2411 41.0431 97.5384Z" fill="#0A123F" />
                    <path d="M59.502 104.418C59.502 104.418 59.0361 106.359 59.502 106.413C59.9678 106.466 66.6934 106.67 66.7335 106.359C66.8674 105.294 61.3707 104.311 60.8326 104.418C60.2944 104.525 59.5609 104.137 59.502 104.418Z" fill="#1A1C2E" />
                    <path d="M55.5161 61.4764C56.5576 61.0641 58.0623 60.6651 60.6862 60.5527C62.4693 60.4777 63.8696 60.5393 64.9646 60.6866C60.3756 50.8365 59.5777 45.9261 59.9954 43.1604C60.2016 41.7816 59.6635 40.3973 58.5577 39.5486L58.1266 39.2166C56.1212 37.6771 53.1869 38.7775 52.7076 41.2595C52.547 42.0895 52.4238 43.0372 52.3676 44.1109C52.1025 49.2006 52.7799 54.7347 55.5161 61.4764Z" fill="#052653" />
                    <path d="M66.5475 54.1539C66.5475 54.1539 70.1405 52.2102 71.0883 52.6118C72.0361 53.0134 68.8233 55.249 66.3574 55.1419L66.5475 54.1539Z" fill="#CDA17B" />
                    <path d="M67.0681 53.7588C67.3465 54.4576 67.6277 55.143 67.9464 55.7936C55.1913 56.6852 51.9196 54.0614 52.779 42.2755C52.9102 40.4737 53.3091 40.2809 54.854 40.6959C56.0186 41.0091 55.9811 41.6597 55.8366 43.2662C54.8888 53.7695 55.4429 53.1484 67.0681 53.7588Z" fill="#325481" />
                    <path d="M62.1712 51.339C61.8606 51.4408 61.5475 51.5425 61.2369 51.6443C60.348 46.8303 60.2248 43.0606 58.5943 40.2788C57.4644 38.3484 56.1284 36.9802 52.7924 35.9816C52.7603 34.5063 52.4121 33.2506 52.4121 33.2506L53.1779 33.0605C56.6371 34.7714 58.6344 36.5465 59.8526 38.7232C61.5607 41.7942 61.7054 45.6764 62.1712 51.339Z" fill="#3B3E54" />
                    <path d="M61.2805 51.0782C61.2805 51.0782 60.7289 52.2536 61.0207 52.35C61.3099 52.4464 65.5696 53.4504 65.6339 53.2576C65.8588 52.5963 62.4852 51.2549 62.1265 51.2523C61.7677 51.2496 61.3555 50.9069 61.2805 51.0782Z" fill="#734058" />
                    <path d="M48.0885 51.4647C47.7887 51.3389 47.4861 51.2104 47.1836 51.0819C49.6682 46.8623 52.0484 43.9386 52.6401 40.7686C53.0524 38.5704 52.94 36.6615 51.0765 33.719C52.019 32.5838 52.5812 31.4058 52.5812 31.4058L53.2826 31.7645C54.7712 35.3254 55.1194 37.9734 54.6107 40.4178C53.8851 43.8556 51.4513 46.881 48.0885 51.4647Z" fill="#3B3E54" />
                    <path d="M47.5837 50.683C47.5837 50.683 46.3975 51.2105 46.5555 51.4728C46.7135 51.7352 49.2677 55.2854 49.4444 55.1837C50.0468 54.833 48.3788 51.6067 48.1111 51.3711C47.8407 51.1328 47.7551 50.6027 47.5837 50.683Z" fill="#734058" />
                    <path d="M60.2228 36.3115C59.7034 37.2299 58.4557 37.5029 57.9524 38.1991C57.5267 38.7934 55.5669 37.4789 55.2402 38.9702C53.939 44.9434 50.0996 42.4882 50.0996 42.4882C51.0287 41.0317 51.1572 38.0625 51.1117 35.8965C51.0796 34.4213 50.9644 33.3209 50.9644 33.3209L52.0167 33.0612L55.7195 32.1455C58.3487 34.5043 60.2228 36.3115 60.2228 36.3115Z" fill="#292B3B" />
                    <path d="M63.5684 21.7786C63.5684 21.7786 64.1869 19.5537 64.7223 19.3261C65.2578 19.0985 64.9097 21.2832 63.8977 22.2444L63.5684 21.7786Z" fill="#FFD3AD" />
                    <path d="M63.5802 21.2354C63.8881 21.4254 64.1959 21.6102 64.4877 21.7655C61.4167 26.8686 57.4971 29.3398 53.5962 23.6102C52.9991 22.7347 53.3044 22.7374 53.8077 22.3652C53.8559 22.2046 53.8211 22.2501 53.8077 22.3652C54.3111 21.9931 54.6484 21.8351 55.1303 22.6811C58.2789 28.1992 60.5333 24.724 63.5802 21.2354Z" fill="#E1ABCD" />
                    <path d="M51.154 24.7544C51.0067 24.0154 50.9987 23.753 51.0228 23.1345C51.1005 21.2898 52.8862 20.7008 54.4498 21.2497C54.5328 21.2791 54.6614 21.3113 54.7604 21.3996C55.7645 22.2965 56.1205 22.3313 56.498 23.9993C57.1084 26.7062 57.1754 30.4277 57.5904 33.3996C55.9277 33.3059 54.5569 33.8441 52.7765 34.0717C52.5355 34.1011 51.1407 33.8066 50.7257 33.8468C50.4446 30.7704 51.7537 27.7316 51.154 24.7544Z" fill="#9B6880" />
                    <path d="M53.6888 18.3418C54.7022 17.0489 54.7332 15.3812 53.758 14.6167C52.7828 13.8523 51.1707 14.2807 50.1573 15.5736C49.1438 16.8665 49.1128 18.5342 50.088 19.2987C51.0632 20.0631 52.6753 19.6347 53.6888 18.3418Z" fill="#693D17" />
                    <path d="M51.9488 18.1703C52.6316 19.8865 54.3798 20.8022 55.4267 20.3845C56.4762 19.9669 56.4255 18.3765 55.7454 16.6603C55.0627 14.9441 53.8658 14.4113 52.819 14.8263C51.7695 15.244 51.2661 16.4568 51.9488 18.1703Z" fill="#FFD3AD" />
                    <path d="M52.248 18.2356L54.1784 18.0615C54.2025 18.3266 54.3846 20.8433 54.2775 21.7911C54.2052 22.083 53.7233 22.2115 53.2012 22.083C52.7327 21.9651 52.3954 21.676 52.3847 21.4083H52.3819C52.4435 20.838 52.331 19.1593 52.248 18.2356Z" fill="#FFD3AD" />
                    <path d="M55.2392 15.2443C55.199 15.1854 55.1535 15.1346 55.1107 15.0837C55.1963 14.9605 55.2659 14.8106 55.207 14.6767C55.1321 14.5107 54.9153 14.4786 54.7359 14.4706C54.7519 14.3099 54.7466 14.1413 54.6797 13.994C54.6127 13.8468 54.4654 13.7263 54.3047 13.729C54.1494 13.7316 54.0638 13.7798 53.9353 13.67C53.8523 13.6004 53.8255 13.4746 53.7345 13.413C53.6274 13.3407 53.4828 13.4559 53.448 13.5817C53.4132 13.7075 53.4293 13.8468 53.3704 13.9619C53.3222 14.0556 53.2284 14.1198 53.1507 14.1921C53.0383 14.2966 52.9553 14.4277 52.8884 14.567C52.4894 14.7115 52.1334 14.9445 51.8951 15.1962C51.7291 15.3729 51.6059 15.5763 51.5149 15.7852C51.4935 15.7986 51.4748 15.812 51.4507 15.8227C51.3998 15.8414 51.3463 15.8468 51.2954 15.8575C51.0518 15.911 50.8696 16.1332 50.8188 16.3769C50.7679 16.6205 50.8295 16.8749 50.9473 17.0971C51.0088 17.2149 51.0892 17.3407 51.0678 17.4719C51.0571 17.5308 51.0276 17.5844 51.0062 17.6379C50.9393 17.7986 50.9339 17.986 51.0142 18.1413C51.0918 18.2966 51.2606 18.4063 51.4346 18.393C51.4962 18.3876 51.555 18.3689 51.6112 18.3501C51.7023 18.3207 51.8255 18.2885 51.9487 18.2484C52.2111 18.1948 52.5296 18.0583 52.7652 17.5978C53.2204 16.7089 53.2071 17.5094 54.5243 16.899C55.8336 16.2859 55.2392 15.2443 55.2392 15.2443Z" fill="#693D17" />
                    <path d="M51.4136 18.9452C51.5715 19.3442 52.1713 19.6976 52.4605 19.5824C52.7496 19.4673 52.6935 19.1139 52.5355 18.715C52.3775 18.316 51.9946 18.0349 51.7055 18.15C51.4163 18.2652 51.2556 18.5463 51.4136 18.9452Z" fill="#FFD3AD" />
                    <path d="M52.0783 12.5365C51.168 11.9635 49.6847 11.9823 48.8654 12.74C48.0462 13.4977 48.255 14.8471 48.986 15.6369C49.7142 16.4241 51.6741 16.7346 52.978 15.6931C53.8508 14.997 53.2296 13.2647 52.0783 12.5365Z" fill="#693D17" />
                    <path d="M46.7656 12.4689C46.7656 12.4689 46.9772 10.169 47.4618 9.8504C47.9464 9.53179 47.9946 11.7433 47.17 12.8678L46.7656 12.4689Z" fill="#FFD3AD" />
                    <path d="M47.4831 12.3044C47.1297 12.2696 46.7763 12.2509 46.4336 12.2188C46.546 18.6391 45.6706 20.1652 51.5313 23.5093C52.4283 24.0207 52.4283 23.7369 52.7308 23.0755C52.8754 23.0488 52.862 23.0702 52.7308 23.0755C53.0333 22.4142 52.9718 22.2295 52.1097 21.8252C46.4658 19.1853 48.2006 17.9939 47.4831 12.3044Z" fill="#E1ABCD" />
                    <path d="M54.6726 34.9732C55.5321 37.0883 57.7034 38.2021 58.9966 37.6773C60.2897 37.1526 60.2121 35.1847 59.3527 33.0695C58.4932 30.9544 57.0073 30.3038 55.7142 30.8313C54.421 31.3587 53.8105 32.858 54.6726 34.9732Z" fill="#CDA17B" />
                    <path d="M54.0201 36.0877C54.2209 36.5804 54.9653 37.0114 55.3214 36.8642C55.6775 36.7196 55.6052 36.2832 55.4044 35.7905C55.2036 35.2979 54.727 34.9552 54.3709 35.0998C54.0148 35.247 53.8193 35.5951 54.0201 36.0877Z" fill="#CDA17B" />
                    <path d="M53.8204 33.7504C53.8204 33.7504 53.7159 32.2136 54.5754 31.2926C55.4348 30.3716 57.5125 29.6246 58.7173 31.3193C58.7173 31.3193 59.4616 32.6018 57.8391 33.3702C56.2166 34.1386 56.2274 33.148 55.6705 34.2511C55.1136 35.3542 54.1685 34.9767 54.0774 35.2203L53.8204 33.7504Z" fill="#1A1C2E" />
                    <path d="M55.0391 35.0479L57.4247 34.8149C57.4568 35.1416 57.7057 38.2527 57.5852 39.4254C57.4968 39.7868 56.9025 39.9528 56.2572 39.7949C55.6762 39.653 55.2559 39.2996 55.2399 38.9676H55.2371C55.3094 38.2661 55.1515 36.1911 55.0391 35.0479Z" fill="#CDA17B" />
                    <path d="M55.6956 35.8377C56.1132 35.5298 56.3729 35.9288 57.1145 36.558C57.8535 37.1845 59.3662 37.3505 59.8428 35.4254C60.148 34.1992 60.4559 36.3598 60.4559 36.3625C60.3997 37.4067 60.0061 37.9689 59.2404 38.2795C58.1828 38.7106 56.6969 38.3331 55.5216 37.2835C55.4064 36.7427 55.4118 36.0466 55.6956 35.8377Z" fill="#1A1A1A" />
                    <path d="M59.9206 35.3628C59.9206 35.3628 59.8886 35.3735 59.8323 35.3896C59.3799 35.5315 57.3235 36.2169 56.9862 36.9478C56.6087 37.7698 58.0491 36.8059 58.5203 36.324C58.9942 35.842 59.4226 36 59.8429 35.9599C60.266 35.9197 60.5417 35.4592 59.9206 35.3628Z" fill="#1A1C2E" />
                    <path d="M22.8367 102.417L21.6426 101.555C21.7872 101.354 35.1633 81.2901 36.1593 67.3008L38.6332 67.4052C37.6078 81.8122 23.4391 101.585 22.8367 102.417Z" fill="#FFD3AD" />
                    <path d="M47.0831 105.076C47.0081 104.835 38.7056 80.827 38.1406 67.3838L40.5985 67.3223C41.1581 80.578 48.4191 104.407 48.494 104.645L47.0831 105.076Z" fill="#FFD3AD" />
                    <path d="M22.1626 100.462C22.1626 100.462 20.6365 101.453 20.9122 101.782C21.188 102.111 25.5066 106.465 25.7288 106.283C26.4865 105.662 23.4664 101.563 23.0434 101.297C22.6177 101.03 22.3794 100.315 22.1626 100.462Z" fill="#8E3693" />
                    <path d="M46.7925 30.6394C46.7925 30.6394 46.597 27.0972 47.261 26.5028C47.9277 25.9084 48.4954 29.2578 47.4967 31.1561L46.7925 30.6394Z" fill="#FFD3AD" />
                    <path d="M46.3988 30.4968C46.9745 30.4513 47.5449 30.4057 48.1071 30.4004C48.2945 39.5115 47.4698 43.4928 39.1405 47.7927C37.8661 48.4513 37.5474 47.9801 37.261 46.9145C37.012 46.9439 37.012 46.9466 37.261 46.9145C36.9772 45.8516 37.3306 45.5811 38.4497 45.0189C45.7724 41.3348 45.7536 38.2933 46.3988 30.4968Z" fill="#BFA9D0" />
                    <path d="M35.1499 85.0383C35.5836 85.3302 35.9852 85.6622 36.4055 85.9701C37.3667 86.6715 38.4216 87.2338 39.4952 87.7478C40.1271 88.0504 40.7697 88.3342 41.4471 88.5109C42.4912 88.784 43.5541 88.7813 44.6117 88.6234C43.2034 79.1133 43.9236 70.4252 40.8526 61.397C40.5501 61.5095 40.2502 61.6246 39.9477 61.7397C39.9584 61.3944 39.9665 61.049 39.9691 60.6982C38.0039 60.9205 36.0414 61.1427 34.0815 61.4051C34.5795 70.3823 33.2088 77.1642 30.502 83.78C32.1325 83.6996 33.7924 84.128 35.1499 85.0383Z" fill="#4B276D" />
                    <path d="M38.1821 34.7758C37.5101 34.7999 36.7444 35.3407 36.5838 36.0288C36.4231 36.7169 37.0014 37.3514 37.6573 37.504C38.3106 37.6566 39.5208 36.8534 39.6814 36.1653C39.8421 35.4772 39.0335 34.7463 38.1821 34.7758Z" fill="#4B1F00" />
                    <path d="M35.8644 34.1308C34.5418 33.3008 32.3758 33.3437 31.179 34.4521C29.9795 35.5605 30.2739 37.5284 31.3288 38.6716C32.3864 39.8149 35.2405 40.2486 37.1521 38.7225C38.4292 37.6998 37.5404 35.1777 35.8644 34.1308Z" fill="#4B1F00" />
                    <path d="M37.1442 38.3522C36.1642 37.723 34.5899 37.715 33.7358 38.5048C32.8818 39.2947 33.1307 40.7405 33.9206 41.5945C34.7104 42.4486 37.1602 42.5316 38.0143 41.7418C38.8657 40.952 38.3811 39.1501 37.1442 38.3522Z" fill="#4B1F00" />
                    <path d="M35.8497 39.7448C36.9474 41.5119 39.0706 42.1785 40.1496 41.5092C41.2286 40.8372 40.859 39.0808 39.7613 37.3137C38.6636 35.5466 37.2285 35.1906 36.1468 35.8626C35.0678 36.5346 34.7519 37.9777 35.8497 39.7448Z" fill="#FFD3AD" />
                    <path d="M35.4313 40.8477C35.6883 41.26 36.4219 41.5304 36.7217 41.3456C37.0189 41.1609 36.8878 40.7807 36.6307 40.3684C36.3737 39.9561 35.8945 39.7232 35.5973 39.9079C35.2974 40.0953 35.1769 40.438 35.4313 40.8477Z" fill="#FFD3AD" />
                    <path d="M34.8967 38.7784C34.8967 38.7784 34.5674 37.4129 35.1966 36.4544C35.8284 35.4959 37.5795 34.5053 38.9208 35.8413C38.9208 35.8413 39.7883 36.8774 38.4469 37.8199C37.1083 38.7596 36.9637 37.8681 36.6344 38.9471C36.305 40.026 35.3974 39.8306 35.3519 40.0635L34.8967 38.7784Z" fill="#4B1F00" />
                    <path d="M42.0629 65.186C40.4324 65.4323 39.0937 65.0039 37.6051 64.3346C36.4619 63.8205 35.0561 63.7054 33.8781 63.3868C32.451 58.4176 32.6091 50.0481 34.2022 46.5675C35.5998 43.5099 35.6613 43.9784 36.2583 43.6411C37.0937 43.1672 38.1191 43.1672 38.8928 43.7375C38.9062 43.7482 38.9223 43.7589 38.9357 43.7696C39.8326 44.4497 40.0575 46.123 40.3922 47.8553C40.7242 49.5875 41.356 49.1886 40.1753 52.4122C39.6666 53.7991 41.8086 62.5755 42.0629 65.186Z" fill="#5C0461" />
                    <path d="M36.5367 39.94L38.7215 39.873C38.7322 40.1729 38.7696 43.0163 38.5902 44.0765C38.4885 44.4005 37.9369 44.5156 37.3586 44.3336C36.8392 44.1702 36.4777 43.8222 36.4831 43.5196H36.4805C36.5876 42.8851 36.5688 40.9868 36.5367 39.94Z" fill="#FFD3AD" />
                    <path d="M46.6443 104.026C46.6443 104.026 46.2186 105.796 46.6443 105.844C47.07 105.892 53.2012 106.08 53.236 105.796C53.3592 104.824 48.3471 103.93 47.8545 104.026C47.3645 104.125 46.7005 103.769 46.6443 104.026Z" fill="#8E3693" />
                    <path d="M23.7711 60.9009C23.7711 60.9009 24.7429 64.4967 24.186 65.2517C23.6318 66.0067 22.32 62.6894 22.9331 60.5234L23.7711 60.9009Z" fill="#FFD3AD" />
                    <path d="M24.1263 61.7441C23.5239 61.8271 23.0233 61.9235 22.3727 62.0198C21.7703 50.2876 24.5413 48.1912 35.1465 44.6945C36.769 44.159 37.7596 46.9408 34.9617 47.5647C25.1304 49.7521 24.8305 51.5031 24.1263 61.7441Z" fill="#BFA9D0" />
                    <path d="M45.5384 1.7041L42.0371 7.77094H49.0397L45.5384 1.7041Z" fill="#FF8C87" />
                    <path d="M45.5369 1.7041L44.3691 7.77094H46.7047L45.5369 1.7041Z" fill="#FE5E5A" />
                    <path d="M49.156 9.75335H41.9194L40.5176 7.28467H50.5578L49.156 9.75335Z" fill="#FE5E5A" />
                    <path d="M49.5467 1.70428H45.5391V0H49.5467L48.758 0.853148L49.5467 1.70428Z" fill="#FF8C87" />
                    <path d="M13.2115 78.4405C11.4528 80.0882 5.10199 81.7691 2.93725 79.8448C0.772501 77.9205 -1.71858 55.8485 12.0879 56.4981C15.2935 57.3101 16.6986 58.1457 18.4573 56.4981C20.2161 54.8504 20.2161 52.1787 18.4573 50.531C10.2526 42.8445 10.2526 30.3813 18.4573 22.6967L20.8615 20.4444C30.3933 11.5146 45.8488 11.5146 55.3807 20.4444L82.1006 45.4766" stroke="#FE5E5A" strokeWidth="0.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M72.5279 35.5742L69.543 36.3749L71.7293 38.5612L72.5279 35.5742Z" fill="#FE5E5A" />
                    <path d="M72.0195 36.0846L75.0046 35.2839L72.8182 33.0996L72.0195 36.0846Z" fill="#FE5E5A" />
                    <path d="M67.8873 30.938L64.9023 31.7367L67.0886 33.923L67.8873 30.938Z" fill="#FE5E5A" />
                    <path d="M67.3789 31.446L70.364 30.6452L68.1776 28.4609L67.3789 31.446Z" fill="#FE5E5A" />
                    <path d="M63.399 26.5181L60.4141 27.3168L62.6004 29.5031L63.399 26.5181Z" fill="#FE5E5A" />
                    <path d="M62.8926 27.026L65.8776 26.2253L63.6913 24.041L62.8926 27.026Z" fill="#FE5E5A" />
                    <path d="M82.4995 63.5078C82.4995 63.5078 79.6092 65.444 81.9771 70.6376C84.3449 75.8311 82.4995 77.6543 82.4995 77.6543" stroke="#FE5E5A" strokeWidth="0.5" strokeMiterlimit="10" />
                    <path d="M82.8087 62.7802C82.8087 62.7802 79.3456 62.4494 77.8894 67.9676C76.4332 73.4859 73.8496 73.7279 73.8496 73.7279" stroke="#FE5E5A" strokeWidth="0.5" strokeMiterlimit="10" />
                    <path d="M100.078 46.0566C100.078 46.0566 102.928 44.0619 106.915 48.1441C110.903 52.2263 113.271 51.1634 113.271 51.1634" stroke="#FE5E5A" strokeWidth="0.5" strokeMiterlimit="10" />
                    <path d="M99.2871 46.0782C99.2871 46.0782 100.229 42.7301 105.901 43.3634C111.572 43.9967 112.732 41.6753 112.732 41.6753" stroke="#FE5E5A" strokeWidth="0.5" strokeMiterlimit="10" />
                    <path d="M99.8643 63.5083L100.387 45.6265H100.385L91.1836 54.8276L99.8643 63.5083Z" fill="#FFB5B3" />
                    <path d="M100.384 45.6263L76.3047 39.9487L91.1834 54.8274L100.384 45.6263Z" fill="#FF9F9C" />
                    <path d="M81.9785 64.0303H81.9806L99.8624 63.5079L91.1817 54.8271L81.9785 64.0303Z" fill="#FF9F9C" />
                    <path d="M91.1834 54.8274L76.3047 39.9487L81.9802 64.0305L91.1834 54.8274Z" fill="#FF8B87" />
                    <path d="M297.747 23.5664H221.229C220.124 23.5664 219.229 24.4618 219.229 25.5664V158.054C219.229 159.159 220.124 160.054 221.229 160.054H297.747C298.851 160.054 299.747 159.159 299.747 158.054V25.5664C299.747 24.4618 298.851 23.5664 297.747 23.5664Z" fill="#F5FAFA" />
                    <path d="M243.178 142.791C243.55 142.791 243.834 142.921 244.029 143.182C244.224 143.442 244.322 143.768 244.322 144.159C244.322 144.643 244.171 145.08 243.869 145.471C243.586 145.843 243.195 146.029 242.699 146.029C241.847 146.029 240.579 145.964 238.894 145.834C238.557 145.815 237.936 145.778 237.031 145.722C236.127 145.666 235.417 145.638 234.903 145.638C234.672 145.638 234.335 145.722 233.892 145.89C233.803 145.908 233.625 145.955 233.359 146.029C233.111 146.104 232.898 146.141 232.721 146.141C232.437 146.141 232.198 146.011 232.002 145.75C231.825 145.471 231.736 145.061 231.736 144.522C231.736 143.926 231.869 143.47 232.135 143.154C232.402 142.819 232.818 142.521 233.386 142.261C234.592 141.702 235.727 140.995 236.792 140.139C237.874 139.264 238.734 138.324 239.373 137.319C240.029 136.314 240.357 135.356 240.357 134.444C240.357 133.718 240.198 133.188 239.878 132.853C239.577 132.499 239.089 132.322 238.415 132.322C237.634 132.322 237.005 132.546 236.526 132.992C236.065 133.439 235.834 134.062 235.834 134.863C235.834 135.961 235.311 136.51 234.264 136.51C233.767 136.51 233.377 136.361 233.093 136.063C232.827 135.765 232.694 135.337 232.694 134.779C232.694 133.848 232.925 132.992 233.386 132.211C233.847 131.429 234.539 130.805 235.461 130.34C236.384 129.856 237.51 129.614 238.841 129.614C240.437 129.614 241.688 130.033 242.592 130.871C243.497 131.708 243.949 132.871 243.949 134.36C243.949 135.775 243.355 137.254 242.167 138.799C240.996 140.344 239.302 141.711 237.084 142.903L243.178 142.791Z" fill="#458FB0" />
                    <path d="M252.213 146.057C250.474 146.057 249.126 145.462 248.168 144.27C247.228 143.079 246.758 141.376 246.758 139.162C246.758 137.282 247.033 135.626 247.583 134.193C248.151 132.741 248.931 131.615 249.925 130.815C250.936 130.014 252.106 129.614 253.437 129.614C255.175 129.614 256.514 130.21 257.455 131.401C258.412 132.592 258.891 134.295 258.891 136.51C258.891 138.389 258.608 140.055 258.04 141.507C257.49 142.94 256.71 144.056 255.698 144.857C254.705 145.657 253.543 146.057 252.213 146.057ZM252.479 142.931C253.437 142.931 254.191 142.372 254.741 141.255C255.29 140.139 255.565 138.622 255.565 136.705C255.565 135.421 255.361 134.444 254.953 133.774C254.545 133.085 253.951 132.741 253.171 132.741C252.213 132.741 251.459 133.309 250.909 134.444C250.359 135.561 250.084 137.077 250.084 138.994C250.084 140.278 250.279 141.256 250.67 141.926C251.078 142.596 251.681 142.931 252.479 142.931Z" fill="#458FB0" />
                    <path d="M272.514 142.791C272.886 142.791 273.17 142.921 273.365 143.182C273.56 143.442 273.658 143.768 273.658 144.159C273.658 144.643 273.507 145.08 273.205 145.471C272.922 145.843 272.531 146.029 272.035 146.029C271.183 146.029 269.915 145.964 268.23 145.834C267.893 145.815 267.272 145.778 266.367 145.722C265.463 145.666 264.753 145.638 264.239 145.638C264.008 145.638 263.671 145.722 263.228 145.89C263.139 145.908 262.962 145.955 262.695 146.029C262.447 146.104 262.234 146.141 262.057 146.141C261.773 146.141 261.534 146.011 261.338 145.75C261.161 145.471 261.072 145.061 261.072 144.522C261.072 143.926 261.205 143.47 261.471 143.154C261.738 142.819 262.154 142.521 262.722 142.261C263.928 141.702 265.064 140.995 266.128 140.139C267.21 139.264 268.07 138.324 268.709 137.319C269.365 136.314 269.693 135.356 269.693 134.444C269.693 133.718 269.534 133.188 269.214 132.853C268.913 132.499 268.425 132.322 267.751 132.322C266.97 132.322 266.341 132.546 265.862 132.992C265.401 133.439 265.17 134.062 265.17 134.863C265.17 135.961 264.647 136.51 263.6 136.51C263.103 136.51 262.713 136.361 262.429 136.063C262.163 135.765 262.03 135.337 262.03 134.779C262.03 133.848 262.261 132.992 262.722 132.211C263.183 131.429 263.875 130.805 264.797 130.34C265.72 129.856 266.846 129.614 268.177 129.614C269.773 129.614 271.024 130.033 271.928 130.871C272.833 131.708 273.285 132.871 273.285 134.36C273.285 135.775 272.691 137.254 271.503 138.799C270.332 140.344 268.638 141.711 266.421 142.903L272.514 142.791Z" fill="#458FB0" />
                    <path d="M287.403 142.791C287.775 142.791 288.059 142.921 288.254 143.182C288.449 143.442 288.547 143.768 288.547 144.159C288.547 144.643 288.396 145.08 288.094 145.471C287.811 145.843 287.42 146.029 286.924 146.029C286.072 146.029 284.804 145.964 283.119 145.834C282.782 145.815 282.161 145.778 281.256 145.722C280.352 145.666 279.642 145.638 279.128 145.638C278.897 145.638 278.56 145.722 278.116 145.89C278.028 145.908 277.85 145.955 277.584 146.029C277.336 146.104 277.123 146.141 276.946 146.141C276.662 146.141 276.422 146.011 276.227 145.75C276.05 145.471 275.961 145.061 275.961 144.522C275.961 143.926 276.094 143.47 276.36 143.154C276.626 142.819 277.043 142.521 277.611 142.261C278.817 141.702 279.952 140.995 281.017 140.139C282.099 139.264 282.959 138.324 283.598 137.319C284.254 136.314 284.582 135.356 284.582 134.444C284.582 133.718 284.423 133.188 284.103 132.853C283.802 132.499 283.314 132.322 282.64 132.322C281.859 132.322 281.23 132.546 280.751 132.992C280.289 133.439 280.059 134.062 280.059 134.863C280.059 135.961 279.536 136.51 278.489 136.51C277.992 136.51 277.602 136.361 277.318 136.063C277.052 135.765 276.919 135.337 276.919 134.779C276.919 133.848 277.15 132.992 277.611 132.211C278.072 131.429 278.764 130.805 279.686 130.34C280.609 129.856 281.735 129.614 283.066 129.614C284.662 129.614 285.913 130.033 286.817 130.871C287.722 131.708 288.174 132.871 288.174 134.36C288.174 135.775 287.58 137.254 286.391 138.799C285.221 140.344 283.527 141.711 281.309 142.903L287.403 142.791Z" fill="#458FB0" />
                    <path d="M192.68 23.5664H116.162C115.058 23.5664 114.162 24.4618 114.162 25.5664V158.054C114.162 159.159 115.058 160.054 116.162 160.054H192.68C193.785 160.054 194.68 159.159 194.68 158.054V25.5664C194.68 24.4618 193.785 23.5664 192.68 23.5664Z" fill="#F5FAFA" />
                    <path d="M107.079 98.5834C107.027 98.5674 106.979 98.5435 106.932 98.5157C106.545 98.2927 106.41 97.7991 106.633 97.4089L109.543 92.3646C109.766 91.9784 110.264 91.8431 110.65 92.066C111.036 92.289 111.172 92.7826 110.945 93.1728L108.034 98.2171C107.839 98.5595 107.433 98.7028 107.079 98.5834Z" fill="#FFD3AD" />
                    <path d="M106.836 96.7244C106.836 96.7244 105.041 97.8909 105.363 98.2771C105.685 98.6633 110.77 103.787 111.032 103.572C111.924 102.844 108.369 98.0183 107.871 97.7038C107.373 97.3932 107.091 96.5492 106.836 96.7244Z" fill="#2A4345" />
                    <path d="M146.255 54.582C146.255 54.582 149.778 56.8195 149.894 57.8666C150.009 58.9137 146.414 57.1619 145.279 54.9085L146.255 54.582Z" fill="#FFD3AD" />
                    <path d="M146.665 54.2996C146.315 54.8808 145.969 55.4581 145.586 55.9996C136.736 49.9401 133.495 46.4246 135.084 35.5079C135.327 33.8398 135.996 33.8557 137.21 34.3096C137.353 34.0508 137.349 34.0508 137.21 34.3096C138.424 34.7634 138.44 35.289 138.209 36.7461C136.72 46.2773 139.646 48.3436 146.665 54.2996Z" fill="#3C6063" />
                    <path d="M137.137 101.836C137.09 101.86 137.038 101.884 136.986 101.896C136.556 102.023 136.106 101.776 135.979 101.35L134.327 95.7684C134.199 95.3384 134.446 94.8885 134.872 94.7611C135.302 94.6337 135.752 94.8805 135.879 95.3065L137.531 100.888C137.647 101.27 137.472 101.661 137.137 101.836Z" fill="#FFD3AD" />
                    <path d="M137.335 97.8702C136.59 98.3599 135.822 98.8059 135.022 99.204C130.129 84.7958 127.955 66.0559 122.369 53.2163C124.515 52.3802 126.673 51.568 128.827 50.7559C133.998 65.9484 130.037 80.3248 137.335 97.8702Z" fill="#292B3B" />
                    <path d="M109.524 96.3891C108.732 96.0029 107.956 95.5928 107.195 95.1469C116.221 79.7593 121.886 69.193 120.863 50.7676C123.168 50.4611 125.481 50.1983 127.79 49.9355C127.727 67.5885 118.797 82.0963 109.524 96.3891Z" fill="#3B3E54" />
                    <path d="M143.088 25.0609C142.407 24.6548 141.308 24.7066 140.711 25.2838C140.114 25.8611 140.285 26.8604 140.835 27.4258C141.384 27.9911 143.092 27.9672 143.689 27.3899C144.286 26.8087 143.952 25.5745 143.088 25.0609Z" fill="#1A1C2E" />
                    <path d="M138.203 28.6087C137.419 26.9446 135.253 25.6108 133.362 25.9453C131.471 26.2797 130.507 28.4137 130.826 30.2172C131.144 32.0207 133.692 34.2661 136.559 33.9715C138.47 33.7764 139.194 30.7148 138.203 28.6087Z" fill="#1A1C2E" />
                    <path d="M139.777 27.9355C139.208 26.6894 137.655 25.6781 136.306 25.917C134.956 26.1559 134.283 27.7444 134.522 29.0901C134.761 30.4397 137.134 32.08 138.479 31.8411C139.829 31.6063 140.498 29.5121 139.777 27.9355Z" fill="#1A1C2E" />
                    <path d="M137.618 28.4965C137.582 30.945 139.258 32.9555 140.751 32.9794C142.248 33.0033 142.996 31.0286 143.036 28.5801C143.072 26.1316 141.878 24.8656 140.381 24.8457C138.888 24.8178 137.654 26.048 137.618 28.4965Z" fill="#FFD3AD" />
                    <path d="M136.5 29.3209C136.492 29.8902 137.046 30.6267 137.46 30.6347C137.874 30.6427 137.981 30.1808 137.993 29.6115C138.001 29.0422 137.675 28.5047 137.261 28.4968C136.846 28.4888 136.508 28.7515 136.5 29.3209Z" fill="#FFD3AD" />
                    <path d="M137.282 26.9315C137.282 26.9315 137.824 25.3709 139.058 24.8215C140.292 24.272 142.657 24.4074 143.135 26.5852C143.135 26.5852 143.334 28.1657 141.407 28.2414C139.484 28.321 139.906 27.3456 138.899 28.2015C137.887 29.0575 137.111 28.2891 136.92 28.4882L137.282 26.9315Z" fill="#1A1C2E" />
                    <path d="M127.576 57.6317C125.805 56.8394 124.754 55.5614 123.707 53.953C122.902 52.7148 121.585 51.7075 120.621 50.6445C122.373 44.816 127.851 36.6344 131.645 34.2019C134.978 32.0639 134.735 32.5656 135.543 32.6134C136.67 32.6771 137.689 33.326 138.087 34.385C138.095 34.4049 138.103 34.4248 138.111 34.4447C138.565 35.6869 137.725 37.4904 136.952 39.4174C136.18 41.3443 137.056 41.3483 133.839 43.7888C132.449 44.8359 128.982 54.8846 127.576 57.6317Z" fill="#5C9399" />
                    <path d="M138.169 29.127L140.374 30.4487C140.195 30.7513 138.424 33.5939 137.568 34.5256C137.261 34.7804 136.644 34.5455 136.186 33.9961C135.776 33.5024 135.637 32.9291 135.836 32.6345L135.832 32.6305C136.349 32.0731 137.536 30.182 138.169 29.127Z" fill="#FFD3AD" />
                    <path d="M135.645 100.92C135.645 100.92 135.143 103.002 135.645 103.058C136.146 103.118 143.361 103.333 143.404 102.998C143.548 101.856 137.651 100.8 137.074 100.916C136.493 101.035 135.713 100.617 135.645 100.92Z" fill="#2A4345" />
                    <path d="M163.439 58.2883V53.7496C163.439 52.6587 164.323 51.7749 165.414 51.7749H179.643C180.734 51.7749 181.618 52.6587 181.618 53.7496V59.5822" stroke="#292B3B" strokeWidth="2" strokeMiterlimit="10" />
                    <path d="M203.649 59.8647V99.9322C203.649 101.433 202.431 102.651 200.93 102.651H145.893C144.392 102.651 143.174 101.433 143.174 99.9322V59.8647C143.174 58.3638 144.392 57.1455 145.893 57.1455H200.93C202.431 57.1455 203.649 58.3638 203.649 59.8647Z" fill="#6F728A" />
                    <path d="M156.546 57.1455H152.154V102.651H156.546V57.1455Z" fill="#292B3B" />
                    <path d="M194.899 57.1455H190.508V102.651H194.899V57.1455Z" fill="#292B3B" />
                    <path d="M170.312 88.0926C169.492 87.2725 168.158 87.2725 167.334 88.0926C166.514 88.9128 166.514 90.2465 167.334 91.0707L170.527 94.2636V88.3116L170.312 88.0926Z" fill="#3B3E54" />
                    <path d="M173.722 91.0667L172.687 92.1018L170.525 94.2636V88.3116L170.744 88.0926C171.565 87.2725 172.898 87.2725 173.722 88.0926C174.543 88.9128 174.543 90.2465 173.722 91.0667Z" fill="#292B3B" />
                    <path d="M166.552 96.2109C166.552 97.6402 164.772 98.7987 162.579 98.7987C160.385 98.7987 158.605 97.6402 158.605 96.2109C158.605 94.7816 160.385 93.623 162.579 93.623C164.772 93.623 166.552 94.7816 166.552 96.2109Z" fill="#56B1DA" />
                    <path d="M175.684 91.4825L174.314 97.0205L178.283 98.0022L179.653 92.4642L175.684 91.4825Z" fill="#9BC6DA" />
                    <path d="M179.649 92.4679L178.279 98.0059L182.248 98.9876L183.618 93.4496L179.649 92.4679Z" fill="#72BEE0" />
                    <path d="M183.853 82.6665L183.514 83.3553L182.809 83.0487L182.726 83.8131L181.957 83.7653L182.137 84.5098L181.4 84.7248L181.826 85.3658L181.209 85.8237L181.826 86.2775L181.4 86.9185L182.137 87.1335L181.957 87.882L182.726 87.8302L182.809 88.5946L183.514 88.2881L183.853 88.9768L184.41 88.4433V83.2L183.853 82.6665Z" fill="#56B1DA" />
                    <path d="M186.992 86.277L187.609 85.8232L186.992 85.3653L187.418 84.7243L186.682 84.5093L186.861 83.7648L186.092 83.8126L186.009 83.0482L185.304 83.3548L184.966 82.666L184.408 83.1995V88.4428L184.966 88.9763L185.304 88.2876L186.009 88.5941L186.092 87.8297L186.861 87.8815L186.682 87.133L187.418 86.918L186.992 86.277Z" fill="#458FB0" />
                    <path d="M141.182 56.3701C141.182 56.3701 145.434 57.4252 145.896 58.4284C146.358 59.4317 142.194 58.8863 140.318 57.0151L141.182 56.3701Z" fill="#FFD3AD" />
                    <path d="M142.251 56.4894C141.992 57.1542 141.805 57.7275 141.531 58.4521C129.121 52.3687 128.544 48.3198 131.008 35.4125C131.386 33.4378 134.814 34.0071 133.855 37.2439C130.491 48.6025 132.123 49.9123 142.251 56.4894Z" fill="#3C6063" />
                    <path d="M289.836 58.5968C289.836 76.6695 275.185 91.3191 257.114 91.3191C239.311 91.3191 224.824 77.0988 224.399 59.3969C224.391 59.1316 224.389 58.8649 224.389 58.5968C224.389 51.4681 226.669 44.87 230.54 39.4942C236.481 31.2445 246.17 25.8716 257.114 25.8716C266.525 25.8716 275.01 29.8438 280.977 36.2066C285.804 41.3499 288.988 48.0521 289.689 55.4774C289.786 56.5044 289.836 57.5442 289.836 58.5968Z" fill="#458FB0" />
                    <path d="M260.343 51.1803L259.134 52.9532L256.796 54.9686C256.796 54.9686 256.46 58.3532 256.144 58.5956C255.829 58.8381 257.36 60.611 257.36 60.611L258.221 62.7876L260.825 63.1099L263.888 62.304L267.272 62.5465L268.643 63.1113L267.354 65.5289L269.53 68.8337L268.321 72.4608L269.208 75.8454L270.336 78.8278C270.336 78.8278 269.853 80.2784 270.336 80.2784C270.82 80.2784 272.754 80.2784 272.754 80.2784L277.509 76.7326V72.7033C277.509 72.7033 280.33 69.3186 280.491 69.0762C280.653 68.8337 281.62 65.208 281.62 65.208L284.198 62.2256L282.989 60.2915L280.974 59.7267L277.669 54.9714L276.541 52.1502L273.478 51.1831L271.14 52.4725L269.851 51.1831L268.562 49.9736L267.917 48.9253L264.29 50.0535L261.872 49.8923L260.343 51.1803Z" fill="#63ADCE" />
                    <path d="M236.403 61.6592L234.227 64.6017L235.315 69.9617L237.451 71.6548L240.353 73.9925L241.481 78.1844L244.947 83.1822C244.947 83.1822 246.397 86.6481 246.801 86.8093C247.204 86.9704 249.057 88.5822 249.057 88.5822L252.281 88.2598V87.2115C252.281 87.2115 249.782 84.6327 249.621 84.3104C249.459 83.988 249.541 80.6833 249.621 80.2811C249.701 79.8774 251.716 76.4129 251.716 76.4129L251.151 73.914C251.151 73.914 252.924 71.4964 252.924 71.0928C252.924 70.6891 252.52 68.9162 252.52 68.9162L252.037 65.8754L247.926 64.6031L244.864 64.9654L243.575 63.0313L240.19 62.6276L236.403 61.6592Z" fill="#63ADCE" />
                    <path d="M285.57 53.84L284.201 54.4861L286.537 56.7425L284.201 58.9191H282.104L280.411 55.4546L278.235 53.5205L276.542 52.1498L279.282 49.6524L276.542 48.9264L275.171 47.8766C275.171 47.8766 273.077 47.8766 272.19 48.4428C271.303 49.0062 270.149 47.5557 270.149 47.5557L267.433 46.3462L265.983 46.6671L264.13 48.5213H261.792L260.825 49.6509L258.729 48.1176L260.503 46.3447H263.404L263.967 45.4576L262.92 45.1367L262.838 44.2495L264.208 43.766L262.758 42.4766L261.628 42.7975H260.098V41.9917L262.515 40.5411L264.692 42.7975H265.498L266.627 41.7506L267.757 42.0715L269.771 42.7975L271.705 42.3967C271.705 42.3967 274.043 40.2202 274.043 39.9791C274.043 39.7381 272.913 39.1718 272.913 39.1718C272.913 39.1718 271.784 39.1718 271.543 39.1718C271.301 39.1718 272.189 37.1579 272.189 37.1579L270.148 38.2048C270.148 38.2048 270.334 39.6554 270.148 39.9791C269.961 40.3 269.045 40.8634 269.045 40.8634C269.045 40.8634 267.191 39.4942 266.948 39.7367C266.706 39.9791 265.822 39.7367 265.822 39.7367L265.096 38.366L267.03 37.8825L267.997 36.4319L271.461 34.2554C271.461 34.2554 273.074 34.4978 273.638 34.8188C274.201 35.1425 275.331 35.3023 275.331 35.3023L274.121 36.5931L275.251 37.8825H277.269L280.493 36.5931L280.976 36.2066C285.803 41.3498 288.986 48.052 289.688 55.4774L288.714 55.1294L285.57 53.84Z" fill="#63ADCE" />
                    <path d="M241.887 47.5528C241.887 48.0363 240.596 49.6494 240.596 49.6494L238.582 50.6165L236.244 52.8729L235.843 56.8209L235.277 58.9176H233.504L232.617 57.3044L230.924 56.8209L229.314 58.3542L229.556 59.9645C229.556 59.9645 231.57 61.3352 231.65 61.6575C231.733 61.9813 232.133 62.9483 232.133 62.9483L234.23 63.9952V65.1249L229.957 62.867L225.446 61.6575L224.399 59.3968C224.391 59.1315 224.389 58.8648 224.389 58.5967C224.389 51.468 226.669 44.8699 230.54 39.4941L230.766 39.3315L232.658 40.862V42.3952L231.57 43.3623L229.636 44.0056V44.7315L231.733 44.4891L233.343 43.6832L235.036 42.3938H236.407L235.277 43.4407L233.343 45.5374L232.054 47.3103L232.658 48.5997L233.827 48.0363L233.909 48.9235L234.715 49.9704L235.845 47.7938L236.328 45.6173L238.101 44.5704L239.148 45.0539H241.165C241.161 45.0553 241.887 47.0693 241.887 47.5528Z" fill="#63ADCE" />
                    <path d="M240.19 37.1566H241.883V38.687L242.77 39.3317L243.092 43.1199H244.623L245.752 40.8635L248.17 37.9624L249.943 36.7529L252.925 34.5764L253.329 31.7551L255.263 29.4987L253.49 28.854L251.958 29.4188L247.727 31.4328L245.269 32.1588L242.609 33.5294L238.256 34.7375L236.079 35.987V37.8811L234.227 39.3317L236.403 39.654L237.854 38.4459L239.143 36.9954L240.19 37.1566Z" fill="#63ADCE" />
                    <path d="M284.533 96.7885C284.589 96.7885 284.641 96.7837 284.698 96.7696C285.145 96.6801 285.437 96.2421 285.343 95.79L284.156 89.95C284.067 89.5026 283.629 89.2106 283.177 89.3048C282.729 89.3943 282.437 89.8322 282.531 90.2844L283.718 96.1244C283.803 96.5153 284.147 96.7885 284.533 96.7885Z" fill="#FFD3AD" />
                    <path d="M285.568 95.6859C285.568 95.6859 286.081 97.8147 285.568 97.8759C285.055 97.9372 277.67 98.1585 277.628 97.8147C277.482 96.6467 283.515 95.5635 284.108 95.6859C284.701 95.8084 285.502 95.3751 285.568 95.6859Z" fill="#292B3B" />
                    <path d="M250.377 26.1191C250.377 26.1191 248.031 22.5491 246.957 22.4502C245.884 22.3513 247.734 26.0013 250.061 27.1222L250.377 26.1191Z" fill="#FFD3AD" />
                    <path d="M250.512 25.5073C250.098 26.0631 249.678 26.5858 249.273 27.118C257.577 33.8481 263.977 34.5075 272.878 28.9784C274.239 28.1306 274.112 27.5842 273.298 26.5199C273.397 26.2938 273.411 26.3079 273.298 26.5199C272.483 25.4602 272.059 25.4414 270.674 26.2938C261.632 31.8983 259.729 30.7021 250.512 25.5073Z" fill="#325481" />
                    <path d="M270.074 96.788C270.13 96.788 270.182 96.7832 270.239 96.7691C270.686 96.6796 270.978 96.2417 270.884 95.7895L269.697 89.9495C269.608 89.5021 269.17 89.2101 268.718 89.3043C268.27 89.3938 267.978 89.8318 268.072 90.2839L269.259 96.1239C269.344 96.5148 269.688 96.788 270.074 96.788Z" fill="#FFD3AD" />
                    <path d="M268.265 93.3869C269.15 93.3634 270.031 93.2739 270.903 93.142C268.275 79.1025 266.287 60.2638 273.314 47.1426C271.18 46.0971 269.037 45.0751 266.89 44.0625C261.756 55.4976 266.127 81.3113 268.265 93.3869Z" fill="#0A123F" />
                    <path d="M282.799 92.9921C283.679 92.7331 284.551 92.4552 285.398 92.1067C278.828 79.2681 274.02 59.0542 275.169 44.4401C272.781 44.3177 270.389 44.2376 267.996 44.167C268.896 58.7952 277.114 79.8051 282.799 92.9921Z" fill="#000835" />
                    <path d="M271.125 95.6857C271.125 95.6857 271.638 97.8145 271.125 97.8758C270.612 97.937 263.227 98.1583 263.184 97.8145C263.038 96.6465 269.076 95.5633 269.665 95.6857C270.258 95.8035 271.059 95.3749 271.125 95.6857Z" fill="#292B3B" />
                    <path d="M275.495 48.5277C274.35 48.0756 272.697 47.6376 269.815 47.5152C267.856 47.4304 266.32 47.501 265.119 47.6612C270.158 36.843 271.035 31.4505 270.578 28.4127C270.352 26.8962 270.94 25.3797 272.155 24.4472L272.631 24.0846C274.835 22.3938 278.057 23.5995 278.584 26.3311C278.763 27.2448 278.895 28.2856 278.956 29.463C279.243 35.0439 278.499 41.1241 275.495 48.5277Z" fill="#052653" />
                    <path d="M276.429 19.4222C275.482 21.7441 273.099 22.9685 271.681 22.3892C270.264 21.81 270.344 19.6529 271.291 17.3311C272.237 15.0092 273.867 14.2933 275.285 14.8726C276.702 15.4519 277.371 17.1003 276.429 19.4222Z" fill="#FFD3AD" />
                    <path d="M277.142 20.6468C276.92 21.1884 276.105 21.6594 275.71 21.4992C275.319 21.3391 275.399 20.8634 275.62 20.3218C275.842 19.7802 276.364 19.4034 276.755 19.5635C277.146 19.7284 277.358 20.1099 277.142 20.6468Z" fill="#FFD3AD" />
                    <path d="M276.019 19.5072L273.4 19.2529C273.362 19.6109 273.089 23.0301 273.221 24.3158C273.315 24.7114 273.97 24.8951 274.681 24.7209C275.317 24.5654 275.783 24.1792 275.797 23.8119H275.802C275.722 23.0395 275.896 20.76 276.019 19.5072Z" fill="#FFD3AD" />
                    <path d="M295.463 22.3141C295.463 22.3141 295.986 17.8587 296.947 17.2606C297.907 16.6625 297.889 20.9624 296.231 23.11L295.463 22.3141Z" fill="#FFD3AD" />
                    <path d="M295.326 21.2593C295.976 21.5277 296.621 21.7962 297.224 22.0081C292.849 32.7179 286.062 38.6333 276.864 28.7948C275.456 27.2877 276.045 27.2076 276.897 26.341C276.94 26.016 276.888 26.1196 276.897 26.341C277.754 25.4744 278.353 25.0741 279.53 26.5529C287.226 36.2125 290.513 28.8701 295.326 21.2593Z" fill="#325481" />
                    <path d="M271.904 14.5126C272.585 14.1065 273.684 14.1582 274.281 14.7355C274.878 15.3128 274.707 16.3121 274.158 16.8774C273.608 17.4428 271.9 17.4189 271.303 16.8416C270.706 16.2603 271.04 15.0261 271.904 14.5126Z" fill="#1A1C2E" />
                    <path d="M276.339 21.0102C276.937 19.7781 278.586 18.7907 280.025 19.0383C281.465 19.2859 282.199 20.8658 281.956 22.201C281.714 23.5363 279.774 25.1987 277.591 24.9806C276.136 24.8361 275.585 22.5695 276.339 21.0102Z" fill="#1A1C2E" />
                    <path d="M275.215 17.3872C275.784 16.141 277.337 15.1298 278.686 15.3687C280.036 15.6075 280.709 17.1961 280.47 18.5417C280.231 19.8914 277.858 21.5317 276.513 21.2928C275.163 21.0579 274.494 18.9638 275.215 17.3872Z" fill="#1A1C2E" />
                    <path d="M277.712 16.3832C277.712 16.3832 277.17 14.8225 275.936 14.2731C274.702 13.7237 272.337 13.8591 271.859 16.0368C271.859 16.0368 271.66 17.6174 273.587 17.693C275.51 17.7726 275.088 16.7972 276.096 17.6532C277.107 18.5092 277.883 17.7408 278.074 17.9399L277.712 16.3832Z" fill="#1A1C2E" />
                    <path d="M278.434 10.1978L276.098 16.0755L273.602 15.0865L272.443 14.0362L268.002 10L278.434 10.1978Z" fill="#FFA19E" />
                    <path d="M286.153 17.21L280.157 17.0969L278.589 17.0687L276.098 16.0749L278.434 10.1973L286.153 17.21Z" fill="#FFA19E" />
                    <path d="M273.305 11.9352L272.324 14.4038L280.037 17.4676L281.017 14.999L273.305 11.9352Z" fill="#FF7975" />
                    <path d="M273.213 12.0718C273.284 11.8928 275.069 12.4344 277.197 13.2822C279.326 14.1299 280.998 14.9588 280.927 15.1378C280.857 15.3167 279.072 14.7752 276.943 13.9274C274.814 13.0844 273.142 12.2507 273.213 12.0718Z" fill="#FF7975" />
                    <path d="M282.084 14L281.91 19.0064" stroke="#2E2C1A" strokeWidth="1.18197" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M281.43 18.9872C281.439 18.7235 281.661 18.5163 281.925 18.5257C282.188 18.5351 282.396 18.7565 282.386 19.0202C282.377 19.284 282.155 19.4912 281.892 19.4818C281.628 19.4771 281.421 19.2557 281.43 18.9872Z" fill="#1A1C2E" />
                    <path d="M281.911 18.6763L281.002 21.3137L282.631 21.3702L281.911 18.6763Z" fill="#1A1C2E" />
                    <path d="M254.982 18.5728H241.988V36.1723H254.982V18.5728Z" fill="white" />
                    <path d="M253.479 22.228H243.488V22.8948H253.479V22.228Z" fill="#44B4B8" />
                    <path d="M250.961 19.9097H246.006V20.5764H250.961V19.9097Z" fill="#44B4B8" />
                    <path d="M253.479 23.6123H243.488V24.279H253.479V23.6123Z" fill="#44B4B8" />
                    <path d="M253.479 24.9966H243.488V25.6634H253.479V24.9966Z" fill="#44B4B8" />
                    <path d="M253.479 26.3809H243.488V27.0476H253.479V26.3809Z" fill="#44B4B8" />
                    <path d="M253.479 27.7651H243.488V28.4319H253.479V27.7651Z" fill="#44B4B8" />
                    <path d="M253.479 29.1494H243.488V29.8162H253.479V29.1494Z" fill="#44B4B8" />
                    <path d="M253.479 30.5337H243.488V31.2005H253.479V30.5337Z" fill="#44B4B8" />
                    <path d="M137.73 142.791C138.118 142.791 138.413 142.921 138.617 143.182C138.82 143.442 138.921 143.768 138.921 144.159C138.921 144.643 138.764 145.08 138.45 145.471C138.155 145.843 137.749 146.029 137.232 146.029C136.346 146.029 135.026 145.964 133.272 145.834C132.921 145.815 132.275 145.778 131.333 145.722C130.392 145.666 129.653 145.638 129.118 145.638C128.878 145.638 128.527 145.722 128.065 145.89C127.973 145.908 127.788 145.955 127.512 146.029C127.253 146.104 127.032 146.141 126.847 146.141C126.552 146.141 126.302 146.011 126.099 145.75C125.915 145.471 125.822 145.061 125.822 144.522C125.822 143.926 125.961 143.47 126.238 143.154C126.515 142.819 126.948 142.521 127.539 142.261C128.795 141.702 129.976 140.995 131.084 140.139C132.21 139.264 133.106 138.324 133.77 137.319C134.453 136.314 134.795 135.356 134.795 134.444C134.795 133.718 134.629 133.188 134.296 132.853C133.983 132.499 133.475 132.322 132.773 132.322C131.961 132.322 131.306 132.546 130.807 132.992C130.327 133.439 130.087 134.062 130.087 134.863C130.087 135.961 129.542 136.51 128.453 136.51C127.936 136.51 127.53 136.361 127.235 136.063C126.958 135.765 126.819 135.337 126.819 134.779C126.819 133.848 127.059 132.992 127.539 132.211C128.019 131.429 128.739 130.805 129.699 130.34C130.659 129.856 131.832 129.614 133.216 129.614C134.878 129.614 136.18 130.033 137.121 130.871C138.063 131.708 138.534 132.871 138.534 134.36C138.534 135.775 137.915 137.254 136.678 138.799C135.46 140.344 133.696 141.711 131.389 142.903L137.73 142.791Z" fill="#458FB0" />
                    <path d="M147.134 146.057C145.325 146.057 143.922 145.462 142.925 144.27C141.946 143.079 141.457 141.376 141.457 139.162C141.457 137.282 141.743 135.626 142.316 134.193C142.906 132.741 143.719 131.615 144.753 130.815C145.805 130.014 147.023 129.614 148.408 129.614C150.217 129.614 151.611 130.21 152.59 131.401C153.587 132.592 154.085 134.295 154.085 136.51C154.085 138.389 153.79 140.055 153.199 141.507C152.627 142.94 151.814 144.056 150.762 144.857C149.728 145.657 148.519 146.057 147.134 146.057ZM147.411 142.931C148.408 142.931 149.193 142.372 149.765 141.256C150.337 140.139 150.624 138.622 150.624 136.705C150.624 135.421 150.411 134.444 149.987 133.774C149.562 133.085 148.944 132.741 148.131 132.741C147.134 132.741 146.35 133.309 145.777 134.444C145.205 135.561 144.919 137.077 144.919 138.994C144.919 140.278 145.122 141.256 145.528 141.926C145.953 142.596 146.58 142.931 147.411 142.931Z" fill="#458FB0" />
                    <path d="M160.509 146.057C160.048 146.057 159.642 145.918 159.291 145.638C158.94 145.359 158.765 144.922 158.765 144.326L158.792 144.019C158.995 142.102 159.235 140.111 159.512 138.045C159.808 135.961 160.057 134.36 160.26 133.243L158.1 133.858L157.768 133.913C157.417 133.913 157.14 133.774 156.937 133.495C156.734 133.216 156.632 132.899 156.632 132.546L156.66 132.294C156.697 131.941 156.808 131.652 156.992 131.429C157.177 131.206 157.528 130.982 158.045 130.759C158.58 130.536 159.374 130.294 160.426 130.033L161.589 129.754C162.014 129.661 162.365 129.614 162.642 129.614C163.177 129.614 163.574 129.754 163.832 130.033C164.109 130.312 164.248 130.703 164.248 131.206C164.248 131.429 164.22 131.671 164.165 131.931C163.869 133.383 163.306 137.515 162.475 144.326C162.328 145.48 161.672 146.057 160.509 146.057Z" fill="#458FB0" />
                    <path d="M172.583 146.057C170.774 146.057 169.37 145.462 168.373 144.27C167.395 143.079 166.906 141.376 166.906 139.162C166.906 137.282 167.192 135.626 167.764 134.193C168.355 132.741 169.167 131.615 170.201 130.815C171.254 130.014 172.472 129.614 173.857 129.614C175.666 129.614 177.06 130.21 178.038 131.401C179.035 132.592 179.534 134.295 179.534 136.51C179.534 138.389 179.239 140.055 178.648 141.507C178.075 142.94 177.263 144.056 176.211 144.857C175.177 145.657 173.968 146.057 172.583 146.057ZM172.86 142.931C173.857 142.931 174.641 142.372 175.214 141.256C175.786 140.139 176.072 138.622 176.072 136.705C176.072 135.421 175.86 134.444 175.435 133.774C175.011 133.085 174.392 132.741 173.58 132.741C172.583 132.741 171.798 133.309 171.226 134.444C170.654 135.561 170.367 137.077 170.367 138.994C170.367 140.278 170.57 141.256 170.977 141.926C171.401 142.596 172.029 142.931 172.86 142.931Z" fill="#458FB0" />
                </svg> : <svg width="182" height="177" viewBox="0 0 182 177" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.4311 49.9302H9.87842V140.225H16.4311V49.9302Z" fill="#464646" />
                    <path d="M11.8936 43.6558L9.87842 49.9301H16.4311L14.4159 43.6558H11.8936Z" fill="white" />
                    <path d="M13.1557 39.731L11.8945 43.6562H14.4168L13.1557 39.731Z" fill="#464646" />
                    <path d="M43.8541 45.8438H37.3013V136.139H43.8541V45.8438Z" fill="#C9C9C9" />
                    <path d="M39.3166 39.5688L37.3013 45.8432H43.8541L41.8387 39.5688H39.3166Z" fill="white" />
                    <path d="M40.5785 35.644L39.3174 39.5693H41.8395L40.5785 35.644Z" fill="#DDDDDD" />
                    <path d="M26.2467 56.0977H19.6938V146.393H26.2467V56.0977Z" fill="#C9C9C9" />
                    <path d="M21.7092 49.8228L19.6938 56.0971H26.2467L24.2313 49.8228H21.7092Z" fill="white" />
                    <path d="M22.9709 45.8979L21.71 49.8232H24.2321L22.9709 45.8979Z" fill="#999999" />
                    <path d="M161.698 107.729C161.698 145.988 130.709 177 92.484 177C54.8267 177 24.1838 146.897 23.2847 109.423C23.2697 108.861 23.2637 108.296 23.2637 107.729C23.2637 92.6379 28.0877 78.6701 36.2756 67.29C48.8411 49.8258 69.3352 38.4517 92.484 38.4517C112.39 38.4517 130.337 46.8607 142.96 60.3302C153.17 71.2182 159.903 85.4064 161.388 101.125C161.593 103.299 161.698 105.501 161.698 107.729Z" fill="#999999" />
                    <path d="M99.319 92.0285L96.7606 95.7817L91.8158 100.048C91.8158 100.048 91.1039 107.213 90.4372 107.726C89.7704 108.24 93.0076 111.993 93.0076 111.993L94.8298 116.6L100.339 117.283L106.816 115.577L113.975 116.09L116.874 117.286L114.147 122.404L118.751 129.4L116.193 137.078L118.069 144.243L120.456 150.557C120.456 150.557 119.433 153.627 120.456 153.627C121.478 153.627 125.569 153.627 125.569 153.627L135.628 146.121V137.591C135.628 137.591 141.595 130.426 141.936 129.913C142.277 129.4 144.322 121.724 144.322 121.724L149.777 115.411L147.219 111.316L142.956 110.121L135.966 100.054L133.579 94.0818L127.102 92.0346L122.157 94.7641L119.43 92.0346L116.703 89.4741L115.339 87.2549L107.667 89.6432L102.553 89.302L99.319 92.0285Z" fill="#C9C9C9" />
                    <path d="M48.6853 114.213L44.0815 120.442L46.3834 131.789L50.9028 135.373L57.0392 140.322L59.4256 149.196L66.7567 159.776C66.7567 159.776 69.8249 167.113 70.6787 167.454C71.5325 167.795 75.4515 171.207 75.4515 171.207L82.2697 170.525V168.306C82.2697 168.306 76.9841 162.846 76.6431 162.164C76.3022 161.482 76.4742 154.486 76.6431 153.634C76.8121 152.78 81.075 145.446 81.075 145.446L79.8803 140.156C79.8803 140.156 83.6304 135.038 83.6304 134.183C83.6304 133.329 82.7766 129.576 82.7766 129.576L81.7538 123.138L73.0591 120.445L66.5817 121.212L63.8544 117.118L56.6952 116.263L48.6853 114.213Z" fill="#C9C9C9" />
                    <path d="M152.677 97.6611L149.781 99.0289L154.722 103.806L149.781 108.413H145.346L141.765 101.079L137.161 96.9847L133.58 94.0831L139.375 88.7961L133.58 87.2592L130.681 85.037C130.681 85.037 126.252 85.037 124.375 86.2357C122.499 87.4283 120.058 84.3576 120.058 84.3576L114.314 81.7971L111.246 82.4765L107.327 86.4017H102.382L100.336 88.7931L95.9014 85.5472L99.6546 81.7941H105.791L106.983 79.916L104.768 79.2367L104.593 77.3586L107.493 76.335L104.424 73.6055L102.035 74.2848H98.7976V72.5789L103.911 69.5082L108.515 74.2848H110.22L112.609 72.0686L114.999 72.748L119.259 74.2848L123.35 73.4364C123.35 73.4364 128.294 68.8288 128.294 68.3185C128.294 67.8082 125.905 66.6095 125.905 66.6095C125.905 66.6095 123.515 66.6095 123.006 66.6095C122.496 66.6095 124.372 62.3461 124.372 62.3461L120.055 64.5624C120.055 64.5624 120.45 67.6331 120.055 68.3185C119.66 68.9979 117.723 70.1905 117.723 70.1905C117.723 70.1905 113.801 67.2919 113.288 67.8052C112.775 68.3185 110.905 67.8052 110.905 67.8052L109.369 64.9036L113.46 63.88L115.506 60.8092L122.833 56.2017C122.833 56.2017 126.246 56.7149 127.437 57.3943C128.629 58.0797 131.019 58.4179 131.019 58.4179L128.46 61.1504L130.85 63.88H135.118L141.937 61.1504L142.96 60.3322C153.169 71.2202 159.902 85.4083 161.387 101.127L159.326 100.391L152.677 97.6611Z" fill="#C9C9C9" />
                    <path d="M60.2793 84.3511C60.2793 85.3747 57.5489 88.7896 57.5489 88.7896L53.289 90.8368L48.3443 95.6135L47.4965 103.971L46.2988 108.41H42.5488L40.6722 104.995L37.0911 103.971L33.685 107.217L34.1979 110.626C34.1979 110.626 38.4579 113.528 38.6268 114.21C38.8018 114.895 39.6494 116.943 39.6494 116.943L44.0844 119.159V121.55L35.0457 116.77L25.5032 114.21L23.2886 109.424C23.2736 108.863 23.2676 108.298 23.2676 107.73C23.2676 92.6394 28.0916 78.6716 36.2796 67.2915L36.7562 66.9473L40.7597 70.1871V73.4329L38.4578 75.4801L34.3669 76.8419V78.3787L38.8018 77.8654L42.2078 76.1595L45.7889 73.4299H48.6881L46.2988 75.6461L42.2078 80.0847L39.4806 83.8378L40.7597 86.5673L43.2306 85.3747L43.4056 87.2528L45.1101 89.469L47.4995 84.8614L48.5223 80.2538L52.2724 78.0375L54.4867 79.0611H58.7527C58.7437 79.0641 60.2793 83.3275 60.2793 84.3511Z" fill="#C9C9C9" />
                    <path d="M56.6952 62.3421H60.2763V65.5819L62.1529 66.9467L62.8347 74.9662H66.0718L68.4612 70.1895L73.575 64.0481L77.325 61.4876L83.6333 56.88L84.4872 50.9076L88.5781 46.1309L84.828 44.7661L81.5879 45.9618L72.6367 50.2252L67.4386 51.7621L61.8119 54.6637L52.6044 57.2212L48.0005 59.8662V63.8759L44.0815 66.9467L48.6853 67.6291L51.7535 65.0716L54.4808 62.0009L56.6952 62.3421Z" fill="#C9C9C9" />
                    <path d="M26.4482 24.6625L92.0543 49.322V0L26.4482 24.6625Z" fill="#999999" />
                    <path d="M92.0547 0V49.322L157.658 24.6625L92.0547 0Z" fill="#7D7D7D" />
                    <path d="M130.132 25.7017H53.979V50.1045H130.132V25.7017Z" fill="#C9C9C9" />
                    <path d="M92.0556 28.8985C113.085 28.8985 130.132 27.467 130.132 25.701C130.132 23.935 113.085 22.5034 92.0556 22.5034C71.0265 22.5034 53.979 23.935 53.979 25.701C53.979 27.467 71.0265 28.8985 92.0556 28.8985Z" fill="#DDDDDD" />
                    <path d="M104.566 4.79541V50.7809" stroke="#5D5D5D" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M108.779 51.7303C109.332 49.3696 107.868 47.0073 105.51 46.4538C103.151 45.9002 100.79 47.365 100.237 49.7257C99.6842 52.0863 101.148 54.4487 103.507 55.0023C105.865 55.5558 108.226 54.0909 108.779 51.7303Z" fill="#5D5D5D" />
                    <path d="M104.565 47.7397L97.0952 72.2603H112.035L104.565 47.7397Z" fill="#5D5D5D" />
                    <path d="M49.357 138.725C49.5138 141.041 48.6329 143.148 47.1395 144.646C45.7608 146.026 43.857 146.883 41.7512 146.883H1.08907C0.488701 146.883 0 146.394 0 145.793C0 145.192 0.488701 144.703 1.08907 144.703H36.4836C39.2532 144.703 41.7211 142.713 42.068 139.963C42.4904 136.638 39.9079 133.8 36.6677 133.8H1.08907C0.488701 133.8 0 133.311 0 132.71C0 132.109 0.488701 131.62 1.08907 131.62H41.513C45.5587 131.623 49.0824 134.682 49.357 138.725Z" fill="#7A7A7A" />
                    <path d="M47.1984 139.253C47.1984 139.386 47.1953 139.519 47.1832 139.649C47.1741 139.794 47.1561 139.936 47.135 140.078C47.0928 140.352 47.0294 140.621 46.9479 140.884C46.9027 141.029 46.8514 141.174 46.791 141.312C46.6764 141.593 46.5407 141.862 46.3808 142.119C46.2903 142.267 46.1907 142.409 46.0851 142.547C45.8619 142.84 45.6114 143.112 45.3338 143.354C45.1589 143.508 44.9718 143.65 44.7757 143.782C43.9098 144.365 42.8691 144.703 41.7498 144.703H2.17676V133.803H41.7498C42.86 133.803 43.8949 134.138 44.7577 134.712C44.9538 134.845 45.1408 134.987 45.3188 135.141C45.5964 135.382 45.8498 135.654 46.0731 135.947C46.1786 136.083 46.2782 136.228 46.3687 136.376C46.5286 136.632 46.6674 136.901 46.7821 137.182C46.8394 137.321 46.8937 137.466 46.9389 137.611C47.0204 137.87 47.0837 138.139 47.129 138.417C47.1501 138.559 47.1682 138.701 47.1802 138.846C47.1923 138.979 47.1984 139.117 47.1984 139.253Z" fill="white" />
                    <path d="M45.3222 135.142H2.18018V134.713H44.7642C44.9573 134.846 45.1442 134.988 45.3222 135.142Z" fill="#8D8D8D" />
                    <path d="M46.3757 136.376H2.18066V135.947H46.0801C46.1826 136.083 46.2822 136.228 46.3757 136.376Z" fill="#8D8D8D" />
                    <path d="M46.9459 137.612H2.18066V137.183H46.7891C46.8464 137.322 46.8977 137.467 46.9459 137.612Z" fill="#8D8D8D" />
                    <path d="M47.1842 138.846H2.18066V138.417H47.136C47.1571 138.559 47.1721 138.701 47.1842 138.846Z" fill="#8D8D8D" />
                    <path d="M47.1842 139.652C47.1751 139.797 47.1571 139.939 47.136 140.081H2.18066V139.652H47.1842Z" fill="#8D8D8D" />
                    <path d="M46.9489 140.887C46.9037 141.032 46.8524 141.177 46.792 141.316H2.17773V140.887H46.9489Z" fill="#8D8D8D" />
                    <path d="M46.3818 142.119C46.2913 142.267 46.1917 142.409 46.0861 142.548H2.17773V142.119H46.3818Z" fill="#8D8D8D" />
                    <path d="M45.3348 143.354C45.1598 143.508 44.9728 143.65 44.7767 143.783H2.17773V143.354H45.3348Z" fill="#8D8D8D" />
                    <path d="M100.502 138.725C100.659 141.041 99.7779 143.148 98.2845 144.646C96.9058 146.026 95.0021 146.883 92.8963 146.883H52.2341C51.6337 146.883 51.145 146.394 51.145 145.793C51.145 145.192 51.6337 144.703 52.2341 144.703H87.6288C90.3983 144.703 92.8661 142.713 93.213 139.963C93.6354 136.638 91.0529 133.8 87.8128 133.8H52.2341C51.6337 133.8 51.145 133.311 51.145 132.71C51.145 132.109 51.6337 131.62 52.2341 131.62H92.658C96.7067 131.623 100.23 134.682 100.502 138.725Z" fill="#C9C9C9" />
                    <path d="M98.3429 139.253C98.3429 139.386 98.3399 139.519 98.3279 139.649C98.3188 139.794 98.3007 139.936 98.2795 140.078C98.2373 140.352 98.1739 140.621 98.0925 140.884C98.0472 141.029 97.9961 141.174 97.9357 141.312C97.8211 141.593 97.6852 141.862 97.5253 142.119C97.4348 142.267 97.3353 142.409 97.2297 142.547C97.0064 142.84 96.7561 143.112 96.4785 143.354C96.3035 143.508 96.1165 143.65 95.9204 143.782C95.0545 144.365 94.0136 144.703 92.8943 144.703H53.3213V133.803H92.8943C94.0045 133.803 95.0394 134.138 95.9022 134.712C96.0983 134.845 96.2854 134.987 96.4634 135.141C96.7409 135.382 96.9943 135.654 97.2176 135.947C97.3232 136.083 97.4227 136.228 97.5132 136.376C97.6731 136.632 97.812 136.901 97.9266 137.182C97.9839 137.321 98.0382 137.466 98.0835 137.611C98.1649 137.87 98.2283 138.139 98.2735 138.417C98.2946 138.559 98.3128 138.701 98.3218 138.846C98.3369 138.979 98.3429 139.117 98.3429 139.253Z" fill="white" />
                    <path d="M96.4648 135.142H53.3228V134.713H95.9067C96.1028 134.846 96.2898 134.988 96.4648 135.142Z" fill="#8D8D8D" />
                    <path d="M97.5176 136.376H53.3228V135.947H97.222C97.3276 136.083 97.4271 136.228 97.5176 136.376Z" fill="#8D8D8D" />
                    <path d="M98.0879 137.612H53.3228V137.183H97.931C97.9913 137.322 98.0426 137.467 98.0879 137.612Z" fill="#8D8D8D" />
                    <path d="M98.3262 138.846H53.3228V138.417H98.2779C98.299 138.559 98.3172 138.701 98.3262 138.846Z" fill="#8D8D8D" />
                    <path d="M98.3262 139.652C98.3172 139.797 98.299 139.939 98.2779 140.081H53.3228V139.652H98.3262Z" fill="#8D8D8D" />
                    <path d="M98.091 140.887C98.0457 141.032 97.9946 141.177 97.9343 141.316H53.3198V140.887H98.091Z" fill="#8D8D8D" />
                    <path d="M97.5268 142.119C97.4363 142.267 97.3367 142.409 97.2311 142.548H53.3228V142.119H97.5268Z" fill="#8D8D8D" />
                    <path d="M96.4798 143.354C96.3049 143.508 96.1178 143.65 95.9217 143.783H53.3228V143.354H96.4798Z" fill="#8D8D8D" />
                    <path d="M149.84 138.725C149.997 141.041 149.116 143.148 147.622 144.646C146.244 146.026 144.34 146.883 142.234 146.883H101.572C100.972 146.883 100.483 146.394 100.483 145.793C100.483 145.192 100.972 144.703 101.572 144.703H136.967C139.736 144.703 142.204 142.713 142.551 139.963C142.973 136.638 140.391 133.8 137.151 133.8H101.572C100.972 133.8 100.483 133.311 100.483 132.71C100.483 132.109 100.972 131.62 101.572 131.62H141.996C146.042 131.623 149.565 134.682 149.84 138.725Z" fill="#7D7D7D" />
                    <path d="M147.681 139.253C147.681 139.386 147.678 139.519 147.666 139.649C147.657 139.794 147.639 139.936 147.618 140.078C147.576 140.352 147.512 140.621 147.431 140.884C147.386 141.029 147.334 141.174 147.274 141.312C147.159 141.593 147.024 141.862 146.864 142.119C146.773 142.267 146.674 142.409 146.568 142.547C146.345 142.84 146.094 143.112 145.817 143.354C145.642 143.508 145.455 143.65 145.259 143.782C144.393 144.365 143.352 144.703 142.233 144.703H102.66V133.803H142.233C143.343 133.803 144.378 134.138 145.241 134.712C145.437 134.845 145.624 134.987 145.802 135.141C146.079 135.382 146.333 135.654 146.556 135.947C146.662 136.083 146.761 136.228 146.852 136.376C147.012 136.632 147.15 136.901 147.265 137.182C147.322 137.321 147.377 137.466 147.422 137.611C147.503 137.87 147.567 138.139 147.612 138.417C147.633 138.559 147.651 138.701 147.663 138.846C147.675 138.979 147.681 139.117 147.681 139.253Z" fill="white" />
                    <path d="M145.803 135.142H102.661V134.713H145.245C145.438 134.846 145.625 134.988 145.803 135.142Z" fill="#8D8D8D" />
                    <path d="M146.855 136.376H102.66V135.947H146.559C146.665 136.083 146.765 136.228 146.855 136.376Z" fill="#8D8D8D" />
                    <path d="M147.425 137.612H102.66V137.183H147.268C147.326 137.322 147.38 137.467 147.425 137.612Z" fill="#8D8D8D" />
                    <path d="M147.664 138.846H102.66V138.417H147.615C147.636 138.559 147.652 138.701 147.664 138.846Z" fill="#8D8D8D" />
                    <path d="M147.664 139.652C147.655 139.797 147.636 139.939 147.615 140.081H102.66V139.652H147.664Z" fill="#8D8D8D" />
                    <path d="M147.429 140.887C147.384 141.032 147.332 141.177 147.272 141.316H102.658V140.887H147.429Z" fill="#8D8D8D" />
                    <path d="M146.862 142.119C146.771 142.267 146.672 142.409 146.566 142.548H102.658V142.119H146.862Z" fill="#8D8D8D" />
                    <path d="M145.815 143.354C145.64 143.508 145.453 143.65 145.257 143.783H102.658V143.354H145.815Z" fill="#8D8D8D" />
                    <path d="M44.0263 124.503C43.8694 122.187 44.7502 120.079 46.2436 118.582C47.6223 117.202 49.5261 116.344 51.6319 116.344H92.294C92.8944 116.344 93.3831 116.833 93.3831 117.434C93.3831 118.035 92.8944 118.524 92.294 118.524H56.8995C54.1299 118.524 51.662 120.514 51.3151 123.265C50.8927 126.589 53.4753 129.427 56.7155 129.427H92.294C92.8944 129.427 93.3831 129.916 93.3831 130.517C93.3831 131.118 92.8944 131.607 92.294 131.607H51.8703C47.8216 131.604 44.2978 128.546 44.0263 124.503Z" fill="#7A7A7A" />
                    <path d="M46.1875 123.974C46.1875 123.841 46.1906 123.708 46.2027 123.578C46.2117 123.433 46.2297 123.292 46.2508 123.15C46.2931 122.875 46.3565 122.603 46.4379 122.343C46.4832 122.199 46.5345 122.054 46.5948 121.915C46.7095 121.634 46.8452 121.365 47.0051 121.109C47.0956 120.961 47.1951 120.819 47.3007 120.68C47.524 120.387 47.7745 120.115 48.052 119.874C48.227 119.72 48.4141 119.578 48.6102 119.445C49.476 118.862 50.5168 118.524 51.6361 118.524H91.2091V129.424H51.6361C50.5259 129.424 49.4911 129.089 48.6283 128.515C48.4322 128.382 48.245 128.24 48.067 128.086C47.7895 127.845 47.5361 127.573 47.3128 127.28C47.2072 127.144 47.1077 126.999 47.0172 126.851C46.8573 126.595 46.7186 126.326 46.6039 126.045C46.5466 125.906 46.4923 125.761 46.4471 125.617C46.3656 125.357 46.3021 125.088 46.2569 124.81C46.2358 124.668 46.2177 124.527 46.2056 124.382C46.1936 124.246 46.1875 124.11 46.1875 123.974Z" fill="white" />
                    <path d="M48.063 128.087H91.205V128.516H48.6211C48.4281 128.383 48.238 128.241 48.063 128.087Z" fill="#8D8D8D" />
                    <path d="M47.0078 126.852H91.2029V127.281H47.3035C47.1979 127.142 47.0983 127 47.0078 126.852Z" fill="#8D8D8D" />
                    <path d="M46.438 125.618H91.2031V126.046H46.5949C46.5345 125.908 46.4832 125.763 46.438 125.618Z" fill="#8D8D8D" />
                    <path d="M46.2002 124.381H91.2037V124.81H46.2484C46.2272 124.668 46.2092 124.526 46.2002 124.381Z" fill="#8D8D8D" />
                    <path d="M46.2002 123.576C46.2092 123.431 46.2272 123.289 46.2484 123.147H91.2037V123.576H46.2002Z" fill="#8D8D8D" />
                    <path d="M46.4355 122.341C46.4808 122.196 46.5321 122.051 46.5924 121.912H91.2067V122.341H46.4355Z" fill="#8D8D8D" />
                    <path d="M47.0024 121.109C47.0929 120.961 47.1925 120.819 47.2981 120.68H91.2065V121.109H47.0024Z" fill="#8D8D8D" />
                    <path d="M48.0508 119.875C48.2258 119.721 48.4128 119.579 48.6089 119.446H91.2079V119.875H48.0508Z" fill="#8D8D8D" />
                    <path d="M155.272 123.464C155.429 125.78 154.548 127.887 153.055 129.385C151.676 130.765 149.773 131.622 147.667 131.622H107.005C106.404 131.622 105.916 131.133 105.916 130.532C105.916 129.932 106.404 129.442 107.005 129.442H142.399C145.169 129.442 147.637 127.453 147.984 124.702C148.406 121.378 145.823 118.539 142.583 118.539H107.005C106.404 118.539 105.916 118.05 105.916 117.449C105.916 116.849 106.404 116.359 107.005 116.359H147.428C151.474 116.362 154.998 119.421 155.272 123.464Z" fill="#A1A1A1" />
                    <path d="M153.114 123.992C153.114 124.125 153.111 124.258 153.099 124.388C153.09 124.532 153.072 124.674 153.051 124.816C153.008 125.091 152.945 125.363 152.863 125.622C152.818 125.767 152.767 125.912 152.707 126.051C152.592 126.332 152.456 126.601 152.296 126.857C152.206 127.005 152.106 127.147 152.001 127.286C151.777 127.579 151.527 127.851 151.25 128.092C151.075 128.246 150.887 128.388 150.691 128.521C149.826 129.104 148.785 129.442 147.665 129.442H108.092V118.542H147.665C148.776 118.542 149.81 118.877 150.673 119.451C150.869 119.584 151.056 119.726 151.234 119.88C151.512 120.121 151.765 120.393 151.989 120.686C152.094 120.822 152.194 120.967 152.284 121.115C152.444 121.371 152.583 121.64 152.698 121.921C152.755 122.06 152.809 122.205 152.854 122.349C152.936 122.609 152.999 122.878 153.045 123.156C153.066 123.298 153.084 123.439 153.096 123.584C153.108 123.717 153.114 123.853 153.114 123.992Z" fill="white" />
                    <path d="M151.236 119.879H108.094V119.45H150.678C150.871 119.58 151.058 119.725 151.236 119.879Z" fill="#8D8D8D" />
                    <path d="M152.289 121.114H108.094V120.685H151.993C152.099 120.821 152.199 120.966 152.289 121.114Z" fill="#8D8D8D" />
                    <path d="M152.859 122.35H108.094V121.921H152.702C152.76 122.06 152.814 122.202 152.859 122.35Z" fill="#8D8D8D" />
                    <path d="M153.098 123.581H108.094V123.152H153.049C153.071 123.294 153.086 123.436 153.098 123.581Z" fill="#8D8D8D" />
                    <path d="M153.098 124.387C153.089 124.532 153.071 124.674 153.049 124.815H108.094V124.387H153.098Z" fill="#8D8D8D" />
                    <path d="M152.862 125.622C152.817 125.767 152.766 125.912 152.706 126.051H108.091V125.622H152.862Z" fill="#8D8D8D" />
                    <path d="M152.295 126.857C152.205 127.005 152.105 127.147 152 127.286H108.091V126.857H152.295Z" fill="#8D8D8D" />
                    <path d="M151.249 128.092C151.074 128.246 150.886 128.388 150.69 128.521H108.091V128.092H151.249Z" fill="#8D8D8D" />
                    <path d="M96.578 109.258C96.4212 106.942 97.302 104.834 98.7954 103.337C100.174 101.957 102.078 101.099 104.184 101.099H144.846C145.446 101.099 145.935 101.588 145.935 102.189C145.935 102.79 145.446 103.279 144.846 103.279H109.451C106.682 103.279 104.214 105.269 103.867 108.02C103.444 111.344 106.027 114.182 109.267 114.182H144.846C145.446 114.182 145.935 114.671 145.935 115.272C145.935 115.873 145.446 116.362 144.846 116.362H104.422C100.376 116.362 96.8526 113.301 96.578 109.258Z" fill="#7D7D7D" />
                    <path d="M98.7422 108.729C98.7422 108.596 98.7451 108.463 98.7572 108.333C98.7663 108.188 98.7844 108.046 98.8055 107.905C98.8478 107.63 98.9112 107.358 98.9926 107.098C99.0379 106.953 99.089 106.808 99.1494 106.67C99.264 106.389 99.3999 106.12 99.5598 105.863C99.6503 105.715 99.7498 105.574 99.8554 105.435C100.079 105.142 100.329 104.87 100.607 104.628C100.782 104.474 100.969 104.333 101.165 104.2C102.031 103.617 103.071 103.279 104.191 103.279H143.764V114.179H104.191C103.081 114.179 102.046 113.844 101.183 113.27C100.987 113.137 100.8 112.995 100.622 112.841C100.344 112.6 100.091 112.328 99.8675 112.035C99.7619 111.899 99.6624 111.754 99.5718 111.606C99.4119 111.35 99.2731 111.081 99.1585 110.8C99.1012 110.661 99.0469 110.516 99.0016 110.371C98.9201 110.112 98.8568 109.843 98.8116 109.565C98.7905 109.423 98.7723 109.281 98.7633 109.136C98.7452 109.004 98.7422 108.868 98.7422 108.729Z" fill="white" />
                    <path d="M100.615 112.841H143.757V113.27H101.173C100.98 113.14 100.793 112.998 100.615 112.841Z" fill="#8D8D8D" />
                    <path d="M99.5596 111.606H143.755V112.035H99.8552C99.7526 111.899 99.6531 111.754 99.5596 111.606Z" fill="#8D8D8D" />
                    <path d="M98.9902 110.372H143.755V110.8H99.147C99.0897 110.661 99.0385 110.52 98.9902 110.372Z" fill="#8D8D8D" />
                    <path d="M98.7524 109.14H143.756V109.568H98.8006C98.7795 109.427 98.7645 109.285 98.7524 109.14Z" fill="#8D8D8D" />
                    <path d="M98.7524 108.334C98.7615 108.189 98.7795 108.047 98.8006 107.905H143.756V108.334H98.7524Z" fill="#8D8D8D" />
                    <path d="M98.9873 107.099C99.0326 106.954 99.0839 106.809 99.1442 106.67H143.758V107.099H98.9873Z" fill="#8D8D8D" />
                    <path d="M99.5542 105.863C99.6447 105.715 99.7443 105.573 99.8499 105.435H143.758V105.863H99.5542Z" fill="#8D8D8D" />
                    <path d="M100.603 104.628C100.778 104.474 100.965 104.333 101.161 104.2H143.76V104.628H100.603Z" fill="#8D8D8D" />
                    <path d="M155.872 92.9406C156.029 95.2565 155.148 97.364 153.655 98.8616C152.276 100.242 150.372 101.099 148.266 101.099H107.604C107.004 101.099 106.515 100.61 106.515 100.009C106.515 99.4082 107.004 98.919 107.604 98.919H142.999C145.768 98.919 148.236 96.9292 148.583 94.1786C149.006 90.8542 146.423 88.016 143.183 88.016H107.604C107.004 88.016 106.515 87.5268 106.515 86.9259C106.515 86.3251 107.004 85.8359 107.604 85.8359H148.028C152.074 85.839 155.598 88.8976 155.872 92.9406Z" fill="#A1A1A1" />
                    <path d="M153.713 93.4686C153.713 93.6014 153.711 93.7343 153.698 93.8642C153.689 94.0091 153.671 94.151 153.65 94.2929C153.608 94.5677 153.545 94.8394 153.463 95.0991C153.418 95.244 153.367 95.389 153.306 95.5278C153.192 95.8087 153.056 96.0774 152.896 96.334C152.805 96.482 152.706 96.6239 152.6 96.7628C152.377 97.0557 152.127 97.3274 151.849 97.5689C151.674 97.7229 151.487 97.8648 151.291 97.9977C150.425 98.5804 149.384 98.9186 148.265 98.9186H108.692V88.0186H148.265C149.375 88.0186 150.41 88.3537 151.273 88.9274C151.469 89.0603 151.656 89.2022 151.834 89.3562C152.112 89.5977 152.365 89.8695 152.588 90.1624C152.694 90.2982 152.793 90.4431 152.884 90.5911C153.044 90.8477 153.183 91.1165 153.297 91.3973C153.355 91.5362 153.409 91.6811 153.454 91.8261C153.536 92.0857 153.599 92.3544 153.644 92.6322C153.665 92.7741 153.683 92.916 153.695 93.061C153.707 93.1969 153.713 93.3327 153.713 93.4686Z" fill="white" />
                    <path d="M151.837 89.3555H108.695V88.9268H151.279C151.472 89.0596 151.659 89.2015 151.837 89.3555Z" fill="#8D8D8D" />
                    <path d="M152.89 90.5904H108.695V90.1616H152.594C152.7 90.3005 152.799 90.4424 152.89 90.5904Z" fill="#8D8D8D" />
                    <path d="M153.46 91.8262H108.695V91.3975H153.303C153.36 91.5364 153.415 91.6813 153.46 91.8262Z" fill="#8D8D8D" />
                    <path d="M153.698 93.0606H108.695V92.6318H153.65C153.671 92.7737 153.686 92.9157 153.698 93.0606Z" fill="#8D8D8D" />
                    <path d="M153.698 93.8667C153.689 94.0116 153.671 94.1535 153.65 94.2954H108.695V93.8667H153.698Z" fill="#8D8D8D" />
                    <path d="M153.463 95.1016C153.418 95.2465 153.367 95.3914 153.306 95.5303H108.692V95.1016H153.463Z" fill="#8D8D8D" />
                    <path d="M152.896 96.3335C152.805 96.4814 152.706 96.6234 152.6 96.7623H108.692V96.3335H152.896Z" fill="#8D8D8D" />
                    <path d="M151.849 97.5684C151.674 97.7223 151.487 97.8643 151.291 97.9971H108.692V97.5684H151.849Z" fill="#8D8D8D" />
                    <path d="M13.6025 126.951C13.5784 126.927 13.5602 126.897 13.5421 126.867C13.3973 126.622 13.4819 126.308 13.7263 126.163L16.9091 124.294C17.1534 124.149 17.4672 124.234 17.612 124.478C17.7568 124.723 17.6724 125.037 17.428 125.182L14.2451 127.051C14.0309 127.178 13.7624 127.129 13.6025 126.951Z" fill="#FBFBFB" />
                    <path d="M14.476 125.659C14.476 125.659 13.2481 125.08 13.1486 125.382C13.049 125.684 11.981 130.137 12.1831 130.21C12.874 130.448 14.289 126.918 14.292 126.547C14.295 126.173 14.657 125.741 14.476 125.659Z" fill="#FBFBFB" />
                    <path d="M53.8163 89.8583C53.8163 89.8583 56.1304 88.572 56.7459 88.8256C57.3613 89.0792 55.2977 90.5527 53.6987 90.5014L53.8163 89.8583Z" fill="#FBFBFB" />
                    <path d="M53.8023 89.646C54.095 89.96 54.3906 90.2529 54.6772 90.5518C50.0402 95.2802 46.1484 96.1377 40.2835 93.3569C39.3874 92.9311 39.4266 92.5839 39.852 91.8743C39.7675 91.7505 39.7766 91.7415 39.852 91.8743C40.2774 91.1648 40.5368 91.1225 41.4479 91.5513C47.4094 94.3653 48.4956 93.4927 53.8023 89.646Z" fill="#999999" />
                    <path d="M52.3917 112.685C52.3585 112.691 52.3223 112.691 52.2892 112.691C52.0056 112.676 51.7884 112.434 51.8035 112.15L52.0056 108.46C52.0207 108.177 52.262 107.959 52.5456 107.974C52.8292 107.989 53.0464 108.231 53.0313 108.515L52.8292 112.205C52.8171 112.452 52.627 112.651 52.3917 112.685Z" fill="#FBFBFB" />
                    <path d="M35.7896 105.993C36.7338 107.15 37.6752 108.213 38.7402 109.263C47.0005 102.032 51.9634 98.466 51.6496 110.791L53.3602 110.716C54.2834 96.6211 46.1044 98.1006 35.7896 105.993Z" fill="#464646" />
                    <path d="M16.1464 126.408C15.7964 125.962 15.4526 125.509 15.1509 125.029C23.0703 120.892 32.7396 112.395 37.3947 104.593C38.6467 105.387 39.8837 106.206 41.1146 107.027C35.4186 114.101 23.74 121.777 16.1464 126.408Z" fill="#464646" />
                    <path d="M51.647 111.839C51.647 111.839 51.0375 113.053 51.3392 113.161C51.6409 113.27 56.0638 114.448 56.1363 114.245C56.3897 113.56 52.9021 112.056 52.528 112.044C52.1539 112.032 51.7314 111.661 51.647 111.839Z" fill="#FBFBFB" />
                    <path d="M35.6812 106.084C36.4354 105.972 37.1685 106.637 38.9244 106.969C40.1161 107.195 41.1238 105.601 41.8267 105.867C40.3122 98.6263 40.7798 96.41 41.4827 94.6406C41.8327 93.759 41.691 92.7565 41.0906 92.0228L40.8552 91.736C39.7661 90.4044 37.6513 90.6792 36.9484 92.2523C36.713 92.7776 36.4838 93.3876 36.2816 94.0881C35.3192 97.4185 34.9119 101.193 35.6812 106.084Z" fill="#999999" />
                    <path d="M38.6805 88.7623C39.4468 90.115 41.0066 90.6766 41.8332 90.2086C42.6599 89.7406 42.4366 88.4181 41.6703 87.0684C40.904 85.7157 39.8451 85.4077 39.0215 85.8757C38.1919 86.3438 37.9142 87.4126 38.6805 88.7623Z" fill="#FBFBFB" />
                    <path d="M38.3347 89.5736C38.5127 89.8877 39.0527 90.1141 39.282 89.9843C39.5083 89.8544 39.4208 89.5676 39.2428 89.2536C39.0648 88.9396 38.7118 88.7494 38.4855 88.8792C38.2592 89.006 38.1567 89.2566 38.3347 89.5736Z" fill="#FBFBFB" />
                    <path d="M37.9952 88.013C37.9952 88.013 37.7901 86.9864 38.2909 86.2889C38.7917 85.5914 40.1252 84.906 41.0876 85.9447C41.0876 85.9447 41.7031 86.7448 40.6744 87.4061C39.6486 88.0673 39.5671 87.397 39.2895 88.1911C39.0119 88.9852 38.3392 88.8131 38.3 88.9852L37.9952 88.013Z" fill="#1A1C2E" />
                    <path d="M39.4559 88.4927L41.0729 88.707C41.0427 88.9275 40.7289 91.029 40.4664 91.7869C40.3518 92.0133 39.9324 92.0314 39.5282 91.8261C39.1662 91.6419 38.9399 91.343 38.9821 91.1195H38.9792C39.136 90.6666 39.3533 89.2687 39.4559 88.4927Z" fill="#FBFBFB" />
                    <path d="M32.3459 106.84C32.3459 106.84 31.0577 109.303 31.3383 109.944C31.6189 110.584 33.1123 108.379 33.0157 106.701L32.3459 106.84Z" fill="#FBFBFB" />
                    <path d="M33.2942 107.095C32.8718 107.192 32.4584 107.28 32.0511 107.367C30.8655 98.8222 30.1232 98.182 36.6247 92.264C37.6203 91.3582 37.6686 91.7175 38.2086 92.4301C38.3836 92.3939 38.4017 92.418 38.2086 92.4301C38.7456 93.1427 38.7788 93.2997 37.7621 94.0877C31.1098 99.257 33.0226 99.2207 33.2942 107.095Z" fill="#999999" />
                    <path d="M61.8367 72.1249L59.7607 71.2476L49.4344 95.7218L51.5104 96.5991L61.8367 72.1249Z" fill="#292B3B" />
                    <path d="M59.3639 79.7297L56.0371 78.3237L48.5426 96.0862L51.8694 97.4922L59.3639 79.7297Z" fill="#717171" />
                    <path d="M71.6448 46.1908C64.4102 43.1321 56.0684 46.5259 53.0153 53.7665C49.9621 61.007 53.3501 69.3557 60.5847 72.4113C67.8193 75.4699 76.1611 72.0761 79.2142 64.8356C82.2673 57.5981 78.8794 49.2494 71.6448 46.1908ZM61.0795 71.2307C54.4966 68.4498 51.4132 60.853 54.1918 54.2647C56.9704 47.6763 64.561 44.5905 71.1439 47.3714C77.7269 50.1522 80.8102 57.7491 78.0316 64.3374C75.253 70.9257 67.6624 74.0116 61.0795 71.2307Z" fill="#292B3B" />
                    <path opacity="0.61" d="M66.1162 72.2484C73.2609 72.2484 79.0528 66.4518 79.0528 59.3012C79.0528 52.1507 73.2609 46.354 66.1162 46.354C58.9716 46.354 53.1797 52.1507 53.1797 59.3012C53.1797 66.4518 58.9716 72.2484 66.1162 72.2484Z" fill="white" />
                    <path d="M168.856 76.7319C168.491 76.7319 168.192 77.0278 168.192 77.3962V83.897H158.677V77.3962C158.677 77.0308 158.381 76.7319 158.013 76.7319C157.645 76.7319 157.349 77.0278 157.349 77.3962V146.402C157.349 146.767 157.645 147.066 158.013 147.066C158.381 147.066 158.677 146.77 158.677 146.402V140.671H168.192V146.402C168.192 146.767 168.488 147.066 168.856 147.066C169.224 147.066 169.519 146.77 169.519 146.402V77.3962C169.516 77.0278 169.221 76.7319 168.856 76.7319ZM158.677 111.561V106.093H168.192V111.561H158.677ZM168.192 113.007V118.475H158.677V113.007H168.192ZM158.677 104.643V99.1751H168.192V104.643H158.677ZM158.677 119.921H168.192V125.39H158.677V119.921ZM168.192 97.7288H158.677V92.2607H168.192V97.7288ZM158.677 126.839H168.192V132.307H158.677V126.839ZM168.192 85.3432V90.8114H158.677V85.3432H168.192ZM158.677 139.222V133.753H168.192V139.222H158.677Z" fill="#999999" />
                    <path d="M180.919 134.509C180.95 134.5 180.983 134.485 181.01 134.467C181.248 134.331 181.33 134.026 181.191 133.788L179.405 130.69C179.269 130.451 178.965 130.37 178.726 130.509C178.488 130.645 178.407 130.95 178.545 131.188L180.331 134.286C180.452 134.494 180.699 134.582 180.919 134.509Z" fill="#C9C9C9" />
                    <path d="M181.071 133.365C181.071 133.365 182.175 134.08 181.976 134.319C181.777 134.557 178.654 137.704 178.495 137.574C177.948 137.127 180.13 134.162 180.434 133.969C180.742 133.775 180.914 133.259 181.071 133.365Z" fill="#464646" />
                    <path d="M159.334 82.8322C159.334 82.8322 159.092 80.2778 158.552 79.9245C158.012 79.5713 157.967 82.026 158.884 83.2761L159.334 82.8322Z" fill="#FBFBFB" />
                    <path d="M159.602 82.6899C159.186 82.7201 158.772 82.7473 158.371 82.8047C159.216 89.3417 160.233 92.0984 166.647 94.2784C167.627 94.6105 167.805 94.2392 167.896 93.4481C168.077 93.442 168.077 93.4451 167.896 93.4481C167.986 92.657 167.702 92.503 166.843 92.2192C161.213 90.3713 160.899 88.1943 159.602 82.6899Z" fill="#C9C9C9" />
                    <path d="M164.951 136.994C164.981 137.009 165.011 137.021 165.044 137.033C165.307 137.112 165.584 136.961 165.663 136.698L166.676 133.268C166.755 133.005 166.604 132.728 166.342 132.649C166.079 132.571 165.802 132.722 165.723 132.984L164.709 136.414C164.64 136.644 164.749 136.885 164.951 136.994Z" fill="#C9C9C9" />
                    <path d="M164.891 134.657C165.376 134.908 165.874 135.134 166.387 135.324C168.46 126.206 168.595 114.614 171.187 106.41C169.823 106.036 168.454 105.677 167.087 105.317C164.897 114.931 168.23 123.464 164.891 134.657Z" fill="#464646" />
                    <path d="M179.418 133.159C179.904 132.92 180.38 132.67 180.848 132.395C175.306 122.938 171.827 116.446 172.455 105.121C171.04 104.93 169.619 104.77 168.201 104.61C168.24 115.459 173.725 124.375 179.418 133.159Z" fill="#464646" />
                    <path d="M168.041 88.3C168.689 87.7656 169.82 87.6237 170.499 88.1189C171.178 88.6141 171.127 89.6709 170.632 90.3532C170.137 91.0326 168.387 91.3044 167.709 90.8092C167.03 90.314 167.22 88.9764 168.041 88.3Z" fill="#1A1C2E" />
                    <path d="M169.082 89.1881C168.445 90.5528 166.979 91.211 166.146 90.8215C165.313 90.432 165.428 89.1397 166.068 87.775C166.704 86.4102 167.703 86.0328 168.536 86.4223C169.368 86.8148 169.718 87.8263 169.082 89.1881Z" fill="#C9C9C9" />
                    <path d="M169.48 89.9454C169.332 90.2625 168.829 90.5222 168.599 90.4135C168.37 90.3048 168.433 90.0209 168.581 89.7039C168.729 89.3869 169.055 89.1755 169.284 89.2842C169.513 89.3929 169.628 89.6284 169.48 89.9454Z" fill="#C9C9C9" />
                    <path d="M169.686 88.4114C169.686 88.4114 169.807 87.403 169.27 86.7689C168.733 86.1348 167.393 85.5732 166.545 86.6481C166.545 86.6481 166.011 87.4663 167.055 88.028C168.096 88.5865 168.123 87.9344 168.452 88.6802C168.781 89.4259 169.414 89.2086 169.469 89.3716L169.686 88.4114Z" fill="#1A1C2E" />
                    <path d="M166.8 107.986C167.991 108.022 168.915 107.603 169.925 106.993C170.701 106.525 171.699 106.323 172.517 105.993C173.114 102.304 172.285 96.3072 170.846 93.943C169.581 91.8657 169.578 92.2099 169.12 92.0166C168.48 91.7479 167.744 91.8355 167.237 92.3095C167.228 92.3186 167.219 92.3276 167.207 92.3367C166.622 92.9013 166.604 94.1242 166.513 95.3953C166.423 96.6665 165.937 96.434 167.059 98.6472C167.545 99.6044 166.761 106.09 166.8 107.986Z" fill="#999999" />
                    <path d="M168.608 89.3867L167.037 89.5256C167.055 89.74 167.269 91.7872 167.489 92.533C167.589 92.7564 167.996 92.7926 168.394 92.6115C168.753 92.4484 168.983 92.1706 168.952 91.9532H168.955C168.823 91.5063 168.675 90.1416 168.608 89.3867Z" fill="#C9C9C9" />
                    <path d="M165.72 136.139C165.72 136.139 166.466 137.223 166.191 137.368C165.916 137.51 161.829 139.222 161.729 139.038C161.394 138.413 164.547 136.513 164.903 136.453C165.259 136.393 165.618 135.979 165.72 136.139Z" fill="#464646" />
                    <path d="M159.36 100.318C159.36 100.318 157.625 98.2589 156.946 98.277C156.267 98.2951 157.698 100.442 159.239 100.97L159.36 100.318Z" fill="#FBFBFB" />
                    <path d="M159.3 100.003C159.044 100.39 158.775 100.707 158.534 101.069C166.402 105.206 169.374 103.343 170.828 94.7132C171.051 93.3937 169.012 93.276 168.84 95.305C168.236 102.434 165.521 102.902 159.3 100.003Z" fill="#C9C9C9" />
                    <path d="M38.1011 83.0692L39.5944 86.8374L41.1964 86.2003L41.9356 85.527L44.7804 82.9424L38.1011 83.0692Z" fill="#FBFBFB" />
                    <path d="M33.1562 87.5617L36.9938 87.4922L37.9984 87.4711L39.5943 86.8371L38.101 83.0688L33.1562 87.5617Z" fill="#FBFBFB" />
                    <path d="M41.5363 84.2682L36.5957 86.2324L37.2239 87.8151L42.1645 85.8509L41.5363 84.2682Z" fill="#999999" />
                    <path d="M41.4364 84.2714C41.3911 84.1567 40.2476 84.5039 38.8839 85.0474C37.5203 85.5909 36.4493 86.1223 36.4945 86.2371C36.5398 86.3518 37.6832 86.0045 39.0469 85.4611C40.4135 84.9176 41.4816 84.3862 41.4364 84.2714Z" fill="#5D5D5D" />
                    <path d="M35.9653 85.0747L36.0774 88.2813" stroke="#717171" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M36.381 88.2722C36.375 88.1031 36.233 87.9702 36.0638 87.9763C35.8947 87.9823 35.7617 88.1242 35.7678 88.2933C35.7738 88.4624 35.9158 88.5952 36.0849 88.5892C36.2541 88.5831 36.3871 88.4413 36.381 88.2722Z" fill="#999999" />
                    <path d="M36.0675 88.0703L36.6498 89.7642L35.606 89.8004L36.0675 88.0703Z" fill="#999999" />
                    <path d="M148.428 85.294C148.464 85.294 148.498 85.291 148.534 85.2819C148.821 85.2246 149.008 84.9438 148.947 84.6539L148.187 80.9099C148.13 80.623 147.849 80.4358 147.559 80.4962C147.273 80.5536 147.086 80.8344 147.146 81.1242L147.906 84.8683C147.961 85.1189 148.181 85.294 148.428 85.294Z" fill="#FBFBFB" />
                    <path d="M149.094 84.5867C149.094 84.5867 149.423 85.9515 149.094 85.9907C148.765 86.03 144.035 86.1719 144.008 85.9515C143.914 85.2027 147.779 84.5082 148.159 84.5867C148.539 84.6652 149.052 84.3874 149.094 84.5867Z" fill="#464646" />
                    <path d="M126.55 39.9872C126.55 39.9872 125.047 37.6985 124.36 37.6351C123.672 37.5716 124.857 39.9117 126.348 40.6303L126.55 39.9872Z" fill="#FBFBFB" />
                    <path d="M126.641 39.5947C126.376 39.951 126.107 40.2861 125.848 40.6273C131.166 44.9421 135.267 45.3648 140.969 41.82C141.84 41.2765 141.759 40.9263 141.237 40.2439C141.3 40.0989 141.309 40.108 141.237 40.2439C140.715 39.5645 140.444 39.5524 139.557 40.0989C133.764 43.692 132.545 42.9251 126.641 39.5947Z" fill="#5D5D5D" />
                    <path d="M139.177 85.294C139.213 85.294 139.246 85.291 139.282 85.2819C139.569 85.2246 139.756 84.9438 139.696 84.6539L138.936 80.9099C138.878 80.623 138.598 80.4358 138.308 80.4962C138.021 80.5536 137.834 80.8344 137.895 81.1242L138.655 84.8683C138.709 85.1189 138.929 85.294 139.177 85.294Z" fill="#FBFBFB" />
                    <path d="M138.006 83.112C138.573 83.0969 139.137 83.0395 139.695 82.955C138.012 73.9541 136.739 61.8765 141.24 53.4644C139.873 52.7941 138.501 52.1389 137.125 51.4897C133.836 58.8209 136.636 75.3702 138.006 83.112Z" fill="#464646" />
                    <path d="M147.32 82.8588C147.884 82.6927 148.442 82.5146 148.985 82.2911C144.776 74.0602 141.696 61.101 142.432 51.7318C140.903 51.6532 139.37 51.6019 137.837 51.5566C138.414 60.9349 143.678 74.4044 147.32 82.8588Z" fill="#464646" />
                    <path d="M139.839 84.5872C139.839 84.5872 140.168 85.952 139.839 85.9912C139.51 86.0305 134.78 86.1724 134.753 85.952C134.659 85.2032 138.527 84.5087 138.904 84.5872C139.284 84.6627 139.797 84.3879 139.839 84.5872Z" fill="#464646" />
                    <path d="M142.64 54.3533C141.907 54.0635 140.848 53.7827 139.002 53.7042C137.747 53.6498 136.763 53.6951 135.994 53.7978C139.222 46.8622 139.783 43.405 139.49 41.4575C139.346 40.4852 139.723 39.513 140.501 38.9151L140.806 38.6826C142.218 37.5987 144.281 38.3716 144.619 40.1229C144.734 40.7087 144.818 41.3759 144.857 42.1308C145.041 45.7088 144.565 49.6068 142.64 54.3533Z" fill="#999999" />
                    <path d="M143.237 35.6935C142.631 37.1821 141.104 37.9671 140.196 37.5957C139.288 37.2243 139.339 35.8414 139.946 34.3529C140.552 32.8643 141.596 32.4054 142.504 32.7767C143.412 33.1481 143.841 34.2049 143.237 35.6935Z" fill="#C9C9C9" />
                    <path d="M143.699 36.4781C143.557 36.8253 143.035 37.1273 142.782 37.0246C142.531 36.9219 142.583 36.617 142.724 36.2697C142.866 35.9225 143.201 35.6809 143.451 35.7836C143.702 35.8893 143.838 36.1339 143.699 36.4781Z" fill="#C9C9C9" />
                    <path d="M143.836 34.8331C143.836 34.8331 143.908 33.7521 143.305 33.103C142.699 32.4538 141.239 31.9284 140.391 33.121C140.391 33.121 139.866 34.0239 141.009 34.5643C142.15 35.1048 142.144 34.4073 142.533 35.1863C142.922 35.9653 143.589 35.6966 143.655 35.8687L143.836 34.8331Z" fill="#1A1C2E" />
                    <path d="M142.978 35.7475L141.3 35.5845C141.276 35.8139 141.101 38.006 141.185 38.8303C141.246 39.084 141.665 39.2017 142.121 39.09C142.528 38.9904 142.827 38.7427 142.836 38.5072H142.839C142.787 38.0121 142.899 36.5507 142.978 35.7475Z" fill="#C9C9C9" />
                    <path d="M142.517 36.3026C142.225 36.0852 142.041 36.366 141.519 36.8099C141 37.2507 139.935 37.3685 139.6 36.0128C139.386 35.1492 139.168 36.671 139.168 36.671C139.208 37.4047 139.485 37.8003 140.022 38.0207C140.767 38.3226 141.811 38.0599 142.638 37.3202C142.719 36.9397 142.716 36.4506 142.517 36.3026Z" fill="#1A1C2E" />
                    <path d="M139.543 35.9673C139.543 35.9673 139.565 35.9733 139.604 35.9854C139.921 36.085 141.369 36.5681 141.604 37.0844C141.87 37.6642 140.856 36.9848 140.524 36.6436C140.192 36.3024 139.89 36.4171 139.595 36.3869C139.302 36.3598 139.109 36.0367 139.543 35.9673Z" fill="#1A1C2E" />
                    <path d="M155.431 37.5475C155.431 37.5475 155.766 34.6912 156.382 34.3077C156.997 33.9242 156.985 36.6809 155.923 38.0578L155.431 37.5475Z" fill="white" />
                    <path d="M155.343 36.8706C155.76 37.0427 156.173 37.2148 156.559 37.3507C153.756 44.2168 149.409 48.0092 143.517 41.7017C142.615 40.7354 142.992 40.6841 143.538 40.1285C143.532 39.9866 143.565 39.9202 143.538 40.1285C144.087 39.5729 144.47 39.3163 145.225 40.2644C150.154 46.4572 152.26 41.75 155.343 36.8706Z" fill="#5D5D5D" />
                    <path d="M143.478 29.5365L141.982 33.3047L140.383 32.6706L139.641 31.9973L136.796 29.4097L143.478 29.5365Z" fill="#C9C9C9" />
                    <path d="M148.422 34.032L144.582 33.9596L143.577 33.9414L141.981 33.3043L143.477 29.5361L148.422 34.032Z" fill="#C9C9C9" />
                    <path d="M140.191 30.6503L139.562 32.2329L144.503 34.1971L145.131 32.6145L140.191 30.6503Z" fill="#7D7D7D" />
                    <path d="M140.136 30.7377C140.181 30.623 141.324 30.9702 142.688 31.5137C144.052 32.0572 145.123 32.5886 145.077 32.7034C145.032 32.8181 143.889 32.4709 142.525 31.9274C141.161 31.3869 140.09 30.8525 140.136 30.7377Z" fill="#696969" />
                    <path d="M145.613 31.5415L145.501 34.7511" stroke="#1A1C2E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M145.193 34.739C145.199 34.5699 145.341 34.437 145.51 34.4431C145.679 34.4491 145.812 34.591 145.806 34.7601C145.8 34.9292 145.658 35.0621 145.489 35.056C145.319 35.053 145.187 34.9111 145.193 34.739Z" fill="#1A1C2E" />
                    <path d="M145.508 34.5405L144.926 36.2314L145.97 36.2677L145.508 34.5405Z" fill="#1A1C2E" />
                    <path d="M170.284 83.5951L168.791 87.3633L167.189 86.7292L166.45 86.0559L163.605 83.4683L170.284 83.5951Z" fill="#464646" />
                    <path d="M175.228 88.0906L171.391 88.0181L170.386 88L168.79 87.3629L170.283 83.5947L175.228 88.0906Z" fill="#464646" />
                    <path d="M166.996 84.7034L166.368 86.2861L171.308 88.2503L171.936 86.6676L166.996 84.7034Z" fill="#C9C9C9" />
                    <path d="M166.947 84.7973C166.992 84.6826 168.136 85.0298 169.499 85.5733C170.863 86.1168 171.934 86.6482 171.889 86.7629C171.844 86.8777 170.7 86.5304 169.337 85.9869C167.97 85.4465 166.902 84.912 166.947 84.7973Z" fill="#999999" />
                    <path d="M172.42 85.6001L172.308 88.8097" stroke="#1A1C2E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M172.003 88.7976C172.009 88.6285 172.151 88.4956 172.32 88.5017C172.489 88.5077 172.622 88.6496 172.616 88.8187C172.61 88.9878 172.468 89.1206 172.299 89.1146C172.13 89.1085 171.997 88.9666 172.003 88.7976Z" fill="#1A1C2E" />
                    <path d="M172.318 88.5986L171.735 90.2895L172.779 90.3257L172.318 88.5986Z" fill="#1A1C2E" />
                    <path d="M124.75 25.0396H113.168V40.7405H124.75V25.0396Z" fill="white" />
                    <path d="M123.414 28.2998H114.508V28.8946H123.414V28.2998Z" fill="#8D8D8D" />
                    <path d="M121.17 26.2319H116.753V26.8267H121.17V26.2319Z" fill="#8D8D8D" />
                    <path d="M123.414 29.5347H114.508V30.1295H123.414V29.5347Z" fill="#8D8D8D" />
                    <path d="M123.414 30.771H114.508V31.3658H123.414V30.771Z" fill="#8D8D8D" />
                    <path d="M123.414 32.0054H114.508V32.6002H123.414V32.0054Z" fill="#8D8D8D" />
                    <path d="M123.414 33.2402H114.508V33.835H123.414V33.2402Z" fill="#8D8D8D" />
                    <path d="M123.414 34.4761H114.508V35.0709H123.414V34.4761Z" fill="#8D8D8D" />
                    <path d="M123.414 35.7104H114.508V36.3053H123.414V35.7104Z" fill="#8D8D8D" />
                    <path d="M129.894 125.623L126.524 122.969L123.157 125.623V105.864H129.894V125.623Z" fill="#999999" />
                    <path d="M73.7474 128.57L70.3806 125.916L67.0107 128.57V119.412H73.7474V128.57Z" fill="#999999" />
                    <path d="M165.428 43.8596C162.976 43.8596 160.915 42.0238 160.628 39.5872L159.395 29.0947H171.462L170.228 39.5872C169.945 42.0238 167.881 43.8596 165.428 43.8596Z" fill="#C9C9C9" />
                    <path d="M166.147 40.8403H164.714V50.125H166.147V40.8403Z" fill="#C9C9C9" />
                    <path d="M168.557 50.9554H162.3C162.3 49.9228 163.135 49.0864 164.167 49.0864H166.686C167.721 49.0864 168.557 49.9228 168.557 50.9554Z" fill="#C9C9C9" />
                    <path d="M160.224 30.8428H156.432C155.78 30.8428 155.304 31.4618 155.473 32.0928L156.749 36.8816C157.114 38.2554 158.357 39.2096 159.775 39.2096H161.808" stroke="#999999" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M170.633 30.8428H174.425C175.077 30.8428 175.553 31.4618 175.384 32.0928L174.111 36.8816C173.746 38.2554 172.503 39.2096 171.085 39.2096H169.052" stroke="#999999" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M168.047 38.4359C169.504 36.9773 169.504 34.6125 168.047 33.154C166.589 31.6954 164.227 31.6953 162.769 33.1539C161.312 34.6125 161.312 36.9773 162.769 38.4359C164.227 39.8945 166.59 39.8945 168.047 38.4359Z" fill="#FBFBFB" />
                    <path d="M167.355 37.7421C168.43 36.6667 168.43 34.9232 167.355 33.8478C166.281 32.7725 164.539 32.7724 163.464 33.8478C162.39 34.9231 162.39 36.6667 163.464 37.7421C164.539 38.8174 166.281 38.8174 167.355 37.7421Z" fill="#999999" />
                    <path d="M80.3361 76.5148C82.0923 76.5148 83.516 75.09 83.516 73.3324C83.516 71.5747 82.0923 70.1499 80.3361 70.1499C78.5799 70.1499 77.1562 71.5747 77.1562 73.3324C77.1562 75.09 78.5799 76.5148 80.3361 76.5148Z" fill="white" />
                    <path d="M91.4938 40.0265C93.25 40.0265 94.6737 38.6017 94.6737 36.8441C94.6737 35.0865 93.25 33.6616 91.4938 33.6616C89.7376 33.6616 88.314 35.0865 88.314 36.8441C88.314 38.6017 89.7376 40.0265 91.4938 40.0265Z" fill="white" />
                    <path d="M82.5295 31.7467C83.7642 31.7467 84.765 30.745 84.765 29.5094C84.765 28.2737 83.7642 27.272 82.5295 27.272C81.2949 27.272 80.2939 28.2737 80.2939 29.5094C80.2939 30.745 81.2949 31.7467 82.5295 31.7467Z" fill="white" />
                    <path d="M51.1466 74.4645C52.3812 74.4645 53.3822 73.4628 53.3822 72.2271C53.3822 70.9915 52.3812 69.9897 51.1466 69.9897C49.9119 69.9897 48.9111 70.9915 48.9111 72.2271C48.9111 73.4628 49.9119 74.4645 51.1466 74.4645Z" fill="white" />
                    <path d="M87.0632 67.1305C88.2979 67.1305 89.2988 66.1288 89.2988 64.8931C89.2988 63.6574 88.2979 62.6558 87.0632 62.6558C85.8286 62.6558 84.8276 63.6574 84.8276 64.8931C84.8276 66.1288 85.8286 67.1305 87.0632 67.1305Z" fill="white" />
                </svg>

            }
        </>


    )
}

export default EmptyTimeLine;