import { useState } from "react";
import TimeLinesList from "components/TimeLinesList/TimeLinesList";
import { SHARED_TIMELINES } from "../../api/endpoints";
import { useTranslation } from "react-i18next";
import { websiteIstance } from "../../api/axios";
import TimeLineCard from "components/TimeLineCard/TimeLineCard";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import Filters from "components/Filters/Filters";


export default function MyTimeLine() {
    const [isGrid, setIsGrid] = useState(true);
    const [isFiltersOpen, setIsFiltersOpenOpen] = useState(false);
    const [timelines, setTimelines] = useState([]);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const { t } = useTranslation(["timeline"]);
    const userId = +localStorage.getItem("userId")

    const headline = t("Shared with me");
    const data = timelines || [1]

    const filtersToggle = () => {
        setIsFiltersOpenOpen(prevValue => !prevValue)
    }
    async function fetchData(page) {
        if(!window.isAuth) return
        const endpoint = SHARED_TIMELINES.replace(":userId", userId)
        const response = await websiteIstance.request({
            method: "get",
            url: page ? endpoint.replace("?", `?page=${page}`) : endpoint.replace("?", `?page=0`)
        });
        const data = response?.data?.data;
        const totalPages = data?.totalPages;
        const currentPage = data?.page;
        setCurrentPage(currentPage)
        setTotalPages(totalPages)
        setTimelines(prevTimelines => [...prevTimelines, ...data?.timelines])
    }


    const onLayoutChange = (isTypeGrid) => {
        setIsGrid(isTypeGrid)
    }


    const renderItem = (item, idx) => {
        return (
            <TimeLineCard
                thumbnail={item.thumbnail}
                countOfEvents={item.events?.length}
                isGrid={isGrid}
                key={item.id + "_" + idx}
                path={`/timelines/${item.id}`}
                title={item.title}
                createdAt={item.createdAt}
                isShared={true}
            />
        )
    }


    return (
        <>
            <BottomSheet handleClose={filtersToggle} open={isFiltersOpen}>
                <Filters onClose={filtersToggle} url={window.location.href} />
            </BottomSheet>
            <TimeLinesList
                headline={headline}
                isGrid={isGrid}
                isLoading={false}
                isShared={true}
                onLayoutChange={onLayoutChange}
                totalPages={totalPages}
                currentPage={currentPage}
                fetch={fetchData}
                data={data}
                renderItem={renderItem}
                onFilter={filtersToggle}
            />
        </>
    )

}