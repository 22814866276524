
import { useRef, useState } from "react";
import ReactSlick from "react-slick";
import LeftSquareArrow from "../Icons/LeftSquareArrow";
import RightSquareArrow from "../Icons/RightSquareArrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import Dots from "./Dots";


export default function StorySlider({ images }) {
    const [currentIndex, setCurrentIndex] = useState(1)
    const reactSlickRef = useRef(null);
    const reactSlickDotsRef = useRef(null);
    const mobilePageSlickSettings = {
        centerPadding: '100px',
        slidesToShow: 1,
        speed: 300,
        focusOnSelect: true,
        arrows: false,
        cssEase: "linear",
        dots: true,
        infinite: true,
        afterChange: (idx) => {
            setCurrentIndex(idx + 1)
        },
        appendDots: dots => (

            <div>
                <div className="story-slider-controlls">
                    <button  className="text-button" onClick={gotoPrev} >
                        <LeftSquareArrow />
                    </button>
                    <span>
                        <Dots numberOfDots={images.length > 7 ? 7 : images.length > 5 ? 5 : images.length > 3 ? 3 : images.length - 1} sliderRef={reactSlickDotsRef} >
                            {dots}
                        </Dots>
                    </span>
                    <button  className="text-button" onClick={gotoNext} >
                        <RightSquareArrow />
                    </button>
                </div>
            </div>
          
        ),
        customPaging: () => {
            return (
                <div className={"custom-dot"} />
            )
        },
       
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerPadding: '50px',
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerPadding: '50px',
                    slidesToShow: 1,
                }
            }
        ],
    };
    function gotoNext() {
        reactSlickRef.current.slickNext();
        reactSlickDotsRef.current.slickNext();
    }
    function gotoPrev() {
        reactSlickRef.current.slickPrev();
        reactSlickDotsRef.current.slickPrev();
    }

    return (
        <div className="story-slider-wrapper">
            <ReactSlick ref={reactSlickRef} {...mobilePageSlickSettings} >
                {(images || []).map(img => <div className="slide">
                    <div className="slide-counter-chip">
                        <span>
                            {currentIndex} / {images.length || 0}
                        </span>
                    </div>
                    <div className="slide-img-wrapper">
                        <img alt="memory of family" src={img} />
                    </div>
                </div>)}             
            </ReactSlick>          
        </div>
    );
}