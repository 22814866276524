import { useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CustomTypography from "../../../../components/CustomTypography/CustomTypography";
import SquareClose from "../../../../components/Icons/SquareClose";
import Gallery from "../../../../components/StorySlider/StorySlider";
import "./style.css";

const MemoryStory = ({ handleClose, open }) => {
    var elem = document.getElementById("html");
    function openFullscreen() {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }
    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }
    // useEffect(() => {
      
    //     if (open){
    //         openFullscreen()
    //     } else {
    //         closeFullscreen()
    //     }
    // }, [open])
    return (
        <div id="memory-story" className="memory-story">
            <Container className="memory-story-header container">
                <CustomTypography className="memory-story-header--title" type="headline">
                    Mohammed’s birthday
                    <span>27/08/1996</span>
                </CustomTypography>
                <Button className="text-button" onClick={handleClose}>
                    <SquareClose />
                </Button>
            </Container>
            <div className="memory-story--gallery">
                <Gallery />
            </div>
            {/* <Container className="memory-story-footer">
                <div>
                    <SquareDeleteOutline />
                    <Button color="primary" fullWidth variant="contained">
                        {t("Edit Event")}
                    </Button>
                </div>
            </Container> */}
        </div>              
    )
}

export default MemoryStory;