import { useEffect, useState } from "react";

export default function UpdateWindowDimensions() {
  const [newWidth, setNewWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setNewWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  });

  return newWidth;
}
