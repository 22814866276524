const SquareEdit = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="37" height="37" rx="3.5" fill="#F3F3F3" stroke="#ECECEC" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.6167 13.0417L21.6583 19L19 16.3417L24.9583 10.3834C25.6917 9.65004 26.8833 9.65004 27.6167 10.3834C28.35 11.1167 28.35 12.3084 27.6167 13.0417ZM25.4167 18.0834V26.3334H12.5833V13.5H19V11.6667H12.5833C11.575 11.6667 10.75 12.4917 10.75 13.5V26.3334C10.75 27.3417 11.575 28.1667 12.5833 28.1667H25.4167C26.425 28.1667 27.25 27.3417 27.25 26.3334V18.0834H25.4167ZM17.9 17.1667L17.1667 20.8334L20.8333 20.1L17.9 17.1667Z" fill="black" />
        </svg>

    )
}

export default SquareEdit;