import "./style.css";
import Plus from "./PlusIcon";
import { useTranslation } from "react-i18next";

const ImageGallery = ({ images, handleClickOnImage, width, uploadMedia, showActions }) => {
  const { t } = useTranslation(["add-event"]);

  const remainingImages = images.length - 3;

  if (width <= 1024 && images.length === 1) {
    return (
      <div style={{ display: "flex", position: 'relative' }}>
        {images.map((image, index) => {
          return (
            <div key={index} style={{ width: "100%", height: "213px" }}>
              <img
                src={image}
                alt={image?.alt}
                onClick={() => handleClickOnImage(index)}
                style={{
                  height: "100%",
                  width: "100%",
                  // padding: "5px",
                  objectFit: "cover",
                  // borderRadius: "6px",
                }}
              />

            </div>
          );
        })}
        {showActions && <button className="upload-media-btn" onClick={uploadMedia}>
          <Plus /> {t("PHOTOS/AUDIO")}
        </button>}
      </div>
    );
  }

  if (width <= 1024 && images.length === 2) {
    return (
      <div style={{ display: "flex", gap: '0px', position: 'relative' }}>
        {images.map((image, index) => {
          return (
            <div key={index} style={{ height: "213px", flex: '0 0 50%', width: '100%' }}>
              <img
                src={image}
                alt={image?.alt}
                onClick={() => handleClickOnImage(index)}
                style={{
                  height: "100%",
                  width: "100%",
                  // padding: "5px",
                  objectFit: "cover",
                  // borderRadius: "6px",
                }}
              />
            </div>
          );
        })}
        {showActions && <button className="upload-media-btn" onClick={uploadMedia}>
          <Plus /> {t("PHOTOS/AUDIO")}
        </button>}
      </div>
    );
  }
  if (width <= 1024) {
    return (
      <div style={{ display: "flex", gap: "0px", height: "233px", width: '100%' }}>
        <img
          src={images[0]}
          alt={images[0]?.alt}
          style={{
            width: "64%",
            objectFit: "cover",
            // borderRadius: "6px",
            height: '100%'

            // padding: "5px",
          }}
          onClick={() => handleClickOnImage(0)}
        />

        <div
          style={{ width: "36%", display: "flex", flexDirection: "column", gap: "0px", position: 'relative' }}
        >

          {images.slice(1, 3).map((image, index) => {
            return (
              <div key={index} style={{ position: "relative", height: "116.5px", width: '100%' }}>
                <img
                  src={image}
                  alt={image.alt}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    // borderRadius: "6px",
                    // maxHeight: "104px"
                    // padding: "5px",
                  }}
                  onClick={() => handleClickOnImage(index + 1)}
                />

                {index === 1 && images.length > 3 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      color: "#FBFBFB",
                      fontSize: "26px",
                      background: "rgba(0, 0, 0, .5)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: 'column',
                      // borderRadius: "6px",
                      width: "calc(100%)",
                      height: "calc(100%)",
                      padding: "0px",
                    }}
                    onClick={() => handleClickOnImage(index + 1)}
                  >
                    <div>

                      {`+${remainingImages}`}
                    </div>

                  </div>
                )}

              </div>
            );
          })}
          {showActions && <button className="upload-media-btn" onClick={uploadMedia}>
            <Plus /> {t("PHOTOS/AUDIO")}
          </button>}
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: images.length === 1 ? "block" : "flex", gap: "0px", height: "318px", position: 'relative' }}>
      <img
        src={images[0]}
        alt={images[0]?.alt}
        style={{
          width: images.length === 1 ? "100%" : (images.length === 2 ? '50%' : "70%"),
          objectFit: "cover",
          // borderRadius: "6px",
          height: '100%'

          // padding: "5px",
        }}
        onClick={() => handleClickOnImage(0)}
      />
      {showActions && <button className="upload-media-btn" onClick={uploadMedia}>
        <Plus /> {t("PHOTOS/AUDIO")}
      </button>}
      <div
        style={{ width: images.length === 2 ? "50%" : "30%", display: "flex", flexDirection: "column", gap: "0px", position: 'relative' }}
      >

        {images.slice(1, 3).map((image, index) => {
          return (
            <div key={index} style={{ position: "relative", height: images.length === 2 ? '100%' : "158.5px" }}>
              <img
                src={image}
                alt={image.alt}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  // borderRadius: "6px",
                  // maxHeight: "104px"
                  // padding: "5px",
                }}
                onClick={() => handleClickOnImage(index + 1)}
              />

              {index === 1 && images.length > 3 && (
                <div
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    color: "#FBFBFB",
                    fontSize: "26px",
                    background: "rgba(0, 0, 0, .5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: 'column',
                    // borderRadius: "6px",
                    width: "calc(100%)",
                    height: "calc(100%)",
                    padding: "0px",
                  }}
                  onClick={() => handleClickOnImage(index + 1)}
                >
                  <div>

                    {`+${remainingImages}`}
                  </div>

                </div>
              )}

            </div>
          );
        })}

      </div>
      {showActions && <button className="upload-media-btn" onClick={uploadMedia}>
        <Plus /> {t("PHOTOS/AUDIO")}
      </button>}
    </div>
  );
};

export default ImageGallery;
