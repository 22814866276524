import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader({style={}}) {
    return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <CircularProgress style={style} />
        </Box>
    );
}