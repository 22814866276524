import { Navbar, ScrollToTop } from "components";

export default function MainLayout({ children }) {
  return (
    <main>
      <Navbar />
      <ScrollToTop />
      <div>{children}</div>
    </main>
  );
}
