// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 700px) {
    .home-page-wrapper .home-page--container {
            padding-top: 29px;
    }
    .home-page-wrapper .home-page--container .home-page--container---cards {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 18px;
        margin-bottom: 20px;
    }
    .home-page-wrapper .home-page--container .home-page--container---cards .media-card {
        width: 552px;
        display: flex;
        flex-direction: column;
        padding: 30px;
    }
    .home-page-wrapper .home-page--container .home-page--container---cards .media-card .media-card--content {
        min-height: 98px;
        /* padding: 0 31px; */
    }
    .home-page-wrapper .home-page--container .home-page--container---footer {
        display: none;
    }

    .media-card .media-card-image-wrapper {
        /* padding: 0 31px; */
        height: 370px;
        margin-bottom: 47px;
    }
    .media-card .media-card-image-wrapper .media-card--image {
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: unset;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/Home/homeStyleDesktop.css"],"names":[],"mappings":"AAAA;IACI;YACQ,iBAAiB;IACzB;IACA;QACI,gBAAgB;QAChB,aAAa;QACb,mBAAmB;QACnB,uBAAuB;QACvB,SAAS;QACT,mBAAmB;IACvB;IACA;QACI,YAAY;QACZ,aAAa;QACb,sBAAsB;QACtB,aAAa;IACjB;IACA;QACI,gBAAgB;QAChB,qBAAqB;IACzB;IACA;QACI,aAAa;IACjB;;IAEA;QACI,qBAAqB;QACrB,aAAa;QACb,mBAAmB;IACvB;IACA;QACI,SAAS;QACT,WAAW;QACX,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":["@media (min-width: 700px) {\n    .home-page-wrapper .home-page--container {\n            padding-top: 29px;\n    }\n    .home-page-wrapper .home-page--container .home-page--container---cards {\n        margin-top: 10px;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        gap: 18px;\n        margin-bottom: 20px;\n    }\n    .home-page-wrapper .home-page--container .home-page--container---cards .media-card {\n        width: 552px;\n        display: flex;\n        flex-direction: column;\n        padding: 30px;\n    }\n    .home-page-wrapper .home-page--container .home-page--container---cards .media-card .media-card--content {\n        min-height: 98px;\n        /* padding: 0 31px; */\n    }\n    .home-page-wrapper .home-page--container .home-page--container---footer {\n        display: none;\n    }\n\n    .media-card .media-card-image-wrapper {\n        /* padding: 0 31px; */\n        height: 370px;\n        margin-bottom: 47px;\n    }\n    .media-card .media-card-image-wrapper .media-card--image {\n        margin: 0;\n        width: 100%;\n        height: 100%;\n        object-fit: unset;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
