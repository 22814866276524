import BottomCheveron from "../BottomCheveron";
import HorizontalDivider from "../HorizontalDivider";
import getCategoryColor from "../../../../helpers/getCategoryColor";
import getCategoryIcon from "../../../../helpers/getCategoryIcon";
import UseWidth from "hooks/useUpdateWindowDimensions";
import Headset from "components/Icons/Headset";
import { useTranslation } from "react-i18next";
import months from "data/months.json";
import convertToTwoDigits from "../../../../helpers/convertToTwoDigits";
import "./style.css";


const MemoryCard = ({ title, details, thumbnail, category, onClick, date, files }) => {
    const width = UseWidth()
    const color = getCategoryColor(category);
    const Icon = getCategoryIcon(category);
    const { t } = useTranslation(["memories"]);

    // console.log(details)
    return (
        <div style={width >= 700 ? {
            display: 'flex',
            gap: 22,
            marginBottom: 10,
            maxWidth: 894,
            width: 894
        } : {}}>
            <div className="memory-card-avatar-wrapper" style={width < 700 ? {
                display: 'none',
                width: 1

            } : { ...(!thumbnail && { alignItems: "center" }), borderColor: color, backgroundImage: `url(${thumbnail})`, minWidth: 146, height: 146, visibility: (!thumbnail && !files?.voices?.length) ? "hidden" : "visible" }}>
                {files?.voices?.length ? <div className={`headset-icon ${!thumbnail && "no-background"}`}>
                    <Headset color={thumbnail ? "#FBFBFB" : color} />
                </div> : null}
            </div>
            <div className="memory-card-wrapper" style={width >= 700 ? {
                width: '100%'
            } : {}} role={"button"} onClick={onClick}>
                <div className="memory-card-wrapper--top-section">

                    <div>
                        <BottomCheveron color={color} />
                    </div>
                    <div className="memory-card-wrapper--top-section-icon">

                        {Icon}
                    </div>
                    <div>
                        <p className="title-card">
                            {title || t(category)}
                        </p>
                    </div>
                    {width >= 700 && <div style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <p style={{
                            fontStyle: "italic",
                            fontWeight: 700,
                            fontSize: 16,
                            margin: 0
                        }}>
                            <span style={{ padding: '0 5px' }}>
                                {date && date.day && date.month && `${convertToTwoDigits(date.day)}`}
                            </span>
                            <span>
                                {date && date.month && `${months[date.month]}`}
                            </span>
                        </p>
                    </div>}
                </div>
                <div className="memory-card-divider-avatar-wrapper">
                    <div className="memory-card-divider-wrapper" style={(width >= 700 || (!thumbnail && !files?.voices?.length)) ? {
                        width: '100%'
                    } : {}}>
                        <HorizontalDivider color={color} />
                    </div>
                    <div className="memory-card-avatar-wrapper" style={(width >= 700 || (!thumbnail && !files?.voices?.length)) ? {
                        visibility: 'hidden',
                        width: 1

                    } : { ...(!thumbnail && { alignItems: "center" }), borderColor: color, backgroundImage: `url(${thumbnail})` }}>
                        {files?.voices?.length ? <div className={`headset-icon ${!thumbnail && "no-background"}`}>
                            <Headset color={thumbnail ? "#FBFBFB" : color} />
                        </div> : null}
                    </div>
                </div>
                <div className="memory-card-wrapper--bottom-section">
                    {(details || (date && date.day)) && <div className="memory-card-wrapper--bottom-section---content">
                        <div>
                            <BottomCheveron color={color} />
                        </div>

                        <div>
                            <p>
                                {

                                    date && date.day && date.month &&
                                    width < 700 && <div>
                                        <p style={{
                                            fontStyle: "italic",
                                            fontWeight: 700,
                                            fontSize: 12,
                                            margin: 0
                                        }}>
                                            <span style={{ padding: '0 5px' }}>
                                                {date && date.day && date.month && `${date.day}`}
                                            </span>
                                            <span>
                                                {date && date.month && `${months[date.month]}`}
                                            </span>
                                        </p>
                                    </div>
                                }
                                <div dangerouslySetInnerHTML={{ __html: width < 700 ? (details ? details.substr(0,130) : '') :  (details ? details.substr(0,300) : '') }}></div>
                            </p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default MemoryCard;