

const Download = () => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6673 20.6683L4.14732 13.9617C3.91614 13.7239 3.59996 13.5876 3.26833 13.5829C2.9367 13.5782 2.6168 13.7055 2.37898 13.9367C2.14117 14.1678 2.00494 14.484 2.00025 14.8157C1.99556 15.1473 2.1228 15.4672 2.35398 15.705L11.104 24.705C11.2205 24.825 11.3599 24.9203 11.5139 24.9854C11.6679 25.0505 11.8334 25.0841 12.0007 25.0841C12.1679 25.0841 12.3334 25.0505 12.4874 24.9854C12.6414 24.9203 12.7808 24.825 12.8973 24.705L21.6473 15.705C21.7618 15.5872 21.8519 15.4481 21.9126 15.2955C21.9733 15.1429 22.0034 14.9799 22.0011 14.8157C21.9987 14.6515 21.9641 14.4893 21.8991 14.3385C21.8341 14.1877 21.7401 14.0511 21.6223 13.9367C21.5046 13.8222 21.3654 13.732 21.2128 13.6713C21.0602 13.6107 20.8972 13.5806 20.733 13.5829C20.5688 13.5853 20.4066 13.6199 20.2558 13.6849C20.105 13.7499 19.9685 13.8439 19.854 13.9617L13.334 20.6683V1.32167C13.3309 0.97007 13.1891 0.633922 12.9394 0.386391C12.6896 0.13886 12.3523 -1.34593e-05 12.0007 9.78391e-10C11.259 9.7839e-10 10.6673 0.591667 10.6673 1.32167V20.6667V20.6683ZM0.333984 28.3333C0.333984 27.4133 1.08398 26.6667 2.01232 26.6667H21.989C22.9157 26.6667 23.6673 27.4067 23.6673 28.3333C23.6673 29.2533 22.9173 30 21.989 30H2.01232C1.79253 30.0013 1.57465 29.9592 1.37119 29.876C1.16773 29.7929 0.982707 29.6704 0.826749 29.5155C0.670791 29.3606 0.546973 29.1765 0.462409 28.9736C0.377844 28.7707 0.3342 28.5531 0.333984 28.3333Z" fill="#333333" />
        </svg>
    )
}

export default Download;