import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import StyledButton from "components/StyledButton/StyledButton";

import Notfound404 from "components/Icons/Notfound404";
import CustomTypography from "components/CustomTypography/CustomTypography";

import "./style.css";

const EmptyState = ({onCreate}) => {
    const { t } = useTranslation("timeline");
    const navigate = useNavigate();


    const onBack = () => navigate("/")
    const gotoMytimeline = () => navigate("/my-timeline")

    return (
        <Container className="container">
            <div className="not-found-404-wrapper">
                <Notfound404 />
                <CustomTypography className="not-found-404-wrapper--text" type="subHeadLine">                    
                    {t("Page Not Found!")}
                </CustomTypography>
                <CustomTypography className="not-found-404-wrapper--text-description" type="normal">                    
                    {t("notFoundDescription")}
                </CustomTypography>       
                <div className="not-found-404-actions-wrapper">
                   <StyledButton className="not-found-404-create-btn" onClick={gotoMytimeline} color="primary" fullWidth variant="contained">
                        {t("go to my timelines")}
                    </StyledButton>
                    <StyledButton onClick={onBack} className="not-found-404-wrapper---back-button" color="primary" fullWidth variant="contained">
                        <span>
                            {t("Back to home")}
                        </span>
                    </StyledButton>
                </div>         
            </div>
        </Container>
    )
}

export default EmptyState;
