import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import StyledButton from "components/StyledButton/StyledButton";

export default function ReusableDialog({ open, handleClose, handleAccept, children, title, agreeText, disagreeText, loading }) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title &&<DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>}
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={handleClose}>{disagreeText || "Disagree"}</StyledButton>
                    <StyledButton loading={loading} onClick={handleAccept} 
                        color="danger"
                    >
                        {agreeText || "Agree"} {loading && "...."}
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}