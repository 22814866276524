import Button from '@mui/material/Button';
import cls from "classnames";
import LoadingButton from '@mui/lab/LoadingButton';

import './style.css';

const StyledButton = ({ onClick, className, children, variant, type, loading, color, ...rest }) => {
    const buttonClassname = cls("styled-button-wrapper", {
        [className]: !!className,
        "outlined": variant === "outlined"
    })
    if (loading) {
        return (
            <LoadingButton  color={color} loading={loading} variant={variant} onClick={onClick} className={buttonClassname} {...rest}>
                {children}
            </LoadingButton>
        )
    }
    return (
        <Button type={type} color={color} variant={variant} onClick={onClick} className={buttonClassname} {...rest}>
            {children}
        </Button>
    )
}

export default StyledButton;