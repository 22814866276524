// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-wrapper {
    position: relative;
}
.upload-media-btn {
    position: absolute;
    right: 12px;
    bottom: 20px;
    display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 20px;
        gap: 6px;
        background: #FFFFFF;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            border: none;
            font-family: 'Verdana';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                /* identical to box height, or 129% */
            
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.0125em;
                text-transform: uppercase;
            
                color: #0084A6;
}`, "",{"version":3,"sources":["webpack://./src/pages/AddEvent/components/Cover/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;QACT,mBAAmB;QACnB,uBAAuB;QACvB,mBAAmB;QACnB,iBAAiB;QACjB,QAAQ;QACR,mBAAmB;YACf,2CAA2C;YAC3C,kBAAkB;YAClB,YAAY;YACZ,sBAAsB;gBAClB,kBAAkB;gBAClB,gBAAgB;gBAChB,eAAe;gBACf,iBAAiB;gBACjB,qCAAqC;;gBAErC,aAAa;gBACb,mBAAmB;gBACnB,kBAAkB;gBAClB,wBAAwB;gBACxB,yBAAyB;;gBAEzB,cAAc;AAC9B","sourcesContent":[".cover-wrapper {\n    position: relative;\n}\n.upload-media-btn {\n    position: absolute;\n    right: 12px;\n    bottom: 20px;\n    display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n        padding: 9px 20px;\n        gap: 6px;\n        background: #FFFFFF;\n            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);\n            border-radius: 4px;\n            border: none;\n            font-family: 'Verdana';\n                font-style: normal;\n                font-weight: 400;\n                font-size: 14px;\n                line-height: 18px;\n                /* identical to box height, or 129% */\n            \n                display: flex;\n                align-items: center;\n                text-align: center;\n                letter-spacing: 0.0125em;\n                text-transform: uppercase;\n            \n                color: #0084A6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
