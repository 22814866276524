// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky-bottom-actions {
    box-sizing: border-box;
    position: fixed;
    height: var(--sticky-bottom-actions-height);
    background: #FFFFFF;
    border: 1px solid rgba(225, 223, 215, 0.2);
    box-shadow: 0px -16px 31px -18px rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0px 0px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 17px;
}
.sticky-bottom-actions div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.06em;
    cursor: pointer;
}

[lang='fr'] .sticky-bottom-actions div {
    flex: 1 1;
    font-size: 0.75rem;
}

@media (min-width: 900px) {
    .sticky-bottom-actions {
          display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/StickyBottomActions/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,2CAA2C;IAC3C,mBAAmB;IACnB,0CAA0C;IAC1C,mDAAmD;IACnD,8BAA8B;IAC9B,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,8BAA8B;IAC9B,aAAa;AACjB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,QAAQ;IACR,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI;UACM,aAAa;IACnB;AACJ","sourcesContent":[".sticky-bottom-actions {\n    box-sizing: border-box;\n    position: fixed;\n    height: var(--sticky-bottom-actions-height);\n    background: #FFFFFF;\n    border: 1px solid rgba(225, 223, 215, 0.2);\n    box-shadow: 0px -16px 31px -18px rgba(0, 0, 0, 0.1);\n    border-radius: 4px 4px 0px 0px;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    justify-content: space-between;\n    padding: 17px;\n}\n.sticky-bottom-actions div{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    gap: 8px;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 17px;\n    text-align: center;\n    letter-spacing: -0.06em;\n    cursor: pointer;\n}\n\n[lang='fr'] .sticky-bottom-actions div {\n    flex: 1;\n    font-size: 0.75rem;\n}\n\n@media (min-width: 900px) {\n    .sticky-bottom-actions {\n          display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
