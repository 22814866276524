import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BottomSheet from "components/Modals/BottomSheet/BottomSheet";
import Mic from "assets/icons/mic.svg";
import CrossMenu from "assets/icons/cross-menu.svg";
import RecorderPause from "assets/icons/recorder-pause.svg";
import RecorderDelete from "assets/icons/recorder-delete.svg";
import RecorderDone from "assets/icons/recorder-done.svg";
import RecordButton from "assets/icons/record-button.svg";
import RecorderPlay from "assets/icons/recorder-play.svg";
import { useReactMediaRecorder } from "react-media-recorder";

import "./style.css";

const MAX_RECORDING_TIME = 15 * 60;

const normalize = (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c;

export default function RecorderModal({
  recorderDrawerOpen,
  setRecorderDrawerOpen,
  handleUpdateAudioList,
  categoryValue,
}) {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [timeLeft, setTimeLeft] = useState(MAX_RECORDING_TIME);
  const [recordedTime, setRecordedTime] = useState(0);
  const [audioPermission, setAudioPermission] = useState(true);
  const [canvas, setCanvas] = useState(null);
  const [fileName, setFileName] = useState("");
  const [reNameOpen, setReNameOpen] = useState(false);
  const [fromDelete, setFromDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const analyserRef = useRef(null);

  const { t } = useTranslation(["add-event"]);

  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    pauseRecording,
    resumeRecording,
    clearBlobUrl,
    previewAudioStream,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    mediaRecorderOptions: { mimeType: "audio/wav" },
  });

  const drawEqualizer = useCallback(() => {
    if (analyserRef.current && canvas && isRecording && !isPaused) {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;
      const BAR_WIDTH = 2;

      const fbcArray = new Uint8Array(analyserRef.current.frequencyBinCount);
      const barCount = WIDTH / 4 || 230;

      analyserRef.current.getByteFrequencyData(fbcArray);

      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      ctx.fillStyle = "#066F90";

      for (var i = 0; i < barCount; i++) {
        const barHeight = normalize(fbcArray[i], 0, 255, 0, HEIGHT);

        if (i === 0) {
          const barPos = WIDTH / 2 - BAR_WIDTH / 2;

          ctx.fillRect(
            barPos,
            HEIGHT / 2 - barHeight / 2,
            BAR_WIDTH,
            barHeight
          );
        } else {
          const barPos = WIDTH / 2 + i * BAR_WIDTH + i * BAR_WIDTH;

          ctx.fillRect(
            barPos,
            HEIGHT / 2 - barHeight / 2,
            BAR_WIDTH,
            barHeight
          );
          ctx.save();
          ctx.scale(-1, 1);
          ctx.translate(-WIDTH, 0);
          ctx.fillRect(
            barPos,
            HEIGHT / 2 - barHeight / 2,
            BAR_WIDTH,
            barHeight
          );
          ctx.restore();
        }
      }

      window.RequestAnimationFrame =
        window.requestAnimationFrame(drawEqualizer) ||
        window.msRequestAnimationFrame(drawEqualizer) ||
        window.mozRequestAnimationFrame(drawEqualizer) ||
        window.webkitRequestAnimationFrame(drawEqualizer);
    }
  }, [canvas, isPaused, isRecording]);

  const handlePauseRecording = () => {
    pauseRecording();
    setRecordedTime(MAX_RECORDING_TIME - timeLeft);
    setIsPaused(true);
  };

  const handleResumeRecording = () => {
    resumeRecording();
    setIsPaused(false);
  };

  const handleStartRecording = () => {
    if (audioPermission) {
      startRecording();
      setIsRecording(true);
    }
  };

  const handleDelteAudio = () => {
    setFromDelete(true);
    stopRecording();
    clearBlobUrl();
    setRecordedTime(0);
    setTimeLeft(MAX_RECORDING_TIME);
    setIsRecording(false);
    setIsPaused(false);
    setRecorderDrawerOpen(false);
    analyserRef.current = null;
  };

  const getUserAudioPermission = async () => {
    try {
      const data = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      if (data.active) {
        setAudioPermission(true);
      }
    } catch (error) {
      console.error(`you got an error: ${error}`);
      setAudioPermission(false);
    }
  };

  useEffect(() => {
    getUserAudioPermission();
  }, []);

  useEffect(() => {
    if (!reNameOpen && mediaBlobUrl && !fromDelete) {
      setReNameOpen(true);
      setIsRecording(false);
    } else if (reNameOpen && mediaBlobUrl && fileName.length) {
      if (mediaBlobUrl) {
        fetch(mediaBlobUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], `${fileName}.wav`, {
              type: "audio/wav",
              lastModified: Date.now(),
            });

            handleUpdateAudioList(file, recordedTime);
            clearBlobUrl();
            setRecorderDrawerOpen(false);
          });
      }
    }
  }, [mediaBlobUrl, reNameOpen]);

  useEffect(() => {
    let intervalId = null;

    if (isRecording) {
      intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
        setRecordedTime(recordedTime + 1);
      }, 1000);
    }

    if ((!isRecording && timeLeft !== MAX_RECORDING_TIME) || isPaused) {
      clearInterval(intervalId);
    }

    if (timeLeft === 0) {
      stopRecording();
    }

    return () => clearInterval(intervalId);
  }, [isRecording, timeLeft, recordedTime, isPaused, status]);

  useEffect(() => {
    if (!previewAudioStream || isPaused || !isRecording) {
      analyserRef.current = null;
    } else if (
      previewAudioStream instanceof MediaStream &&
      !analyserRef.current
    ) {
      const context = new AudioContext();
      const analyser = context.createAnalyser();

      const source = context.createMediaStreamSource(previewAudioStream);

      source.connect(analyser);
      analyserRef.current = analyser;

      if (isRecording) {
        drawEqualizer();
      }
    }
  }, [previewAudioStream, isRecording, drawEqualizer, isPaused]);

  useEffect(() => {
    if (canvas && (isPaused || !isRecording)) {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;

      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, WIDTH, HEIGHT);

      ctx.fillStyle = "#066f9033";
      ctx.fillRect(0, HEIGHT / 2, WIDTH, 2);
    }
  }, [canvas, isPaused, isRecording]);

  const minutes = Math.floor(recordedTime / 60);
  const seconds = recordedTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!fileName.length) {
        setErrorMessage(t("RecordNameRequired"))
        return;
      } else {
        setErrorMessage("")
        setReNameOpen(false);
      }
    } catch (error) {
    } finally {
    }
  };

  return (
    <div>
      {reNameOpen && (
        <BottomSheet
          handleClose={() => {}}
          open={reNameOpen}
          className="top-top"
        >
          <div className="rename-container">
            <div>
              <h1 className="rename-title">{t("Rename your Record")}</h1>
            </div>
            <form onSubmit={onSubmit}>
              <div>
                <h6 className="rename-lable">{t("Recording Title")}</h6>
                <input
                  className="rename-input"
                  type="text"
                  name="fileName"
                  value={fileName}
                  onChange={(e) => {
                    if(e.target.value){
                      setErrorMessage("")
                    } else {
                      setErrorMessage(t("RecordNameRequired"))
                    }
                    setFileName(e.target.value)
                  }}
                  placeholder={t("The First Part")}
                  style={{padding: '10px 10px'}}
                />
                <strong className="rename-input-error">
                  {errorMessage}
                </strong>
              </div>
              <button type="submit" className="rename-btn">
                {t("Save")}
              </button>
            </form>
          </div>
        </BottomSheet>
      )}

      <BottomSheet
        handleClose={() => setRecorderDrawerOpen(false)}
        open={recorderDrawerOpen}
        className="recorder-no-padding"
      >
        <div className="recorder-header">
          <h1 className="recorder-title">{t("Record your Story")}</h1>
          <img
            src={CrossMenu}
            alt={t("close image")}
            onClick={() => setRecorderDrawerOpen(false)}
          />
        </div>
        {categoryValue === "FamilyMemory" && (
          <div>
            <h3 className="recorder-subtitle">
              {t("Here are some hints to record helpfull audio!")}
            </h3>
            <ul className="recorder-list">
              <li className="recorder-item-list">
                {t("What was a life-changing moment that you had?")}
              </li>
              <li className="recorder-item-list">
                {t("What is a favorite family tradition?")}
              </li>
              <li className="recorder-item-list">
                {t("What is something difficult your family has overcome?")}
              </li>
            </ul>
          </div>
        )}

        <div className="recorder-mic">
          <img src={Mic} alt={t("mic image")} />
        </div>

        <div className="recorder-times">
          <h2 className="recorder-time">{formattedTime}</h2>
          {timeLeft <= 60 && (
            <h2 className="recorder-time-left">{`${timeLeft} seconds left!`}</h2>
          )}
        </div>

        <div className="recorder-equalizer-container">
          <canvas
            className="recorder-equalizer-canvas"
            ref={(r) => setCanvas(r)}
          ></canvas>
        </div>

        <div className="recorder-control">
        <div onClick={() => handleDelteAudio()} className="recorder-icon-container">
            <img
              src={RecorderDelete}
              alt={t("Recorder delete")}
              width="42px"
            />
            <span className="recorder-icon-text">{t("Discard")}</span>
          </div> 
        
          {!isRecording ? (
            <img
              src={RecordButton}
              alt={t("Record Button")}
              onClick={() => handleStartRecording()}
            />
          ) : isPaused ? (
            <img
              src={RecorderPlay}
              alt={t("Recorder Play")}
              onClick={() => handleResumeRecording()}
            />
          ) : (
            <img
              src={RecorderPause}
              alt={t("Recorder Pause")}
              onClick={() => handlePauseRecording()}
            />
          )}
          <div onClick={stopRecording} className="recorder-icon-container">
            <img
              src={RecorderDone}
              alt={t("Recorder done")}
              width="42px"
            />
            <span className="recorder-icon-text">{t("Save")}</span>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}